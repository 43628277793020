import { Formik } from 'formik'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Col, Form, Modal, Row } from 'react-bootstrap'
import Select from 'react-select'
import api from '../../../config/AxiosInstance.js'
import * as Common from '../../../utils/Common.js'
import { CreateSoSjSchema } from '../../../utils/validationSchema.js'
import CustomButton from '../../Button/CustomButton'
function ModalAddPickupCommand(props) {
  const [selectItem, setSelectItem] = useState(null)
  const [selectBatch, setSelectBatch] = useState(null)
  const [selectDataItem, setSelectDataItem] = useState(null)
  const [isClearableItem, setIsClearableItem] = useState(true)
  const [isSearchableItem, setIsSearchableItem] = useState(true)
  const [errorPack, setErrorPack] = useState({
    error: false,
    message: ''
  })
  useEffect(() => {
    if (props.show) {
      if (errorPack.error) {
        setErrorPack({
          error: false,
          message: ''
        })
      }
      setSelectDataItem(null)
      setSelectBatch(null)
      setSelectItem(null)
    }
  }, [props.show])
  const handleSubmit = async (values) => {
    try {
      // create new object data
      const objNewValue = {
        item_alias: selectDataItem.item_alias,
        item_code: values.item,
        item_name: selectDataItem.item_name,
        uom: selectDataItem.uom,
        item_batch_id: selectBatch.item_batch_id,
        batch: selectBatch.batch,
        exp_date: selectBatch.exp_date,
        total_pack: values.pack,
        total_qty: values.pack * selectDataItem.conversion_factor
      }
      const res = await api.post('/erp/sales-order/check_slot', objNewValue)
      if (res.status === 200) {
        // add property id
        const newData = { ...res.data.data, id: props.lengthPickupCommands + 1 }
        props.onHandleAddPickupCommands(newData)
        props.onHide()
        setSelectBatch(null)
        setSelectDataItem(null)
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }

  const optionItems = props.soRequests?.reduce((acc, request) => {
    acc.push({
      value: request.item_code,
      label: request.item_name
    })
    return acc
  }, [])

  // options dummy
  const optionBatches = selectDataItem?.batches?.reduce((acc, batch) => {
    acc.push({
      value: batch.item_batch_id,
      label: batch.batch
    })
    return acc
  }, [])

  const totalPackBatch = selectBatch?.slots.reduce((acc, slot) => {
    return acc + slot.total_pack
  }, 0)

  const handleChangeItem = (item) => {
    if (!item) {
      setSelectItem(null)
      setSelectDataItem(null)
      setSelectBatch(null)
    } else {
      const { value } = item
      setSelectItem(value)
      setSelectDataItem(
        props.soRequests.filter(
          (soRequest, index) => soRequest.item_code === value
        )[0]
      )
    }
  }

  // event handler
  const handleInputPack = (value) => {
    const qtyItemSoRequest =
      props?.soRequests.filter(
        (soRequest) => soRequest.item_code === selectDataItem.item_code
      )[0]?.available_qty ?? 0

    const qtyPickupCommand =
      props?.pickupCommands.filter(
        (pickupCommand) => pickupCommand.item_code === selectDataItem.item_code
      )[0]?.available_qty ?? 0
    if (
      value * selectDataItem.conversion_factor >
      qtyItemSoRequest - qtyPickupCommand
    ) {
      setErrorPack({
        error: true,
        message: `Your input ${value} more than ${
          qtyItemSoRequest - qtyPickupCommand
        } available and available total pack on batch ${
          totalPackBatch * selectDataItem.conversion_factor
        }, please input less than equal ${
          qtyItemSoRequest - qtyPickupCommand
        } available`
      })
      return
    }

    if (selectDataItem && value > 0 && totalPackBatch < value) {
      setErrorPack({
        error: true,
        message: `${totalPackBatch} available pack`
      })
    } else {
      setErrorPack({
        error: false,
        message: ''
      })
    }
  }

  return (
    <Modal {...props}>
      <Modal.Header closeButton>
        <Modal.Title>Add Item</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{
            item: '',
            itemAlias: '',
            batch: '',
            pack: 0,
            qty: 0
          }}
          validationSchema={CreateSoSjSchema}
          validateOnChange={true}
          validateOnBlur={true}
          onSubmit={(values) => {
            handleSubmit(values)
          }}
        >
          {({
            handleBlur,
            handleSubmit,
            handleChange,
            setFieldValue,
            values,
            errors,
            touched,
            isValid,
            dirty
          }) => {
            return (
              <Form className="d-grid gap-2" onSubmit={handleSubmit}>
                <Row>
                  <Col md={12}>
                    <Form.Label className="fw-normal">
                      <small className="text-uppercase">Item</small>
                    </Form.Label>
                    <Select
                      options={optionItems}
                      onChange={(selectedOption) => {
                        handleChangeItem(selectedOption)
                        setFieldValue('item', selectedOption?.value)
                        if (!selectedOption) {
                          setFieldValue('batch', { value: '', label: '' })
                          setFieldValue('pack', 0)
                        }
                      }}
                      onBlur={handleBlur}
                      isClearable={isClearableItem}
                      isSearchable={isSearchableItem}
                    />
                    {touched.item && !!errors.item && (
                      <Form.Control.Feedback type="invalid">
                        {errors.item}
                      </Form.Control.Feedback>
                    )}
                  </Col>
                  <Col md={12} className="mt-3">
                    <Form.Label className="fw-normal">
                      <small className="text-uppercase">Item Alias</small>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={selectDataItem?.item_alias ?? ''}
                      disabled={true}
                    />
                  </Col>
                  <Col md={6} className="mt-3">
                    <Form.Label className="fw-normal">
                      <small className="text-uppercase">Batch</small>
                    </Form.Label>
                    <Select
                      options={optionBatches}
                      onChange={(selectedOption) => {
                        setFieldValue('batch', selectedOption?.value)
                        setSelectBatch(
                          selectDataItem?.batches?.filter(
                            (batch) =>
                              batch.item_batch_id === selectedOption?.value
                          )[0]
                        )
                      }}
                      onBlur={handleBlur}
                      isClearable
                      isSearchable
                      value={values.batch?.value}
                      isDisabled={selectDataItem ? false : true}
                    />
                    {touched.batch && !!errors.batch && (
                      <Form.Control.Feedback type="invalid">
                        {errors.batch}
                      </Form.Control.Feedback>
                    )}
                  </Col>
                  <Col md={6} className="mt-3">
                    <Form.Label className="fw-normal">
                      <small className="text-uppercase">EXP Date</small>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      disabled={true}
                      value={
                        selectBatch?.exp_date
                          ? moment(selectBatch.exp_date).format('DD-MM-YYYY')
                          : '-'
                      }
                    />
                  </Col>
                  <Col md={6} className="mt-3">
                    <Form.Label className="fw-normal">
                      <small className="text-uppercase">Pack</small>
                    </Form.Label>
                    <Form.Control
                      type="number"
                      onBlur={handleBlur}
                      value={values.pack}
                      name="pack"
                      onChange={(e) => {
                        setFieldValue('pack', e.target.value)
                        handleInputPack(e.target.value)
                      }}
                      disabled={selectBatch ? false : true}
                    />
                    {touched.pack && !!errors.pack && selectBatch && (
                      <p className="text-danger">{errors.pack}</p>
                    )}
                    {errorPack.error && (
                      <p className="text-danger mt-2 fw-bold">
                        {errorPack.message}
                      </p>
                    )}
                  </Col>
                  <Col md={6} className="mt-3">
                    <Form.Label className="fw-normal">
                      <small className="text-uppercase">Qty</small>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      value={
                        values.pack * (selectDataItem?.conversion_factor ?? 0)
                      }
                      disabled={true}
                    />
                    {touched.qty && !!errors.qty && (
                      <Form.Control.Feedback type="invalid">
                        {errors.qty}
                      </Form.Control.Feedback>
                    )}
                    {errorPack.error && (
                      <p className="text-danger mt-2 fw-bold">
                        {errorPack.message}
                      </p>
                    )}
                  </Col>
                </Row>
                <Modal.Footer className="mt-3">
                  <button className="btn" onClick={() => props.onHide()}>
                    Cancel
                  </button>
                  <CustomButton
                    onClick={handleSubmit}
                    variant={
                      !isValid || !dirty || errorPack.error
                        ? 'secondary'
                        : 'warning'
                    }
                    disabled={!isValid || !dirty || errorPack.error}
                    text={'Add'}
                  />
                </Modal.Footer>
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default ModalAddPickupCommand
