import { faQrcode } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { Col, Container, Row, Table } from 'react-bootstrap'
import { Loader } from 'react-bootstrap-typeahead'
import { Link, useLocation } from 'react-router-dom'
import ModalDownloadQr from '../../../components/modal/pallete/ModalDownloadQr'
import ModalTypeDownloadRack from '../../../components/modal/racks/ModalTypeDownloadRack'
import api from '../../../config/AxiosInstance'
import * as Common from '../../../utils/Common'
const headerTitleStyle = {
  color: 'var(--primary-orange)',
  fontSize: '24px',
  fontWeight: '600'
}

function DetailRack() {
  const { pathname } = useLocation()
  const [rackId, setRackId] = useState(pathname?.split('/')[4])
  const [editId, setEditId] = useState({})
  const [slots, setSlots] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [showDownloadQr, setShowDownloadQr] = useState(false)
  const [showTypeDownload, setShowTypeDownload] = useState(false)
  useEffect(() => {
    fetchDetail(rackId)
  }, [pathname, rackId])
  const fetchDetail = async (rackId) => {
    try {
      setIsLoading(true)
      const response = await api.get(`master/racks/${rackId}/slots/`)
      if (response.data.success) setSlots(response.data.data)
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setIsLoading(false)
    }
  }
  function handleDownloadQr(data) {
    setShowDownloadQr(true)
    setEditId({
      ...editId,
      src: data.qr,
      paletName: data.code,
      id: data.id
    })
  }
  return (
    <>
      <Container>
        <Row>
          <Col md={12}>
            <h3 style={headerTitleStyle}>Rack Detail</h3>
            <p className="text-muted">
              <Link
                to="/setting/racks"
                style={{ textDecoration: 'none' }}
                className="text-muted"
              >
                Racks
              </Link>
            </p>
          </Col>
          <Col md={12}>
            <div className="d-flex flex-row justify-content-end mt-2 mb-2">
              <button
                className="btn text-white"
                style={{ backgroundColor: '#203568' }}
                onClick={() => setShowTypeDownload(true)}
              >
                Print All QR
              </button>
            </div>
          </Col>
          <Col md={12}>
            <Table responsive="sm" hover bordered striped>
              <thead>
                <th className="p-2 align-middle" style={{ border: 'none' }}>
                  Slot List
                </th>
                <th
                  className="p-2 align-middle text-center"
                  style={{ border: 'none' }}
                >
                  Action
                </th>
              </thead>
              <tbody>
                {isLoading ? (
                  <tr>
                    <td className="p-2 align-middle" colSpan={2}>
                      <div className="d-flex flex-row justify-content-center align-items-center">
                        <Loader />
                      </div>
                    </td>
                  </tr>
                ) : (
                  slots?.map((data, i) => (
                    <tr key={i}>
                      <td className="p-2 align-middle">{data.code}</td>
                      <td className="p-2 align-middle" style={{ width: '10%' }}>
                        <div className="d-flex flex-row justify-content-center">
                          <button
                            className="btn btn-secondary ml-2"
                            onClick={() => {
                              handleDownloadQr(data)
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faQrcode}
                              style={{ fontSize: '15px', rotate: '90deg' }}
                              className="fa-flip-horizontal"
                            />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
      <ModalDownloadQr
        show={showDownloadQr}
        onHide={() => {
          setShowDownloadQr(false)
          setEditId({})
        }}
        paletData={editId}
        type={'Slot'}
      />
      <ModalTypeDownloadRack
        show={showTypeDownload}
        onHide={() => {
          setShowTypeDownload(false)
        }}
        slots={slots}
      />
    </>
  )
}

export default DetailRack
