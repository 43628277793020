import { faPencil } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import Loader from '../../../components/atoms/Loader/index'
import RowEmpty from '../../../components/atoms/RowEmpty'
import TableAction from '../../../components/atoms/Table/TableAction'
import TablePagination from '../../../components/atoms/Table/TablePagination'
import api from '../../../config/AxiosInstance'
import '../../../styles/style.css'
import * as Common from '../../../utils/Common'
import MapEditorDetail from './Detail'

const headerTitleStyle = {
  color: 'var(--primary-orange)',
  fontSize: '24px',
  fontWeight: '600'
}

const MapEditor = () => {
  const [metaData, setMetaData] = useState({
    per_page: 10,
    current_page: 1,
    total_row: 0,
    total_page: 0
  })
  const [pageCount, setpageCount] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [mapEditors, setMapEditors] = useState([])
  const [editData, setEditData] = useState({})
  const [step, setStep] = useState(0)
  const handlePageClick = async (data) => {
    const current_page = data.selected + 1
    setMetaData({
      ...metaData,
      current_page: current_page
    })
    window.scrollTo(0, 0)
  }
  useEffect(() => {
    fetchMapEditors()
  }, [metaData.current_page, metaData.per_page])

  function handleOnChangeMeta(e) {
    setMetaData({
      ...metaData,
      per_page: parseInt(e.target.value, 10),
      current_page: 1
    })
  }
  function handleDetailData(id) {
    setEditData(id)
  }
  function handleResetDetailData() {
    setEditData({})
    if (Number(step) === 1) {
      setStep(0)
    }
  }
  function handleStep(value) {
    setStep(value)
  }
  async function fetchMapEditors() {
    setIsLoading(true)
    try {
      const res = await api.get(
        `/settings/map/editor/warehouse/all?per_page=${metaData.per_page}&page=${metaData.current_page}`
      )

      if (res.data.data.length > 0) {
        setMapEditors(res.data.data)
        setMetaData(res.data.metadata)
        setpageCount(res.data.metadata.total_page || 0)
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setIsLoading(false)
    }
  }
  return (
    <div className="container">
      {Object.keys(editData).length === 0 ? (
        <>
          <h3 style={headerTitleStyle}>Map Editor</h3>
          <div className="row mt-3">
            <div className="col-12">
              <Table
                hover
                className='shadow-sm mb-5 bg-white rounded"'
                size="sm"
                style={{ fontSize: '14px' }}
              >
                <thead className="table-secondary">
                  <tr>
                    <th className="p-2 align-middle">No</th>
                    <th className="p-2 align-middle">Warehouse</th>
                    <th className="p-2 align-middle text-end" width="15%">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {isLoading && (
                    <tr>
                      <td colSpan={3}>
                        <Loader />
                      </td>
                    </tr>
                  )}
                  {!isLoading && mapEditors.length === 0 && (
                    <RowEmpty colSpan={3} />
                  )}
                  {!isLoading &&
                    mapEditors.length > 0 &&
                    mapEditors?.map((mapEditor, i) => (
                      <TbodyRow
                        data={mapEditor}
                        key={mapEditor.id}
                        row={
                          (metaData.current_page - 1) * metaData.per_page +
                          i +
                          1
                        }
                        onHandleDetailData={() => handleDetailData(mapEditor)}
                      />
                    ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan={3} className="p-3">
                      <TablePagination
                        meta={metaData}
                        handleOnChangeMeta={handleOnChangeMeta}
                        handlePageChange={handlePageClick}
                      />
                    </td>
                  </tr>
                </tfoot>
              </Table>
            </div>
          </div>
        </>
      ) : (
        <>
          <MapEditorDetail
            data={editData}
            step={step}
            onHandleResetDetailData={() => handleResetDetailData()}
            onHandleStep={handleStep}
          />
        </>
      )}
    </div>
  )
}

function TbodyRow({ data, onHandleDetailData, row }) {
  const iconStyle = {
    transform: 'rotate(0deg)',
    fontSize: '12px'
  }
  return (
    <tr key={data.id}>
      <td className="p-2 align-middle">{row}</td>
      <td className="p-2 align-middle">{data.warehouse_name}</td>
      <td className="p-2 align-middle">
        <TableAction>
          <button
            className="btn btn-primary-orange btn-sm"
            onClick={() => onHandleDetailData()}
          >
            <div className="d-flex flex-row gap-2">
              <div className="d-flex flex-column justify-content-center items-content-center">
                <FontAwesomeIcon
                  icon={faPencil}
                  className="fa-flip-horizontal"
                  style={iconStyle}
                />
              </div>
              <span className="ml-2">Update Map</span>
            </div>
          </button>
        </TableAction>
      </td>
    </tr>
  )
}
export default MapEditor
