import { faPen, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef, useState } from 'react'
import { Card, Col, Container, Form, Row, Table } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import Select from 'react-select'
import Swal from 'sweetalert2'
import RowEmpty from '../../../components/atoms/RowEmpty'
import CustomButton from '../../../components/Button/CustomButton'
import Breadcrumb from '../../../components/Label/Breadcrumb'
import Label from '../../../components/Label/Input'
import ModalUpdateQtyDc from '../../../components/modal/dc/ModalUpdateQtyDc'
import api from '../../../config/AxiosInstance'
import {
  convertDateWithoutTime,
  convertDateWithTime
} from '../../../helpers/constants'
import * as Common from '../../../utils/Common'
function Detail() {
  const selectedSeriesNumberRef = useRef({
    label: '',
    value: ''
  })
  const selectedDeliveryNoteRef = useRef({
    label: '',
    value: ''
  })
  const selectedSalesInvoiceRef = useRef({
    label: '',
    value: ''
  })
  const [modalUpdateItem, setModalUpdateItem] = useState(false)
  const [selectedItem, setSelectedItem] = useState()
  const [detail, setDetail] = useState({})
  const fileInputDeliveryProof = useRef(null)
  const [items, setItems] = useState([])
  const [proof, setProof] = useState([])
  const [seriesNumbers, setSeriesNumbers] = useState([])
  const [selectedSeriesNumber, setSelectedSeriesNumber] = useState('')
  const [loadingSeriesNumber, setLoadingSeriesNumber] = useState(false)
  const [deliveryNotes, setDeliveryNotes] = useState([])
  const [selectedDeliveryNote, setSelectedDeliveryNote] = useState('')
  const [loadingDeliveryNote, setLoadingDeliveryNote] = useState(false)
  const [salesInvoices, setSalesInvoices] = useState([])
  const [selectedSalesInvoice, setSelectedSalesInvoice] = useState('')
  const [loadingSalesInvoice, setLoadingSalesInvoice] = useState(false)
  const { id } = useParams()

  useEffect(() => {
    loadData(id)
  }, [id])

  const loadData = async (id) => {
    try {
      const res = await api.get(`outbound/dcdrafts/detail/${id}`)
      const { data } = res.data
      if (res.data.success) {
        setDetail(data)
        setSelectedSeriesNumber(!data?.series_number ? '' : data?.series_number)
        selectedSeriesNumberRef.current = {
          label: !data?.series_number ? '' : data?.series_number,
          value: !data?.series_number ? '' : data?.series_number
        }

        setSelectedDeliveryNote(
          !data?.series_delivery_note ? '' : data?.series_delivery_note
        )
        selectedDeliveryNoteRef.current = {
          label: !data?.series_delivery_note ? '' : data?.series_delivery_note,
          value: !data?.series_delivery_note ? '' : data?.series_delivery_note
        }
        setSelectedSalesInvoice(
          !data?.series_sales_invoice ? '' : data?.series_sales_invoice
        )
        selectedSalesInvoiceRef.current = {
          label: !data?.series_sales_invoice ? '' : data?.series_sales_invoice,
          value: !data?.series_sales_invoice ? '' : data?.series_sales_invoice
        }
        setItems(data?.items)
        setProof(data?.delivery_proof)
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }

  const loadSeriesNumberOptions = async (inputValue) => {
    try {
      setLoadingSeriesNumber(true)
      const res = await api.get(
        `/outbound/dcdrafts/${detail?.dc_id}/all-series?`,
        {
          params: {
            type: 'Delivery Confirmation',
            q: inputValue
          }
        }
      )

      if (res.data.success) {
        const options = res.data.data.reduce((acc, data) => {
          acc.unshift({
            value: data.name,
            label: data.name
          })
          return acc
        }, [])
        setSeriesNumbers(options)
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setLoadingSeriesNumber(false)
    }
  }
  const loadSeriesNumberFocusOptions = (inputValue) => {
    loadSeriesNumberOptions(inputValue)
  }
  const loadDeliveryNoteOptions = async (inputValue) => {
    try {
      setLoadingDeliveryNote(true)
      const res = await api.get(
        `/outbound/dcdrafts/${detail?.dc_id}/all-series?`,
        {
          params: {
            type: 'Delivery Note',
            q: inputValue
          }
        }
      )

      if (res.data.success) {
        const options = res.data.data.reduce((acc, data) => {
          acc.unshift({
            value: data.name,
            label: data.name
          })
          return acc
        }, [])
        setDeliveryNotes(options)
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setLoadingDeliveryNote(false)
    }
  }
  const loadDeliveryNoteFocusOptions = (inputValue) => {
    loadDeliveryNoteOptions(inputValue)
  }
  const loadSalesInvoiceOptions = async (inputValue) => {
    try {
      setLoadingSalesInvoice(true)
      const res = await api.get(
        `/outbound/dcdrafts/${detail?.dc_id}/all-series?`,
        {
          params: {
            type: 'Sales Invoice',
            q: inputValue
          }
        }
      )

      if (res.data.success) {
        const options = res.data.data.reduce((acc, data) => {
          acc.unshift({
            value: data.name,
            label: data.name
          })
          return acc
        }, [])
        setSalesInvoices(options)
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setLoadingSalesInvoice(false)
    }
  }
  const loadSalesInvoiceFocusOptions = (inputValue) => {
    loadSalesInvoiceOptions(inputValue)
  }

  const handleImageChange = async (e) => {
    try {
      const file = e.target.files[0]
      const res = await api.postForm(
        `outbound/dcdrafts/upload-proof/${detail?.dc_id}`,
        {
          proof: file
        }
      )
      if (res.data.success) {
        Common.showPopUpSuccessMessage(res.data.message)
        loadData(id)
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }

  const handleSubmit = async () => {
    Swal.fire({
      title: 'Send to ERP?',
      text: `This action can't be repeated!`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#f89d1d',
      confirmButtonText: 'Send'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const values = {
            series_number: selectedSeriesNumber,
            series_delivery_noted: selectedDeliveryNote,
            series_sales_invoice: selectedSalesInvoice
          }
          const res = await api.post(
            `/outbound/dcdrafts/${id}/send-erp`,
            values
          )
          if (res.data.success) {
            Common.showPopUpSuccessMessage(res.data.message)
            loadData(id)
          }
        } catch (err) {
          const errMsg =
            err?.response?.data?.errors?.message || 'An error occurred'
          Common.showPopUpErrorMessage(errMsg)
        }
      }
    })
  }

  const handleUpdateItem = (data) => {
    setModalUpdateItem(true)
    setSelectedItem(data)
  }

  return (
    <>
      <Container className="mb-5">
        <Row>
          <Col md={12} className="mt-3">
            <Breadcrumb
              label={detail.wms_dc_no || '-'}
              link="/outbound/dc"
              back="Delivery Confirmation"
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Form onSubmit={handleSubmit}>
            <div>
              <Row className="mb-5">
                <Label
                  label="customer"
                  value={detail.customer || '-'}
                  col={4}
                />
                <Label
                  label="ERP SJ No."
                  value={detail.erp_sj_no || '-'}
                  col={4}
                />
                <Label label="#so" value={detail.so || '-'} col={4} />
                <Label label="source" value={detail.source || '-'} col={4} />
                <Label
                  label="delivery type"
                  value={detail.delivery_type || '-'}
                  col={4}
                />
                <Label label="driver" value={detail.driver || '-'} col={4} />
                <Label
                  label="delivery date"
                  value={
                    !detail.delivery_date
                      ? '-'
                      : convertDateWithoutTime(detail?.delivery_date)
                  }
                  col={4}
                />
                <Label
                  label="ERP DC No."
                  value={detail.erp_dc_no || '-'}
                  col={4}
                />
              </Row>
              <Row style={{ marginTop: '-3rem', marginBottom: '2rem' }}>
                <Col md={4}>
                  <Form.Group>
                    <Form.Label className="fw-bold">
                      <small style={{ fontSize: '13px' }}>
                        SERIES <sup style={{ color: 'red' }}>*</sup>
                      </small>
                    </Form.Label>
                    <Select
                      value={selectedSeriesNumberRef.current}
                      isSearchable={true}
                      isClearable={true}
                      isDisabled={detail?.erp_dc_no ? true : false}
                      isLoading={loadingSeriesNumber}
                      loadingMessage={() => 'Loading...'}
                      options={seriesNumbers}
                      defaultOptions
                      placeholder="Select Series Number"
                      key={1}
                      autoLoad={false}
                      onFocus={() => loadSeriesNumberFocusOptions('')}
                      onInputChange={(e) => {
                        loadSeriesNumberFocusOptions(e)
                      }}
                      onChange={(e) => {
                        if (!e) {
                          e = {
                            value: '',
                            label: ''
                          }
                        }
                        setSelectedSeriesNumber(e?.value)
                        selectedSeriesNumberRef.current = e
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group>
                    <Form.Label className="fw-bold">
                      <small style={{ fontSize: '13px' }}>
                        DELIVERY NOTES <sup style={{ color: 'red' }}>*</sup>
                      </small>
                    </Form.Label>
                    <Select
                      value={selectedDeliveryNoteRef.current}
                      isSearchable={true}
                      isClearable={true}
                      isDisabled={detail?.erp_dc_no ? true : false}
                      isLoading={loadingDeliveryNote}
                      loadingMessage={() => 'Loading...'}
                      options={deliveryNotes}
                      defaultOptions
                      placeholder="Select Delivery Note"
                      key={1}
                      autoLoad={false}
                      onFocus={() => loadDeliveryNoteFocusOptions('')}
                      onInputChange={(e) => {
                        loadDeliveryNoteFocusOptions(e)
                      }}
                      onChange={(e) => {
                        if (!e) {
                          e = {
                            value: '',
                            label: ''
                          }
                        }
                        setSelectedDeliveryNote(e?.value)
                        selectedDeliveryNoteRef.current = e
                      }}
                    />
                  </Form.Group>
                </Col>
                <Col md={4}>
                  <Form.Group>
                    <Form.Label className="fw-bold">
                      <small style={{ fontSize: '13px' }}>
                        SALES INVOICE <sup style={{ color: 'red' }}>*</sup>
                      </small>
                    </Form.Label>
                    <Select
                      value={selectedSalesInvoiceRef.current}
                      isSearchable={true}
                      isClearable={true}
                      isDisabled={detail?.erp_dc_no ? true : false}
                      isLoading={loadingSalesInvoice}
                      loadingMessage={() => 'Loading...'}
                      options={salesInvoices}
                      defaultOptions
                      placeholder="Select Sales Invoice"
                      key={1}
                      autoLoad={false}
                      onFocus={() => loadSalesInvoiceFocusOptions('')}
                      onInputChange={(e) => {
                        loadSalesInvoiceFocusOptions(e)
                      }}
                      onChange={(e) => {
                        if (!e) {
                          e = {
                            value: '',
                            label: ''
                          }
                        }
                        setSelectedSalesInvoice(e?.value)
                        selectedSalesInvoiceRef.current = e
                      }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Table
                    hover
                    responsive="sm"
                    className="shadow-sm mb-5 bg-white rounded"
                    size="sm"
                    style={{ fontSize: '12px' }}
                  >
                    <thead className="table-secondary">
                      <tr>
                        <th className="p-2 align-middle">No</th>
                        <th className="p-2 align-middle">Item</th>
                        <th className="p-2 text-end align-middle">Batch</th>
                        <th className="p-2 text-end align-middle">Total Qty</th>
                        <th className="p-2 text-end align-middle">
                          Confirmed Qty
                        </th>
                        <th className="p-2 text-end align-middle">
                          Difference
                        </th>
                        {!detail.erp_dc_no && (
                          <th className="p-2 text-end align-middle">Action</th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {items.length > 0 ? (
                        items.map((valueDetail, index) => (
                          <tr key={index}>
                            <td className="p-2 align-middle">{index + 1}</td>
                            <td className="p-2 align-middle">
                              {valueDetail?.item || '-'}
                            </td>
                            <td className="p-2 text-end align-middle">
                              {valueDetail?.batch || '-'}
                            </td>
                            <td className="p-2 text-end align-middle">
                              {valueDetail?.total_qty || '-'}
                            </td>
                            <td className="p-2 text-end align-middle">
                              {valueDetail?.confirmed_qty || '-'}
                            </td>
                            <td
                              className="p-2 text-end align-middle"
                              style={{ backgroundColor: '#C9CACD' }}
                            >
                              {valueDetail?.difference_qty || '0'}
                            </td>
                            {!detail.erp_dc_no && (
                              <td className="p-2 text-end align-middle">
                                <button
                                  type="button"
                                  className="btn btn-secondary ml-2"
                                  onClick={() => handleUpdateItem(valueDetail)}
                                >
                                  <FontAwesomeIcon icon={faPen} />
                                </button>
                              </td>
                            )}
                          </tr>
                        ))
                      ) : (
                        <RowEmpty colSpan={7} />
                      )}
                    </tbody>
                  </Table>
                </Col>
              </Row>

              <Row className="mb-5">
                <Col md={12}>
                  <h5 className="text-secondary mb-3">DELIVERY PROOF</h5>
                </Col>
                <div className="d-flex flex-row flex-wrap gap-2 w-100">
                  {proof.length > 0 &&
                    proof.map((image, i) => (
                      <img
                        src={image}
                        alt="Preview"
                        style={{
                          border: '2px solid #dee2e6',
                          borderRadius: '10px',
                          width: '100px',
                          height: '100px',
                          marginBottom: '15px'
                        }}
                      />
                    ))}
                  <div
                    style={{
                      border: '2px solid #dee2e6',
                      borderRadius: '10px',
                      width: '100px',
                      height: '100px',
                      padding: '30px',
                      cursor: 'pointer'
                    }}
                  >
                    <label
                      htmlFor="file"
                      onClick={() => fileInputDeliveryProof.current.click()}
                      style={{ cursor: 'pointer' }}
                    >
                      <FontAwesomeIcon
                        icon={faPlus}
                        size="lg"
                        style={{ marginLeft: '10px' }}
                        className="text-secondary"
                      />
                      <small
                        style={{ marginLeft: '5px' }}
                        className="text-secondary"
                      >
                        Foto
                      </small>
                    </label>
                    <input
                      id="file-upload"
                      type="file"
                      onChange={handleImageChange}
                      ref={fileInputDeliveryProof}
                      hidden={true}
                      accept="image/*"
                    />
                  </div>
                </div>
              </Row>
            </div>

            {!detail?.erp_dc_no && (
              <div className="d-flex justify-content-end mb-5">
                <CustomButton
                  onClick={handleSubmit}
                  variant={
                    detail.erp_dc_no ||
                    selectedSeriesNumber === '' ||
                    selectedDeliveryNote === '' ||
                    selectedSalesInvoice === ''
                      ? 'secondary'
                      : 'warning'
                  }
                  disabled={
                    detail.erp_dc_no ||
                    selectedSeriesNumber === '' ||
                    selectedDeliveryNote === '' ||
                    selectedSalesInvoice === ''
                      ? true
                      : false
                  }
                  text={'Send to ERP'}
                />
              </div>
            )}

            <Row>
              <Col md={12}>
                <Card>
                  <Card.Body>
                    <Row>
                      <Label
                        label="created by"
                        value={!detail?.createdby ? '-' : detail?.createdby}
                        col={3}
                      />
                      <Label
                        label="created at"
                        value={
                          !detail?.createdAt
                            ? '-'
                            : convertDateWithTime(detail?.createdAt)
                        }
                        col={3}
                      />
                      <Label
                        label="last updated by"
                        value={!detail?.updatedby ? '-' : detail?.updatedby}
                        col={3}
                      />
                      <Label
                        label="last updated at"
                        value={
                          !detail?.updatedAt
                            ? '-'
                            : convertDateWithTime(detail?.updatedAt)
                        }
                        col={3}
                      />
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Form>
        </Row>
      </Container>

      <ModalUpdateQtyDc
        show={modalUpdateItem}
        onHide={() => setModalUpdateItem(false)}
        selectedItem={selectedItem}
        refreshdata={() => loadData(id)}
      />
    </>
  )
}

export default Detail
