import {
  faCaretDown,
  faCaretUp,
  faCartShopping,
  faCogs,
  faDatabase,
  faFileArchive,
  faGear,
  faReceipt,
  faTruckFast
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useEffect, useState } from 'react'
import {
  Col,
  Collapse,
  Container,
  Nav,
  OverlayTrigger,
  Popover,
  Row,
  Stack
} from 'react-bootstrap'
import { useNavigate } from 'react-router'
import { useLocation } from 'react-router-dom'
import baj_logo from '../../assets/baj_logo.png'
import { UserContext } from '../../context/userContext'
import '../../styles/style.css'
function Sidebar(props) {
  const { collapsed } = props
  const headMenuStyle = {
    backgroundColor: 'var(--primary-orange)',
    color: 'white'
  }
  const headMenuStyleHover = {
    color: 'var(--primary-grey)'
  }

  const navigate = useNavigate()
  const [state, dispatch] = useContext(UserContext)
  const subMenusData = state.username?.access.reduce((acc, cur) => {
    acc.push({ ...cur, collapse: false })
    return acc
  }, [])
  const [sidebars, setSidebars] = useState(subMenusData)
  const [visiblePopover, setVisiblePopover] = useState(null)
  const [popoverTarget, setPopoverTarget] = useState(null)
  const { pathname } = useLocation()
  const headMenu = pathname.split('/')[1]
  const poHeadMenu = pathname.split('/')[2]
  const routeValue = `${headMenu}/${poHeadMenu}`
  useEffect(() => {
    setSidebars((prevSidebar) =>
      prevSidebar.map((value) =>
        value.menu?.split(' ')[0].toLowerCase() ===
          (headMenu === 'manage' ? 'cms' : headMenu) &&
        /^\/outbound\/plan(\/.*)?$/.test(pathname) === false
          ? { ...value, collapse: true }
          : value
      )
    )
  }, [headMenu])

  // read icon menu
  const icons = (value) => {
    let icon = null
    switch (value) {
      case 'Purchase Order':
        icon = faCartShopping
        break
      case 'Arrival Plan':
      case 'Material Request':
        icon = faReceipt
        break
      case 'Inbound':
      case 'Outbound Plan':
      case 'Outbound':
        icon = faTruckFast
        break
      case 'Master':
        icon = faDatabase
        break
      case 'Setting':
        icon = faGear
        break
      case 'CMS':
        icon = faCogs
        break
      case 'Work Order Plan':
        icon = faFileArchive
        break
      default:
        icon = faCaretUp
    }
    return icon
  }
  const collapseAction = (action) => {
    setSidebars(
      sidebars.map((sidebar) =>
        sidebar.menu === action
          ? { ...sidebar, collapse: !sidebar.collapse }
          : sidebar
      )
    )
  }
  const popover = (submenu) => (
    <Popover id="popover-basic" onMouseLeave={handlePopoverMouseLeave}>
      <Popover.Body>
        {submenu.map((value) => (
          <div>
            {value.slug ? (
              <p
                className="fs-6 cursor-pointer"
                role="button"
                onClick={() => {
                  navigate(`/${value.slug}`, { replace: true })
                  setVisiblePopover(null)
                }}
              >
                {value.menu}
              </p>
            ) : (
              <p className="fs-6 cursor-pointer" role="button">
                {value.menu}
              </p>
            )}
          </div>
        ))}
      </Popover.Body>
    </Popover>
  )
  const handlePopoverClick = (event, menu) => {
    const target = event.currentTarget
    setVisiblePopover(visiblePopover === menu ? null : menu)
    setPopoverTarget(target)
  }

  const handleMouseLeave = (event, menu) => {
    // Hide popover only if mouse leaves both the trigger and the popover
    if (
      event.relatedTarget &&
      event.relatedTarget.closest('.popover') === null
    ) {
      setVisiblePopover(null)
    }
  }

  const handlePopoverMouseLeave = (event) => {
    if (event.relatedTarget && !event.relatedTarget.closest('.btn')) {
      setVisiblePopover(null)
    }
  }
  const regexSidebarActive = (menu, pathname) => {
    const pattern = menu.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
    const patternRegex = new RegExp(`^${pattern}(\\/.*)?$`)
    return patternRegex.test(pathname)
  }
  return (
    <>
      <Container
        className="mt-3 ms-1 overflow-y-auto overflow-x-hidden"
        style={{ height: '100vh' }}
      >
        <Stack className="mb-5">
          <img src={baj_logo} alt="baj" style={{ width: '80%' }} />
        </Stack>
        <Row>
          <>
            {sidebars?.map((sidebar, index) =>
              !sidebar.submenu.length ? (
                <Col md={12} className="mb-3" key={sidebar.sequence}>
                  <Row
                    style={
                      regexSidebarActive(`/${sidebar.slug}`, pathname)
                        ? headMenuStyle
                        : headMenuStyleHover
                    }
                    className="pt-2 pb-2 rounded-3"
                    key={sidebar.sequence}
                  >
                    <Col
                      md={10}
                      key={sidebar.sequence}
                      onClick={() => {
                        navigate(`/${sidebar.slug}`, { replace: true })
                        setVisiblePopover(null)
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      <div
                        className={`ms-1 d-flex ${
                          collapsed ? 'flex-row' : 'flex-column'
                        } align-items-center justify-content-start `}
                      >
                        <FontAwesomeIcon
                          icon={icons(sidebar.menu)}
                          style={{ fontSize: '13px' }}
                          className={`${collapsed ? '' : 'ms-2'}`}
                        />
                        <small
                          className={`ms-3 fw-bold cursor-pointer ${
                            !collapsed ? 'text-center mt-2' : ''
                          }`}
                          style={{
                            fontSize: `${collapsed ? '14px' : '12px'}`
                          }}
                        >
                          {sidebar.menu}
                        </small>
                      </div>
                    </Col>
                  </Row>
                </Col>
              ) : (
                <>
                  <Col
                    md={12}
                    onClick={() => {
                      collapseAction(sidebar?.menu)
                    }}
                    role="button"
                    className="pointer-auto mb-3"
                    key={sidebar.sequence}
                  >
                    <Row
                      style={
                        headMenu === sidebar?.submenu[0].slug?.split('/')[0] &&
                        // pathname !== '/outbound/plan'
                        /^\/outbound\/plan(\/.*)?$/.test(pathname) === false
                          ? headMenuStyle
                          : headMenuStyleHover
                      }
                      className="pt-2 pb-2 rounded-3"
                    >
                      {collapsed && (
                        <>
                          <Col md={10}>
                            <div className={`ms-1 d-flex align-items-center`}>
                              <FontAwesomeIcon
                                icon={icons(sidebar.menu)}
                                style={{ fontSize: '13px' }}
                              />
                              <small className="ms-3 fw-bold fs-6">
                                {sidebar.menu}
                              </small>
                            </div>
                          </Col>
                          <Col md={2} className="me-auto">
                            <FontAwesomeIcon
                              icon={sidebar.collapse ? faCaretUp : faCaretDown}
                              aria-expanded={sidebar.collapse ? true : false}
                              aria-controls={sidebar.menu}
                            />
                          </Col>
                        </>
                      )}
                      {!collapsed && (
                        <OverlayTrigger
                          show={visiblePopover === sidebar.menu}
                          placement="right"
                          overlay={popover(
                            sidebar.submenu.sort(
                              (a, b) => a.sequence - b.sequence
                            )
                          )}
                        >
                          <Col
                            md={12}
                            onClick={(e) => handlePopoverClick(e, sidebar.menu)}
                            onMouseEnter={(e) =>
                              handlePopoverClick(e, sidebar.menu)
                            }
                            onMouseLeave={(e) =>
                              handleMouseLeave(e, sidebar.menu)
                            }
                          >
                            <div
                              className="d-flex flex-column justify-content-center items-content-center gap-2"
                              style={{ marginInlineStart: '-.5rem' }}
                            >
                              <div className="d-flex flex-row justify-content-center gap-4">
                                <FontAwesomeIcon
                                  icon={icons(sidebar.menu)}
                                  style={{ fontSize: '13px' }}
                                />
                              </div>
                              <small
                                className="ms-1 fw-bold text-center"
                                style={{ fontSize: '12px' }}
                              >
                                {sidebar.menu}
                              </small>
                            </div>
                          </Col>
                        </OverlayTrigger>
                      )}
                    </Row>
                  </Col>
                  {collapsed && (
                    <>
                      <Col
                        md={12}
                        className={`${collapsed ? 'ms-4' : 'ms-2'}`}
                        key={sidebar.sequence}
                      >
                        <Collapse
                          in={sidebar.collapse ? true : false}
                          key={sidebar.sequence}
                        >
                          <div id={sidebar.menu} key={sidebar.sequence}>
                            <Row>
                              {sidebar.submenu
                                .filter(
                                  (valueSubmenu) =>
                                    valueSubmenu.menu !==
                                    'Customer Delivery Plan'
                                )
                                .sort((a, b) => a.sequence - b.sequence)
                                .map((submenu, index) => (
                                  <Nav.Link
                                    className="mb-3"
                                    onClick={() =>
                                      submenu.slug
                                        ? navigate(`/${submenu.slug}`, {
                                            replace: true
                                          })
                                        : null
                                    }
                                  >
                                    <small
                                      className={
                                        submenu.slug &&
                                        routeValue ===
                                          `${submenu.slug?.split('/')[0]}/${
                                            submenu.slug?.split('/')[1]
                                          }`
                                          ? `text-orange fw-bold`
                                          : 'text-secondary'
                                      }
                                      style={{
                                        fontSize: `${
                                          collapsed ? '14px' : '12px'
                                        }`
                                      }}
                                    >
                                      {submenu.menu}
                                    </small>
                                  </Nav.Link>
                                ))}
                            </Row>
                          </div>
                        </Collapse>
                      </Col>
                    </>
                  )}
                </>
              )
            )}
          </>
        </Row>
      </Container>
    </>
  )
}

export default Sidebar
