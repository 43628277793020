import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { Col, Container, Form, Nav, Row, Table } from 'react-bootstrap'
import { Loader } from 'react-bootstrap-typeahead'
import { useLocation, useNavigate } from 'react-router-dom'
import TableAction from '../../../components/atoms/Table/TableAction'
import TablePagination from '../../../components/atoms/Table/TablePagination'
import api from '../../../config/AxiosInstance'
import * as Common from '../../../utils/Common'
const headerTitleStyle = {
  color: 'var(--primary-orange)',
  fontSize: '24px',
  fontWeight: '600'
}

const MasterStock = () => {
  const { state } = useLocation()

  const [warehouse, setWarehouse] = useState([])
  const [warehouseid, setWarehouseid] = useState(state?.warehouse_id || '')
  const [stocks, setStocks] = useState([])
  const [itemName, setItemName] = useState(state?.item_name || '')
  const [type, setType] = useState('normal')
  const [view, setView] = useState(false)
  const [loading, setLoading] = useState(false)
  const [meta, setMeta] = useState({
    per_page: 10,
    current_page: 1,
    total_row: 0,
    total_page: 0
  })

  const fetchWarehouse = async () => {
    try {
      const response = await api.get(
        '/master/warehouses/all?is_parent=true&forUser=1'
      )
      setWarehouse(response.data.data)
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }

  const fetchStock = async () => {
    try {
      setLoading(true)
      const response = await api.get(`/master/stocks`, {
        params: {
          per_page: meta.per_page,
          page: meta.current_page,
          warehouse_id: warehouseid,
          type,
          item_name: itemName
        }
      })
      setStocks(response.data.data)
      setMeta(response.data.metadata)
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setLoading(false)
    }
  }

  const handleSelected = () => {
    setMeta((prevMeta) => ({
      ...prevMeta,
      current_page: 1
    }))
    setView((prevView) => !prevView)
  }

  const handleOnChangeMeta = (e) => {
    setMeta((prevMeta) => ({
      ...prevMeta,
      per_page: parseInt(e.target.value, 10)
    }))
  }

  const handleDetail = (code) => {
    const encodedName = encodeURIComponent(code)
    navigate(`/master/stock/${encodedName}`, {
      state: { warehouse_id: warehouseid, item_name: itemName, type }
    })
  }

  const handlePageChange = (data) => {
    setMeta((prevMeta) => ({ ...prevMeta, current_page: data.selected + 1 }))
    window.scrollTo(0, 0)
  }

  useEffect(() => {
    fetchWarehouse()
  }, [])

  useEffect(() => {
    if (warehouseid) {
      fetchStock()
    }
  }, [meta.per_page, meta.current_page, type, view])

  const navigate = useNavigate()
  return (
    <Container>
      <Row>
        <Col>
          <h1 style={headerTitleStyle}>Master Stock</h1>
        </Col>
      </Row>
      <Row className="d-flex flex-row gap-2 gap-md-0 gap-lg-0 align-items-center">
        <Col xs={12} md={4} lg={4}>
          <Form.Group>
            <Form.Label>
              <small
                className="text-secondary fw-bold text-uppercase"
                style={{ fontSize: '11px' }}
              >
                warehouse name
              </small>
            </Form.Label>
            <Form.Select
              name="warehouse"
              id="warehouse"
              value={warehouseid}
              onChange={(e) => setWarehouseid(e.target.value)}
            >
              <option value="">All</option>
              {warehouse.map((warehouse) => (
                <option key={warehouse.id} value={warehouse.id}>
                  {warehouse.warehouse_name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col xs={12} md={6} lg={6}>
          <Form.Group>
            <Form.Label>
              <small
                className="text-secondary fw-bold text-uppercase"
                style={{ fontSize: '11px' }}
              >
                item name
              </small>
            </Form.Label>
            <Form.Control
              value={itemName}
              placeholder="Enter Item Name..."
              onChange={(e) => setItemName(e.target.value)}
            ></Form.Control>
          </Form.Group>
        </Col>
        <Col
          xs={12}
          md={2}
          lg={2}
          className="d-flex justify-content-end justify-content-md-start justify-content-lg-start align-items-end pt-0 pt-md-4 pt-lg-4"
        >
          <button
            className={`btn ${
              warehouseid === '' ? 'btn-secondary' : 'btn-primary-orange'
            } mt-1`}
            onClick={() => handleSelected()}
            disabled={warehouseid === ''}
          >
            View
          </button>
        </Col>
      </Row>
      <Row className="mt-3 mb-3">
        <Col md={12}>
          <Nav variant="underline" defaultActiveKey={type}>
            <Nav.Item>
              <Nav.Link
                eventKey={'normal'}
                onClick={() => setType('normal')}
                style={{ color: `${type === 'normal' ? 'black' : 'grey'}` }}
              >
                Normal
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey={'quarantine'}
                onClick={() => setType('quarantine')}
                style={{ color: `${type === 'quarantine' ? 'black' : 'grey'}` }}
              >
                Quarantine
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <hr />
        </Col>
      </Row>
      <Row className="mt-4 mb-3">
        <h1
          className="text-secondary fw-bold text-uppercase"
          style={{ fontSize: '18px' }}
        >
          item list
        </h1>
      </Row>
      <Row>
        <Table>
          <thead className="table-secondary">
            <tr>
              <th className="align-middle p-3">Item Name</th>
              <th className="align-middle P-3">Item Alias</th>
              <th className="align-middle P-3">Item Code</th>
              <th className="align-middle P-3">Total Pack</th>
              <th className="align-middle P-3">Total Qty</th>
              <th className="align-middle P-3 text-end">Action</th>
            </tr>
          </thead>
          <tbody>
            {loading && (
              <tr>
                <td colSpan={6} className="p-3 text-center">
                  <Loader />
                </td>
              </tr>
            )}
            {!loading && stocks.length === 0 && (
              <tr>
                <td colSpan={6} className="p-3 text-center">
                  No data
                </td>
              </tr>
            )}
            {!loading &&
              stocks.length > 0 &&
              stocks.map((stock) => (
                <tr key={stock.item_id}>
                  <td className="p-3 align-middle">{stock.item_name}</td>
                  <td className="p-3 align-middle">
                    {stock.item_alias ?? '-'}
                  </td>
                  <td className="p-3 align-middle">{stock.item_code}</td>
                  <td className="p-3 align-middle">
                    {stock.total_pack} {stock.uom_pack}
                  </td>
                  <td className="p-3 align-middle">
                    {stock.total_qty} {stock.uom_qty}
                  </td>
                  <td className="p-3 align-middle text-end">
                    <TableAction>
                      <button
                        className="btn btn-secondary"
                        onClick={() => handleDetail(stock.item_id)}
                      >
                        <FontAwesomeIcon
                          icon={faMagnifyingGlass}
                          style={{ fontSize: '14px' }}
                        ></FontAwesomeIcon>
                      </button>
                    </TableAction>
                  </td>
                </tr>
              ))}
          </tbody>
          <tfoot>
            <tr>
              <td className="p-3" colSpan={9}>
                <TablePagination
                  meta={meta}
                  handlePageChange={handlePageChange}
                  handleOnChangeMeta={handleOnChangeMeta}
                />
              </td>
            </tr>
          </tfoot>
        </Table>
      </Row>
    </Container>
  )
}

export default MasterStock
