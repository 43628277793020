import React from 'react'
import { Form } from 'react-bootstrap'

const index = ({
  label,
  name,
  id,
  value,
  handleChange,
  handleBlur,
  touched,
  errors,
  disabled = false,
  required = false,
  options,
  hasData,
  ref,
  col,
  bold = 'bold',
  reff,
  placeholder = ''
}) => (
  <Form.Group
    className={`mb-3 col-12 col-md-${col} col-lg-${col}`}
    controlId={name}
  >
    <Form.Label
      className={`text-muted text-uppercase fw-${bold ? 'bold' : 'normal'}`}
      style={{ fontSize: '13px', letterSpacing: '0.5px' }}
    >
      {label} {required && <sup style={{ color: 'red' }}>*</sup>}
    </Form.Label>
    <Form.Select
      id={id}
      name={name}
      onBlur={handleBlur}
      onChange={handleChange}
      value={value}
      isInvalid={touched[name] && !!errors[name]}
      disabled={disabled}
      required={required}
      options={options}
      ref={reff}
      placeholder={placeholder}
    >
      <option value="">All</option>
      {options.map((option) => (
        <option
          key={option.id || option.value}
          value={option.id || option.value}
          hasData={parseInt(option.hasData)}
        >
          {option.name || option.item_name || option.label}
        </option>
      ))}
    </Form.Select>
    {touched[name] && !!errors[name] && (
      <Form.Control.Feedback type="invalid">
        {errors[name]}
      </Form.Control.Feedback>
    )}
  </Form.Group>
)

export default index
