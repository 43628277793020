import {
  faCaretDown,
  faCaretUp,
  faCircleExclamation,
  faClockRotateLeft
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import {
  Badge,
  Card,
  Col,
  Collapse,
  Container,
  Row,
  Spinner,
  Table
} from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import {
  BadgeStatusCOA,
  BadgeStatusPrLists
} from '../../../components/atoms/StatusLabel'
import Breadcrumb from '../../../components/Label/Breadcrumb'
import Label from '../../../components/Label/Input'
import ModalPrDetailTimeline from '../../../components/modal/pr/ModalPrDetailTimeline'
import api from '../../../config/AxiosInstance'
import {
  convertDateWithoutTime,
  convertDateWithTime
} from '../../../helpers/constants'
import '../../../styles/style.css'
import * as Common from '../../../utils/Common'
function DetailPr() {
  const [open, setOpen] = useState(true)
  const [icon, setIcon] = useState(faCaretUp)
  const [isLoading, setIsLoading] = useState(false)
  const [openVehicle, setOpenVehicle] = useState(true)
  const [iconVehicle, setIconVehicle] = useState(faCaretUp)
  const [openItemCoa, setOpenItemCoa] = useState(true)
  const [iconItemCoa, setIconItemCoa] = useState(faCaretUp)
  const [modalTimeline, setModalTimeline] = useState(false)
  const [detail, setDetail] = useState({})
  const [vehicles, setVehicles] = useState([])
  const [differentDate, setDifferentDate] = useState()
  const [detailInbounds, setDetailInbounds] = useState({})
  const [inbounds, setInbounds] = useState([])
  const [receiptDetails, setReceiptDetails] = useState([])
  const { id } = useParams()
  useEffect(() => {
    loadData(id)
  }, [id])

  const loadData = async (id) => {
    try {
      setIsLoading(true)
      const res = await api.get(`inbound/history/${id}`)
      const { data } = res
      if (data.success) {
        setDetail(data.data)
        setVehicles(data.data?.vehicle)
        setInbounds(data.data?.inbounds)
        setReceiptDetails(data.data?.receipt_details)
        // check validate different date from item coa
        const checkDifferentDate = data.data?.item_coa?.filter(
          (item_coa) =>
            item_coa.coa_ed !== item_coa.label_ed ||
            item_coa.coa_pd !== item_coa.label_pd
        )?.length
        setDifferentDate(checkDifferentDate > 0 ? true : false)
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setIsLoading(false)
    }
  }

  const [dataHistory, setDataHistory] = useState({})

  async function handleTimline(item) {
    setModalTimeline(true)
    setDetailInbounds(item)

    const res = await api.get(`inbound/pr-draft/history/${item.id}`)
    setDataHistory(res.data)
  }

  const statusTextMapping = {
    unassigned: 'unassigned',
    waiting_rnr: 'waiting rnr',
    receiving_progress: 'receiving progress',
    rnr: 'receiving progress',
    waiting_om: 'waiting om',
    request_pnp: 'waiting om',
    waiting_pnp: 'waiting pnp',
    pnp: 'put away',
    put_away: 'put away',
    done: 'done',
    revised: 'waiting rnr'
  }

  const statusCoaMapping = {
    unchecked: 'unchecked',
    match: 'match',
    not_match: 'not match'
  }

  return (
    <>
      <Container className="mb-5">
        {isLoading && (
          <>
            <div>
              <Row>
                <Col md={12}>
                  <div
                    style={{ height: '100vh' }}
                    className="d-flex flex-column justify-content-center"
                  >
                    <div className="d-flex flex-row justify-content-center">
                      <div className="d-flex flex-column gap-3">
                        <div className="d-flex flex-row justify-content-center gap-2">
                          <Spinner animation="grow" variant="warning" />
                          <Spinner animation="grow" variant="warning" />
                          <Spinner animation="grow" variant="warning" />
                          <Spinner animation="grow" variant="warning" />
                          <Spinner animation="grow" variant="warning" />
                        </div>
                        <p
                          className="fw-bold text-center"
                          style={{ fontSize: '13px' }}
                        >
                          Loading...
                        </p>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </>
        )}
        {!isLoading && (
          <>
            <Row>
              <Col md={12} className="mt-3">
                <Breadcrumb
                  label={detail?.pr_draft}
                  labelSub={inbounds[0]?.inbound_code}
                  link="/inbound/history"
                  back="INBOUND HISTORY"
                  type="BadgeStatusPrList"
                  status={
                    detail?.status ? detail?.status.replaceAll('_', ' ') : ''
                  }
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs={12} md={12} lg={12}>
                <Row className="mb-3">
                  <Label label="#po" value={detail?.po_info?.po} col={3} />
                  <Label
                    label="po type"
                    value={detail?.po_info?.po_type}
                    col={3}
                  />
                  <Label
                    label="branch"
                    value={detail?.po_info?.branch}
                    col={3}
                  />
                  <Label
                    label="supplier"
                    value={detail?.po_info?.supplier}
                    col={3}
                  />
                  <Label
                    label="SERIES"
                    value={detail?.po_info?.series}
                    col={3}
                  />
                  <Label
                    label="accepted warehouse"
                    value={detail?.po_info?.destination_warehouse}
                    col={3}
                  />
                  <Label
                    label="received warehouse"
                    value={detail?.po_info?.received_warehouse}
                    col={3}
                  />
                </Row>
                {/* ARRIVAL */}
                <Row className="shadow-sm mb-5 bg-white rounded">
                  <Col md={12} className="mb-2">
                    <h5 className="text-secondary">
                      ARRIVAL INFO
                      <FontAwesomeIcon
                        style={{ cursor: 'pointer' }}
                        icon={icon}
                        className="ms-3"
                        onClick={() => {
                          setOpen(!open)
                          open === true
                            ? setIcon(faCaretDown)
                            : setIcon(faCaretUp)
                        }}
                        aria-expanded={open}
                        aria-controls="example-collapse-text"
                      />
                    </h5>
                  </Col>
                  <Collapse in={open}>
                    <div id="example-collapse-text">
                      <Row className="mb-3">
                        {detail?.po_info?.po_type === 'local' && (
                          <>
                            <Label
                              label="#do"
                              value={detail?.arrival_info?.do}
                              col={3}
                            />
                            <Label
                              label="expedition"
                              value={detail?.arrival_info?.expedition}
                              col={3}
                            />
                            <Label
                              label="pic name"
                              value={detail?.arrival_info?.pic_name}
                              col={3}
                            />
                            <Label
                              label="pic number"
                              value={detail?.arrival_info?.pic_number}
                              col={3}
                            />
                          </>
                        )}
                        {detail?.po_info?.po_type === 'import' && (
                          <>
                            <Label
                              label="#do"
                              value={detail?.arrival_info?.do}
                              col={3}
                            />
                            <Label
                              label="expedition"
                              value={detail?.arrival_info?.expedition}
                              col={3}
                            />
                            <Label
                              label="pib date"
                              value={convertDateWithoutTime(
                                detail?.arrival_info?.pib_date
                              )}
                              col={3}
                            />
                            <Label
                              label="#aju"
                              value={detail?.arrival_info?.aju}
                              col={3}
                            />
                            <Label
                              label="pic name"
                              value={detail?.arrival_info?.pic_name}
                              col={3}
                            />
                            <Label
                              label="pic number"
                              value={detail?.arrival_info?.pic_number}
                              col={3}
                            />
                          </>
                        )}
                      </Row>
                    </div>
                  </Collapse>
                </Row>
                {/* VEHICLE */}
                <Row className="shadow-sm mb-5 bg-white rounded">
                  <Col md={12} className="mb-3">
                    <h5 className="text-secondary">
                      VEHICLE
                      <FontAwesomeIcon
                        style={{ cursor: 'pointer' }}
                        icon={iconVehicle}
                        className="ms-3"
                        onClick={() => {
                          setOpenVehicle(!openVehicle)
                          openVehicle === true
                            ? setIconVehicle(faCaretDown)
                            : setIconVehicle(faCaretUp)
                        }}
                        aria-expanded={openVehicle}
                        aria-controls="example-collapse-text1"
                      />
                    </h5>
                  </Col>
                  <Collapse in={openVehicle}>
                    <div id="example-collapse-text1">
                      <Row>
                        <Col md={12} className="mt-3">
                          <Table
                            hover
                            responsive="sm"
                            className="shadow-sm mb-4 bg-white rounded"
                            size="sm"
                            style={{ fontSize: '12px' }}
                          >
                            <thead className="table-secondary">
                              <tr>
                                <th className="p-3 align-middle">No</th>
                                <th className="p-3" style={{ width: '30%' }}>
                                  Plate No
                                </th>
                                <th className="p-3 text-end align-middle">
                                  SIM
                                </th>
                                <th className="p-3 text-end align-middle">
                                  Validity
                                </th>
                                <th className="p-3 text-end align-middle">
                                  Notes
                                </th>
                                <th className="p-3 text-end align-middle">
                                  STNK
                                </th>
                                <th className="p-3 text-end align-middle">
                                  Validity
                                </th>
                                <th
                                  className="p-3 text-end align-middle"
                                  style={{ width: '20%' }}
                                >
                                  Notes
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {!vehicles?.length && (
                                <tr>
                                  <td
                                    colSpan={9}
                                    className="text-center text-secondary"
                                  >
                                    No Data
                                  </td>
                                </tr>
                              )}
                              {vehicles?.length > 0 &&
                                vehicles?.map((vehicle, index) => (
                                  <tr>
                                    <td className="p-3">{index + 1}</td>
                                    <td className="p-3">{vehicle?.plate_no}</td>
                                    <td className="p-3 text-primary text-end">
                                      {vehicle?.sim?.split('/').pop()}
                                    </td>
                                    <td className="p-3 text-end">
                                      {vehicle?.sim_validity
                                        ? 'Valid'
                                        : 'Invalid'}
                                    </td>
                                    <td className="p-3 text-end">
                                      {vehicle?.sim_notes ||
                                      vehicle?.sim_notes !== ''
                                        ? vehicle?.sim_notes
                                        : '-'}
                                    </td>
                                    <td className="p-3 text-primary text-end">
                                      {vehicle?.stnk?.split('/').pop()}
                                    </td>
                                    <td className="p-3 text-end">
                                      {vehicle?.stnk_validity
                                        ? 'Valid'
                                        : 'Invalid'}
                                    </td>
                                    <td className="p-3 text-end">
                                      {vehicle?.stnk_notes ||
                                      vehicle?.stnk_notes !== ''
                                        ? vehicle?.stnk_notes
                                        : '-'}
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </Table>
                        </Col>
                      </Row>
                    </div>
                  </Collapse>
                </Row>
                {/* ITEM & COA */}
                <Row className="shadow-sm mb-5 bg-white rounded">
                  <Col md={12} className="mb-3">
                    <h5 className="text-secondary">
                      <FontAwesomeIcon
                        icon={faCircleExclamation}
                        className="text-danger me-2"
                        hidden={differentDate ? false : true}
                      />
                      ITEM & COA
                      <FontAwesomeIcon
                        style={{ cursor: 'pointer' }}
                        icon={iconItemCoa}
                        className="ms-3"
                        onClick={() => {
                          setOpenItemCoa(!openItemCoa)
                          openItemCoa === true
                            ? setIconItemCoa(faCaretDown)
                            : setIconItemCoa(faCaretUp)
                        }}
                        aria-expanded={openItemCoa}
                        aria-controls="example-collapse-text2"
                      />
                    </h5>
                  </Col>
                  <Collapse in={openItemCoa}>
                    <div id="example-collapse-text2">
                      <Row className="">
                        <Col md={12} className="mt-3">
                          <Table
                            hover
                            responsive="sm"
                            className="shadow-sm mb-4 bg-white rounded"
                            size="sm"
                            style={{ fontSize: '12px' }}
                          >
                            <thead className="table-secondary">
                              <tr>
                                <th className="p-3 align-middle">No</th>
                                <th
                                  className="p-3 align-middle"
                                  style={{ width: '20%' }}
                                >
                                  Item
                                </th>
                                <th className="p-3 align-middle">Status COA</th>
                                <th className="p-3 text-end align-middle">
                                  Batch
                                </th>
                                <th className="p-3 text-end align-middle">
                                  Total Pack
                                </th>
                                <th className="p-3 text-end align-middle">
                                  Total Qty
                                </th>
                                <th className="p-3 text-end align-middle">
                                  COA Document
                                </th>
                                <th className="p-3 text-end align-middle">
                                  COA MD
                                </th>
                                <th className="p-3 text-end align-middle">
                                  COA ED
                                </th>
                                <th className="p-3 text-end align-middle">
                                  Label MD
                                </th>
                                <th className="p-3 text-end align-middle">
                                  Label ED
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {detail?.item_coa?.map((item, i) => {
                                return (
                                  <>
                                    <tr key={i}>
                                      <td className="p-3 align-middle">
                                        {i + 1}
                                      </td>
                                      <td className="p-3 align-middle">
                                        {' '}
                                        {item?.item?.item_name}
                                      </td>
                                      <td className="p-3 align-middle">
                                        <BadgeStatusCOA
                                          text={
                                            statusCoaMapping[
                                              item?.coa_status
                                            ] || 'unchecked'
                                          }
                                        />
                                      </td>
                                      <td className="p-3 text-end align-middle">
                                        {item?.batch ? item?.batch?.batch : '-'}
                                      </td>
                                      <td className="p-3 text-end align-middle">
                                        {item?.total_pack !== null
                                          ? item?.total_pack
                                          : '-'}
                                      </td>
                                      <td className="p-3 text-end align-middle">
                                        {item?.total_qty !== null
                                          ? item?.total_qty
                                          : '-'}
                                      </td>
                                      <td className="p-3 text-end align-middle text-primary">
                                        <a
                                          href={
                                            item.coa_doc ? item.coa_doc : '#'
                                          }
                                          target="_blank"
                                          className="text-decoration-none"
                                          rel="noreferrer"
                                        >
                                          {item?.coa_doc
                                            ? item?.coa_doc
                                                .split('/')
                                                .splice(-1)
                                            : '-'}
                                        </a>
                                      </td>
                                      <td className="p-3 text-end align-middle">
                                        {item.coa_pd
                                          ? convertDateWithoutTime(item.coa_pd)
                                          : '-'}
                                      </td>
                                      <td className="p-3 text-end align-middle">
                                        {item.coa_ed
                                          ? convertDateWithoutTime(item.coa_ed)
                                          : '-'}
                                      </td>
                                      <td className="p-3 text-end align-middle">
                                        {!item.label_pd
                                          ? '-'
                                          : convertDateWithoutTime(
                                              item.label_pd
                                            )}
                                      </td>
                                      <td className="p-3 text-end align-middle">
                                        {!item.label_ed
                                          ? '-'
                                          : convertDateWithoutTime(
                                              item.label_ed
                                            )}
                                      </td>
                                    </tr>
                                  </>
                                )
                              })}
                            </tbody>
                          </Table>
                        </Col>
                        <Col
                          xs={12}
                          md={12}
                          lg={12}
                          hidden={
                            detail?.items_coa?.filter(
                              (coa) => coa.coa_status === 'not_match'
                            ).length > 0
                              ? false
                              : true
                          }
                          style={{
                            marginTop: '-10px',
                            marginBottom: '10px'
                          }}
                        >
                          <small className="text-danger fw-bold">
                            Different date / Not complete !
                          </small>
                        </Col>
                      </Row>
                    </div>
                  </Collapse>
                </Row>
                {/* INBOUND */}
                {inbounds.length > 0 &&
                  inbounds?.map((data, i) => (
                    <>
                      <Row key={i} className="mb-5 shadow-sm bg-white rounded">
                        <Col md={6}>
                          <Row>
                            <Col md={12}>
                              <h5 className="fw-bold">
                                <FontAwesomeIcon
                                  icon={faClockRotateLeft}
                                  style={{
                                    fontSize: '14px',
                                    marginRight: '5px',
                                    cursor: 'pointer'
                                  }}
                                  onClick={() => handleTimline(data)}
                                />
                                {data.inbound_code}
                                <sup
                                  style={{
                                    fontSize: '12px',
                                    marginLeft: '5px',
                                    marginTop: '-5px'
                                  }}
                                >
                                  {!data?.erp_code && (
                                    <BadgeStatusPrLists
                                      text={
                                        statusTextMapping[data.status] || 'done'
                                      }
                                    />
                                  )}
                                </sup>
                                {data?.erp_code && (
                                  <>
                                    <span className="text-warning fw-bold ms-2">
                                      - {data.erp_code}
                                    </span>
                                  </>
                                )}
                              </h5>
                            </Col>
                            <Col
                              xs={6}
                              md={6}
                              lg={6}
                              className="mt-1 mt-md-4 mb-md-4 mt-lg-4 mb-lg-4"
                            >
                              {data.staging}
                            </Col>
                          </Row>
                        </Col>
                        <Col
                          xs={12}
                          md={6}
                          lg={6}
                          className="fw-bold text-start text-md-end text-lg-end mt-1 mt-md-2 mt-lg-2"
                          style={{ fontSize: '12px' }}
                        >
                          {data.vehicle} -{' '}
                          {data.status === 'done'
                            ? 'DONE'
                            : statusTextMapping[data.status].toUpperCase()}
                        </Col>

                        <>
                          <Col md={12}>
                            <Table
                              hover
                              responsive="sm"
                              className="shadow-sm mb-4 bg-white rounded"
                              size="sm"
                              style={{ fontSize: '12px' }}
                            >
                              <thead className="table-secondary">
                                <tr>
                                  <th className="p-3 align-middle">Palet</th>

                                  <th
                                    className="p-3 align-middle"
                                    style={{ width: '20%' }}
                                  >
                                    Item
                                  </th>
                                  <th className="p-3 text-end align-middle">
                                    Batch
                                  </th>
                                  <th className="p-3 text-end align-middle">
                                    Pack
                                  </th>
                                  <th className="p-3 text-end align-middle">
                                    Condition
                                  </th>
                                  <th className="p-3 text-end align-middle">
                                    Location
                                  </th>
                                  <th className="p-3 text-end align-middle">
                                    Destination Area
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {data?.items?.map((item, i) => {
                                  return (
                                    <tr key={i}>
                                      <td
                                        className="p-3 align-middle"
                                        style={{
                                          backgroundColor: `${
                                            item.from_mobile
                                              ? '#FFCCCC'
                                              : 'white'
                                          }`
                                        }}
                                      >
                                        {item.palet_id
                                          ? '#' + item.palet_id
                                          : '-'}
                                      </td>
                                      <td
                                        className="p-3 align-middle"
                                        style={{
                                          backgroundColor: `${
                                            item.from_mobile
                                              ? '#FFCCCC'
                                              : 'white'
                                          }`
                                        }}
                                      >
                                        {item.item_name}
                                      </td>
                                      <td
                                        className="p-3 text-end align-middle"
                                        style={{
                                          backgroundColor: `${
                                            item.from_mobile
                                              ? '#FFCCCC'
                                              : 'white'
                                          }`
                                        }}
                                      >
                                        {item.batch}
                                      </td>
                                      <td
                                        className="p-3 text-end align-middle"
                                        style={{
                                          backgroundColor: `${
                                            item.from_mobile
                                              ? '#FFCCCC'
                                              : 'white'
                                          }`
                                        }}
                                      >
                                        {item?.total_pack}
                                      </td>
                                      <td
                                        className="p-3 text-end align-middle"
                                        style={{
                                          backgroundColor: `${
                                            item.from_mobile
                                              ? '#FFCCCC'
                                              : 'white'
                                          }`
                                        }}
                                      >
                                        <Badge
                                          bg={
                                            item?.condition === 'damaged'
                                              ? 'danger'
                                              : 'success'
                                          }
                                          className="text-uppercase fw-bold p-2"
                                        >
                                          {item?.condition === 'damaged'
                                            ? 'rusak'
                                            : 'Baik'}
                                        </Badge>
                                      </td>
                                      <td
                                        className="p-3 text-end align-middle"
                                        style={{
                                          backgroundColor: `${
                                            item.from_mobile
                                              ? '#FFCCCC'
                                              : 'white'
                                          }`
                                        }}
                                      >
                                        {item.location}
                                      </td>
                                      <td
                                        className="p-3 align-middle"
                                        style={{
                                          backgroundColor: `${
                                            item.from_mobile
                                              ? '#FFCCCC'
                                              : 'white'
                                          }`
                                        }}
                                      >
                                        {item?.area && (
                                          <p className="p-2 text-end d-flex h-100 justify-content-end mt-3">
                                            {item?.area}
                                          </p>
                                        )}
                                      </td>
                                    </tr>
                                  )
                                })}
                              </tbody>
                            </Table>
                          </Col>
                        </>
                      </Row>
                    </>
                  ))}
                {/* ITEM RECEIPT DETAILS */}
                <Row>
                  <Col md={12} className="mb-3">
                    <h5 className="fw-bold">ITEM RECEIPT DETAILS</h5>
                  </Col>
                  <Col md={12} className="">
                    <Table
                      hover
                      responsive="sm"
                      className="shadow-sm mb-5 bg-white rounded"
                      size="sm"
                      style={{ fontSize: '12px' }}
                    >
                      <thead className="table-secondary">
                        <tr>
                          <th
                            className="p-3 align-middle"
                            style={{ width: '30%' }}
                          >
                            Item
                          </th>
                          <th className="p-3 align-middle">Batch</th>
                          <th className="p-3 text-end align-middle">DO Pack</th>
                          <th className="p-3 text-end align-middle">DO Qty</th>
                          <th className="p-3 text-end align-middle">
                            Accepted Pack
                          </th>
                          <th className="p-3 text-end align-middle">
                            Accepted Qty
                          </th>
                          <th className="p-3 text-end align-middle">
                            Quarantine Pack
                          </th>
                          <th className="p-3 text-end align-middle">
                            Quarantine Qty
                          </th>
                          <th className="p-3 text-end align-middle">
                            Rejected Pack
                          </th>
                          <th className="p-3 text-end align-middle">
                            Rejected Qty
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {!receiptDetails.length && (
                          <tr>
                            <td
                              className="p-3 align-middle fw-bold text-center"
                              colSpan={10}
                            >
                              No data
                            </td>
                          </tr>
                        )}
                        {receiptDetails.length > 0 &&
                          receiptDetails.map((receipt_detail) => (
                            <tr>
                              <td className="p-3 align-middle">
                                {receipt_detail?.item_name}
                              </td>
                              <td className="p-3 align-middle">
                                {receipt_detail?.batch}
                              </td>
                              <td className="p-3 text-end align-middle">
                                {receipt_detail?.do_pack}
                              </td>
                              <td className="p-3 text-end align-middle">
                                {receipt_detail?.do_qty}
                              </td>
                              <td className="p-3 text-end align-middle">
                                {receipt_detail?.good}
                              </td>
                              <td className="p-3 text-end align-middle">
                                {receipt_detail?.good_qty}
                              </td>
                              <td className="p-3 text-end align-middle">
                                {receipt_detail?.damaged}
                              </td>
                              <td className="p-3 text-end align-middle">
                                {receipt_detail?.damaged_qty}
                              </td>
                              <td className="p-3 text-end align-middle">
                                {receipt_detail?.reject}
                              </td>
                              <td className="p-3 text-end align-middle">
                                {receipt_detail?.reject_qty}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
                {/* CREATED AT UPDATED AT */}
                <Row>
                  <Col md={12}>
                    <Card>
                      <Card.Body>
                        <Row>
                          <Label
                            label="created by"
                            value={detail?.created_by ?? '-'}
                            col={3}
                          />
                          <Label
                            label="created at"
                            value={
                              detail?.created_at
                                ? convertDateWithTime(detail?.created_at)
                                : '-'
                            }
                            col={3}
                          />
                          <Label
                            label="last updated by"
                            value={detail?.updated_by ?? '-'}
                            col={3}
                          />
                          <Label
                            label="last updated at"
                            value={
                              detail?.updated_at
                                ? convertDateWithTime(detail?.updated_at)
                                : '-'
                            }
                            col={3}
                          />
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        )}
      </Container>
      <ModalPrDetailTimeline
        show={modalTimeline}
        onHide={() => setModalTimeline(false)}
        detailinbounds={detailInbounds}
        datahistory={dataHistory}
        refreshdata={() => loadData(id)}
      />
    </>
  )
}

export default DetailPr
