import React, { useEffect, useState, useRef } from 'react'
import { Col, Container, Form, Row, Table, Button } from 'react-bootstrap'
import api from '../../../config/AxiosInstance'
import * as Common from '../../../utils/Common'
import Loader from '../../../components/atoms/Loader'
import Pagination from '../../../components/atoms/Pagination/Index'
import ModalTypePrp from '../../../components/modal/prp/ModalTypePrp'
import Select from 'react-select'

const headerTitleStyle = {
  color: 'var(--primary-orange)',
  fontSize: '24px',
  fontWeight: '600'
}

const titleStyle = {
  fontSize: '12px',
  letterSpacing: '0.5px'
}

const initialMeta = {
  per_page: 10,
  current_page: 1,
  total_row: 0,
  total_page: 0
}

const btnOrange = {
  backgroundColor: 'var(--primary-orange)',
  borderColor: 'var(--primary-orange)',
  color: 'white'
}

const PurchaseReceiptPlan = () => {
  const [pageCount, setPageCount] = useState(0)
  const [purchasePlan, setPurchasePlan] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [meta, setMeta] = useState(initialMeta)
  const [prpName, setPrpName] = useState('')
  const [dataCreatePrp, setDataCreatePrp] = useState({})
  const [loading, setLoading] = useState(false)
  const [filter, setFilter] = useState({
    startDate: '',
    endDate: ''
  })
  const { per_page, current_page } = meta
  const [selectedPrinciple, setSelectedPrinciple] = useState('')
  const [principles, setPrinciples] = useState([])
  const refSrcPrinciples = useRef()
  const [selectedWarehouse, setSelectedWarehouse] = useState('')
  const [warehouses, setWarehouses] = useState([])
  const refSrcWarehouses = useRef()

  useEffect(() => {
    fetchPRP(
      per_page,
      current_page,
      selectedPrinciple,
      selectedWarehouse,
      filter.startDate,
      filter.endDate
    )
  }, [per_page, current_page])

  useEffect(() => {
    getPrinciples()
    getWarehouses()
  }, [])

  async function fetchPRP(
    per_page,
    current_page,
    selectedPrinciple,
    selectedWarehouse
  ) {
    setLoading(true)
    try {
      const res = await api.get(`inbound/purchase-order/prp`, {
        params: {
          page: current_page,
          per_page,
          principle: selectedPrinciple || '',
          dest_warehouse: selectedWarehouse || '',
          start_date: filter.startDate || '',
          end_date: filter.endDate || ''
        }
      })
      const { data, metadata } = res.data
      setPurchasePlan(data)
      setMeta(metadata)
      setPageCount(metadata.total_page || 0)
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setLoading(false)
    }
  }

  const getPrinciples = async () => {
    try {
      const options = [{ value: '', label: 'All' }]
      const res = await api.get(`/inbound/purchase-order/prp/principles`)
      const { data } = res.data
      data.map((data) =>
        options.push({ value: data.supplier, label: data.supplier })
      )
      setPrinciples(options)
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }

  const getWarehouses = async () => {
    try {
      const options = [{ value: '', label: 'All' }]
      const res = await api.get(`/inbound/purchase-order/prp/dest-warehouses`)
      const { data } = res.data
      data.map((data) =>
        options.push({ value: data.destination, label: data.destination })
      )
      setWarehouses(options)
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }

  const handleOnChangeMeta = (e) => {
    setMeta((prevMeta) => ({
      ...prevMeta,
      per_page: parseInt(e.target.value, 10),
      current_page: 1
    }))
  }

  const handlePageChange = (data) => {
    setMeta((prevMeta) => ({
      ...prevMeta,
      current_page: parseInt(data.selected + 1)
    }))
    window.scrollTo(0, 0)
  }

  const handleSelected = async () => {
    setLoading(true)
    try {
      const page = 1
      setMeta((prevMeta) => ({
        ...prevMeta,
        current_page: page
      }))
      fetchPRP(
        per_page,
        current_page,
        selectedPrinciple,
        selectedWarehouse,
        filter.startDate,
        filter.endDate
      )
    } catch (err) {
      Common.showPopUpErrorMessage(
        err?.response?.data?.errors?.message || 'An error occurred'
      )
    } finally {
      setLoading(false)
    }
  }

  const handleReset = () => {
    refSrcPrinciples.current?.clearValue()
    refSrcWarehouses.current?.clearValue()
    setFilter({ startDate: '', endDate: '' })
    setMeta({
      per_page: 10,
      current_page: 1,
      total_row: 0,
      total_page: 0
    })
  }

  const handleCreate = (code, data) => {
    setPrpName(code)
    setDataCreatePrp(data)
    setShowModal(true)
  }

  return (
    <Container>
      <Row>
        <Col>
          <h3 style={headerTitleStyle} className="mb-3">
            Purchase Receipt Plan
          </h3>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md={4}>
          <Form.Group>
            <Form.Label className="text-secondary fw-bold" style={titleStyle}>
              <small>PRINCIPLE</small>
            </Form.Label>
            <Select
              isClearable
              defaultValue={selectedPrinciple}
              onChange={(e) => {
                if (!e) {
                  e = {
                    target: refSrcPrinciples,
                    value: ''
                  }
                }
                setSelectedPrinciple(e?.value)
              }}
              options={principles}
              isSearchable
              ref={refSrcPrinciples}
            />
          </Form.Group>
        </Col>
        <Col md={4}>
          <Form.Group>
            <Form.Label className="text-secondary fw-bold" style={titleStyle}>
              <small>DESTINATION WAREHOUSE</small>
            </Form.Label>
            <Select
              isClearable
              defaultValue={selectedWarehouse}
              onChange={(e) => {
                if (!e) {
                  e = {
                    target: refSrcWarehouses,
                    value: ''
                  }
                }
                setSelectedWarehouse(e?.value)
              }}
              options={warehouses}
              isSearchable
              ref={refSrcWarehouses}
            />
          </Form.Group>
        </Col>
        <Col md={2}>
          <Form.Group className="mb-3">
            <Form.Label className="text-secondary fw-bold" style={titleStyle}>
              <small>START DATE</small>
            </Form.Label>
            <input
              type="date"
              name="startDate"
              id="startDate"
              className="form-control"
              value={filter.startDate}
              onChange={(e) =>
                setFilter((prevFilter) => ({
                  ...prevFilter,
                  startDate: e.target.value
                }))
              }
              style={{ height: '40px' }}
            />
          </Form.Group>
        </Col>
        <Col md={2}>
          <Form.Group className="mb-3">
            <Form.Label className="text-secondary fw-bold" style={titleStyle}>
              <small>END DATE</small>
            </Form.Label>
            <input
              type="date"
              name="endDate"
              id="endDate"
              className="form-control"
              value={filter.endDate}
              onChange={(e) =>
                setFilter((prevFilter) => ({
                  ...prevFilter,
                  endDate: e.target.value
                }))
              }
              style={{ height: '40px' }}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md={6} className="mt-3"></Col>
        <Col md={6} className="text-end">
          <Button
            type="button"
            className="btn btn-secondary text-white me-2"
            onClick={() => handleReset()}
          >
            Reset Filter
          </Button>
          <Button style={btnOrange} onClick={() => handleSelected()}>
            Apply Filter
          </Button>
        </Col>
      </Row>
      <Table
        responsive="sm"
        hover
        size="sm"
        style={{ fontSize: '14px' }}
        className="shadow-sm bg-white rounded mt-3 mb-5"
      >
        <thead className="table-secondary">
          <tr>
            <th className="p-2 align-middle">#PO</th>
            <th className="p-2 align-middle">Plan Date</th>
            <th className="p-2 align-middle">Item Code</th>
            <th className="p-2 align-middle">Qty PO</th>
            <th className="p-2 align-middle">Qty PR</th>
            <th className="p-2 align-middle">Sisa</th>
            <th className="p-2 align-middle">Principle</th>
            <th className="p-2 align-middle">Destination Warehouse</th>
            <th className="p-2 align-middle">Action</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan={9}>
                <Loader />
              </td>
            </tr>
          ) : (
            purchasePlan.map((purchase, index) => (
              <tr key={index}>
                <td className="p-2 align-middle">{purchase.name}</td>
                <td className="p-2 align-middle">{purchase.plan_date}</td>
                <td className="p-2 align-middle">{purchase.item_code}</td>
                <td className="p-2 align-middle">{purchase.quantity}</td>
                <td className="p-2 align-middle">{purchase.quantity}</td>
                <td className="p-2 align-middle">
                  {purchase.remaining_receive_quantity}
                </td>
                <td className="p-2 align-middle">{purchase.supplier}</td>
                <td className="p-2 align-middle">{purchase.destination}</td>
                <td className="p-2 align-middle">
                  <button
                    className="btn btn-primary-blue"
                    onClick={() => handleCreate(purchase.name, purchase)}
                  >
                    + PR DRAFT
                  </button>
                </td>
              </tr>
            ))
          )}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={5} className="p-3">
              <div className="d-flex align-items-center">
                <span className="mr-2">Show</span>
                <select
                  name="per_page"
                  className="form-select"
                  aria-label="show-page"
                  style={{ width: '60px' }}
                  value={meta.per_page}
                  onChange={handleOnChangeMeta}
                >
                  {[10, 25, 50, 100].map((value) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </select>
                <span className="ml-2">
                  Entries | {meta.current_page} to {meta.per_page} of{' '}
                  {meta.total_row} entries
                </span>
              </div>
            </td>
            <td colSpan={4} className="p-3">
              <div className="d-flex justify-content-end">
                <Pagination
                  pageCount={pageCount}
                  onPageChange={handlePageChange}
                  page={meta.current_page}
                />
              </div>
            </td>
          </tr>
        </tfoot>
      </Table>

      <ModalTypePrp
        show={showModal}
        onHide={() => setShowModal(false)}
        code={prpName}
        data={dataCreatePrp}
      />
    </Container>
  )
}

export default PurchaseReceiptPlan
