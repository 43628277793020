import { faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Formik } from 'formik'
import Multiselect from 'multiselect-react-dropdown'
import { useEffect, useState } from 'react'
import { Col, Form, Modal, Row } from 'react-bootstrap'
import Select from 'react-select'
import Toggle from 'react-toggle'
import api from '../../../../config/AxiosInstance'
import '../../../../styles/style.css'
import * as Common from '../../../../utils/Common'
import { MapEditorSchema } from '../../../../utils/validationSchema'
import CustomButton from '../../../Button/CustomButton'
function FormUpdateRack(props) {
  const [activeToggle, setActiveToggle] = useState(false)
  const [racks, setRacks] = useState([])
  const [areas, setAreas] = useState([])
  const [inputArea, setInputArea] = useState('')
  const [typeSuggestion, setTypeSuggestion] = useState('')
  const [suggestions, setSuggestions] = useState([])
  const [detail, setDetail] = useState({})
  useEffect(() => {
    if (props?.warehouseId && props?.show) {
      const controller = new AbortController()
      fetchRacks(props.warehouseId, controller)
      fetchShowMapData()
      fetchAreas(props?.warehouseId)
      // clean up effect function
      return function () {
        controller.abort()
      }
    }
  }, [props.warehouseId])

  const fetchShowMapData = async () => {
    try {
      const res = await api.get(
        `/settings/map/editor/slot/${props?.dataShowMap}`
      )
      setDetail(res.data.data)
      setActiveToggle(res.data.data.is_active)
      setTypeSuggestion(res.data.data.type)
      fetchTypeSuggestion(res.data.data.type, '')
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }
  const fetchRacks = async (warehouseId, controller) => {
    try {
      const values = {
        warehouse_id: warehouseId,
        type: 'rack'
      }
      const res = await api.post(`/settings/map/editor/rack/all`, values, {
        signal: controller.signal
      })

      const optionValue = []
      if (res.data.data.length > 0) {
        res.data.data.map((rack) =>
          optionValue.push({
            value: rack.id,
            label: rack.name
          })
        )
      }
      setRacks(optionValue)
    } catch (err) {
      if (err.message !== 'canceled') {
        const errMsg =
          err?.response?.data?.errors?.message || 'An error occurred'

        Common.showPopUpErrorMessage(errMsg)
      }
    }
  }

  const fetchTypeSuggestion = async (type, query) => {
    try {
      let optionValue = []
      if (type === 'item') {
        const res = await api.get(`/master/items/all`, {
          params: { q: query }
        })
        if (res.data.data.length > 0) {
          res.data.data.map((item) =>
            optionValue.push({
              value: item.item_code,
              label: item.item_code
            })
          )
        }
      }

      if (type === 'category') {
        const res = await api.get(`/master/categories/all`, {
          params: { name: query }
        })
        if (res.data.data.length > 0) {
          res.data.data.map((category) =>
            optionValue.push({
              value: category.id,
              label: category.name
            })
          )
        }
      }

      setSuggestions(optionValue)
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'

      Common.showPopUpErrorMessage(errMsg)
    }
  }

  const fetchAreas = async () => {
    try {
      const res = await api.post(`settings/map/editor/area/all`, {
        warehouse_id: props?.warehouseId
      })
      const optionValue = []
      if (res.data.data.length > 0) {
        res.data.data.map((area) =>
          optionValue.push({
            value: area.id,
            label: area.name
          })
        )
      }
      setAreas(optionValue)
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'

      Common.showPopUpErrorMessage(errMsg)
    }
  }

  const handleInputAreaChange = (newValue) => {
    setInputArea(newValue)
  }
  return (
    <Formik
      initialValues={{
        rack: racks.find((rack) => rack.label === detail.rack_name),
        beam: { value: detail?.beam_name, label: detail?.beam_name },
        slot: { value: detail?.level_name, label: detail?.level_name },
        area: { value: detail?.area_id, label: detail?.area_name },
        type: typeSuggestion,
        type_id: detail?.types
      }}
      enableReinitialize={true}
      validationSchema={MapEditorSchema}
      validateOnChange={true}
      validateOnBlur={true}
      onSubmit={(values) => {
        values.activeToggle = activeToggle
        values.id = detail?.id
        props.handleSubmit(values)
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        errors,
        touched,
        isValid,
        dirty,
        setFieldValue,
        setFieldTouched
      }) => {
        return (
          <Form className="d-grid gap-2" onSubmit={handleSubmit}>
            <Row>
              <Col md={12}>
                <h5>
                  Slot Detail <sup style={{ color: 'red' }}>*</sup>
                </h5>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Form.Group>
                  <Form.Label
                    className="text-muted"
                    style={{ fontSize: '13px' }}
                  >
                    RACK
                  </Form.Label>
                  <Select
                    options={racks}
                    onBlur={() => setFieldTouched('rack')}
                    onChange={(e) => {
                      setFieldValue('rack', e)

                      if (!e) {
                        setFieldValue('beam', '')
                        setFieldValue('slot', '')
                        setFieldValue('area', '')
                        setFieldValue('type', '')
                        setFieldValue('type_id', [])
                      }
                    }}
                    value={values.rack}
                    placeholder="Search racks..."
                    isClearable
                    isDisabled={true}
                    isSearchable
                  />
                  {touched.rack && !!errors.rack && (
                    <p className="text-danger mt-2">{errors.rack}</p>
                  )}
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group>
                  <Form.Label
                    className="text-muted"
                    style={{ fontSize: '13px' }}
                  >
                    BEAM
                  </Form.Label>
                  <Form.Select
                    aria-label="beam"
                    name="beam"
                    disabled={true}
                    style={{ height: '38px' }}
                  >
                    <option value={detail?.beam_name}>
                      {detail?.beam_name}
                    </option>
                  </Form.Select>
                  {touched.beam && !!errors.beam && (
                    <p className="text-danger mt-2">{errors.beam}</p>
                  )}
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group>
                  <Form.Label
                    className="text-muted"
                    style={{ fontSize: '13px' }}
                  >
                    SLOT
                  </Form.Label>

                  <Form.Select
                    aria-label="slot"
                    name="slot"
                    disabled={true}
                    style={{ height: '38px' }}
                  >
                    <option value={detail?.code_name}>
                      {detail?.code_name}
                    </option>
                  </Form.Select>
                  {touched.slot && !!errors.slot && (
                    <p className="text-danger mt-2">{errors.slot}</p>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3 mt-2 ">
              <Col md={12}>
                <Form.Group>
                  <Form.Label
                    className="text-muted text-uppercase"
                    style={{ fontSize: '13px' }}
                  >
                    Item Suggestion
                  </Form.Label>
                  <div className="d-flex flex-row gap-3 mt-2">
                    <div className="d-flex flex-row gap-2">
                      <Form.Check.Input
                        inline
                        id="itemSuggestionOne"
                        name="itemSuggestion"
                        type="radio"
                        className="access-management"
                        checked={values.type === 'item'}
                        onChange={() => {
                          setTypeSuggestion('item')
                          fetchTypeSuggestion('item', '')
                          setFieldValue('type', 'item')
                          setFieldValue(
                            'type_id',
                            detail?.type === 'item' ? detail?.type_id : []
                          )
                        }}
                        disable={detail?.type_editable ? false : true}
                      ></Form.Check.Input>
                      <Form.Check.Label
                        className="ml-2"
                        htmlFor="itemSuggestionOne"
                      >
                        Define by Item Code
                      </Form.Check.Label>
                    </div>

                    <div className="d-flex flex-row gap-2 ml-3">
                      <Form.Check.Input
                        inline
                        id="itemSuggestionTwo"
                        name="itemSuggestion"
                        type="radio"
                        checked={values.type === 'category'}
                        className="access-management"
                        onChange={() => {
                          setTypeSuggestion('category')
                          fetchTypeSuggestion('category', '')
                          setFieldValue('type', 'category')
                          setFieldValue(
                            'type_id',
                            detail?.type === 'category' ? detail?.types : []
                          )
                        }}
                        disable={detail?.type_editable ? false : true}
                      ></Form.Check.Input>
                      <Form.Check.Label
                        className="ml-2"
                        htmlFor="itemSuggestionTwo"
                      >
                        Define by Category
                      </Form.Check.Label>
                    </div>
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Form.Group className="mb-3">
                  <Multiselect
                    customCloseIcon={
                      <span className="ml-3" style={{ cursor: 'pointer' }}>
                        <FontAwesomeIcon icon={faClose} />
                      </span>
                    }
                    onSearch={(e) => {
                      fetchTypeSuggestion(
                        typeSuggestion,
                        e?.length === 0 ? '' : e
                      )
                    }}
                    placeholder="Item Code or Category"
                    options={suggestions}
                    displayValue={'label'}
                    onSelect={(e) => {
                      setFieldTouched('type_id')
                      setFieldValue('type_id', e)
                      fetchTypeSuggestion(typeSuggestion, '')
                    }}
                    selectedValues={
                      typeSuggestion === detail?.type ? values.type_id : []
                    }
                    onRemove={(e) => {
                      setFieldValue('type_id', e.length === 0 ? [] : e)
                    }}
                    style={{
                      chips: {
                        background: 'inherit',
                        borderRadius: '5px',
                        color: 'black',
                        border: '1px solid #F1F2F7'
                      },
                      searchBox: {
                        width: '100%'
                      },
                      customClose: {
                        cursor: 'pointer'
                      }
                    }}
                    disable={detail?.type_editable ? false : true}
                  />
                  {touched.type_id && !!errors.type_id && (
                    <p className="text-danger mt-2">{errors.type_id}</p>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group>
                  <Form.Label
                    className="text-muted"
                    style={{ fontSize: '13px' }}
                  >
                    Area
                  </Form.Label>
                  <Select
                    options={
                      inputArea !== ''
                        ? areas.filter((area) => area.label == inputArea)
                        : areas
                    }
                    onBlur={() => setFieldTouched('area')}
                    onInputChange={handleInputAreaChange}
                    onChange={(e) => {
                      setFieldValue('area', e)
                    }}
                    value={values.area}
                    placeholder="Search areas..."
                    isClearable
                    isSearchable
                    isDisabled={detail?.type_editable ? false : true}
                  />
                  {touched.area && !!errors.area && (
                    <p className="text-danger mt-2">{errors.area}</p>
                  )}
                </Form.Group>
              </Col>
              <Col md={2}>
                <Form.Label className="text-muted" style={{ fontSize: '13px' }}>
                  Active
                </Form.Label>
                <label className="d-block">
                  <Toggle
                    className="actived"
                    icons={false}
                    onChange={() => setActiveToggle((active) => !active)}
                    checked={activeToggle}
                    disabled={detail?.type_editable ? false : true}
                  />
                </label>
              </Col>
            </Row>
            <Modal.Footer className="mt-3">
              <CustomButton
                text={'Remove'}
                variant={'white'}
                classNameValue={'text-black'}
                onClick={() => props.handleRemoveCoordinateMap(detail?.id)}
              />
              <CustomButton
                onClick={handleSubmit}
                variant={'warning'}
                disabled={!isValid}
                text={'Update'}
              />
            </Modal.Footer>
          </Form>
        )
      }}
    </Formik>
  )
}

export default FormUpdateRack
