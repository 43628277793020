import { faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { Button, Form, OverlayTrigger, Popover, Table } from 'react-bootstrap'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import api from '../../../config/AxiosInstance'
import { DELIVERY_TYPES } from '../../../helpers/constants/index'
import '../../../styles/style.css'
import * as Common from '../../../utils/Common'
import ModalActionRemove from '../../../components/modal/CustomerDeliveryPlan/ModalActionRemove'
import ModalCancelSjDraft from '../../../components/modal/CustomerDeliveryPlan/ModalCancelSjDraft'
import ModalAddPickupCommand from '../../../components/modal/Sj/ModalAddPickupCommand'
import ModalUpdatePickupCommand from '../../../components/modal/Sj/ModalUpdatePickupCommand'
import RowEmpty from '../../../components/atoms/RowEmpty'
import Label from '../../../components/Label/Input'

function Create() {
  const { state, pathname } = useLocation()
  const navigate = useNavigate()
  // style
  const headerTitleStyle = {
    color: 'var(--primary-orange)',
    fontSize: '24px',
    fontWeight: '600'
  }
  const labelFormStyle = {
    fontSize: '13px',
    letterSpacing: '0.5px'
  }

  // state
  const [modalAddShow, setModalAddShow] = useState(false)
  const [modalRemoveShow, setModalRemoveShow] = useState(false)
  const [modalCancelSjShow, setModalCancelSjShow] = useState(false)
  const [modalUpdateShow, setModalUpdateShow] = useState(false)
  const [detailSo, setDetailSo] = useState({})
  const [pickupCommands, setPickupCommands] = useState([])
  const [pickupCommandId, setPickupCommandId] = useState(0)
  const [deliveryType, setDeliveryType] = useState(DELIVERY_TYPES[0].value)
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const {
    so,
    customer,
    delivery_date,
    branch,
    src,
    delivery_address,
    so_requests
  } = detailSo
  // event
  useEffect(() => {
    if (!state?.resultCustomerDeliveryPlan)
      navigate('/outbound/customer/delivery/plan', { replace: true })
    const timeout = setTimeout(() => window.scrollTo(0, 0), 0)
    getSoDetail()
    if (state?.pickupCommands) {
      setPickupCommands(state?.pickupCommands)
    }
    return () => clearTimeout(timeout)
  }, [pathname, state])
  const handleModalRemoveShow = (value) => {
    setPickupCommandId(value)
    setModalRemoveShow(true)
  }
  const handleModalUpdateShow = (value) => {
    setModalUpdateShow(true)
    setPickupCommandId(value)
  }

  const getSoDetail = async () => {
    try {
      const res = await api.post(
        '/erp/sales-order/detail',
        state.resultCustomerDeliveryPlan
      )
      setDetailSo(res.data.data)
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }
  const handleAddPickupCommands = (value) => {
    setPickupCommands((pickupCommand) => [...pickupCommand, value])
  }
  const handleUpdatePickupCommands = (id, value) => {
    setPickupCommands((pickupCommands) =>
      pickupCommands.map((pickupCommand) =>
        pickupCommand.id === id ? value : pickupCommand
      )
    )
  }
  const handleRemovePickupCommands = (value) => {
    setPickupCommands(
      pickupCommands.filter((pickupCommand) => pickupCommand.id !== value)
    )
    navigate('', {
      state: {
        resultCustomerDeliveryPlan: state.resultCustomerDeliveryPlan,
        pickupCommands: pickupCommands.filter(
          (pickupCommand) => pickupCommand.id !== value
        )
      }
    })
  }
  const handleRedirectSlotLocation = (value) => {
    navigate('/outbound/sj/create/location', {
      state: {
        resultCustomerDeliveryPlan: state.resultCustomerDeliveryPlan,
        pickupCommandId: value,
        pickupCommands: pickupCommands
      }
    })
  }

  const handleSubmit = async () => {
    try {
      setLoadingSubmit(true)
      const values = {
        so,
        customer,
        delivery_date: moment(delivery_date).format('YYYY-MM-DD'),
        items: so_requests?.reduce((acc, pickupCommand) => {
          acc.push(pickupCommand?.item_code)
          return acc
        }, []),
        delivery_type: deliveryType,
        pickup_command: pickupCommands?.reduce((acc, pickupCommand) => {
          acc.push({
            item_code: pickupCommand?.item_code,
            item_batch_id: pickupCommand?.item_batch_id,
            total_qty: Number(pickupCommand?.total_qty),
            total_pack: Number(pickupCommand?.total_pack),
            location: pickupCommand?.locations?.slots
              .filter((slotFilter) => slotFilter.is_checked)
              .reduce((accSlot, slot) => {
                accSlot.push({
                  id: slot?.slot_id,
                  total_pack: Number(slot?.total_pack),
                  rnr_staging_area_id: slot?.rnr_staging_area_id,
                  name: slot?.name
                })
                return accSlot
              }, [])
          })
          return acc
        }, [])
      }

      const res = await api.post('/outbound/sjdraft', values)
      if (res.status === 200) {
        navigate('/outbound/customer/delivery/plan', {
          state: { messageSuccess: true },
          replace: true
        })
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setLoadingSubmit(false)
    }
  }

  const checkItemCodeLength = () => {
    const itemCodeSoRequestLength = so_requests?.reduce((acc, so_request) => {
      if (acc.indexOf(so_request?.item_code) === -1) {
        acc.push(so_request?.item_code)
      }
      return acc
    }, []).length
    const pickupCommandLength = pickupCommands?.reduce((acc, pickupCommand) => {
      if (acc.indexOf(pickupCommand?.item_code) === -1) {
        acc.push(pickupCommand?.item_code)
      }
      return acc
    }, []).length

    return itemCodeSoRequestLength === pickupCommandLength
  }

  const propsSubmit = () => {
    if (loadingSubmit) {
      return { disabled: true }
    }
    return { onClick: () => handleSubmit() }
  }
  return (
    <>
      <div className="container mb-5">
        <div className="row">
          <div className="col">
            <h4 style={headerTitleStyle}>Create SJ</h4>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item sj-item">
                  <Link
                    to="/outbound/customer/delivery/plan"
                    className="text-decoration-none"
                  >
                    <span className="text-black fw-bold">
                      CUSTOMER DELIVERY PLAN
                    </span>
                  </Link>
                </li>
                <li
                  className="breadcrumb-item sj-item active"
                  aria-current="page"
                >
                  CREATE SJ DRAFT
                </li>
              </ol>
            </nav>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <Label label="#so" value={so} />
          </div>
          <div className="col-6">
            <Label label="customer" value={customer} />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Label label="branch" value={branch} />
          </div>
          <div className="col">
            <Label label="Source Warehouse" value={src} />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Form.Group className="mb-3" controlId="addressdelivsjdraft">
              <Form.Label
                className="text-uppercase fw-bold"
                style={labelFormStyle}
              >
                DELIVERY ADDRESS
              </Form.Label>
              <div
                dangerouslySetInnerHTML={{ __html: delivery_address }}
                style={{ backgroundColor: '#e9ecef', padding: '1rem' }}
                className="w-100 rounded"
              />
            </Form.Group>
          </div>
        </div>
        <div className="row">
          <div className="col-4">
            <Label
              label="delivery date"
              value={moment(delivery_date).format('DD-MM-YYYY')}
            />
          </div>
          <div className="col-3">
            <Form.Group className="mb-3" controlId="delivtypesjdraft">
              <Form.Label
                className="text-uppercase fw-bold"
                style={labelFormStyle}
              >
                DELIVERY TYPE
              </Form.Label>
              <Form.Select
                aria-label="select-delivery-type"
                onChange={(e) => setDeliveryType(e.target.value)}
              >
                {DELIVERY_TYPES.map((devType, index) => (
                  <option value={devType.value} key={devType.id}>
                    {devType.label}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </div>
        </div>
      </div>
      <div style={{ borderTop: '3px solid #F1F2F7' }}></div>
      <div className="container">
        <div className="row mt-4">
          <h6 style={{ color: '#828282' }} className="fw-bold">
            SO REQUEST
          </h6>
          <div className="col-12 mt-3">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th className="p-2 align-middle">No</th>
                  <th className="p-2 align-middle">Item</th>
                  <th className="p-2 align-middle">Item Alias</th>
                  <th className="p-2 align-middle">Item Code</th>
                  <th className="p-2 align-middle text-end">Batch</th>
                  <th className="p-2 align-middle text-end">Total Qty</th>
                  <th className="p-2 align-middle text-end">UOM</th>
                </tr>
              </thead>
              <tbody>
                {!so_requests?.length && <RowEmpty colSpan={7} />}
                {so_requests?.length &&
                  so_requests?.map((soRequest, index) => (
                    <tr key={soRequest.item_name}>
                      <td className="p-2 align-middle">{index + 1}</td>

                      <OverlayTrigger
                        trigger={['hover', 'click']}
                        key={'bottom'}
                        placement={'bottom'}
                        overlay={
                          <Popover id={`popover-positioned-bottom`}>
                            {/* <Popover.Header as="h3">{`Popover right`}</Popover.Header> */}
                            <Popover.Body>
                              <p className="text-center fw-bold text-uppercase">
                                item name
                              </p>
                              <p className="text-center">
                                {soRequest.item_name}
                              </p>
                              <p className="text-center fw-bold text-uppercase">
                                item alias
                              </p>
                              <p className="text-center">
                                {soRequest.item_alias ?? '-'}
                              </p>
                              <p className="text-center fw-bold text-uppercase">
                                item code
                              </p>
                              <p className="text-center">
                                {soRequest.item_code}
                              </p>
                            </Popover.Body>
                          </Popover>
                        }
                      >
                        <td className="p-2 align-middle">
                          <p style={{ width: '50%' }} className="text-truncate">
                            {soRequest.item_name}
                          </p>
                        </td>
                      </OverlayTrigger>
                      <td className="p-2 align-middle">
                        {soRequest.item_alias ?? '-'}
                      </td>
                      <td className="p-2 align-middle">
                        {soRequest.item_code}
                      </td>
                      <td className="p-2 align-middle text-end">
                        {soRequest.batch_no ?? '-'}
                      </td>
                      <td className="p-2 align-middle text-end">
                        {soRequest.stock_qty}
                      </td>
                      <td className="p-2 align-middle text-end">
                        {soRequest.stock_uom}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </div>
        <div className="row mb-3 mt-2">
          <div className="col-12">
            <div className="d-flex">
              <div
                style={{
                  backgroundColor: '#8FC9FF',
                  height: '15px',
                  width: '15px',
                  marginTop: '.2rem'
                }}
              ></div>
              <span className="ml-2 text-muted">Need Extend</span>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-5">
            <div className="d-flex align-items-center h-100 mt-1">
              <h6 style={{ color: '#828282' }} className="fw-bold">
                PICK UP COMMAND
              </h6>
            </div>
          </div>
          <div className="col">
            <div className="d-flex justify-content-end">
              <button
                className="btn btn-primary-orange"
                onClick={() => setModalAddShow(true)}
              >
                Add
              </button>
            </div>
          </div>

          <div className="col-12 mt-3">
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th className="p-2 align-middle">No</th>
                  <th className="p-2 align-middle">Item</th>
                  <th className="p-2 align-middle">Item Alias</th>
                  <th className="p-2 align-middle">Item Code</th>
                  <th className="p-2 align-middle text-end">Batch</th>
                  <th className="p-2 align-middle text-end">Expired</th>
                  <th className="p-2 align-middle text-end">Total Qty</th>
                  <th className="p-2 align-middle text-end">Total Pack</th>
                  <th className="p-2 align-middle">Location</th>
                  <th className="p-2 align-middle text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {!pickupCommands.length && <RowEmpty colSpan={10} />}
                {pickupCommands.length > 0 &&
                  pickupCommands.map((pickupCommand, index) => (
                    <tr key={index}>
                      <td className="p-2 align-middle">{index + 1}</td>
                      <td className="p-2 align-middle">
                        {pickupCommand.item_name}
                      </td>
                      <td className="p-2 align-middle">
                        {pickupCommand.item_alias ?? '-'}
                      </td>
                      <td className="p-2 align-middle">
                        {pickupCommand.item_code}
                      </td>
                      <td className="p-2 align-middle text-end">
                        {pickupCommand.batch}
                      </td>
                      <td className="p-2 align-middle text-end">
                        {pickupCommand.expired
                          ? moment(pickupCommand.expired).format('DD-MM-YYYY')
                          : '-'}
                      </td>
                      <td className="p-2 align-middle text-end">
                        {pickupCommand.total_qty}
                      </td>
                      <td className="p-2 align-middle text-end">
                        {pickupCommand.total_pack}
                      </td>
                      <td className="p-2 align-middle" width={'10%'}>
                        <p
                          className="text-truncate text-primary"
                          style={{ maxWidth: '80px', cursor: 'pointer' }}
                          onClick={() =>
                            handleRedirectSlotLocation(pickupCommand.id)
                          }
                          title={pickupCommand.location}
                        >
                          {pickupCommand.location}
                        </p>
                      </td>
                      <td className="p-2 align-middle text-center">
                        <button
                          className="btn btn-danger btn-sm"
                          onClick={() =>
                            handleModalRemoveShow(pickupCommand.id)
                          }
                        >
                          <FontAwesomeIcon
                            icon={faTrash}
                            className="fa-flip-horizontal"
                          />
                        </button>
                        <button
                          className="btn btn-secondary btn-sm ml-2"
                          onClick={() =>
                            handleModalUpdateShow(pickupCommand.id)
                          }
                        >
                          <FontAwesomeIcon
                            icon={faPencilAlt}
                            className="fa-flip-horizontal"
                            style={{ transform: 'rotate(5deg)' }}
                          />
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
      <div className="row mb-3 mt-2">
        <div className="col-12">
          <div className="d-flex">
            <div
              style={{
                backgroundColor: '#FF8F8F',
                height: '15px',
                width: '15px',
                marginTop: '.2rem'
              }}
            ></div>
            <span className="ml-2 text-muted">Less Items</span>
          </div>
        </div>
      </div>
      <div style={{ borderTop: '3px solid #F1F2F7' }}></div>
      <div className="container mb-5">
        <div className="row mt-3">
          <div className="col">
            <button className="btn" onClick={() => setModalCancelSjShow(true)}>
              <span style={{ color: '#C12727' }} className="fw-bold">
                Cancel
              </span>
            </button>
          </div>
          <div className="col">
            <div className="d-flex justify-content-end">
              {!checkItemCodeLength() && (
                <Button variant="secondary" disabled={true}>
                  Submit
                </Button>
              )}
              {checkItemCodeLength() && (
                <Button
                  variant="transparent"
                  className="btn-primary-orange"
                  {...propsSubmit()}
                >
                  {loadingSubmit ? 'Loading...' : 'Submit'}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
      <ModalAddPickupCommand
        show={modalAddShow}
        onHide={() => setModalAddShow(false)}
        soRequests={so_requests}
        onHandleAddPickupCommands={handleAddPickupCommands}
        lengthPickupCommands={pickupCommands.length}
        pickupCommands={pickupCommands}
      />
      <ModalUpdatePickupCommand
        show={modalUpdateShow}
        onHide={() => setModalUpdateShow(false)}
        soRequests={so_requests}
        pickupCommands={pickupCommands}
        pickupCommandId={pickupCommandId}
        onHandleUpdatePickupCommands={handleUpdatePickupCommands}
      />
      <ModalActionRemove
        show={modalRemoveShow}
        onHide={() => setModalRemoveShow(false)}
        pickupCommandId={pickupCommandId}
        onHandleRemovePickupCommands={handleRemovePickupCommands}
      />
      <ModalCancelSjDraft
        show={modalCancelSjShow}
        onHide={() => setModalCancelSjShow(false)}
      />
    </>
  )
}
export default Create
