import { faPlusSquare } from '@fortawesome/free-regular-svg-icons'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import { Button, Form, Modal, Row } from 'react-bootstrap'
import api from '../../../config/AxiosInstance'
import * as Common from '../../../utils/Common'
import DatePickerComponent from '../../Input/DatePicker'
function UpdateCoa(props) {
  const { detailCoa } = props
  const [data, setData] = useState({
    ...detailCoa,
    fileName: detailCoa?.coa_doc?.split('/').pop(),
    file: null
  })

  // Update state when detailCoa prop changes
  useEffect(() => {
    if (detailCoa) {
      setData({
        ...detailCoa,
        fileName: detailCoa.coa_doc?.split('/').pop(),
        file: null
      })
    }
  }, [detailCoa])
  const fileCoaRef = useRef(null)
  const removeFile = () => {
    setData((prevData) => ({ ...prevData, fileName: null, file: null }))
  }
  const handleChangeInput = (e) => {
    const { name, files, value } = e.target
    if (name === 'fileName') {
      const size = e.target.files[0].size / (1024 * 1024)
      const typeFile = e.target.files[0].type
      if (size > 10) {
        Common.showPopUpErrorMessage('Cant upload file more than 10 MB')
        return
      } else if (typeFile !== 'application/pdf') {
        Common.showPopUpErrorMessage('File upload PDF')
        return
      }
      setData((prevData) => ({
        ...prevData,
        [name]: files[0].name,
        file: files[0]
      }))
    } else {
      setData((prevData) => ({ ...prevData, [name]: value }))
    }
  }

  const handleSubmit = async () => {
    try {
      const formData = new FormData()
      // override data file upload to key docs
      formData.append('coa_file', data.file)
      formData.append('coa_pd', moment(data.coa_pd).format('YYYY-MM-DD'))
      formData.append('coa_ed', moment(data.coa_ed).format('YYYY-MM-DD'))
      formData.append(
        'label_ed',
        data.label_ed ? moment(data.label_ed).format('YYYY-MM-DD') : ''
      )
      formData.append(
        'label_pd',
        data.label_pd ? moment(data.label_pd).format('YYYY-MM-DD') : ''
      )

      const res = await api.postForm(
        `/inbound/pr-draft/coa/${data.id}`,
        formData
      )

      if (res.data.success) {
        Common.showPopUpSuccessMessage(res.data.message)
        props.onHide()
        props.refreshdata()
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }
  const buttonSubmit = () => {
    if (data?.coa_status === 'match') {
      return {
        variant: 'secondary',
        disabled: true
      }
    }
    if (data?.coa_status === 'unchecked' && !data.fileName) {
      return {
        variant: 'secondary',
        disabled: true
      }
    }
    if (
      data?.coa_status === 'not_match' &&
      (!data.fileName ||
        !data.coa_pd ||
        !data.coa_ed ||
        !data?.label_pd ||
        !data?.label_ed)
    ) {
      return {
        variant: 'secondary',
        disabled: true
      }
    }
    return {
      variant: 'warning',
      disabled: false
    }
  }
  return (
    <div>
      <Modal
        {...props}
        centered
        onHide={() => {
          props.onHide()
          setData((prevData) => ({
            ...prevData,
            fileName: detailCoa?.coa_doc?.split('/').pop(),
            file: null
          }))
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Update COA</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 className="fw-bold">{detailCoa?.item?.item_name}</h5>
          <p>{detailCoa?.batch?.batch}</p>
          <Form>
            <Form.Group className="mb-3" controlId="file-coa">
              <Form.Label
                style={{ fontSize: '11px' }}
                className="text-muted fw-bold"
              >
                COA
              </Form.Label>
              {!data.fileName && (
                <div>
                  <FontAwesomeIcon
                    icon={faPlusSquare}
                    style={{ fontSize: '22px' }}
                    onClick={() => fileCoaRef.current.click()}
                    className="cursor-pointer"
                    role="button"
                  />
                  <input
                    type="file"
                    ref={fileCoaRef}
                    hidden
                    name="fileName"
                    onChange={(e) => handleChangeInput(e)}
                    accept="application/pdf, image/png, image/jpg, image/jpeg"
                  />
                </div>
              )}
              {data.fileName && (
                <div
                  style={{
                    padding: '.7rem',
                    borderRadius: '3px',
                    border: '1px solid #F1F2F7'
                  }}
                >
                  <div className="d-flex flex-row gap-2">
                    <div className="d-flex flex-column justify-content-center">
                      <FontAwesomeIcon
                        icon={faXmark}
                        style={{
                          fontSize: '16px'
                        }}
                        className="text-muted cursor-pointer"
                        role="button"
                        onClick={removeFile}
                      />
                    </div>
                    <span>{data.fileName}</span>
                  </div>
                </div>
              )}
            </Form.Group>
            <Row>
              <DatePickerComponent
                label="md"
                name="coa_pd"
                value={data?.coa_pd}
                handleChange={(e) => handleChangeInput(e)}
                col={6}
                disabled={data?.coa_status === 'not_match' ? false : true}
              />
              <DatePickerComponent
                label="ed"
                name="coa_ed"
                value={data?.coa_ed}
                handleChange={(e) => handleChangeInput(e)}
                col={6}
                disabled={data?.coa_status === 'not_match' ? false : true}
              />
            </Row>
            {['match', 'not_match'].includes(data?.coa_status) && (
              <Row>
                <DatePickerComponent
                  label="md"
                  name="label_pd"
                  value={data?.label_pd}
                  handleChange={(e) => handleChangeInput(e)}
                  col={6}
                  disabled={data?.coa_status === 'not_match' ? false : true}
                />
                <DatePickerComponent
                  label="ed"
                  name="label_ed"
                  value={data?.label_ed}
                  handleChange={(e) => handleChangeInput(e)}
                  col={6}
                  disabled={data?.coa_status === 'not_match' ? false : true}
                />
              </Row>
            )}
            <Modal.Footer>
              <Button
                {...buttonSubmit()}
                onClick={handleSubmit}
                className="text-white"
              >
                Save
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default UpdateCoa
