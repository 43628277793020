import {
  faCheck,
  faClose,
  faExclamation
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Col, Row } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import styles from './css/notification.module.css'
function Notification({ type = null, text = '', handleClose, link = '' }) {
  const navigate = useNavigate()
  return (
    <>
      <Row
        className={`position-fixed left-100 right-100 end-0 m-3 ${styles.topNotification}`}
        style={{ maxWidth: '350px', zIndex: 1050 }}
      >
        <Col className="px-0">
          <div className="d-flex align-items-center bg-dark text-white rounded overflow-hidden">
            <div
              className={`${
                type === 'success'
                  ? 'bg-success'
                  : type === 'error'
                  ? 'bg-danger'
                  : 'bg-info'
              } p-3`}
            >
              <FontAwesomeIcon
                icon={type === 'success' ? faCheck : faExclamation}
              />
            </div>
            <div className="flex-grow-1 px-3 py-2 w-auto">
              <span className="fw-bold" style={{ fontSize: '11px' }}>
                {text}
              </span>
            </div>
            <div className="d-flex align-items-center">
              <button
                className="btn btn-link text-white text-decoration-none px-3 cursor-pointer"
                disabled={link === '' ? true : false}
                onClick={() => navigate(link)}
              >
                View
              </button>
              <button
                className="btn btn-link text-white px-3 cursor-pointer"
                onClick={handleClose}
              >
                <FontAwesomeIcon icon={faClose} />
              </button>
            </div>
          </div>
        </Col>
      </Row>
    </>
  )
}

export default Notification
