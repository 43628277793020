import { useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import api from '../../../config/AxiosInstance'
import * as Common from '../../../utils/Common'
import { firstLetterUpperCase } from '../../../utils/TextFormatter'
function ModalMapVas(props) {
  const { selectLevel = '', showDataMap } = props
  const [loadingDelete, setLoadingDelete] = useState(false)
  const handleRemoveMapTypeVas = async () => {
    try {
      setLoadingDelete(true)
      const res = await api.delete(
        `settings/map/editor/slot/${showDataMap}?type=${selectLevel}`,
        {
          data: {
            type: selectLevel
          }
        }
      )
      const { data } = res
      if (data.success) {
        props.onHide()
        props.setShowDataMap(0)
        document
          .querySelector(".leaflet-interactive[stroke='#3388ff']")
          ?.remove()
        props.onHandleListMapEditor()
      }
    } catch (error) {
      const err = error?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(err)
    } finally {
      setLoadingDelete(false)
    }
  }
  return (
    <div>
      <Modal
        {...props}
        onHide={() => {
          props.onHide()
          props.setShowDataMap(0)
          document
            .querySelector(".leaflet-interactive[stroke='#3388ff']")
            ?.remove()
        }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Define Area</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!showDataMap && (
            <p>
              Jadikan sebagai Area{' '}
              {selectLevel &&
              [
                'vas',
                'vas_pharma',
                'transit_vas',
                'transit_vas_pharma'
              ].includes(selectLevel)
                ? firstLetterUpperCase(selectLevel?.replaceAll('_', ' '))
                : ''}
            </p>
          )}
          {showDataMap !== 0 && (
            <p>
              Area{' '}
              {selectLevel &&
              [
                'vas',
                'vas_pharma',
                'transit_vas',
                'transit_vas_pharma'
              ].includes(selectLevel)
                ? firstLetterUpperCase(selectLevel?.replaceAll('_', ' '))
                : ''}
            </p>
          )}
        </Modal.Body>

        <Modal.Footer>
          {!showDataMap && (
            <>
              <Button
                variant="transparent"
                onClick={() => {
                  props.onHide()
                  document
                    .querySelector(".leaflet-interactive[stroke='#3388ff']")
                    ?.remove()
                  props.setShowDataMap(0)
                }}
              >
                Batal
              </Button>
              <Button
                variant={props.loadingMapVas ? 'secondary' : 'warning'}
                className="text-white"
                disabled={props.loadingMapVas}
                onClick={() => {
                  props.onHide()
                  props.handleSubmitMapVas()
                  props.setShowDataMap(0)
                }}
              >
                {props.loadingMapVas ? 'Loading...' : 'Simpan'}
              </Button>
            </>
          )}
          {showDataMap !== 0 && (
            <>
              <Button
                variant="transparent"
                disabled={loadingDelete}
                onClick={() => handleRemoveMapTypeVas()}
              >
                {loadingDelete ? 'Loading...' : 'Remove'}
              </Button>
              <Button
                variant="warning"
                className="text-white"
                onClick={() => {
                  props.onHide()
                  props.setShowDataMap(0)
                }}
              >
                OKE
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default ModalMapVas
