import { faPen } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { Button, Col, Container, Form, Row, Table } from 'react-bootstrap'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import Loader from '../../../components/atoms/Loader'
import RowEmpty from '../../../components/atoms/RowEmpty'
import TableAction from '../../../components/atoms/Table/TableAction'
import TablePagination from '../../../components/atoms/Table/TablePagination'
import ModalAddArea from '../../../components/modal/Area/ModalAddArea'
import ModalUpdateArea from '../../../components/modal/Area/ModalUpdateArea'
import api from '../../../config/AxiosInstance'
import * as Common from '../../../utils/Common'

const MasterArea = () => {
  const [modalAddShow, setModalAddShow] = useState(false)
  const [modalUpdateShow, setModalUpdateShow] = useState(false)
  const [editId, setEditId] = useState(0)
  const [areas, setDataArea] = useState([])
  const [meta, setMeta] = useState({
    per_page: 10,
    current_page: 1,
    total_row: 0,
    total_page: 0
  })
  const [loading, setLoading] = useState(false)
  const { per_page, current_page } = meta
  const [disabledAdd, setDisabledAdd] = useState(true)
  const [options, setOptions] = useState([])
  const [isLoadingWarehouse, setIsLoadingWarehouse] = useState(false)
  const [inputWarehouse, setInputWarehouse] = useState('')
  const [isSelected, setSelected] = useState(false)
  const [warehouseId, setWarehouseId] = useState(0)
  const loadArea = async (per_page, current_page) => {
    setLoading(true)
    try {
      const response = await api.get('/master/area', {
        params: {
          per_page,
          page: current_page,
          warehouse_id: warehouseId ? warehouseId : ''
        }
      })
      setLoading(false)

      const { metadata, data } = response.data
      setDataArea(data)
      setMeta(metadata)
    } catch (err) {
      setLoading(false)
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'

      Common.showPopUpErrorMessage(errMsg)
    }
  }
  const filterBy = () => true

  useEffect(() => {
    loadArea(per_page, current_page)
  }, [per_page, current_page, isSelected])

  function handleOnChangeMeta(e) {
    const perPageValue = parseInt(e.target.value, 10)
    setMeta((prevMeta) => ({
      ...prevMeta,
      per_page: perPageValue,
      current_page: 1
    }))
  }

  const handlePageChange = (data) => {
    const selectedPage = data.selected + 1
    setMeta((prevMeta) => ({ ...prevMeta, current_page: selectedPage }))
    window.scrollTo(0, 0)
  }

  function handleEdit(id) {
    setModalUpdateShow(true)
    setEditId(id)
  }
  async function handleSearch(query) {
    setIsLoadingWarehouse(true)
    try {
      const params = `?q=${query}`
      const res = await api.get(
        `master/warehouses/all${params}&is_parent=true&forUser=1`
      )
      setOptions(res.data.data)
      setInputWarehouse(inputWarehouse)
      setDisabledAdd(true)
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setIsLoadingWarehouse(false)
    }
  }
  const handleSelectWarehouse = () => {
    setMeta((prevMeta) => ({ ...prevMeta, current_page: 1 }))
    setSelected((selected) => !selected)
    setDisabledAdd(warehouseId === '' || !warehouseId ? true : false)
  }
  return (
    <>
      <Container>
        <Row>
          <h4 className="text-warning">Area</h4>
        </Row>
        <Row>
          <Col xs={6} md={11} lg={11}>
            <Form.Group>
              <Form.Label>Warehouse</Form.Label>
              <AsyncTypeahead
                id="basic-typeahead-single"
                labelKey="warehouse_name"
                isLoading={isLoadingWarehouse}
                filterBy={filterBy}
                onSearch={handleSearch}
                onChange={(e) => {
                  setInputWarehouse(e.length > 0 ? e[0].warehouse_name : '')
                  setWarehouseId(e.length > 0 ? e[0].id : 0)
                }}
                options={options}
                placeholder="Choose a warehouse..."
              />
            </Form.Group>
          </Col>
          <Col xs={6} md={1} lg={1}>
            <div
              className="d-flex h-100 align-items-center"
              style={{ marginTop: '.9rem' }}
            >
              <button
                className="btn btn-primary-orange"
                onClick={() => handleSelectWarehouse()}
              >
                Select
              </button>
            </div>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col xs={6} md={4} lg={4} className="mt-3">
            <small className="text-secondary mt-3">
              <strong>AREA LIST</strong>
            </small>
          </Col>
          <Col xs={6} md={8} lg={8} className="text-end mt-3">
            <Button
              className="btn btn-warning text-white me-0 me-md-3 me-lg-3"
              onClick={() => setModalAddShow(true)}
              disabled={disabledAdd}
            >
              Add
            </Button>
            <ModalAddArea
              show={modalAddShow}
              onHide={() => setModalAddShow(false)}
              refreshData={() => loadArea(per_page)}
              warehouseId={warehouseId}
            ></ModalAddArea>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={12}>
            <Table
              responsive="sm"
              hover
              className="shadow-sm bg-white"
              style={{ fontSize: '14px' }}
            >
              <thead className="table-secondary">
                <tr>
                  <th className="p-2 align-middle" style={{ width: 150 }}>
                    Code
                  </th>
                  <th className="p-2 align-middle" style={{ width: 1000 }}>
                    Name
                  </th>
                  <th className="p-2 align-middle text-end">Action</th>
                </tr>
              </thead>
              <tbody>
                {loading && (
                  <tr>
                    <td colSpan={3}>
                      <Loader />
                    </td>
                  </tr>
                )}
                {!loading && areas.length === 0 && <RowEmpty colSpan={3} />}
                {!loading &&
                  areas.length > 0 &&
                  areas.map((data) => {
                    return (
                      <tr key={data.id}>
                        <td className="p-2 align-middle">{data.code}</td>
                        <td className="p-2 align-middle">{data.name}</td>
                        <td className="p-2 align-middle">
                          <TableAction>
                            <button
                              className="btn btn-secondary ml-2"
                              onClick={() => handleEdit(data.id)}
                            >
                              <FontAwesomeIcon
                                icon={faPen}
                                style={{ fontSize: '15px', rotate: '90deg' }}
                                className="fa-flip-horizontal"
                              />
                            </button>
                          </TableAction>
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={4} className="p-3">
                    <TablePagination
                      meta={meta}
                      handlePageChange={handlePageChange}
                      handleOnChangeMeta={handleOnChangeMeta}
                    />
                  </td>
                </tr>
              </tfoot>
            </Table>
          </Col>
        </Row>
      </Container>
      <ModalUpdateArea
        show={modalUpdateShow}
        onHide={() => setModalUpdateShow(false)}
        id={editId}
        refreshData={() => loadArea(per_page, current_page)}
      ></ModalUpdateArea>
    </>
  )
}

export default MasterArea
