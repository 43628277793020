import React, { useEffect, useState } from 'react'
import { Form, Modal, Row } from 'react-bootstrap'
import api from '../../../config/AxiosInstance.js'
import { convertDateWithoutTime } from '../../../helpers/constants/constant.js'
import '../../../styles/style.css'
import * as Common from '../../../utils/Common.js'
import CustomCloseButton from '../../Button/CustomCloseButton.jsx'
import Label from '../../Label/Input/index.jsx'

function ModalUpdateBatch(props) {
  const showModal = props.show
  const [items, setItems] = useState([])
  const [data, setData] = useState({})

  useEffect(
    function () {
      if (showModal) {
        fetchItems()
        fetchBatch(props.data)
      }
    },
    [props.data, showModal]
  )

  async function fetchItems() {
    try {
      const res = await api.get(`master/items/all`)
      const results = res.data

      setItems(results.data)
    } catch (error) {
      const errMsg =
        error?.response?.data?.errors?.message || 'An error occurred'

      Common.showPopUpErrorMessage(errMsg)
    }
  }

  async function fetchBatch(id) {
    try {
      const res = await api.get(`master/batch/${id}`)

      const result = res.data

      setData({
        ...result.data,
        date_patch: result.data.date_patch
          ? new Date(result.data.date_patch).toISOString().split('T')[0]
          : null,
        exp_date: result.data.exp_date
          ? new Date(result.data.exp_date).toISOString().split('T')[0]
          : null,
        manufacturing_date: result.data.manufacturing_date
          ? new Date(result.data.manufacturing_date).toISOString().split('T')[0]
          : null
      })
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'

      Common.showPopUpErrorMessage(errMsg)
    }
  }

  return (
    <Modal {...props} size="lg" centered>
      <Form className="d-grid gap-2">
        <Modal.Header closeButton>
          <Modal.Title>Detail Batch</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Label label="item" value={data.item_id} col={12} />
            <Label label="batch" value={data.batch} col={6} />
            <Label
              label="manufacturing date"
              value={convertDateWithoutTime(data.manufacturing_date)}
              col={6}
            />
            <Label
              label="exp. date"
              value={
                data.exp_date ? convertDateWithoutTime(data.exp_date) : '-'
              }
              col={6}
            />
            <Label
              label="date. patch"
              value={
                data.date_patch ? convertDateWithoutTime(data.date_patch) : '-'
              }
              col={6}
            />
            <Label label="note" value={data.note ? data.note : '-'} col={12} />
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <CustomCloseButton onClick={props.onHide} />
        </Modal.Footer>
      </Form>
    </Modal>
  )
}

export default ModalUpdateBatch
