import React, { useContext } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import MasterAccess from './components/cms/access/MasterAccess'
import ManageRole from './components/cms/manage/role/ManageRole'
import ManageAccess from './components/cms/manage/role/access/ManageAccess'
import MasterRole from './components/cms/role/MasterRole'
import MasterUser from './components/cms/user/MasterUser'
import CreateSjLocation from './components/content/CreateSjLocation'
import History from './components/content/Outbound/History'
import { UserContext } from './context/userContext'
import ReceiveAtWarehousePlan from './pages/ArrivalPlan/ReceiveAtWarehousePlan'
import InboundHistory from './pages/Inbound/History'
import InboundHistoryPrDetail from './pages/Inbound/History/DetailPr'
import PurchaseOrder from './pages/Inbound/PurchaseOrder'
import PurchaseOrderDetail from './pages/Inbound/PurchaseOrder/detail'
import PurchaseReceipt from './pages/Inbound/PurchaseReceipt'
import PurchaseReceiptCreate from './pages/Inbound/PurchaseReceipt/config'
import PurchaseReceiptDetail from './pages/Inbound/PurchaseReceipt/detail'
import PurchaseReceiptPlan from './pages/Inbound/PurchaseReceipt/plan'
import ReceiveMLDetail from './pages/Inbound/ReceiveML/Detail'
import ReceiveMLIndex from './pages/Inbound/ReceiveML/Index'
import Dashboard from './pages/Layout/Dashboard'
import DashboardMover from './pages/Layout/DashboardMover'
import Login from './pages/Login'
import MasterArea from './pages/Master/Area'
import MasterBatch from './pages/Master/Batch'
import MasterBranch from './pages/Master/Branch'
import MasterCategory from './pages/Master/Categories'
import MasterDriver from './pages/Master/Driver'
import MasterExpedition from './pages/Master/Expedition'
import MasterItems from './pages/Master/Items'
import MasterItemDetail from './pages/Master/Items/detail'
import MasterPallete from './pages/Master/Pallete'
import MasterRackDetail from './pages/Master/RackDetail'
import MasterSector from './pages/Master/Sector'
import MasterStock from './pages/Master/Stock'
import MasterStockDetail from './pages/Master/Stock/detail'
import MasterVehicle from './pages/Master/Vehicle'
import MaterialRequest from './pages/MaterialRequest'
import CreateMaterialRequest from './pages/MaterialRequest/create'
import MaterialRequestDetail from './pages/MaterialRequest/detail'
import IndexMover from './pages/Mover/IndexMover'
import Notification from './pages/Notification'
import CustomerDeliveryPlan from './pages/Outbound/Customer'
import DeliveryConfirmation from './pages/Outbound/Dc'
import DeliveryConfirmationDetail from './pages/Outbound/Dc/detail'
import DeliveryHistory from './pages/Outbound/DeliveryHistory'
import DeliveryHistoryDetail from './pages/Outbound/DeliveryHistory/detail'
import OutboundPlan from './pages/Outbound/Plan/'
import CreateOutboundPlan from './pages/Outbound/Plan/create'
import CreateDetailOutboundPlan from './pages/Outbound/Plan/createDetail'
import DetailOutboundPlan from './pages/Outbound/Plan/detail'
import SJ from './pages/Outbound/Sj'
import SlotLocation from './pages/Outbound/Sj/SlotLocation'
import CreateSj from './pages/Outbound/Sj/create'
import DetailSj from './pages/Outbound/Sj/detail'
import Transfer from './pages/Outbound/Transfer'
import SlotLocationTr from './pages/Outbound/Transfer/SlotLocation'
import DetailTransfer from './pages/Outbound/Transfer/detail'
import MapEditor from './pages/Settings/Map'
import UploadMap from './pages/Settings/Map/UploadMap'
import MasterRack from './pages/Settings/Rack'
import DetailRack from './pages/Settings/Rack/DetailRack'
import MasterStaging from './pages/Settings/Staging'
import WorkOrderPlan from './pages/WorkOrderPlan'
import SLotLocationWorkOrderPlan from './pages/WorkOrderPlan/SlotLocation'
import WorkOrderPlanDetail from './pages/WorkOrderPlan/detail'
import PrivateRoutes from './utils/PrivateRoutes'
import ProtectedMoverRoutes from './utils/ProtectedMoverRoutes'
function App() {
  const [state] = useContext(UserContext)
  return (
    <BrowserRouter>
      <Routes>
        <Route
          exact
          path="/"
          element={
            !state.login ? <Login /> : <Navigate to="/dashboard" replace />
          }
        />
        <Route
          element={
            !state.login ? (
              <Navigate to="/" replace />
            ) : state.username.role.role_code === 'PnP' ? (
              <Navigate to="/mover/" />
            ) : (
              <PrivateRoutes />
            )
          }
        >
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/master/" element={<Dashboard />}>
            <Route exact path="categories" element={<MasterCategory />} />
            <Route exact path="items" element={<MasterItems />} />
            <Route exact path="rack-details" element={<MasterRackDetail />} />
            <Route exact path="area" element={<MasterArea />} />
            <Route exact path="batch" element={<MasterBatch />} />
            <Route exact path="vehicle" element={<MasterVehicle />} />
            <Route exact path="items/:code" element={<MasterItemDetail />} />
            <Route exact path="expedition" element={<MasterExpedition />} />
            <Route exact path="branch" element={<MasterBranch />} />
            <Route exact path="sector" element={<MasterSector />} />
            <Route exact path="driver" element={<MasterDriver />} />
            <Route exact path="user" element={<MasterUser />} />
            <Route exact path="role" element={<MasterRole />} />
            <Route exact path="access" element={<MasterAccess />} />
            <Route exact path="pallete" element={<MasterPallete />} />
            <Route exact path="stock" element={<MasterStock />} />
            <Route exact path="stock/:code" element={<MasterStockDetail />} />
          </Route>
          <Route exact path="/purchase-order/" element={<Dashboard />}>
            <Route exact index element={<PurchaseOrder />} />
          </Route>
          <Route exact path="/inbound/" element={<Dashboard />}>
            <Route
              exact
              path="purchase-order/detail/:code"
              element={<PurchaseOrderDetail />}
            />
            <Route
              exact
              path="purchase-order/create/:code"
              element={<PurchaseReceiptCreate />}
            />
            <Route
              exact
              path="purchase-receipt/"
              element={<PurchaseReceipt />}
            />
            <Route
              exact
              path="purchase-receipt/:id"
              element={<PurchaseReceiptDetail />}
            />
            <Route exact path="history/" element={<InboundHistory />} />
            <Route
              exact
              path="history/detail/pr/:id"
              element={<InboundHistoryPrDetail />}
            />
            <Route exact path="ml">
              <Route index element={<ReceiveMLIndex />} />
              <Route exact path="detail/:id" element={<ReceiveMLDetail />} />
            </Route>
          </Route>
          <Route exact path="/arrival/" element={<Dashboard />}>
            <Route
              exact
              path="purchase-receipt-plan"
              element={<PurchaseReceiptPlan />}
            />
            <Route
              exact
              path="receive-at-warehouse-plan"
              element={<ReceiveAtWarehousePlan />}
            />
          </Route>
          <Route exact path="/setting/" element={<Dashboard />}>
            <Route exact path="staging" element={<MasterStaging />} />
            <Route exact path="upload" element={<UploadMap />} />
            <Route exact path="editor" element={<MapEditor />} />
            <Route exact path="racks" element={<MasterRack />} />
            <Route exact path="racks/detail/:id" element={<DetailRack />} />
          </Route>
          <Route exact path="/manage/" element={<Dashboard />}>
            <Route exact path="role" element={<ManageRole />} />
            <Route exact path="access" element={<ManageAccess />} />
          </Route>
          <Route exact path="/outbound/" element={<Dashboard />}>
            <Route exact path="sj/" element={<SJ />} />
            <Route exact path="sj/create" element={<CreateSj />} />
            <Route
              exact
              path="sj/create/location"
              element={<CreateSjLocation />}
            />
            <Route exact path="sj/detail/:id" element={<DetailSj />} />
            <Route exact path="sj/detail/location" element={<SlotLocation />} />
            <Route exact path="dc/" element={<DeliveryConfirmation />} />
            <Route
              exact
              path="dc/:id"
              element={<DeliveryConfirmationDetail />}
            />
            <Route exact path="plan/" element={<OutboundPlan />} />
            <Route
              exact
              path="plan/detail/:code"
              element={<DetailOutboundPlan />}
            />
            <Route exact path="plan/create" element={<CreateOutboundPlan />} />
            <Route
              exact
              path="plan/create/detail"
              element={<CreateDetailOutboundPlan />}
            />
            <Route exact path="history" element={<History />} />
            <Route exact path="history/detail/:id" element={<DetailSj />} />
            <Route
              exact
              path="history/detail/location"
              element={<SlotLocation />}
            />
            <Route
              exact
              path="customer/delivery/plan"
              element={<CustomerDeliveryPlan />}
            />
            <Route
              exact
              path="delivery-history"
              element={<DeliveryHistory />}
            />
            <Route
              exact
              path="delivery-history/detail/:id"
              element={<DeliveryHistoryDetail />}
            />
            <Route exact path="transfer/" element={<Transfer />} />
            <Route
              exact
              path="transfer/detail/:id"
              element={<DetailTransfer />}
            />
            <Route
              exact
              path="transfer/location"
              element={<SlotLocationTr />}
            />
          </Route>

          <Route exact path="/workorder-plan/" element={<Dashboard />}>
            <Route exact path="" element={<WorkOrderPlan />} />
            <Route
              exact
              path="/workorder-plan/detail/:id"
              element={<WorkOrderPlanDetail />}
            />
            <Route
              exact
              path="/workorder-plan/location"
              element={<SLotLocationWorkOrderPlan />}
            />
          </Route>

          <Route exact path="/material-request" element={<Dashboard />}>
            <Route exact path="" element={<MaterialRequest />} />
            <Route
              exact
              path="/material-request/detail/:id"
              element={<MaterialRequestDetail />}
            />
            <Route
              exact
              path="/material-request/create/:id"
              element={<CreateMaterialRequest />}
            />
          </Route>

          <Route exact path="/notification" element={<Dashboard />}>
            <Route exact path="" element={<Notification />} />
          </Route>
        </Route>

        <Route
          element={
            !state.login ? (
              <Navigate to="/" replace />
            ) : state.username.role.role_code === 'PnP' ? (
              <ProtectedMoverRoutes />
            ) : (
              <PrivateRoutes />
            )
          }
        >
          <Route exact path="/mover/" element={<DashboardMover />}>
            <Route exact index element={<IndexMover />} />
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App
