import {
  faCircleXmark,
  faFileArrowDown
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Button, Col, Modal, Row } from 'react-bootstrap'
import api from '../../../config/AxiosInstance'
import * as Common from '../../../utils/Common'
import { convertDateFormat } from '../../../utils/FormatDate'
import { firstLetterUpperCase } from '../../../utils/TextFormatter'
import './style.css'

const veticalLine = {
  borderLeft: '3px #E0E0E0 solid',
  height: '50px',
  width: '0px',
  marginLeft: '6px'
}

const circle = {
  width: '15px',
  height: '15px',
  background: '#E0E0E0',
  borderRadius: '50%',
  marginTop: '5px'
}

const titleHistory = {
  color: '#828282'
}

const ModalUpdateVehiclePrDraft = (props) => {
  const { datahistory, detailinbounds } = props
  const [fileArmada, setFileArmada] = useState('')
  // const [loadingFileArmada, setLoadingFileArmada] = useState(false)
  const [loadingFile, setLoadingFile] = useState({
    armada: false,
    material: false,
    goodReceipt: false
  })
  const [fileMaterial, setFileMaterial] = useState('')
  const [fileGoodReceipt, setFileGoodReceipt] = useState('')
  useEffect(() => {
    setFileArmada(datahistory?.data?.inbound?.media?.file_armada)
    setFileMaterial(datahistory?.data?.inbound?.media?.file_material)
  }, [datahistory])

  const handleRemove = async () => {
    try {
      const data = {
        action: detailinbounds.status
      }
      const res = await api.post(
        `inbound/pr-draft/unassign/${detailinbounds.id}`,
        data
      )

      if (res.data.success) {
        props.refreshData()
        Common.showPopUpSuccessMessage(res.data.message)
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }

  const handleDownloadArmada = async () => {
    try {
      setLoadingFile((prev) => ({ ...prev, armada: true }))
      const res = await api.get(
        `/inbound/pr-draft/${detailinbounds?.id}/download-armada`,
        {
          responseType: 'arraybuffer'
        }
      )
      const { status, data } = res
      if (status === 200) {
        const blob = new Blob([data], { type: 'application/pdf' })
        const url = window.URL.createObjectURL(blob)
        // Create a link element, set the href to the URL, and trigger a download
        const link = document.createElement('a')
        link.href = url
        link.setAttribute(
          'download',
          `download-armada-${detailinbounds?.id}.pdf`
        ) // Set the file name
        document.body.appendChild(link)
        link.click() // Trigger the download
        // Clean up
        document.body.removeChild(link)
        window.URL.revokeObjectURL(url)
      }
    } catch (error) {
      const err = error?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(err)
    } finally {
      setLoadingFile((prev) => ({ ...prev, armada: false }))
    }
  }

  const handleDownloadMaterial = async () => {
    try {
      setLoadingFile((prev) => ({ ...prev, material: true }))
      const res = await api.get(
        `/inbound/pr-draft/${detailinbounds?.id}/download-material`,
        {
          responseType: 'arraybuffer'
        }
      )
      const { status, data } = res
      if (status === 200) {
        const blob = new Blob([data], { type: 'application/pdf' })
        const url = window.URL.createObjectURL(blob)
        // Create a link element, set the href to the URL, and trigger a download
        const link = document.createElement('a')
        link.href = url
        link.setAttribute(
          'download',
          `download-material-${detailinbounds?.id}.pdf`
        ) // Set the file name
        document.body.appendChild(link)
        link.click() // Trigger the download
        // Clean up
        document.body.removeChild(link)
        window.URL.revokeObjectURL(url)
      }
    } catch (error) {
      const err = error?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(err)
    } finally {
      setLoadingFile((prev) => ({ ...prev, material: false }))
    }
  }
  const handleDownloadGoodReceipt = async () => {
    try {
      setLoadingFile((prev) => ({ ...prev, goodReceipt: true }))
      const res = await api.get(
        `/inbound/pr-draft/${detailinbounds?.id}/download-good-recipt`,
        {
          responseType: 'arraybuffer'
        }
      )
      const { status, data } = res
      if (status === 200) {
        const blob = new Blob([data], { type: 'application/pdf' })
        const url = window.URL.createObjectURL(blob)
        // Create a link element, set the href to the URL, and trigger a download
        const link = document.createElement('a')
        link.href = url
        link.setAttribute(
          'download',
          `download-goodsreceipt-${detailinbounds?.id}.pdf`
        ) // Set the file name
        document.body.appendChild(link)
        link.click() // Trigger the download
        // Clean up
        document.body.removeChild(link)
        window.URL.revokeObjectURL(url)
      }
    } catch (error) {
      const err = error?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(err)
    } finally {
      setLoadingFile((prev) => ({ ...prev, goodReceipt: false }))
    }
  }

  return (
    <Modal {...props} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          <small>{detailinbounds.ticket}</small>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="mt-3">
          <Col md={3}>
            <h4>
              {detailinbounds?.vehicle?.plate_no ?? detailinbounds?.vehicle}
            </h4>
          </Col>
          {detailinbounds?.status === 'put_away' ? (
            <>
              <Col md={3} style={{ fontSize: '14px' }}>
                <li>
                  Put Away by{' '}
                  {datahistory?.data?.inbound?.action?.pnp?.name === null ? (
                    <text style={{ color: '#F89D1D' }}>---</text>
                  ) : (
                    <div class="dropdown">
                      <span>
                        <text style={{ color: '#F89D1D' }}>
                          {datahistory?.data?.inbound?.action?.pnp?.name}
                        </text>{' '}
                      </span>
                      <div class="dropdown-content">
                        <small
                          style={{ cursor: 'pointer' }}
                          className="text-danger fw-bold"
                          onClick={() => handleRemove()}
                        >
                          <FontAwesomeIcon
                            icon={faCircleXmark}
                            className="text-danger me-2"
                          />
                          REMOVE
                        </small>
                      </div>
                    </div>
                  )}
                </li>
              </Col>
              <Col md={3} style={{ fontSize: '14px' }}>
                <li>
                  Mover by{' '}
                  {datahistory?.data?.inbound?.action?.mover?.name === null ? (
                    <text style={{ color: '#F89D1D' }}>---</text>
                  ) : (
                    <div class="dropdown">
                      <span>
                        <text style={{ color: '#F89D1D' }}>
                          {datahistory?.data?.inbound?.action?.mover?.name ===
                          null
                            ? '-'
                            : datahistory?.data?.inbound?.action?.mover?.name}
                        </text>{' '}
                      </span>
                      <div class="dropdown-content">
                        <small
                          className="text-danger fw-bold"
                          onClick={() => handleRemove()}
                          style={{ cursor: 'pointer' }}
                        >
                          <FontAwesomeIcon
                            icon={faCircleXmark}
                            className="text-danger me-2"
                          />
                          REMOVE
                        </small>
                      </div>
                    </div>
                  )}
                </li>
              </Col>
            </>
          ) : detailinbounds?.status === 'waiting_om' ||
            detailinbounds?.status === 'waiting_pnp' ? (
            <>
              <Col md={3} style={{ fontSize: '14px' }}>
                <li>
                  {detailinbounds?.status === 'waiting_om'
                    ? 'Waiting OM'
                    : 'Waiting PNP'}
                </li>
              </Col>
            </>
          ) : detailinbounds?.status === 'receiving_progress' ? (
            <>
              <Col md={3} style={{ fontSize: '14px' }}>
                <li>
                  Receiving By{' '}
                  {datahistory?.data?.inbound?.action?.rnr?.name === null ? (
                    <text style={{ color: '#F89D1D' }}>---</text>
                  ) : (
                    <div class="dropdown">
                      <span>
                        <text style={{ color: '#F89D1D' }}>
                          {datahistory?.data?.inbound?.action?.rnr?.name}
                        </text>{' '}
                      </span>
                      <div class="dropdown-content">
                        <small
                          style={{ cursor: 'pointer' }}
                          className="text-danger fw-bold"
                          onClick={() => handleRemove()}
                        >
                          <FontAwesomeIcon
                            icon={faCircleXmark}
                            className="text-danger me-2"
                          />
                          REMOVE
                        </small>
                      </div>
                    </div>
                  )}
                </li>
              </Col>
            </>
          ) : (
            <>
              <Col md={3} style={{ fontSize: '14px' }}>
                <li>{firstLetterUpperCase(detailinbounds?.status)}</li>
              </Col>
            </>
          )}
        </Row>
        <Row className="mt-4">
          <Col md={12} style={{ marginLeft: '-1rem' }}>
            <div className="d-flex flex-row gap-1">
              {[
                'receiving_progress',
                'waiting_om',
                'put_away',
                'done'
              ].includes(detailinbounds?.status) && (
                <Button
                  variant="transparent"
                  onClick={() => handleDownloadArmada()}
                  style={{ cursor: 'pointer' }}
                >
                  <FontAwesomeIcon
                    icon={faFileArrowDown}
                    className="text-warning fa-xl"
                  />
                  <small
                    style={{
                      fontSize: '14px',
                      marginRight: '20px',
                      marginLeft: '10px',
                      cursor: 'pointer'
                    }}
                  >
                    {!loadingFile.armada
                      ? 'Checklist Armada'
                      : 'Downloading...'}
                  </small>
                </Button>
              )}
              {['waiting_om', 'put_away', 'done'].includes(
                detailinbounds?.status
              ) && (
                <Button
                  variant="transparent"
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleDownloadMaterial()}
                >
                  <FontAwesomeIcon
                    icon={faFileArrowDown}
                    className="text-warning fa-xl"
                  />
                  <small
                    style={{
                      fontSize: '14px',
                      marginRight: '20px',
                      marginLeft: '10px'
                    }}
                  >
                    {!loadingFile.material
                      ? 'Checklist Materials'
                      : 'Downloading...'}
                  </small>
                </Button>
              )}
              {detailinbounds?.status === 'done' && (
                <Button
                  variant="transparent"
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleDownloadGoodReceipt()}
                >
                  <FontAwesomeIcon
                    icon={faFileArrowDown}
                    className="text-warning fa-xl"
                  />
                  <small
                    style={{
                      fontSize: '14px',
                      marginRight: '20px',
                      marginLeft: '10px'
                    }}
                  >
                    {!loadingFile.goodReceipt
                      ? ' Goods Receipt'
                      : 'Downloading...'}
                  </small>
                </Button>
              )}
            </div>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md={1} className="text-center">
            {datahistory?.data?.histories.map((data, i) => (
              <div key={i} style={{ marginTop: '-4px' }}>
                <div style={circle} />
                <div style={veticalLine} />
              </div>
            ))}
          </Col>
          <Col>
            {datahistory?.data?.histories.map((data, i) => {
              return (
                <div key={i} className="mb-4">
                  <h5 style={titleHistory}>
                    {convertDateFormat(data.createdAt)}
                  </h5>
                  <h5 style={titleHistory}>
                    <small style={{ fontSize: '14px', marginRight: '5px' }}>
                      {data.notes}
                    </small>
                  </h5>
                </div>
              )
            })}
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  )
}

export default ModalUpdateVehiclePrDraft
