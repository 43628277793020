import { faBell } from '@fortawesome/free-regular-svg-icons'
import {
  faBars,
  faCaretDown,
  faCircleUser
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Badge, Nav, Navbar } from 'react-bootstrap'
import { useNavigate } from 'react-router'
import { firstLetterUpperCase } from '../../utils/TextFormatter'
const NavbarPage = ({
  onClick,
  indicator,
  width,
  setShowCanvas = false,
  setShowCanvasWarehouse = false,
  usernameProp
}) => {
  const navigate = useNavigate()
  return (
    <>
      <Navbar className="shadow-sm bg-white">
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto ms-4">
            <Nav.Link
              onClick={() => (width <= 768 ? setShowCanvas(true) : onClick())}
            >
              <FontAwesomeIcon icon={faBars} />
            </Nav.Link>
          </Nav>
          <Nav className="d-flex flex-row justify-content-end">
            <div className="d-flex flex-row gap-md-2 gap-lg-2 ms-1 me-4 me-md-5 me-lg-5">
              <div
                className="cursor-pointer position-relative"
                role="button"
                onClick={() => {
                  navigate('/notification')
                }}
              >
                <FontAwesomeIcon
                  icon={faBell}
                  style={{
                    fontSize: width <= 768 ? '21px' : '24px',
                    cursor: 'pointer'
                  }}
                  className="mt-2 me-3"
                />
                {indicator && (
                  <Badge
                    bg="danger"
                    className="dot-badge rounded-circle"
                  ></Badge>
                )}
              </div>
              <div
                className="d-flex flex-row gap-1"
                onClick={() => setShowCanvasWarehouse(true)}
              >
                <FontAwesomeIcon
                  icon={faCircleUser}
                  className="mt-2"
                  style={{ fontSize: '24px' }}
                />
                <div
                  className="d-flex flex-row gap-2 cursor-pointer"
                  role="button"
                >
                  <p className="mt-2 ms-2" style={{ fontSize: '14px' }}>
                    {firstLetterUpperCase(usernameProp.username.username)}
                  </p>
                  <FontAwesomeIcon
                    icon={faCaretDown}
                    style={{ marginTop: '.75rem' }}
                  />
                </div>
              </div>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  )
}

export default NavbarPage
