import { useEffect, useState } from 'react'
import { Form, Modal, ModalHeader } from 'react-bootstrap'
import api from '../../../config/AxiosInstance'
import * as Common from '../../../utils/Common'
import CustomButton from '../../Button/CustomButton'
import CustomCloseButton from '../../Button/CustomCloseButton'
const ModalCreate = (props) => {
  const { handleCreateMlDraft, disableSubmit, createMl } = props
  const [seriesNumbers, setSeriesNumbers] = useState([])
  useEffect(() => {
    if (createMl?.mlId) fetchSeriesNumber()
  }, [createMl?.mlId])
  const fetchSeriesNumber = async () => {
    try {
      const res = await api.get(`inbound/ml-plan/show-series/${createMl.mlId}`)
      const { data } = res
      if (data.success) {
        setSeriesNumbers(data.data)
      }
    } catch (error) {
      const err = error.response.data.errors.message || 'An error occurred'
      Common.showPopUpErrorMessage(err)
    }
  }
  return (
    <Modal {...props} centered>
      <ModalHeader closeButton>Create ML Draft</ModalHeader>
      <Modal.Body>
        <p>Select series to create</p>
        <Form.Label
          htmlFor="series_number"
          style={{ fontSize: '12px' }}
          className="text-muted"
        >
          Series
        </Form.Label>
        <Form.Select
          aria-label="Default select example"
          name="series_number"
          id="series_number"
          onChange={(e) =>
            props.setCreateMl((prevCreateMl) => ({
              ...prevCreateMl,
              series_number: e.target.value
            }))
          }
        >
          <option>Select Series Number</option>
          {seriesNumbers.length > 0 &&
            seriesNumbers?.map((seriesNumber, index) => (
              <option key={seriesNumber.name} value={seriesNumber.name}>
                {seriesNumber.name}
              </option>
            ))}
        </Form.Select>
      </Modal.Body>
      <Modal.Footer>
        <CustomCloseButton onClick={props.onHide} />
        <CustomButton
          variant={`${
            disableSubmit || !createMl.series_number ? 'secondary' : 'warning'
          }`}
          text={`${disableSubmit ? 'Loading...' : 'Create'}`}
          disabled={disableSubmit || !createMl.series_number}
          onClick={handleCreateMlDraft}
        />
      </Modal.Footer>
    </Modal>
  )
}

export default ModalCreate
