import {
  faCaretDown,
  faCaretUp,
  faPen,
  faTrash
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import {
  Button,
  Card,
  Col,
  Collapse,
  Container,
  Form,
  OverlayTrigger,
  Popover,
  Row,
  Spinner,
  Table
} from 'react-bootstrap'
import { Link, useLocation, useParams, useSearchParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import { BadgeStatusPrListInbounds } from '../../../components/atoms/StatusLabel'
import CustomButton from '../../../components/Button/CustomButton'
import Breadcrumb from '../../../components/Label/Breadcrumb'
import Label from '../../../components/Label/Input'
import ModalAddUpdateItem from '../../../components/modal/Sj/ModalAddUpdateItem'
import ModalSelectFormat from '../../../components/modal/Sj/ModalSelectFormat'
import ModalSolveContinueTicket from '../../../components/modal/Sj/ModalSolveContinueTicket'
import api from '../../../config/AxiosInstance'
import {
  convertDateWithoutTime,
  convertDateWithTime
} from '../../../helpers/constants'
import * as Common from '../../../utils/Common'
import { firstLetterUpperCase } from '../../../utils/TextFormatter'
import ActionButton from '../Plan/ActionButton'
const headerTitleStyle = {
  color: 'var(--primary-orange)',
  fontSize: '24px',
  fontWeight: '600'
}
const Detail = () => {
  const [openCommand, setOpenCommand] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [iconCommand, setIconCommand] = useState(faCaretUp)
  const [detail, setDetail] = useState({})
  const [requestCommand, setRequestCommand] = useState([])
  const [pickupCommand, setPickupCommand] = useState([])
  const [progressDetailCommand, setProgressDetailCommand] = useState(true)
  const { pathname } = useLocation()
  const headMenu = pathname.split('/')[1]
  const poHeadMenu = pathname.split('/')[2]
  const routeValue = `${headMenu}/${poHeadMenu}`

  const [iconProgressDetailCommand, setIconProgressDetailCommand] =
    useState(faCaretUp)
  const [showModalAddUpdateItem, setShowModalAddUpdateItem] = useState(false)
  const { id } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const [showModalSolveContinueTicket, setShowModalSolveContinueTicket] =
    useState(false)
  const [loadingDownloadPrint, setLoadingDownloadPrint] = useState({
    coaDownload: false,
    coaPrint: false,
    sjDownload: false,
    sjPrint: false
  })
  const [submitSolveContinueTicket, setSubmitSolveContinueTicket] =
    useState(false)
  const [editData, setEditData] = useState({
    type: 'create',
    command_id: null,
    item_code: null,
    item_alias: null,
    item_batch_id: null,
    total_pack: null,
    exp_date: null,
    qty: 0
  })
  const [showModalSelectFormat, setShowModalSelectFormat] = useState(false)
  const [selectFormatDownload, setSelectFormatDownload] = useState({
    type: 'sj',
    format: null,
    action: null,
    id: null
  })
  const [loadingFormat, setLoadingFormat] = useState(false)
  useEffect(() => {
    loadData(id)
  }, [id])

  const loadData = async (id) => {
    try {
      setIsLoading(true)
      const res = await api.get(`outbound/sjdraft/show?sj=${id}`)
      const { data } = res.data
      setDetail(data)
      setRequestCommand(data.command)
      setPickupCommand(data.pickup_command)
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setIsLoading(false)
    }
  }

  const handleDownloadPrint = async (type, typeAction, id) => {
    try {
      if (type !== 'sj') {
        setLoadingDownloadPrint((loadingDownloadPrint) => ({
          ...loadingDownloadPrint,
          [type + firstLetterUpperCase(typeAction)]: true
        }))
      } else {
        setLoadingFormat(true)
      }

      if (type === 'sj') {
        const res = await api.get(
          `outbound/sjdraft/file-sj/${id}?print=${encodeURIComponent(
            selectFormatDownload?.format
          )}`,
          {
            responseType: 'arraybuffer'
          }
        )
        const { status, data } = res
        if (status === 200) {
          const blob = new Blob([data], { type: 'application/pdf' })
          const url = window.URL.createObjectURL(blob)
          if (typeAction === 'download') {
            // Create a link element, set the href to the URL, and trigger a download
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `file-sj-${id}.pdf`) // Set the file name
            document.body.appendChild(link)
            link.click() // Trigger the download
            // Clean up
            document.body.removeChild(link)
            window.URL.revokeObjectURL(url)
          }
          if (typeAction === 'print') {
            window.open(url)
          }
        }
      }
      if (type === 'coa') {
        const res = await api.get(`/outbound/sjdraft/file-coa/${id}`, {
          responseType: 'arraybuffer'
        })
        const { status, data } = res
        if (status === 200) {
          const blob = new Blob([data], { type: 'application/pdf' })
          const url = window.URL.createObjectURL(blob)
          if (typeAction === 'download') {
            // Create a link element, set the href to the URL, and trigger a download
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `file-coa-${id}.pdf`) // Set the file name
            document.body.appendChild(link)
            link.click() // Trigger the download
            // Clean up
            document.body.removeChild(link)
            window.URL.revokeObjectURL(url)
          }
          if (typeAction === 'print') {
            window.open(url)
          }
        }
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    } finally {
      if (type === 'sj') {
        setLoadingFormat(false)
        setShowModalSelectFormat(false)
        setSelectFormatDownload((prevSelectFormatDownload) => ({
          ...prevSelectFormatDownload,
          id: null,
          format: null
        }))
      } else {
        setLoadingDownloadPrint((loadingDownloadPrint) => ({
          ...loadingDownloadPrint,
          [type + firstLetterUpperCase(typeAction)]: false
        }))
      }
    }
  }
  const handleSubmitSolveContinueTicket = async (type = 'direct') => {
    try {
      setSubmitSolveContinueTicket(true)
      const res = await api.post(`outbound/sjdraft/${detail?.id}/solve-problem`)
      if (res.data.success) {
        loadData(id)
      }
    } catch (error) {
      const err = error?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(err)
    } finally {
      setSubmitSolveContinueTicket(false)
      if (type === 'modal') {
        setShowModalSolveContinueTicket(false)
      }
    }
  }
  const handleDeleteAddItemPickupCommand = async (commandId) => {
    try {
      const res = await api.delete(
        `outbound/sjdraft/${detail?.id}/delete/${commandId}`
      )
      if (res.data.success) {
        loadData(id)
      }
    } catch (error) {
      const err = error?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(err)
    }
  }
  const handleCreateWorkOrder = (item) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary-orange',
        cancelButton: 'btn btn-transparent',
        actions:
          'd-flex w-100 justify-content-end pt-4 ps-4 pe-4 separator-border-action-sweetalert'
      },
      buttonsStyling: false
    })
    swalWithBootstrapButtons
      .fire({
        title: 'Create Work Order?',
        text: 'Create work order for this item alias?',
        icon: 'warning',
        iconColor: '#828282',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Create',
        cancelButtonText: 'Cancel',
        allowOutsideClick: false,
        reverseButtons: true,
        showCloseButton: true
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          // call function send to erp
          createWorkOrder(item)
        } else {
          return false
        }
      })
  }
  const handleSendToErp = () => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary-orange',
        cancelButton: 'btn btn-transparent',
        actions:
          'd-flex w-100 justify-content-end pt-4 ps-4 pe-4 separator-border-action-sweetalert'
      },
      buttonsStyling: false
    })
    swalWithBootstrapButtons
      .fire({
        title: 'Send to ERP?',
        text: 'This action can’t be repeated!',
        icon: 'warning',
        iconColor: '#828282',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Send!',
        cancelButtonText: 'Cancel',
        allowOutsideClick: false,
        reverseButtons: true,
        showCloseButton: true
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          // call function send to erp
          sendToErp()
        } else {
          return false
        }
      })
  }
  const sendToErp = async () => {
    try {
      const res = await api.post(`outbound/sjdraft/${detail?.id}/send-erp`)
      const { data } = res
      if (data.success) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Send to erp success'
        })
      }
    } catch (error) {
      const err = error?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(err)
    } finally {
      loadData(id)
    }
  }
  const createWorkOrder = async (item) => {
    try {
      const body = {
        sj: id,
        command: item
      }
      const res = await api.post('/outbound/work-order', body)
      const { data } = res
      if (data.success) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Create work order success'
        })
      }
    } catch (error) {
      const err = error?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(err)
    } finally {
      loadData(id)
    }
  }
  if (isLoading) {
    return (
      <>
        <div>
          <Row>
            <Col md={12}>
              <div
                style={{ height: '100vh' }}
                className="d-flex flex-column justify-content-center"
              >
                <div className="d-flex flex-row justify-content-center">
                  <div className="d-flex flex-column gap-3">
                    <div className="d-flex flex-row justify-content-center gap-2">
                      <Spinner animation="grow" variant="warning" />
                      <Spinner animation="grow" variant="warning" />
                      <Spinner animation="grow" variant="warning" />
                      <Spinner animation="grow" variant="warning" />
                      <Spinner animation="grow" variant="warning" />
                    </div>
                    <p
                      className="fw-bold text-center"
                      style={{ fontSize: '13px' }}
                    >
                      Loading...
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </>
    )
  }
  return (
    <>
      <Container className="mb-5">
        <Row>
          <Col md={4} className="mt-3">
            {!searchParams.get('outboundId') && (
              <Breadcrumb
                label={detail?.draft_code}
                link="/outbound/sj"
                back="SJDRAFT"
                type="BadgeStatusPrListInbounds"
                status={detail.draft_status}
              />
            )}
            {searchParams.get('outboundId') &&
              searchParams.get('outboundCode') && (
                <>
                  <h4 style={headerTitleStyle}>
                    {detail?.draft_code}{' '}
                    <sup style={{ fontSize: '12px', marginLeft: '5px' }}>
                      {<BadgeStatusPrListInbounds text={detail.draft_status} />}
                    </sup>
                  </h4>
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item sj-item">
                        <Link
                          to={'/outbound/plan'}
                          className="text-decoration-none"
                        >
                          <span className="text-black fw-bold text-uppercase">
                            Outbound
                          </span>
                        </Link>
                      </li>
                      <li className="breadcrumb-item sj-item">
                        <Link
                          to={`/outbound/plan/detail/${searchParams.get(
                            'outboundId'
                          )}`}
                          className="text-decoration-none"
                        >
                          <span className="text-black fw-bold text-uppercase">
                            {searchParams.get('outboundCode')}
                          </span>
                        </Link>
                      </li>
                      <li
                        className="breadcrumb-item sj-item active"
                        aria-current="page"
                      >
                        {detail?.draft_code}
                      </li>
                    </ol>
                  </nav>
                </>
              )}
          </Col>

          <ActionButton>
            <div
              style={{ marginTop: '2.3rem' }}
              className="d-flex flex-row gap-2"
            >
              {/* button action solve when status sj problem */}
              {detail.draft_status === 'problem' && (
                <CustomButton
                  variant={'transparent'}
                  style={{ backgroundColor: '#F89D1D' }}
                  text={'Solve problem and continue ticket'}
                  disabled={submitSolveContinueTicket}
                  onClick={() =>
                    detail?.pickup_command?.reduce(
                      (acc, cur) =>
                        (acc +=
                          cur.qty -
                          (cur.report_pack
                            ? cur.report_pack * cur.convertion_factor_pack
                            : 0)),
                      0
                    ) !==
                    detail?.command?.reduce((acc, cur) => (acc += cur.qty), 0)
                      ? setShowModalSolveContinueTicket(true)
                      : handleSubmitSolveContinueTicket()
                  }
                />
              )}
              {detail?.draft_status !== 'problem' &&
                detail?.draft_status === 'ongoing' && (
                  <>
                    <CustomButton
                      variant={'transparent'}
                      style={{ backgroundColor: '#F89D1D' }}
                      text={
                        loadingDownloadPrint.coaDownload
                          ? 'Downloading...'
                          : 'Download C.O.A'
                      }
                      onClick={() =>
                        handleDownloadPrint('coa', 'download', detail?.id)
                      }
                    />
                    <CustomButton
                      variant={'transparent'}
                      style={{ backgroundColor: '#203568' }}
                      text={
                        loadingDownloadPrint.coaPrint
                          ? 'Downloading...'
                          : 'Print C.O.A'
                      }
                      onClick={() =>
                        handleDownloadPrint('coa', 'print', detail?.id)
                      }
                    />
                  </>
                )}
              {detail?.draft_status !== 'problem' &&
                ['submit', 'done'].some(
                  (status) => detail?.draft_status === status
                ) && (
                  <>
                    <CustomButton
                      variant={'transparent'}
                      style={{ backgroundColor: '#F89D1D' }}
                      text={
                        loadingDownloadPrint.sjDownload
                          ? 'Downloading...'
                          : 'Download SJ'
                      }
                      onClick={() => {
                        setShowModalSelectFormat(true)
                        setSelectFormatDownload((prevSelectFormatDownload) => ({
                          ...prevSelectFormatDownload,
                          id: detail?.id,
                          action: 'download'
                        }))
                      }}
                    />
                    <CustomButton
                      variant={'transparent'}
                      style={{ backgroundColor: '#203568' }}
                      text={
                        loadingDownloadPrint.sjPrint
                          ? 'Downloading...'
                          : 'Print SJ'
                      }
                      onClick={() => {
                        setShowModalSelectFormat(true)
                        setSelectFormatDownload((prevSelectFormatDownload) => ({
                          ...prevSelectFormatDownload,
                          id: detail?.id,
                          action: 'print'
                        }))
                      }}
                    />
                    <CustomButton
                      variant={'transparent'}
                      style={{ backgroundColor: '#F89D1D' }}
                      text={
                        loadingDownloadPrint.coaDownload
                          ? 'Downloading...'
                          : 'Download C.O.A'
                      }
                      onClick={() =>
                        handleDownloadPrint('coa', 'download', detail?.id)
                      }
                    />
                    <CustomButton
                      variant={'transparent'}
                      style={{ backgroundColor: '#203568' }}
                      text={
                        loadingDownloadPrint.coaPrint
                          ? 'Downloading...'
                          : 'Print C.O.A'
                      }
                      onClick={() =>
                        handleDownloadPrint('coa', 'print', detail?.id)
                      }
                    />
                  </>
                )}
            </div>
          </ActionButton>
        </Row>
        <Row className="mt-3">
          <Form>
            <Row className="mb-5">
              <Label label="#so" value={detail?.so} col={6} />
              <Label label="customer" value={detail?.customer} col={6} />
              <Label label="branch" value={detail?.branch} col={6} />
              <Label label="source warehouse" value={detail?.src} col={6} />
              <Label
                label="delivery address"
                value={detail?.delivery_address ?? '-'}
                col={12}
                address={true}
              />
              <Label
                label="delivery date"
                value={convertDateWithoutTime(detail?.delivery_date)}
                col={3}
              />
              <Label
                label="#Outbound"
                value={detail?.outbound === null ? '-' : detail?.outbound}
                col={3}
              />
            </Row>
            {/* COMMAND */}
            <Row className="shadow-sm mb-5 bg-white rounded">
              <Col md={12} className="mb-3">
                <h5 className="fw-bold">
                  COMMAND
                  <FontAwesomeIcon
                    icon={iconCommand}
                    className="ms-3"
                    onClick={() => {
                      setOpenCommand(!openCommand)
                      openCommand === true
                        ? setIconCommand(faCaretDown)
                        : setIconCommand(faCaretUp)
                    }}
                    aria-expanded={openCommand}
                    aria-controls="example-collapse-text2"
                  />
                </h5>
              </Col>
              <Collapse in={openCommand}>
                <div id="example-collapse-text2">
                  <Row>
                    <Col md={12} className="mt-3">
                      <h6 className="text-secondary mb-3">REQUEST</h6>
                      <Table
                        hover
                        responsive="sm"
                        className="shadow-sm mb-4 bg-white rounded"
                        size="sm"
                        style={{ fontSize: '12px' }}
                      >
                        <thead className="table-secondary">
                          <tr>
                            <th className="p-3 align-middle">No</th>
                            <th
                              className="p-3 align-middle"
                              style={{ width: '20%' }}
                            >
                              Item
                            </th>
                            <th className="p-3 text-end align-middle">
                              Item Alias
                            </th>
                            <th className="p-3 text-end align-middle">
                              Item Code
                            </th>
                            <th className="p-3 text-end align-middle">Batch</th>
                            <th className="p-3 text-end align-middle">
                              Total Qty
                            </th>
                            <th className="p-3 text-end align-middle">UOM</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!requestCommand.length ? (
                            <tr>
                              <td>Data Empty!</td>
                            </tr>
                          ) : (
                            requestCommand.map((item, i) => {
                              return (
                                <tr key={i}>
                                  <td className="p-3 align-middle">{i + 1}</td>
                                  <td className="p-3 align-middle">
                                    {' '}
                                    {item.item_name ?? '-'}
                                  </td>
                                  <td className="p-3 text-end align-middle">
                                    {item.item_alias ?? '-'}
                                  </td>
                                  <td className="p-3 text-end align-middle">
                                    {item.item_code ?? '-'}
                                  </td>
                                  <td className="p-3 text-end align-middle">
                                    {item.batch ?? '-'}
                                  </td>
                                  <td className="p-3 text-end align-middle">
                                    {item.qty ?? '-'}
                                  </td>
                                  <td className="p-3 text-end align-middle">
                                    {item.uom ?? '-'}
                                  </td>
                                </tr>
                              )
                            })
                          )}
                        </tbody>
                      </Table>
                    </Col>
                    <Col md={12}>
                      <div className="text-muted d-flex gap-2">
                        <div
                          style={{
                            backgroundColor: '#8FC9FF',
                            height: '15px',
                            width: '15px',
                            marginTop: '.2rem'
                          }}
                        ></div>
                        <span className="ml-2">Need Extend</span>
                      </div>
                    </Col>
                    <Col md={12} className="mt-3">
                      <Row className="mb-2">
                        <Col md={6}>
                          <h6 className="text-secondary mb-3">
                            PICK UP COMMAND
                          </h6>
                        </Col>
                        {detail?.draft_status === 'problem' && (
                          <Col md={6}>
                            <div
                              className="d-flex flex-row justify-content-end"
                              style={{ marginTop: '-0.5rem' }}
                            >
                              <CustomButton
                                variant={'transparent'}
                                style={{ backgroundColor: '#F89D1D' }}
                                text={'Add'}
                                onClick={() => {
                                  setEditData({
                                    type: 'create',
                                    command_id: null,
                                    item_code: null,
                                    item_alias: null,
                                    item_batch_id: null,
                                    total_pack: null,
                                    exp_date: null,
                                    qty: 0
                                  })
                                  setShowModalAddUpdateItem(true)
                                }}
                              />
                            </div>
                          </Col>
                        )}
                      </Row>

                      <Table
                        hover
                        responsive="sm"
                        className="shadow-sm mb-4 bg-white rounded"
                        size="sm"
                        style={{ fontSize: '12px' }}
                      >
                        <thead className="table-secondary">
                          <tr>
                            <th className="p-3 align-middle">No</th>
                            <th className="p-3 align-middle">Item</th>
                            <th className="p-3 text-end align-middle">
                              Item Alias
                            </th>
                            <th className="p-3 text-end align-middle">
                              Item Code
                            </th>
                            <th className="p-3 text-end align-middle">Batch</th>
                            <th className="p-3 text-end align-middle">
                              Expired
                            </th>
                            <th className="p-3 text-end align-middle">
                              Total Qty
                            </th>
                            <th className="p-3 text-end align-middle">
                              Total Pack
                            </th>
                            <th className="p-3 text-end align-middle">
                              Location
                            </th>
                            {detail?.draft_status !== 'submit' && (
                              <th
                                className="p-3 align-middle text-center"
                                style={{ width: '15%' }}
                                hidden={
                                  routeValue === 'outbound/history'
                                    ? true
                                    : false
                                }
                              >
                                Action
                              </th>
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          {!pickupCommand ? (
                            <tr>
                              <td>Data Empty!</td>
                            </tr>
                          ) : (
                            pickupCommand.map((item, i) => {
                              return (
                                <tr key={i}>
                                  <td
                                    className="p-3 align-middle"
                                    style={{
                                      backgroundColor: item?.item_alias
                                        ? '#FF8F8F'
                                        : ''
                                    }}
                                  >
                                    {i + 1}
                                  </td>
                                  <td
                                    className="p-3 align-middle"
                                    style={{
                                      backgroundColor: item?.item_alias
                                        ? '#FF8F8F'
                                        : ''
                                    }}
                                  >
                                    {' '}
                                    <div className="mt-3">
                                      <OverlayTrigger
                                        trigger={['click', 'hover']}
                                        placement="bottom"
                                        overlay={
                                          <Popover
                                            id={`popover-positioned-${item?.item_name}`}
                                          >
                                            <Popover.Body>
                                              <div className="text-center">
                                                <p className="fw-bold text-uppercase">
                                                  Item Name
                                                </p>
                                                <p>{item?.item_name}</p>
                                              </div>
                                              <hr />
                                              <div className="text-center">
                                                <p className="fw-bold text-uppercase">
                                                  Item Code
                                                </p>
                                                <p>{item?.item_code}</p>
                                              </div>
                                            </Popover.Body>
                                          </Popover>
                                        }
                                      >
                                        <p>
                                          {!item?.item_name
                                            ? '-'
                                            : item?.item_name}
                                        </p>
                                      </OverlayTrigger>
                                    </div>
                                  </td>
                                  <td
                                    className="p-3 text-end align-middle"
                                    style={{
                                      backgroundColor: item?.item_alias
                                        ? '#FF8F8F'
                                        : ''
                                    }}
                                  >
                                    {!item?.item_alias ? '-' : item?.item_alias}
                                  </td>
                                  <td
                                    className="p-3 text-end align-middle"
                                    style={{
                                      backgroundColor: item?.item_alias
                                        ? '#FF8F8F'
                                        : ''
                                    }}
                                  >
                                    {!item?.item_code ? '-' : item?.item_code}
                                  </td>
                                  <td
                                    className="p-3 text-end align-middle"
                                    style={{
                                      backgroundColor: item?.item_alias
                                        ? '#FF8F8F'
                                        : ''
                                    }}
                                  >
                                    {!item?.batch ? '-' : item?.batch}
                                  </td>
                                  <td
                                    className="p-3 text-end align-middle"
                                    style={{
                                      backgroundColor: item?.item_alias
                                        ? '#FF8F8F'
                                        : ''
                                    }}
                                  >
                                    {!item?.expired
                                      ? '-'
                                      : moment(item?.expired).format(
                                          'DD-MM-YYYY'
                                        )}
                                  </td>
                                  <td
                                    className="p-3 text-end align-middle"
                                    style={{
                                      backgroundColor: item?.item_alias
                                        ? '#FF8F8F'
                                        : ''
                                    }}
                                  >
                                    {!item?.qty ? '-' : item?.qty} {item?.uom}
                                  </td>
                                  <td
                                    className="p-3 text-end align-middle text-end"
                                    style={{
                                      backgroundColor: item?.item_alias
                                        ? '#FF8F8F'
                                        : ''
                                    }}
                                  >
                                    {!item?.total_pack
                                      ? '-'
                                      : parseInt(item?.total_pack)}
                                    {item.report_pack > 0 && (
                                      <span className="text-danger ms-2">
                                        ({`-${item?.report_pack}`})
                                      </span>
                                    )}
                                  </td>
                                  <td
                                    className="p-3 text-end align-middle text-primary cursor-pointer"
                                    role="button"
                                    title={item?.location}
                                    style={{
                                      backgroundColor: item?.item_alias
                                        ? '#FF8F8F'
                                        : ''
                                    }}
                                  >
                                    <div
                                      style={{ maxWidth: '95%' }}
                                      className="text-truncate"
                                    >
                                      <Link
                                        to={
                                          routeValue === 'outbound/history'
                                            ? '/outbound/history/detail/location'
                                            : '/outbound/sj/detail/location'
                                        }
                                        state={{
                                          slotLocations: detail?.slot_locations,
                                          batch: item?.batch,
                                          sjCode: detail?.draft_code
                                        }}
                                        className="text-decoration-none"
                                      >
                                        {!item?.location ? '-' : item?.location}
                                      </Link>
                                    </div>
                                  </td>
                                  {detail?.draft_status !== 'submit' && (
                                    <td
                                      className="p-3 text-end align-middle"
                                      style={{
                                        backgroundColor: item?.item_alias
                                          ? '#FF8F8F'
                                          : ''
                                      }}
                                      hidden={
                                        routeValue === 'outbound/history'
                                          ? true
                                          : false
                                      }
                                    >
                                      <div className="d-flex flex-row gap-2 justify-content-end">
                                        <Button
                                          className={`btn ${
                                            item?.has_wo === true ||
                                            detail?.draft_status === 'problem'
                                              ? 'btn-secondary'
                                              : 'btn-primary'
                                          } ml-2 ${
                                            !item?.item_alias ? 'd-none' : ''
                                          }`}
                                          disabled={
                                            item?.has_wo === true ||
                                            detail?.draft_status === 'problem'
                                          }
                                          size="sm"
                                          onClick={() =>
                                            handleCreateWorkOrder(item)
                                          }
                                        >
                                          + WO
                                        </Button>
                                        <Button
                                          className={`btn ${
                                            item?.is_new_command &&
                                            detail?.draft_status === 'problem'
                                              ? 'btn-danger'
                                              : 'btn-secondary'
                                          } ml-1`}
                                          disabled={
                                            !item?.is_new_command ||
                                            detail?.draft_status !== 'problem'
                                              ? true
                                              : false
                                          }
                                          size="sm"
                                          onClick={() =>
                                            handleDeleteAddItemPickupCommand(
                                              item?.command_id
                                            )
                                          }
                                        >
                                          <FontAwesomeIcon
                                            icon={faTrash}
                                            style={{ fontSize: '13px' }}
                                          />
                                        </Button>
                                        <Button
                                          type="button"
                                          className="btn btn-secondary ml-3"
                                          size="sm"
                                          disabled={
                                            !item?.is_new_command ||
                                            detail?.draft_status !== 'problem'
                                              ? true
                                              : false
                                          }
                                          onClick={() => {
                                            setShowModalAddUpdateItem(true)
                                            setEditData({
                                              type: 'update',
                                              command_id: item?.command_id,
                                              item_code: item?.item_code,
                                              item_alias:
                                                item?.item_alias ?? '-',
                                              item_batch_id:
                                                item?.item_batch_id,
                                              total_pack: item?.total_pack,
                                              exp_date: moment(
                                                item?.expired
                                              ).format('DD-MM-YYYY'),
                                              qty: item?.qty
                                            })
                                          }}
                                        >
                                          <FontAwesomeIcon
                                            icon={faPen}
                                            style={{ fontSize: '13px' }}
                                          />
                                        </Button>
                                      </div>
                                    </td>
                                  )}
                                </tr>
                              )
                            })
                          )}
                        </tbody>
                      </Table>
                    </Col>
                    <Col md={12}>
                      <Row>
                        <Col md={6}>
                          <div className="text-muted d-flex gap-2">
                            <div
                              style={{
                                backgroundColor: '#FF8F8F',
                                height: '15px',
                                width: '15px',
                                marginTop: '.2rem'
                              }}
                            ></div>
                            <span className="ml-2 mb-3">Less Items Alias</span>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </Collapse>
            </Row>
            {detail?.is_finish_loading && !detail?.erp?.erp_status && (
              <>
                <Row className="mb-3">
                  <Col md={6} hidden={!detail?.erp?.err_notes ? true : false}>
                    <p>
                      {detail?.erp?.description}, status:
                      <span
                        style={{
                          color:
                            detail?.erp?.erp_status === true
                              ? 'green'
                              : '#C12727'
                        }}
                        className="ms-2 fw-bold text-uppercase"
                      >
                        {detail?.erp?.erp_status === true
                          ? 'Success'
                          : 'Failed'}
                      </span>
                    </p>
                    <div className="d-flex flex-row gap-2">
                      <span>Failed Reason: </span>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: detail?.erp?.err_notes
                        }}
                      ></div>
                    </div>
                  </Col>
                  <Col
                    md={!detail?.erp?.err_notes ? 12 : 6}
                    className="d-flex flex-row justify-content-end"
                  >
                    <div className="d-flex flex-column justify-content-center">
                      <CustomButton
                        variant={'transparent'}
                        style={{ backgroundColor: '#F89D1D' }}
                        text={'Send to ERP'}
                        onClick={() => handleSendToErp()}
                      />
                    </div>
                  </Col>
                </Row>
                <div
                  style={{ height: '1rem', borderTop: '2px solid #F1F2F7' }}
                ></div>
              </>
            )}

            {/* Progress Detail */}
            <Row className="bg-white rounded">
              <Col md={12} className="mb-3">
                <h5 className="fw-bold text-uppercase">
                  Progress Detail
                  <FontAwesomeIcon
                    icon={iconProgressDetailCommand}
                    className="ms-3"
                    onClick={() => {
                      const statusProgressDetailCollapse =
                        !progressDetailCommand
                      setProgressDetailCommand((prev) => !prev)
                      statusProgressDetailCollapse === false
                        ? setIconProgressDetailCommand(faCaretDown)
                        : setIconProgressDetailCommand(faCaretUp)
                    }}
                    aria-expanded={progressDetailCommand}
                    aria-controls="example-collapse-text2"
                  />
                </h5>
              </Col>
              <Collapse in={progressDetailCommand}>
                <ul className="list-unstyled">
                  {detail?.progress?.length > 0 &&
                    detail?.progress?.map((progress) => (
                      <li>
                        <div className="d-flex flex-row gap-3 progress-detail">
                          <div className="circle-progress-detail"></div>
                          <p>{convertDateWithTime(progress?.datetime)}</p>
                          <div
                            className="d-flex flex-column justify-content-center"
                            style={{ height: '13px' }}
                          >
                            <div className="d-flex flex-row gap-3">
                              <div className="circle-small-progress-detail mt-2 ms-1"></div>
                              <span style={{ fontSize: '13px' }}>
                                {progress?.description}
                              </span>
                            </div>
                          </div>
                        </div>
                        <ul
                          className="list-unstyled"
                          style={{ marginLeft: '2rem' }}
                        >
                          {progress?.detail?.length > 0 &&
                            progress?.detail?.map((detail) => (
                              <li
                                style={{
                                  color: `${
                                    detail.color ? detail.color : 'black'
                                  } `
                                }}
                                className="mb-3"
                              >
                                {detail?.description}
                              </li>
                            ))}
                        </ul>
                      </li>
                    ))}
                </ul>
              </Collapse>
            </Row>
            <Row>
              <Label label="PNP" value={detail?.pnp_checker ?? '-'} col={4} />
              <Label label="RNR" value={detail?.rnr_checker ?? '-'} col={4} />
            </Row>
            <div
              style={{ height: '1rem', borderTop: '2px solid #F1F2F7' }}
            ></div>
            <Row>
              <Col md={12}>
                <Card>
                  <Card.Body>
                    <Row>
                      <Col xs={12} md={3} lg={3}>
                        <Label
                          label="CREATED BY"
                          value={!detail?.created_by ? '-' : detail?.created_by}
                        />
                      </Col>
                      <Col xs={12} md={3} lg={3}>
                        <Label
                          label="CREATED AT"
                          value={
                            !detail?.created_at || detail?.created_at === '-'
                              ? '-'
                              : convertDateWithTime(detail?.created_at)
                          }
                        />
                      </Col>
                      <Col xs={12} md={3} lg={3}>
                        <Label
                          label="LAST UPDATED BY"
                          value={!detail?.updated_by ? '-' : detail?.updated_by}
                        />
                      </Col>
                      <Col xs={12} md={3} lg={3}>
                        <Label
                          label="LAST UPDATED AT"
                          value={
                            !detail?.updated_at || detail?.updated_at === '-'
                              ? '-'
                              : convertDateWithTime(detail?.updated_at)
                          }
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Form>
        </Row>
        <ModalSolveContinueTicket
          show={showModalSolveContinueTicket}
          onHide={() => setShowModalSolveContinueTicket(false)}
          handleSubmitSolveContinueTicket={() =>
            handleSubmitSolveContinueTicket('modal')
          }
          submitSolveContinueTicket={submitSolveContinueTicket}
        />
        <ModalSelectFormat
          show={showModalSelectFormat}
          handleClose={() => {
            setShowModalSelectFormat(false)
            setSelectFormatDownload((prevSelectFormatDownload) => ({
              ...prevSelectFormatDownload,
              id: null,
              format: null
            }))
          }}
          setSelectFormatDownload={setSelectFormatDownload}
          selectFormatDownload={selectFormatDownload}
          handleDownloadPrint={handleDownloadPrint}
          loadingFormat={loadingFormat}
        />
        <ModalAddUpdateItem
          show={showModalAddUpdateItem}
          onHide={() => setShowModalAddUpdateItem(false)}
          data={editData}
          reportPickupCommand={pickupCommand?.filter(
            (item) => item.report_pack > 0
          )}
          pickupCommand={pickupCommand}
          command={detail?.command}
          sjDraftId={detail?.id}
          options={pickupCommand
            ?.filter((item) => item.report_pack > 0)
            .reduce((acc, cur) => {
              acc.push({
                id: cur?.item_code,
                label: cur?.item_name
              })
              return acc
            }, [])}
          refreshData={() => loadData(id)}
        />
      </Container>
    </>
  )
}

export default Detail
