import React from 'react'
import { Link } from 'react-router-dom'
import {
  BadgeStatusPrList,
  BadgeStatusPrListInbounds,
  BadgeStatusPrLists,
  ReceiveBill,
  WorkOrderStatus
} from '../../atoms/StatusLabel'

const headerTitleStyle = {
  color: 'var(--primary-orange)',
  fontSize: '24px',
  fontWeight: '600'
}

const index = ({
  label,
  link,
  back,
  status,
  type,
  labelSub = null,
  stateValue = null
}) => (
  <>
    <h4 style={headerTitleStyle}>
      {label}{' '}
      <sup style={{ fontSize: '12px', marginLeft: '5px' }}>
        {type === 'ReceiveBill' && <ReceiveBill text={status} />}
        {type === 'BadgeStatusPrList' && <BadgeStatusPrList text={status} />}
        {type === 'BadgeStatusPrListInbounds' && (
          <BadgeStatusPrListInbounds text={status} />
        )}
        {type === 'BadgeStatusPrLists' && <BadgeStatusPrLists text={status} />}
        {type === 'WorkOrderStatus' && <WorkOrderStatus text={status} />}
      </sup>
    </h4>
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb">
        <li className="breadcrumb-item sj-item">
          <Link to={link} className="text-decoration-none" state={stateValue}>
            <span className="text-black fw-bold">{back}</span>
          </Link>
        </li>
        <li className="breadcrumb-item sj-item active" aria-current="page">
          {!labelSub ? label : labelSub}
        </li>
      </ol>
    </nav>
  </>
)

export default index
