import { useContext, useEffect, useState } from 'react'
import { Form, Offcanvas } from 'react-bootstrap'
import api from '../../config/AxiosInstance'
import { UserContext } from '../../context/userContext'
import * as Common from '../../utils/Common'
function DrawerChangeWarehouse(props) {
  const [state] = useContext(UserContext)

  const {
    showCanvasWarehouse,
    handleCloseCanvasWarehouse,
    setShowModalChangePassword,
    handleLogOut
  } = props
  const [warehousesData, setWarehousesData] = useState([])
  useEffect(() => {
    fetchWarehouses()
  }, [])
  const handleChangeWarehouse = async (e) => {
    try {
      const res = await api.post('users/change-warehouse', {
        warehouse_id: e.target.value
      })
      const { data } = res
      if (data.success) {
        handleCloseCanvasWarehouse()
        window.location.reload()
      }
    } catch (error) {
      const err = error.response.data.errors.message || 'An error occurred'
      Common.showPopUpErrorMessage(err)
    }
  }

  const fetchWarehouses = async () => {
    try {
      const res = await api.get(
        '/master/warehouses/all?is_parent=true&forUser=1'
      )
      const { data } = res
      if (data.success) {
        setWarehousesData(data.data)
      }
    } catch (error) {
      const err = error.response.data.errors.message || 'An error occurred'
      Common.showPopUpErrorMessage(err)
    }
  }
  return (
    <>
      <Offcanvas
        show={showCanvasWarehouse}
        onHide={handleCloseCanvasWarehouse}
        placement="end"
        id="change-warehouse-drawer"
      >
        <Offcanvas.Header closeButton></Offcanvas.Header>
        <Offcanvas.Body>
          <div className="d-flex flex-column gap-1 fs-6">
            <p
              className="ps-2 cursor-pointer"
              role="button"
              onClick={() => setShowModalChangePassword(true)}
            >
              Change Password
            </p>
            <p
              className="ps-2 cursor-pointer"
              role="button"
              onClick={handleLogOut}
              style={{ color: '#C12727' }}
            >
              Sign Out
            </p>
            <div style={{ border: '1px solid #F1F2F7' }}></div>
            <div className="d-flex flex-column gap-3 ps-2 fs-6 mt-4">
              {warehousesData.length &&
                warehousesData.map((warehouse) => (
                  <Form.Check
                    type={'radio'}
                    id={`default-${warehouse.id}`}
                    label={warehouse.warehouse_name}
                    value={warehouse.warehouse_id}
                    checked={warehouse.is_selected}
                    onChange={handleChangeWarehouse}
                    className="cursor-pointer"
                    role="button"
                  />
                ))}
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
}

export default DrawerChangeWarehouse
