import { useEffect, useState } from 'react'
import { Col, Container, Form, Row, Spinner, Table } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import BreadCrumb from '../../components/Label/Breadcrumb/index'
import api from '../../config/AxiosInstance'
import { convertDateWithoutTime } from '../../helpers/constants'
import * as Common from '../../utils/Common'
const DetailMaterialRequest = () => {
  const titleStyle = {
    fontSize: '12px',
    letterSpacing: '0.5px'
  }
  const { id } = useParams()

  const [detailMaterialRequest, setDetailMaterialRequest] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    fetchDetailMaterialRequest(id)
  }, [id])
  const fetchDetailMaterialRequest = async (id) => {
    try {
      setIsLoading(true)
      const response = await api.post(`/material-request/detail`, {
        mr_code: id
      })
      const { data } = response
      if (data.success) {
        setDetailMaterialRequest(data.data)
      }
    } catch (error) {
      const errMsg =
        error?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setIsLoading(false)
    }
  }
  if (isLoading) {
    return (
      <div>
        <Row>
          <Col md={12}>
            <div
              style={{ height: '100vh' }}
              className="d-flex flex-column justify-content-center"
            >
              <div className="d-flex flex-row justify-content-center">
                <div className="d-flex flex-column gap-3">
                  <div className="d-flex flex-row justify-content-center gap-2">
                    <Spinner animation="grow" variant="warning" />
                    <Spinner animation="grow" variant="warning" />
                    <Spinner animation="grow" variant="warning" />
                    <Spinner animation="grow" variant="warning" />
                    <Spinner animation="grow" variant="warning" />
                  </div>
                  <p
                    className="fw-bold text-center"
                    style={{ fontSize: '13px' }}
                  >
                    Loading...
                  </p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
  return (
    <>
      <Container>
        <Row className="mb-2">
          <Col>
            <BreadCrumb
              label={detailMaterialRequest?.code}
              link="/material-request/"
              back="Material Request"
            />
          </Col>
        </Row>
        <Row className="mb-4">
          <Col md={4}>
            <Form.Label
              style={titleStyle}
              className="fw-bold text-secondary text-uppercase"
            >
              <small>source warehouse</small>
            </Form.Label>
            <Form.Control
              disabled
              placeholder={detailMaterialRequest?.src_name ?? '-'}
            />
          </Col>
          <Col md={4}>
            <Form.Label
              style={titleStyle}
              className="fw-bold text-secondary text-uppercase"
            >
              <small>target warehouse</small>
            </Form.Label>
            <Form.Control
              disabled
              placeholder={detailMaterialRequest?.target_name ?? '-'}
            />
          </Col>
          <Col md={2}>
            <Form.Label
              style={titleStyle}
              className="fw-bold text-secondary text-uppercase"
            >
              <small>delivery date</small>
            </Form.Label>
            <Form.Control
              disabled
              placeholder={
                detailMaterialRequest.delivery_date
                  ? convertDateWithoutTime(detailMaterialRequest.delivery_date)
                  : '-'
              }
            />
          </Col>
          <Col md={2}>
            <Form.Label
              style={titleStyle}
              className="fw-bold text-secondary text-uppercase"
            >
              <small>#MR</small>
            </Form.Label>
            <Form.Control disabled placeholder={detailMaterialRequest?.code} />
          </Col>
        </Row>
        <Row>
          <Col>
            <h3 className="text-secondary fw-semibold mb-3">Request</h3>
            <Table
              hover
              size="m"
              responsive="sm"
              className="shadow-sm mb-5 bg-white rounded"
              style={{ fontSize: '13px' }}
            >
              <thead className="table-secondary">
                <tr>
                  <th className="p-3 align-middle">#</th>
                  <th className="p-3 align-middle">Item</th>
                  <th className="p-3 align-middle">Item Code</th>
                  <th className="p-3 align-middle text-end">Qty</th>
                </tr>
              </thead>
              <tbody>
                {!detailMaterialRequest?.items?.length && (
                  <tr key={1}>
                    <td colSpan={4} className="text-center fw-bold">
                      No Data
                    </td>
                  </tr>
                )}
                {detailMaterialRequest?.items?.length > 0 &&
                  detailMaterialRequest?.items.map((item, index) => (
                    <tr key={item.id}>
                      <td className="p-3">{index + 1}</td>
                      <td className="p-3">{item.item_name}</td>
                      <td className="p-3">{item.item_code}</td>
                      <td className="p-3 text-end">
                        {item.total_qty} {item.uom_qty}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default DetailMaterialRequest
