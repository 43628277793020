import L from 'leaflet'
import 'leaflet-draw/dist/leaflet.draw.css'
import 'leaflet/dist/leaflet.css'
import Pusher from 'pusher-js'
import { useContext, useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { ImageOverlay, MapContainer, Rectangle } from 'react-leaflet'
import api from '../../config/AxiosInstance'
import { UserContext } from '../../context/userContext'
import '../../styles/style.css'
import * as Common from '../../utils/Common'
// const socket = io.connect('https://bajnewbe.3qt9.lyr.id/')
function IndexMover(props) {
  const [data, setData] = useState(null)
  const [coordinates, setCoordinates] = useState(null)
  const [state] = useContext(UserContext)
  const pusher = new Pusher('b4fd69a93b89d910f024', {
    cluster: 'ap1'
  })
  var channel = pusher.subscribe(`${state?.username?.roles[0]?.warehouse_id}`)
  channel.bind(`wms`, function (data) {
    setCoordinates(
      data.reduce((accumulator, currentValue) => {
        accumulator.push(currentValue.coordinate)
        return accumulator
      }, [])
    )
  })
  useEffect(() => {
    fetchMe()
  }, [])

  // useEffect(() => {
  //   // Setup the socket connection once when the component mounts
  //   socket.on('wms', (data) => {
  //     let coordinates = data.reduce((accumulator, currentValue) => {
  //       accumulator.push(currentValue.coordinate)
  //       return accumulator
  //     }, [])
  //     setCoordinates(coordinates)
  //   })

  //   // Cleanup when the component is unmounted
  //   return () => {
  //     socket.off('wms')
  //   }
  // }, [socket])

  const fetchMe = async () => {
    try {
      const res = await api.get('users/me')
      const { pnp } = res.data.data
      setData(pnp)
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }

  return (
    <div className="mt-4">
      <Container>
        <Row>
          <Col>
            <Row>
              <Col className="d-flex align-items-center justify-content-center">
                <div
                  className="d-flex flex-row justify-content-center h-100 text-white fw-bold"
                  style={{
                    backgroundColor: '#F89D1D',
                    padding: '.7rem',
                    borderRadius: '12px'
                  }}
                >
                  <p className="d-flex flex-column justify-content-center align-items-center h-100 fs-6">
                    L.N.A.D.1.01.1.1 • EFKA FA 4609 AN • 3 Drum
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <div className="row mt-4">
          <div className="col-12">
            <div className="card">
              <div className="card-body card-map-pnp">
                <div className="leaflet-map-container">
                  <MapContainer
                    center={[500, 500]}
                    zoom={1}
                    crs={L.CRS.Simple}
                    scrollWheelZoom={false}
                    style={{ height: '100%', width: '100%' }}
                  >
                    <ImageOverlay
                      url={
                        !data
                          ? 'http://www.lib.utexas.edu/maps/historical/newark_nj_1922.jpg'
                          : data?.map
                      }
                      bounds={[
                        [0, 0],
                        [1000, 1000]
                      ]}
                    />
                    {coordinates?.map((coordinate, i) => (
                      <Rectangle
                        bounds={coordinate}
                        color="transparent"
                        fillColor={'#F89D1D'}
                        fillOpacity={0.5}
                        key={i}
                      ></Rectangle>
                    ))}
                  </MapContainer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}
export default IndexMover
