import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Loader } from 'react-bootstrap-typeahead'
import Swal from 'sweetalert2'
import api from '../../../config/AxiosInstance.js'
import * as Common from '../../../utils/Common.js'
import CustomButton from '../../Button/CustomButton.jsx'
function ModalAddSelectStagingArea(props) {
  // style
  const labelCheckboxActive = {
    border: '1px solid transparent',
    backgroundColor: '#F2f2f2',
    padding: '1rem',
    marginTop: '.3rem',
    width: '100%'
  }
  const labelCheckboxUnactive = {
    border: '1px solid transparent',
    backgroundColor: '#C9CACD',
    padding: '1rem',
    marginTop: '.3rem',
    width: '100%'
  }
  // state
  const [selectStagingArea, setSelectStagingArea] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [submitButton, setSubmitButton] = useState(false)
  const [stagings, setStagings] = useState([])

  const fetchStagingArea = async () => {
    // read data from warehouse id
    try {
      setIsLoading(true)
      const res = await api.get('/outbound/outbound_plan/staging', {
        params: { warehouse_id: props.warehouseId }
      })
      if (res.status === 200) {
        setStagings(res.data.data)
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (props.show) {
      fetchStagingArea()
    }
  }, [props.show, props.warehouseId, props.inboundId])

  // event handler
  const handleSelectStaging = (e) => {
    const { value, checked } = e.target

    if (selectStagingArea.length + 1 > 2 && checked) {
      Swal.fire({
        icon: 'error',
        title: 'Sorry...',
        text: 'You can only select 2 staging area'
      })
      e.target.checked = false
      return
    }
    if (checked) {
      setSelectStagingArea((prevItems) => [...prevItems, value])
    } else {
      setSelectStagingArea((selectStagingArea) =>
        selectStagingArea.filter((stagingArea) => stagingArea !== value)
      )
    }
  }

  const handleSubmit = async () => {
    try {
      setSubmitButton(true)
      const values = {
        inbound_id: props.inboundId,
        stagings: selectStagingArea
      }
      const res = await api.post(`inbound/ml-draft/assign-staging`, values)
      if (res.data.success) {
        props.onHide()
        props.refreshData()
        Common.showPopUpSuccessMessage(res.data.message)
        setSelectStagingArea([])
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'

      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setSubmitButton(false)
    }
  }
  return (
    <Modal
      {...props}
      onHide={() => {
        props.onHide()
        setSelectStagingArea([])
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title>Select Staging Area</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: '80vh', overflow: 'auto' }}>
        <p style={{ color: '#C12727', paddingTop: '20px' }}>
          Please select carefully as this action cannot be repeated
        </p>
        {isLoading && (
          <div className="text-center">
            <Loader />
          </div>
        )}
        {!isLoading && stagings.length === 0 && (
          <div className="text-center">
            <p className="fw-bold">Staging Area Empty</p>
          </div>
        )}
        {/* form checkbox staging area */}
        {!isLoading &&
          stagings.length > 0 &&
          stagings.map((data, index) => (
            <div
              key={index}
              className={`d-flex flex-row gap-3 ${
                index > 0 ? 'mt-2 form-check' : 'form-check'
              }`}
            >
              <div className="d-flex flex-column justify-content-center">
                <input
                  className="staging-area form-check-input fe-auto"
                  type="checkbox"
                  name="stagings"
                  value={data.staging_area_id}
                  id={data.staging_area_id}
                  disabled={data.is_used}
                  onChange={(e) => handleSelectStaging(e)}
                  role="button"
                  style={{ fontSize: '13px' }}
                />
              </div>
              <label
                className="form-check-label ml-3 fw-bold text-center"
                htmlFor={data.staging_area_id}
                style={
                  data.is_used ? labelCheckboxUnactive : labelCheckboxActive
                }
              >
                {data.staging_name}
              </label>
            </div>
          ))}

        <Modal.Footer className="mt-3">
          <CustomButton
            onClick={handleSubmit}
            variant={selectStagingArea.length === 0 ? 'secondary' : 'warning'}
            disabled={
              selectStagingArea.length === 0 || submitButton ? true : false
            }
            text={submitButton ? 'Loading...' : 'Save'}
          />
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  )
}

export default ModalAddSelectStagingArea
