import { Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { Col, Form, Modal, Row } from 'react-bootstrap'
import api from '../../../config/AxiosInstance'
import * as Common from '../../../utils/Common'
import { RackAddEditSchema } from '../../../utils/validationSchema'
import CustomButton from '../../Button/CustomButton'
import CustomCloseButton from '../../Button/CustomCloseButton'
import SelectInput from '../../Input/SelectInput'
import TextField from '../../Input/TextInput'

const rackes = [
  { id: 'rack', name: 'Rack' },
  { id: 'ground', name: 'Ground' }
]

function ModalRack(props) {
  const [areas, setAreas] = useState([])
  const { warehouseId } = props
  const [dataValue, setDataValue] = useState()
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [typeValue, setTypeValue] = useState('')
  useEffect(() => {
    if (warehouseId?.id !== '') {
      loadArea()
    }
  }, [warehouseId?.id])

  const handleSubmit = async (values) => {
    try {
      setLoadingSubmit(true)
      const response = await api.post('/master/racks', {
        ...values,
        category_id: dataValue
      })
      if (response.data.success) {
        props.onHide()
        props.refreshData()
        Common.showPopUpSuccessMessage(response.data.message)
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setLoadingSubmit(false)
    }
  }

  const loadArea = async () => {
    try {
      const res = await api.get(
        `/master/area/all?id=${warehouseId?.warehouseId}`
      )
      setAreas(res.data.data)
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }

  return (
    <Modal
      {...props}
      onHide={() => {
        props.onHide()
        setDataValue([])
        setTypeValue('')
      }}
      centered
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Add Rack</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{
            warehouse_id: warehouseId?.id,
            rack_name: '',
            total_beam: '',
            total_level: '',
            area_id: '',
            type: 'rack',
            category_id: ''
          }}
          validationSchema={RackAddEditSchema}
          onSubmit={(values) => {
            handleSubmit(values)
          }}
        >
          {({
            handleBlur,
            handleSubmit,
            handleChange,
            values,
            errors,
            isValid,
            dirty,
            touched,
            setFieldValue
          }) => {
            return (
              <Form className="d-grid gap-2" onSubmit={handleSubmit}>
                <Row>
                  <Col md={6}>
                    <TextField
                      label="name"
                      name="rack_name"
                      value={values.rack_name}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                      placeholder="Input rack name"
                    />
                  </Col>
                  <Col md={3}>
                    <TextField
                      label="total beam"
                      name="total_beam"
                      value={values.total_beam}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                      placeholder="Input total beam"
                      type="number"
                    />
                  </Col>
                  <Col md={3}>
                    <TextField
                      label="total level"
                      name="total_level"
                      value={values.total_level}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                      placeholder="Input total level"
                      type="number"
                    />
                  </Col>
                  <Col md={6}>
                    <SelectInput
                      label="area"
                      name="area_id"
                      id="area_id"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                      options={areas}
                    />
                  </Col>
                  <Col md={6}>
                    <SelectInput
                      label="type"
                      name="type"
                      id="type"
                      handleBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                      options={rackes}
                      handleChange={(e) => {
                        if (e.target.value === 'ground') {
                          setFieldValue('type', 'ground')
                          setFieldValue('total_beam', 1)
                          setFieldValue('total_level', 1)
                          setTypeValue('ground')
                        } else {
                          setFieldValue('type', 'rack')
                          setFieldValue('total_beam', values.total_beam ?? '')
                          setFieldValue('total_level', values.total_level ?? '')
                          setTypeValue('rack')
                        }
                      }}
                    />
                  </Col>
                </Row>
                <Modal.Footer className="mt-3">
                  <CustomCloseButton onClick={props.onHide} />
                  <CustomButton
                    onClick={handleSubmit}
                    variant={!loadingSubmit ? 'warning' : 'secondary'}
                    disabled={!isValid || !dirty || loadingSubmit}
                    text={!loadingSubmit ? 'Add' : 'Loading...'}
                  />
                </Modal.Footer>
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default ModalRack
