import { faClose, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Formik } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import { Card, Form, Modal, Row, Col } from 'react-bootstrap'
import api from '../../../config/AxiosInstance'
import '../../../styles/style.css'
import * as Common from '../../../utils/Common'
import { VehicleAddEditSchema } from '../../../utils/validationSchema'
import CustomButton from '../../Button/CustomButton'
import CustomCloseButton from '../../Button/CustomCloseButton'
import TextField from '../../Input/TextInput'
import SelectInput from '../../Input/SelectInput'

const statuses = [
  { id: '1', name: 'Active' },
  { id: '0', name: 'Inactive' }
]

function ModalUpdateVehicle(props) {
  const showModal = props.show
  const [data, setData] = useState({})
  const fileUploadStnk = useRef(null)
  // state filename
  const [fileNameStnk, setFileNameStnk] = useState('')
  useEffect(() => {
    if (showModal) {
      fetchVehicle(props.data)
    }
  }, [props.data, showModal])

  async function fetchVehicle(id) {
    try {
      const res = await api.get(`master/vehicles/${id}`)

      const result = res.data

      result.data.status = result.data.status === true ? 1 : 0
      setData(result.data)
      if (result.data.stnk_doc) {
        setFileNameStnk(result.data.stnk_doc)
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'

      Common.showPopUpErrorMessage(errMsg)
    }
  }

  const handleSubmit = async (values, actions) => {
    try {
      values.type = props.activeKey
      const formData = new FormData()
      for (const key in values) {
        formData.append(key, values[key])
      }
      const response = await api.postForm(
        `master/vehicles/${data.id}`,
        formData
      )

      if (response.data.success) {
        props.onHide()
        props.setIsUpdate((update) => !update)
        Common.showPopUpSuccessMessage(response.data.message)
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'

      Common.showPopUpErrorMessage(errMsg)
    }
  }

  return (
    <Modal {...props} centered>
      <Formik
        initialValues={{
          plate_no: data?.plate_no,
          vehicle_type: data?.vehicle_type,
          length: data?.length,
          width: data?.width,
          height: data?.height,
          volume: data?.volume,
          capacity: data?.capacity,
          status: data?.status,
          typeVehicle: props.activeKey === 'external' ? true : false,
          stnk_doc: data?.stnk_doc
        }}
        enableReinitialize={true}
        validationSchema={VehicleAddEditSchema}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={(values) => {
          handleSubmit(values)
        }}
      >
        {({
          handleBlur,
          handleSubmit,
          handleChange,
          values,
          errors,
          touched,
          isValid,
          setFieldValue
        }) => {
          return (
            <Form className="d-grid gap-2" onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title>Update Vehicle</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col md={6}>
                    <TextField
                      label="PLATE NO."
                      name="plate_no"
                      value={values.plate_no}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                      required={true}
                    />
                  </Col>
                  <Col md={6}>
                    <TextField
                      label="VEHICLE TYPE"
                      name="vehicle_type"
                      value={values.vehicle_type}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                      required={true}
                    />
                  </Col>
                  <Col md={6}>
                    <TextField
                      label="PANJANG(CM)"
                      type="number"
                      name="length"
                      value={values.length}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                      required={true}
                    />
                  </Col>
                  <Col md={6}>
                    <TextField
                      label="LEBAR(CM)"
                      type="number"
                      name="width"
                      value={values.width}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                      required={true}
                    />
                  </Col>
                  <Col md={6}>
                    <TextField
                      label="TINGGI(CM)"
                      type="number"
                      name="height"
                      value={values.height}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                      required={true}
                    />
                  </Col>
                  <Col md={6}>
                    <TextField
                      label="CAPACITY(KG)"
                      type="number"
                      name="capacity"
                      value={values.capacity}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                      required={true}
                    />
                  </Col>
                  <Col md={12}>
                    <TextField
                      label="VOLUME(CM³)"
                      type="number"
                      name="volume"
                      value={values.volume}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                      required={true}
                    />
                  </Col>
                  {props.activeKey === 'external' && (
                    <div className="row">
                      <div className="col">
                        <Form.Group>
                          <Form.Label
                            className="text-muted"
                            style={{ fontSize: '13px' }}
                          >
                            STNK <sup style={{ color: 'red' }}>*</sup>
                          </Form.Label>
                          {fileNameStnk === '' && (
                            <Card
                              style={{
                                width: '3rem',
                                height: '3rem',
                                cursor: 'pointer'
                              }}
                              onClick={() => fileUploadStnk.current.click()}
                            >
                              <Card.Body>
                                <div style={{ marginTop: '-.3rem' }}>
                                  <FontAwesomeIcon
                                    icon={faPlus}
                                    style={{ color: '#828282' }}
                                  />
                                </div>
                              </Card.Body>
                            </Card>
                          )}
                          {fileNameStnk !== '' && (
                            <Card>
                              <Card.Body>
                                <div className="d-flex h-100 align-items-center">
                                  <FontAwesomeIcon
                                    icon={faClose}
                                    style={{
                                      color: '#828282',
                                      fontSize: '20px',
                                      cursor: 'pointer'
                                    }}
                                    onClick={() => {
                                      setFileNameStnk('')
                                      setFieldValue('stnk_doc', '')
                                    }}
                                  />
                                  <span className="ml-2">
                                    {fileNameStnk.split('/').pop()}
                                  </span>
                                </div>
                              </Card.Body>
                            </Card>
                          )}
                          <input
                            type="file"
                            name="stnk_doc"
                            accept="image/jpg,image/jpeg,image/png,.doc,.docx,.pdf"
                            onChange={(e) => {
                              setFieldValue('stnk_doc', e.target.files[0])
                              setFileNameStnk(e.target.files[0].name)
                            }}
                            onBlur={handleBlur}
                            className="d-none"
                            ref={fileUploadStnk}
                          />
                          {!!errors.stnk_doc && (
                            <p className="text-danger mt-2">
                              {errors.stnk_doc}
                            </p>
                          )}
                        </Form.Group>
                      </div>
                    </div>
                  )}
                  <Col md={12}>
                    <SelectInput
                      label="Status"
                      name="status"
                      id="status"
                      handleChange={handleChange}
                      value={values.status}
                      handleBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                      options={statuses}
                      required={true}
                    />
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <CustomCloseButton onClick={props.onHide} />
                <CustomButton
                  onClick={handleSubmit}
                  variant={'warning'}
                  disabled={!isValid}
                  text={'Update'}
                />
              </Modal.Footer>
            </Form>
          )
        }}
      </Formik>
    </Modal>
  )
}

export default ModalUpdateVehicle
