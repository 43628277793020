import { Formik } from 'formik'
import { useState } from 'react'
import { Col, Form, Modal, ModalHeader, Row } from 'react-bootstrap'
import Select from 'react-select'
import api from '../../../config/AxiosInstance'
import * as Common from '../../../utils/Common'
import { AddItemMaterialRequest } from '../../../utils/validationSchema'
import CustomButton from '../../Button/CustomButton'
import CustomCloseButton from '../../Button/CustomCloseButton'
const ModalAddMaterialRequest = (props) => {
  const titleStyle = {
    fontSize: '12px',
    letterSpacing: '0.5px'
  }
  const { items, warehouseId, pickupCommands, handlePickupCommands } = props
  const [batches, setBatches] = useState([])
  const [packBatches, setPackBatches] = useState(null)
  const [errorPack, setErrorPack] = useState(null)
  const [errorBatch, setErrorBatch] = useState(null)
  const [batchOptions, setBatchOptions] = useState(null)
  const fetchBatchByItem = async (itemId) => {
    try {
      const response = await api.get(
        `/material-request/all-batch?item_id=${itemId}`
      )
      const { data } = response
      if (data.success) {
        setBatches(data.data)
        setBatchOptions(
          data?.data?.reduce((acc, item) => {
            acc.push({
              value: item.item_batch_id,
              label: item.batch
            })
            return acc
          }, [])
        )
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }

  const checkQtyBatchExist = async (batchId) => {
    try {
      const response = await api.get(
        `/material-request/quantity-batch?item_batch_id=${batchId}&warehouse_id=${warehouseId}`
      )

      const { data } = response
      if (data.success) {
        setPackBatches(data.data)
        if (!data.data) {
          setErrorBatch(
            `Batch hasn't enough amount quantity, please select another batch`
          )
        } else {
          setErrorBatch(null)
        }
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }
  const getSuggestionSlots = async (value) => {
    try {
      const { batch_id, pack } = value
      const res = await api.get(`/material-request/suggestion`, {
        params: {
          item_batch_id: batch_id,
          warehouse_id: warehouseId,
          total_pack: pack
        }
      })
      const { data } = res

      if (data.success) {
        handlePickupCommands(data.data, value)
        setErrorPack(null)
        setPackBatches(null)
        props.onHide()
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }
  return (
    <Modal
      {...props}
      centered
      onHide={() => {
        props.onHide()
        setErrorPack(null)
        setPackBatches(null)
      }}
    >
      <ModalHeader closeButton>Add Item</ModalHeader>
      <Modal.Body>
        <Formik
          initialValues={{
            item_id: '',
            item_alias: '',
            batch_id: '',
            exp_date: '',
            pack: '',
            qty: 0
          }}
          validationSchema={AddItemMaterialRequest}
          validateOnChange={true}
          validateOnBlur={true}
          onSubmit={(values) => {
            getSuggestionSlots(values)
          }}
        >
          {({
            handleBlur,
            handleSubmit,
            handleChange,
            values,
            errors,
            touched,
            isValid,
            dirty,
            setFieldValue,
            setTouched
          }) => {
            return (
              <Form>
                <Row className="mb-3">
                  <Col>
                    <Form.Label
                      className="fw-semibold text-secondary text-uppercase"
                      style={titleStyle}
                    >
                      <small>item</small>
                    </Form.Label>
                    <Form.Select
                      name="item_id"
                      onChange={(e) => {
                        setFieldValue('item_id', e.target.value)
                        setFieldValue('batch_id', '')
                        setFieldValue('pack', '')
                        setFieldValue('qty', '')
                        fetchBatchByItem(e.target.value)
                      }}
                      onBlur={handleBlur}
                      isInvalid={touched.item_id && !!errors.item_id}
                      value={values.item_id}
                    >
                      <option value="">All</option>
                      {items?.map((item) => (
                        <option value={item?.item_id} key={item?.item_id}>
                          {item?.item_name}
                        </option>
                      ))}
                    </Form.Select>
                    {touched.item_id && errors.item_id && (
                      <Form.Control.Feedback type="invalid">
                        {errors.item_id}
                      </Form.Control.Feedback>
                    )}
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col>
                    <Form.Label
                      className="fw-semibold text-secondary text-uppercase"
                      style={titleStyle}
                    >
                      <small>item alias</small>
                    </Form.Label>
                    <Form.Control value={values.item_alias} disabled />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={6}>
                    <Form.Label
                      className="fw-semibold text-secondary text-uppercase"
                      style={titleStyle}
                    >
                      <small>batch</small>
                    </Form.Label>

                    <Select
                      name="batch_id"
                      options={batchOptions}
                      value={
                        batchOptions?.find(
                          (option) => option.value === values.batch_id
                        ) || null
                      }
                      onChange={(e) => {
                        if (!e) {
                          setErrorBatch(null)
                          setFieldValue('batch_id', '')
                          setFieldValue('exp_date', '')
                          setFieldValue('pack', '')
                          setFieldValue('qty', '')
                          setPackBatches(null)
                          setTouched({ ...touched, batch_id: true }, true) // Set the field as touched
                        } else {
                          setFieldValue('batch_id', e.value)
                          setFieldValue(
                            'exp_date',
                            e.value
                              ? batches?.find(
                                  (batch) => batch.item_batch_id === e.value
                                )?.exp_date || ''
                              : ''
                          )
                          checkQtyBatchExist(e.value)
                        }
                      }}
                      isDisabled={!values.item_id}
                      isClearable={true}
                      onBlur={() => {
                        setTouched({ ...touched, batch_id: true }, true) // Set the field as touched
                        handleBlur('batch_id')
                      }}
                    />
                    {touched.batch_id && !!errors.batch_id && (
                      <p
                        className="text-danger mt-1"
                        style={{ fontSize: '12px' }}
                      >
                        {errors.batch_id}
                      </p>
                    )}
                    {errorBatch && (
                      <p
                        className="text-danger mt-1"
                        style={{ fontSize: '12px' }}
                      >
                        {errorBatch}
                      </p>
                    )}
                  </Col>
                  <Col md={6}>
                    <Form.Label
                      className="fw-semibold text-secondary text-uppercase"
                      style={titleStyle}
                    >
                      <small>EXP Date</small>
                    </Form.Label>
                    <Form.Control value={values.exp_date} disabled />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={6}>
                    <Form.Label
                      className="fw-semibold text-secondary text-uppercase"
                      style={titleStyle}
                    >
                      <small>pack</small>
                    </Form.Label>
                    <Form.Control
                      type="number"
                      name="pack"
                      min={1}
                      disabled={!values.batch_id || !packBatches}
                      onChange={(e) => {
                        handleChange(e)
                        const totalQty =
                          e.target.value *
                          items?.find((item) => item.item_id === values.item_id)
                            ?.convertion_factor_pack
                        const totalQtyRequest = items?.find(
                          (item) => item.item_id === values.item_id
                        )?.total_qty
                        const totalQtyPickupCommand = pickupCommands.items
                          ?.filter(
                            (pickupCommand) =>
                              pickupCommand.item_id === values.item_id
                          )
                          .reduce((acc, item) => {
                            const itemTotal = item.slots.reduce(
                              (slotAcc, slot) => {
                                return slotAcc + parseInt(slot.total_qty) // Convert to number to ensure summation
                              },
                              0
                            )
                            return acc + itemTotal
                          }, 0)

                        if (totalQty > packBatches?.total_qty) {
                          setFieldValue('pack', '')
                          setFieldValue('qty', 0)
                          setErrorPack(
                            'Total qty input more than available qty on batch'
                          )
                        } else if (
                          totalQty + totalQtyPickupCommand >
                          totalQtyRequest
                        ) {
                          setFieldValue('pack', '')
                          setFieldValue('qty', 0)
                          setErrorPack(
                            'Total qty input more than total qty on item request'
                          )
                        } else {
                          setFieldValue('qty', totalQty)
                          setErrorPack(null)
                        }
                      }}
                      onBlur={handleBlur}
                      onFocus={() => {
                        setErrorPack(null)
                        setTouched({ ...touched, pack: false }, true)
                      }}
                      value={values.pack}
                      isInvalid={touched.pack && !!errors.pack}
                    />
                    {touched.pack && !!errors.pack && (
                      <Form.Control.Feedback type="invalid">
                        {errors.pack}
                      </Form.Control.Feedback>
                    )}
                    {errorPack && (
                      <p
                        className="text-danger fw-bold mt-2"
                        style={{ fontSize: '12px' }}
                      >
                        {errorPack}
                      </p>
                    )}
                  </Col>
                  <Col md={6}>
                    <Form.Label
                      className="fw-semibold text-secondary text-uppercase"
                      style={titleStyle}
                    >
                      <small>qty</small>
                    </Form.Label>
                    <Form.Control value={values.qty} disabled />
                  </Col>
                </Row>
                <Modal.Footer>
                  <CustomCloseButton
                    onClick={() => {
                      props.onHide()
                      setErrorPack(null)
                      setPackBatches(null)
                    }}
                  />
                  <CustomButton
                    variant={'warning'}
                    text={'Add'}
                    onClick={handleSubmit}
                    disabled={!isValid || !dirty || errorPack || errorBatch}
                  />
                </Modal.Footer>
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default ModalAddMaterialRequest
