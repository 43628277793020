import { Formik } from 'formik'
import React from 'react'
import { Col, Form, Modal, Row } from 'react-bootstrap'
import 'react-toggle/style.css'
import api from '../../../config/AxiosInstance'
import '../../../styles/style.css'
import * as Common from '../../../utils/Common'
import { StagingAreaAddSchema } from '../../../utils/validationSchema'
import CustomButton from '../../Button/CustomButton'
import CustomCloseButton from '../../Button/CustomCloseButton'
import TextField from '../../Input/TextInput'

function ModalAddStagingArea(props) {
  const handleSubmit = async (values, actions) => {
    try {
      const response = await api.post('/master/stagings', values)

      if (response.data.success) {
        props.onHide()
        props.refreshData()
        Common.showPopUpSuccessMessage(response.data.message)
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'

      Common.showPopUpErrorMessage(errMsg)
    }
  }

  return (
    <Modal {...props} centered>
      <Modal.Header closeButton>
        <Modal.Title>Add Staging Area</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{
            staging_name: '',
            block_total: ''
          }}
          validationSchema={StagingAreaAddSchema}
          onSubmit={(values) => {
            // add data warehouse id from props
            values.warehouse_id = props.data
            handleSubmit(values)
          }}
        >
          {({
            handleBlur,
            handleSubmit,
            handleChange,
            values,
            setFieldValue,
            errors,
            touched,
            isValid,
            dirty
          }) => {
            return (
              <Form className="d-grid gap-2" onSubmit={handleSubmit}>
                <Row>
                  <Col md={12}>
                    <TextField
                      id="staging_name"
                      label="Name"
                      name="staging_name"
                      value={values.staging_name}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                      className="mb-1"
                      required={true}
                    />
                  </Col>
                  <Col md={12}>
                    <TextField
                      id="block_total"
                      label="TOTAL BLOCK"
                      name="block_total"
                      value={values.block_total}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                      className="mb-1"
                      required={true}
                      type="number"
                    />
                  </Col>
                </Row>
                <Modal.Footer className="mt-3">
                  <CustomCloseButton onClick={props.onHide} />
                  <CustomButton
                    onClick={handleSubmit}
                    variant={'warning'}
                    disabled={!isValid || !dirty}
                    text={'Add'}
                  />
                </Modal.Footer>
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default ModalAddStagingArea
