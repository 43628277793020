import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { Col, Container, Row, Table } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import Loader from '../../components/atoms/Loader'
import RowEmpty from '../../components/atoms/RowEmpty'
import { WorkOrderStatus } from '../../components/atoms/StatusLabel'
import TableAction from '../../components/atoms/Table/TableAction'
import TablePagination from '../../components/atoms/Table/TablePagination'
import api from '../../config/AxiosInstance'
import {
  convertDateWithoutTime,
  convertDateWithTime
} from '../../helpers/constants'
import * as Common from '../../utils/Common'

const WorkOrderPlan = () => {
  const headerTitleStyle = {
    color: 'var(--primary-orange)',
    fontSize: '24px',
    fontWeight: '600'
  }

  const [workOrder, setWorkOrder] = useState([])
  const [loading, setLoading] = useState(false)
  const [meta, setMeta] = useState({
    per_page: 10,
    current_page: 1,
    total_row: 0,
    total_page: 10
  })

  const fetchListWorkOrder = async () => {
    try {
      setLoading(true)
      const res = await api.get('/outbound/work-order', {
        params: {
          per_page: meta.per_page,
          page: meta.current_page
        }
      })
      setWorkOrder(res.data.data)
      setMeta(res.data.metadata)
    } catch (error) {
      const errMsg =
        error?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setLoading(false)
    }
  }

  const handlePageChange = (data) => {
    setMeta((prevMeta) => ({
      ...prevMeta,
      current_page: data.selected + 1
    }))
    window.scrollTo(0, 0)
  }

  const handleOnChangeMeta = (e) => {
    setMeta((prevMeta) => ({
      ...prevMeta,
      per_page: parseInt(e.target.value, 10),
      current_page: 1
    }))
  }

  useEffect(() => {
    fetchListWorkOrder()
  }, [meta.per_page, meta.current_page])

  const navigate = useNavigate()
  return (
    <>
      <Container>
        <Row>
          <Col>
            <h1 style={headerTitleStyle}>Work Order Plan</h1>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={12}>
            <Table
              hover
              size="m"
              responsive="sm"
              className="shadow-sm mb-5 bg-white rounded"
              style={{ fontSize: '13px' }}
            >
              <thead className="table-secondary">
                <tr>
                  <th className="p-3 align-middle">#Work Order</th>
                  <th className="p-3 align-middle">Request Time</th>
                  <th className="p-3 align-middle">Deliver Plan</th>
                  <th className="p-3 align-middle">Finish Time</th>
                  <th className="p-3 align-middle">SJ</th>
                  <th className="p-3 align-middle">ERP Doc. No.</th>
                  <th className="p-3 align-middle">Warehouse</th>
                  <th className="p-3 align-middle">Type</th>
                  <th className="p-3 align-middle">Status</th>
                  <th className="p-3 align-middle">Action</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <>
                    <tr>
                      <td colSpan={10}>
                        <Loader />
                      </td>
                    </tr>
                  </>
                ) : workOrder.length === 0 ? (
                  <RowEmpty colSpan={10} />
                ) : (
                  workOrder.map((item) => (
                    <>
                      <tr key={item.id}>
                        <td className="p-3 align-middle">{item.wo_code}</td>
                        <td className="p-3 align-middle">
                          {convertDateWithTime(item.request_time)}
                        </td>
                        <td className="p-3 align-middle">
                          {item.deliver_plan
                            ? convertDateWithoutTime(item.deliver_plan)
                            : '-'}
                        </td>
                        <td className="p-3 align-middle">
                          {item.finish_time ? item.finish_time : '-'}
                        </td>
                        <td className="p-3 align-middle">{item.sj}</td>
                        <td className="p-3 align-middle">
                          {item.erp_wo_no ? item.erp_wo_no : '-'}
                        </td>
                        <td className="p-3 align-middle">{item.warehouse}</td>
                        <td className="p-3 align-middle">{item.type}</td>
                        <td className="p-3 align-middle">
                          <WorkOrderStatus text={item.status} />
                        </td>
                        <td className="p-3 align-middle">
                          <TableAction>
                            <button
                              className="btn btn-secondary"
                              onClick={() =>
                                navigate(
                                  `/workorder-plan/detail/${encodeURIComponent(
                                    item.id
                                  )}`
                                )
                              }
                            >
                              <FontAwesomeIcon icon={faMagnifyingGlass} />
                            </button>
                          </TableAction>
                        </td>
                      </tr>
                    </>
                  ))
                )}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={10} className="p-3">
                    <TablePagination
                      meta={meta}
                      handleOnChangeMeta={handleOnChangeMeta}
                      handlePageChange={handlePageChange}
                    />
                  </td>
                </tr>
              </tfoot>
            </Table>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default WorkOrderPlan
