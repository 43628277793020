import { faImage } from '@fortawesome/free-regular-svg-icons'
import { faPencil } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useMemo, useState } from 'react'
import { Col, Container, Form, Row } from 'react-bootstrap'
import { useDropzone } from 'react-dropzone'
import Select from 'react-select'
import Loader from '../../../components/atoms/Loader/index'
import api from '../../../config/AxiosInstance'
import '../../../styles/style.css'
import * as Common from '../../../utils/Common'

const headerTitleStyle = {
  color: 'var(--primary-orange)',
  fontSize: '24px',
  fontWeight: '600'
}

const UploadMap = () => {
  // style dropzone
  const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: '10px',
    borderColor: '#C9CACD',
    borderStyle: 'solid',
    backgroundColor: 'white',
    color: 'inherit',
    outline: 'none',
    transition: 'border .24s ease-in-out'
  }

  const focusedStyle = {
    borderColor: '#C9CACD'
  }

  const acceptStyle = {
    borderColor: '#00e676'
  }

  const rejectStyle = {
    borderColor: '#ff1744'
  }
  // end style dropzone

  const [options, setOptions] = useState([])
  const [inputValue, setInputValue] = useState('')
  const [selectedValue, setSelectedValue] = useState(null)
  const [files, setFiles] = useState([])
  const [isSelected, setIsSelected] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const maxSize = 1242880 // max size file upload
  const typeImage = ['image/jpg', 'image/jpeg', 'image/png', 'image/svg']

  const onDrop = async (acceptedFiles) => {
    // Check if the file size exceeds the maximum allowed size
    if (acceptedFiles[0].size > maxSize) {
      Common.showPopUpErrorMessage('Cant upload file more than 5 MB')
      return
    }
    // check filter type image upload
    if (!typeImage.includes(acceptedFiles[0].type)) {
      Common.showPopUpErrorMessage('File upload must jpg/jpeg/png/svg')
      return
    }
    try {
      setIsLoading(true)
      if (!selectedValue) {
        Common.showPopUpErrorMessage(
          'Please select warehouse value, before upload image warehouse'
        )
        setIsLoading(false)
        return
      }

      const formData = new FormData()
      formData.append('warehouse_id', selectedValue?.value)
      formData.append('map_image', acceptedFiles[0])

      const res = await api.postForm('settings/map', formData)
      const results = res.data
      setIsSelected(false)
      setFiles([results.data])
      setIsLoading(false)
      Common.showPopUpSuccessMessage('Upload map successfully')
    } catch (error) {
      setIsLoading(false)
      const errMsg =
        error?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject
  } = useDropzone({
    multiple: false,
    accept: { 'image/*': [] },
    onDrop
  })

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isFocused, isDragAccept, isDragReject]
  )
  useEffect(
    function () {
      if (isSelected) {
        fetchShowMapWarehouse(selectedValue.value)
      }
    },
    [isSelected]
  )

  useEffect(
    function () {
      const controller = new AbortController()
      fetchWarehouses(inputValue, controller)
      // clean up effect function
      return function () {
        controller.abort()
      }
    },
    [inputValue]
  )

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview))
  }, [])

  const handleInputChange = (newValue) => {
    setFiles([])
    setInputValue(newValue)
  }

  const handleSelectChange = (selectedOption) => {
    setSelectedValue(selectedOption)
  }

  function handleClickSelect() {
    setIsSelected(true)
  }

  async function fetchWarehouses(inputValue, controller) {
    try {
      const params = inputValue ? `?q=${inputValue}` : ''
      const res = await api.get(
        `master/warehouses/all${params}?is_parent=true&forUser=1`,
        {
          signal: controller.signal
        }
      )
      const optionValue = []
      if (res.data.data.length > 0) {
        res.data.data.map((warehouse) =>
          optionValue.push({
            value: warehouse.id,
            label: warehouse.warehouse_name
          })
        )
      }

      setOptions(optionValue)
    } catch (err) {
      if (err.message !== 'canceled') {
        const errMsg =
          err?.response?.data?.errors?.message || 'An error occurred'
        Common.showPopUpErrorMessage(errMsg)
      }
    }
  }
  async function fetchShowMapWarehouse(id) {
    try {
      setIsLoading(true)
      const res = await api.get(`settings/map/${id}`)
      const results = res.data
      setIsSelected(false)
      setFiles([results.data])
      setIsLoading(false)
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }
  return (
    <Container>
      <Row>
        <h3 style={headerTitleStyle}>Upload Map</h3>
      </Row>
      <Row>
        <FormWarehouse
          options={options}
          onHandleInputChange={handleInputChange}
          onHandleSelectChange={handleSelectChange}
          selectedValue={selectedValue}
          onHandleClickSelect={handleClickSelect}
        />
      </Row>
      <Row>
        <FormUploadMap
          acceptedFiles={acceptedFiles}
          getRootProps={getRootProps}
          getInputProps={getInputProps}
          style={style}
          selectedValue={selectedValue}
          loading={isLoading}
          files={files}
        />
      </Row>
    </Container>
  )
}
function FormWarehouse({
  options,
  onHandleInputChange,
  onHandleSelectChange,
  selectedValue,
  onHandleClickSelect
}) {
  return (
    <Col xs={12} md={12} lg={12} className="col-12">
      <Form.Label>Warehouse</Form.Label>
      <Row>
        <Col xs={9} md={11} lg={11}>
          <Select
            id="warehouse_customer"
            name="warehouse_customer"
            options={options}
            onInputChange={onHandleInputChange}
            onChange={onHandleSelectChange}
            placeholder="Search Warehouse..."
            isClearable
            isSearchable
          />
        </Col>
        <Col xs={3} md={1} lg={1}>
          <button
            className="btn btn-primary-orange"
            disabled={!selectedValue ? true : false}
            onClick={() => onHandleClickSelect()}
            style={{ height: '2.8rem' }}
          >
            Select
          </button>
        </Col>
      </Row>
    </Col>
  )
}

function FormUploadMap({
  acceptedFiles,
  getRootProps,
  getInputProps,
  style,
  selectedValue,
  loading,
  files
}) {
  return (
    <div className="mt-3" style={{ maxWidth: '100%', maxHeight: '100%' }}>
      <div {...getRootProps({ style })}>
        {loading && (
          <>
            <div className="d-flex h-100 align-items-center">
              <Loader>
                <p className="fw-bold mt-3">Please wait...</p>
              </Loader>
            </div>
          </>
        )}
        {!loading && (
          <DragAndDropFile
            acceptedFiles={acceptedFiles}
            getInputProps={getInputProps}
            selectedValue={selectedValue}
            files={files}
          />
        )}
      </div>
    </div>
  )
}

function DragAndDropFile(props) {
  const iconStyle = {
    fontSize: '60px',
    width: '300'
  }
  const iconEditStyle = {
    transform: 'rotate(0deg)'
  }
  const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    width: '100%',
    height: '434px',
    padding: 4,
    boxSizing: 'border-box'
  }

  const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
  }

  const img = {
    display: 'block',
    width: '100%',
    height: '100%',
    objectFit: 'cover'
  }

  return props.files.length > 0 && props.files[0].warehouse_map ? (
    <>
      <input
        {...props.getInputProps()}
        disabled={!props.selectedValue ? true : false}
      />
      <div className="d-flex flex-row justify-content-end w-100 mb-3">
        <button className="btn btn-sm btn-secondary d-flex flex-row gap-1">
          <FontAwesomeIcon
            icon={faPencil}
            className="fa-flip-horizontal mt-1"
            style={iconEditStyle}
          />
          <span>Change Image</span>
        </button>
      </div>
      <aside>
        {
          <div
            style={thumb}
            key={
              props.files[0].hasOwnProperty('warehouse_map')
                ? props.files.id
                : props.files[0].name
            }
          >
            <div style={thumbInner}>
              {props.files[0].hasOwnProperty('warehouse_map') ? (
                <img
                  src={props.files[0].warehouse_map}
                  style={img}
                  alt={props.files[0].warehouse_name}
                />
              ) : (
                <img
                  alt="map"
                  src={props.files[0].preview}
                  style={img}
                  // Revoke data uri after image is loaded
                  onLoad={() => {
                    URL.revokeObjectURL(props.files[0].preview)
                  }}
                />
              )}
            </div>
          </div>
        }
      </aside>
    </>
  ) : (
    <>
      <input
        {...props.getInputProps()}
        disabled={!props.selectedValue ? true : false}
      />
      <div className="d-flex align-items-center h-100">
        <div>
          <FontAwesomeIcon
            icon={faImage}
            className="fa-flip-horizontal"
            style={iconStyle}
          />
          <p className="text-center mt-2">
            Drag and drop an image, or
            <span className="browse-image ms-1">Browse</span>
          </p>
          <p style={{ fontSize: '11px' }} className="text-center mt-2">
            1000 x 1000 px, MAX 5 MB
          </p>
        </div>
      </div>
    </>
  )
}
export default UploadMap
