import { faClose, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useRef, useState } from 'react'
import { Card, Modal } from 'react-bootstrap'
import api from '../../../config/AxiosInstance'
import * as Common from '../../../utils/Common'
import CustomButton from '../../Button/CustomButton'

function ModalUploadSupportingDocument(props) {
  const fileInput = useRef('')
  const [fileUpload, setFileUpload] = useState(null)
  const [fileUploadName, setFileUploadName] = useState('')
  const [disabled, setDisabled] = useState(true)
  useEffect(() => {}, [props.valueInboundId, props.show])
  const handleChange = async (event) => {
    try {
      setFileUpload(event.target.files[0])
      setFileUploadName(event.target.files[0].name)
      setDisabled(false)
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }

  const handleUpload = async () => {
    try {
      setDisabled(true)
      const formData = new FormData()
      formData.append('inbound_id', props.valueInboundId?.inboundId)
      formData.append('docs', fileUpload)
      const res = await api.postForm('inbound/history/upload_doc', formData)

      if (res.data.success) {
        setFileUploadName('')
        setFileUpload(null)
        Common.showPopUpSuccessMessage('Upload successfully')
        props.onHide()
        props.onRefreshData()
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setDisabled(false)
    }
  }

  return (
    <Modal size="md" {...props} centered>
      <Modal.Header closeButton>
        <Modal.Title>Upload Supporting Document</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5 className="fw-bold">{props.valueInboundId?.code}</h5>
        <div className="row mt-3">
          {fileUploadName && (
            <>
              <div className="col-12">
                <Card>
                  <Card.Body>
                    <div className="d-flex flex-row gap-2 h-100 align-items-center">
                      <FontAwesomeIcon
                        icon={faClose}
                        style={{
                          color: '#828282',
                          fontSize: '20px',
                          cursor: 'pointer'
                        }}
                        onClick={() => {
                          setFileUploadName('')
                          setFileUpload('')
                          setDisabled(true)
                        }}
                      />
                      <span className="ml-2">{fileUploadName}</span>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            </>
          )}
          {!fileUploadName && (
            <div className="col-3">
              <h6 className="fw-bold" style={{ color: '#828282' }}>
                Doc
              </h6>

              <Card
                style={{ width: '3rem', height: '3rem', cursor: 'pointer' }}
                className="mt-3"
                onClick={() => fileInput.current.click()}
              >
                <Card.Body>
                  <div style={{ marginTop: '-.3rem' }}>
                    <FontAwesomeIcon
                      icon={faPlus}
                      style={{ color: '#828282' }}
                    />
                  </div>
                  <input
                    type="file"
                    name="change_image"
                    id="change_image"
                    accept="image/jpg,image/jpeg,image/png,.doc,.docx,.pdf"
                    className="d-none"
                    onChange={handleChange}
                    ref={fileInput}
                  />
                </Card.Body>
              </Card>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <CustomButton
          onClick={handleUpload}
          variant={'warning'}
          text={'Save'}
          disabled={disabled}
        />
      </Modal.Footer>
    </Modal>
  )
}

export default ModalUploadSupportingDocument
