import DatePicker from 'react-date-picker'
import './datepickercomponent.css'
function InputDatePicker({
  value,
  onChange,
  onBlur,
  disabled = false,
  errors = false,
  touched = false,
  name = ''
}) {
  return (
    <>
      <DatePicker
        format="dd-MM-y"
        dayPlaceholder="DD"
        monthPlaceholder="MM"
        yearPlaceholder="YYYY"
        value={value}
        onChange={onChange}
        onCalendarClose={onBlur}
        className={`w-100 ${
          errors[name] && touched[name] ? 'error-border' : ''
        }`}
        clearIcon={null}
        calendarIcon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="15px"
            viewBox="0 -960 960 960"
            width="15px"
            fill="currentColor"
          >
            <path d="M200-80q-33 0-56.5-23.5T120-160v-560q0-33 23.5-56.5T200-800h40v-80h80v80h320v-80h80v80h40q33 0 56.5 23.5T840-720v560q0 33-23.5 56.5T760-80H200Zm0-80h560v-400H200v400Zm0-480h560v-80H200v80Zm0 0v-80 80Z" />
          </svg>
        }
        disabled={disabled}
        disableCalendar={disabled}
      />
    </>
  )
}

export default InputDatePicker
