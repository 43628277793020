import { faPen } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { Col, Container, Form, Row, Table } from 'react-bootstrap'
import api from '../../../config/AxiosInstance'
import * as Common from '../../../utils/Common'
import Loader from '../../atoms/Loader'
import TableAction from '../../atoms/Table/TableAction'
import TablePagination from '../../atoms/Table/TablePagination'
import ModalAddUser from '../../modal/user/ModalAddUser'
import ModalUpdateUser from '../../modal/user/ModalUpdateUser'

function MasterUser() {
  const [modalAddShow, setModalAddShow] = useState(false)
  const [modalUpdateShow, setModalUpdateShow] = useState(false)
  const [query, setQuery] = useState('')
  const [users, setUsers] = useState([])
  const [isSelected, setIsSelected] = useState(false)
  const [editId, setEditId] = useState(0)
  const [meta, setMeta] = useState({
    per_page: 10,
    current_page: 1,
    total_row: 0,
    total_page: 0
  })
  const [loading, setLoading] = useState(false)
  const [pageCount, setPageCount] = useState(0)
  const { per_page, current_page } = meta

  const loadUser = async () => {
    setLoading(true)
    try {
      const res = await api.get('/users/list', {
        params: { username: query, per_page, page: current_page }
      })
      const { metadata, data } = res.data

      setLoading(false)
      setUsers(data)
      setMeta(metadata)
      setPageCount(metadata.total_page || 0)
    } catch (err) {
      setLoading(false)
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'

      Common.showPopUpErrorMessage(errMsg)
    }
  }

  useEffect(() => {
    loadUser()
  }, [per_page, current_page, isSelected])

  function handleOnChangeMeta(e) {
    const perPageValue = parseInt(e.target.value, 10)
    setMeta((prevMeta) => ({
      ...prevMeta,
      per_page: perPageValue,
      current_page: 1
    }))
  }

  const handlePageChange = (data) => {
    const selectedPage = data.selected + 1
    setMeta((prevMeta) => ({ ...prevMeta, current_page: selectedPage }))
    window.scrollTo(0, 0)
  }

  function handleEdit(id) {
    setModalUpdateShow(true)
    setEditId(id)
  }

  const handleSearchData = () => {
    setMeta((prevMeta) => ({ ...prevMeta, current_page: 1 }))
    setIsSelected((prevSelected) => !prevSelected)
  }

  const headerTitleStyle = {
    color: 'var(--primary-orange)',
    fontSize: '24px',
    fontWeight: '600'
  }

  const tableStyle = {
    fontSize: '15px'
  }
  return (
    <>
      <Container className="mb-4">
        <Row>
          <h4 style={headerTitleStyle} className="mb-3">
            Master User
          </h4>
        </Row>
        <Row>
          <Col xs={9} md={8} lg={8}>
            <Form.Control
              onChange={(e) => setQuery(e.target.value)}
              placeholder="Search user by username..."
            ></Form.Control>
          </Col>
          <Col xs={3} md={2} lg={2}>
            <button
              className="btn btn-primary-orange"
              onClick={() => handleSearchData()}
            >
              Search
            </button>
          </Col>
        </Row>
        <div className="d-flex justify-content-between align-items-center mt-5">
          <h4
            className="text-muted mt-2 mt-md-0 mt-lg-0"
            style={{ fontSize: '14px' }}
          >
            USER LIST
          </h4>
          <button
            className="btn btn-primary-orange"
            onClick={() => setModalAddShow(true)}
          >
            Add
          </button>
        </div>
        <Row className="mt-4 mt-md-3 mt-lg-3">
          <Table
            responsive="sm"
            hover
            className="shadow-sm bg-white rounded"
            size="sm"
            style={{ fontSize: '14px' }}
          >
            <thead className="table-secondary">
              <tr>
                <th className="p-2 align-middle">Name</th>
                <th className="p-2 align-middle">Username</th>
                <th className="p-2 align-middle text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {loading && (
                <tr>
                  <td colSpan={3}>
                    <Loader />
                  </td>
                </tr>
              )}
              {!loading &&
                users.map((user) => {
                  return (
                    <tr key={user.id}>
                      <td className="p-2 align-middle" style={tableStyle}>
                        {user.name || '-'}
                      </td>
                      <td className="p-2 align-middle" style={tableStyle}>
                        {user.username}
                      </td>
                      <td className="p-2 align-middle">
                        <TableAction direction="center">
                          <button
                            className="btn btn-secondary"
                            onClick={() => handleEdit(user.id)}
                          >
                            <FontAwesomeIcon
                              icon={faPen}
                              style={{ fontSize: '15px', rotate: '90deg' }}
                              className="fa-flip-horizontal"
                            />
                          </button>
                        </TableAction>
                      </td>
                    </tr>
                  )
                })}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={3} className="p-3">
                  <TablePagination
                    meta={meta}
                    handlePageChange={handlePageChange}
                    handleOnChangeMeta={handleOnChangeMeta}
                  />
                </td>
              </tr>
            </tfoot>
          </Table>
        </Row>
      </Container>
      <ModalAddUser
        show={modalAddShow}
        onHide={() => setModalAddShow(false)}
        refreshData={() => loadUser()}
      ></ModalAddUser>
      <ModalUpdateUser
        show={modalUpdateShow}
        onHide={() => setModalUpdateShow(false)}
        id={editId}
        refreshData={() => loadUser()}
      ></ModalUpdateUser>
    </>
  )
}

export default MasterUser
