function TableAction({ children, direction = 'end' }) {
  const justifyContent = (direction = 'end') => {
    switch (direction) {
      case 'center':
        return 'justify-content-center'
      case 'start':
        return 'justify-content-start'
      default:
        return 'justify-content-end'
    }
  }

  return (
    <>
      <div
        className={`d-flex flex-row gap-2 flex-wrap ${justifyContent(
          direction
        )}`}
      >
        {children}
      </div>
    </>
  )
}

export default TableAction
