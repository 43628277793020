import {
  faCaretDown,
  faCaretUp,
  faHistory,
  faPenToSquare
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import {
  Badge,
  Button,
  Card,
  Col,
  Collapse,
  Container,
  Row,
  Spinner,
  Table
} from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import Select from 'react-select'
import Swal from 'sweetalert2'
import reportIcon from '../../../assets/Subtract.png'
import Breadcrumb from '../../../components/Label/Breadcrumb'
import Label from '../../../components/Label/Input'
import Notification from '../../../components/Notifications/Notification'
import { BadgeStatusCOA } from '../../../components/atoms/StatusLabel'
import ModalPrDetailTimeline from '../../../components/modal/ReceiveAtWarehouse/PrDetailTimeline'
import ModalUpdateCoa from '../../../components/modal/ReceiveAtWarehouse/UpdateCoa'
import api from '../../../config/AxiosInstance'
import {
  convertDateWithoutTime,
  convertDateWithTime
} from '../../../helpers/constants'
import '../../../styles/style.css'
import * as Common from '../../../utils/Common'
import { firstLetterUpperCase } from '../../../utils/TextFormatter'
function Detail() {
  // style
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: 14,
      color: !state.data.hasSlot ? '#C12727' : 'black',
      backgroundColor: 'transparent',
      fontWeight: !state.data.is_reject ? 'normal' : 'bold'
    })
  }
  const { id } = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const [loadingArea, setLoadingArea] = useState(false)
  const [successNotification, setSuccessNotification] = useState(false)
  const [detail, setDetail] = useState({})
  const [areaData, setAreaData] = useState([])
  const [assignAreas, setAssignAreas] = useState([])
  const [openCoa, setOpenCoa] = useState(true)
  const [showModalTimeline, setShowModalTimeline] = useState(false)
  const [showModalUpdateCoa, setShowModalUpdateCoa] = useState(false)
  const [prTimelineId, setPrTimelineId] = useState(null)
  const [detailCoa, setDetailCoa] = useState(null)
  const [conditionItemInbound, setConditionItemInbound] = useState({
    inputValue: '',
    condition: ''
  })
  useEffect(() => {
    fetchDetail()
  }, [id])
  useEffect(() => {
    if (conditionItemInbound?.condition) {
      loadFocusOptions(
        conditionItemInbound?.inputValue,
        conditionItemInbound?.condition
      )
    }
  }, [conditionItemInbound])

  useEffect(() => {
    const successNotification = setTimeout(() => {
      setSuccessNotification(false)
    }, 10000)
    return () => clearTimeout(successNotification)
  }, [successNotification])
  const loadOptions = async (inputValue, condition) => {
    try {
      setLoadingArea(true)
      const res = await api.get('/master/area/all', {
        params: {
          q: inputValue,
          id: detail?.po_info?.target_warehouse + ' - BAJ',
          condition: condition === 'good' ? '' : condition
        }
      })

      if (res.data.success) {
        const options = res.data.data.reduce(
          (acc, data) => {
            acc.unshift({
              value: data.id,
              label: `${data.name} ${
                data.has_slot ? '' : `- Slot Unavailable!`
              }`,
              hasSlot: data.has_slot,
              is_reject: false
            })
            return acc
          },
          [{ value: null, label: 'TOLAK', hasSlot: false, is_reject: true }]
        )
        setAreaData(
          condition === 'good' ? options.filter((opt) => opt.value) : options
        )
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setLoadingArea(false)
    }
  }
  const loadFocusOptions = (inputValue, condition) => {
    loadOptions(inputValue, condition)
  }
  const checkAssignArea = (inboundId) => {
    const lengthItemAssignAreaByInbound = assignAreas.find((inbound) => {
      return inbound.inboundId === inboundId
    })?.items.length
    const lengthItemInbounds = detail?.inbounds.find((inbound) => {
      return inbound.id === inboundId
    })?.items.length

    return lengthItemInbounds === lengthItemAssignAreaByInbound
  }
  const handleSubmitAndSendErp = (inboundId) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary-orange',
        cancelButton: 'btn btn-transparent',
        actions:
          'd-flex w-100 justify-content-end pt-4 ps-4 pe-4 separator-border-action-sweetalert'
      },
      buttonsStyling: false
    })
    swalWithBootstrapButtons
      .fire({
        title: 'Send to ERP?',
        text: 'This action can’t be repeated!',
        icon: 'warning',
        iconColor: '#828282',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Send!',
        cancelButtonText: 'Cancel',
        allowOutsideClick: false,
        reverseButtons: true,
        showCloseButton: true
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          try {
            // read data value state assign area id
            if (!checkAssignArea(inboundId)) {
              Swal.close()
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Destination area is required'
              })
              return
            }
            const res = await api.post(
              `inbound/ml-draft/${detail?.po_info?.ml_id}/assign-area`,
              {
                areas: assignAreas.find(
                  (inbound) => inbound.inboundId === inboundId
                )?.items
              }
            )
            if (res.data.success) {
              Swal.close()
              // load data
              fetchDetail()
              Common.showPopUpSuccessMessage('Success')
            }
          } catch (err) {
            Swal.close()
            const errMsg =
              err?.response?.data?.errors?.message || 'An error occurred'
            const swalWithBootstrapButtonError = Swal.mixin({
              customClass: {
                confirmButton: 'btn btn-primary-orange',
                cancelButton: 'btn btn-transparent',
                actions:
                  'd-flex w-100 justify-content-end pt-4 ps-4 pe-4 separator-border-action-sweetalert'
              },
              buttonsStyling: false
            })
            swalWithBootstrapButtonError
              .fire({
                html: `<p>Send to ERP <span class='text-danger fw-bold text-uppercase'>failed</span></p><br><p>Reason: ${errMsg}</p>`,
                icon: 'error',
                showCancelButton: false,
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Resend',
                allowOutsideClick: false,
                showCloseButton: true
              })
              .then(async (result) => {
                if (result.isConfirmed) {
                  try {
                    if (!checkAssignArea(inboundId)) {
                      Swal.close()
                      Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Destination area is required'
                      })
                      return
                    }
                    const res = await api.post(
                      `inbound/ml-draft/${detail?.po_info?.ml_id}/assign-area`,
                      {
                        areas: assignAreas.find(
                          (inbound) => inbound.inboundId === inboundId
                        )?.items
                      }
                    )
                    if (res.data.success) {
                      Swal.close()
                      // load data
                      fetchDetail()
                      Common.showPopUpSuccessMessage('Success')
                    }
                  } catch (err) {
                    Swal.close()
                    const errMsg =
                      err?.response?.data?.errors?.message ||
                      'An error occurred'
                    Common.showPopUpErrorMessage(errMsg)
                  }
                }
              })
          }
        } else {
          return false
        }
      })
  }
  const fetchDetail = async () => {
    try {
      setIsLoading(true)
      const res = await api.get(`inbound/ml-draft/${id}`)
      const { data } = res
      if (data.success) {
        setDetail(data.data)
        // create array assign area
        const tempAssignArea = data?.data?.inbounds.reduce((acc, cur) => {
          let obj = {
            inboundId: cur.id,
            items: cur.items
              .filter(
                (itemValue) =>
                  itemValue.condition === 'good' ||
                  itemValue.area === 'Area Gudang Karantina'
              )
              .reduce((accItem, curItem) => {
                let itemObj = {
                  item_staging_area_id: curItem.id,
                  area_id: curItem.area_id,
                  is_reject: [
                    'Area Gudang Utama',
                    'Area Gudang Karantina'
                  ].includes(curItem.area)
                    ? false
                    : true
                }
                accItem.push(itemObj)
                return accItem
              }, [])
          }
          acc.push(obj)
          return acc
        }, [])
        setAssignAreas(tempAssignArea)
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setIsLoading(false)
    }
  }
  const handleSetAssignArea = (inboundId, itemId, area) => {
    const assignArea = assignAreas.find(
      (inboundValue) => inboundValue.inboundId === inboundId
    )
    // check item staging already exist or not
    const checkItemStaging = assignArea?.items?.find(
      (itemValue) => itemValue.item_staging_area_id === itemId
    )
    let filterInbound = null
    if (checkItemStaging) {
      // update item staging
      filterInbound = assignArea?.items?.map((itemValue) =>
        itemValue.item_staging_id === itemId
          ? { ...itemValue, area_id: area.value, is_reject: area.is_reject }
          : itemValue
      )
    } else {
      // create new item staging
      filterInbound = [
        ...assignArea?.items,
        {
          item_staging_area_id: itemId,
          area_id: area.value,
          is_reject: area.is_reject
        }
      ]
    }
    // override data inbound temporary
    setAssignAreas((prevInboundState) =>
      prevInboundState.map((inbound) =>
        inbound.inboundId === inboundId
          ? { ...inbound, items: filterInbound }
          : inbound
      )
    )
  }
  const statusCoaMapping = {
    unchecked: 'unchecked',
    match: 'match',
    not_match: 'not match'
  }
  const statusTextMapping = {
    unassigned: 'unassigned',
    waiting_rnr: 'waiting rnr',
    receiving_progress: 'receiving progress',
    rnr: 'receiving progress',
    waiting_om: 'waiting om',
    request_pnp: 'waiting om',
    waiting_pnp: 'waiting pnp',
    pnp: 'put away',
    put_away: 'put away',
    revised: 'waiting rnr',
    done: 'done'
  }
  if (isLoading) {
    return (
      <>
        <div>
          <Row>
            <Col md={12}>
              <div
                style={{ height: '100vh' }}
                className="d-flex flex-column justify-content-center"
              >
                <div className="d-flex flex-row justify-content-center">
                  <div className="d-flex flex-column gap-3">
                    <div className="d-flex flex-row justify-content-center gap-2">
                      <Spinner animation="grow" variant="warning" />
                      <Spinner animation="grow" variant="warning" />
                      <Spinner animation="grow" variant="warning" />
                      <Spinner animation="grow" variant="warning" />
                      <Spinner animation="grow" variant="warning" />
                    </div>
                    <p
                      className="fw-bold text-center"
                      style={{ fontSize: '13px' }}
                    >
                      Loading...
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </>
    )
  }
  return (
    <Container className="mb-5">
      <Row>
        <Col md={8} className="mt-3">
          <Breadcrumb
            label={detail?.po_info?.ml}
            link="/inbound/ml"
            back="ML DRAFT LIST"
            status={statusTextMapping[detail?.inbounds[0]?.status || 'done']}
            type="BadgeStatusPrLists"
          />
        </Col>
        {successNotification && (
          <Notification
            type="success"
            text="Success!"
            handleClose={() => setSuccessNotification(false)}
          />
        )}
      </Row>
      <Row>
        <Label
          label="Series"
          value={detail?.po_info?.series_number ?? '-'}
          col={2}
        />
        <Label
          label="#ERP DOC NO"
          value={detail?.po_info?.erp_doc_no ?? '-'}
          col={2}
        />
        <Label
          label="SOURCE WAREHOUSE"
          value={detail?.po_info?.src_warehouse}
          col={4}
        />
        <Label
          label="TARGET WAREHOUSE"
          value={detail?.po_info?.target_warehouse}
          col={4}
        />
      </Row>
      <Row>
        <Label label="#TR" value={detail?.po_info?.tr} col={2} />
        <Label label="#MR" value={detail?.po_info?.mr} col={2} />
        <Label
          label="DELIVERY TYPE"
          value={firstLetterUpperCase(detail?.po_info?.delivery_type)}
          col={2}
        />
        <Label label="CAR" value={detail?.po_info?.vehicle} col={2} />
        <Label
          label="DRIVER"
          value={firstLetterUpperCase(detail?.po_info?.driver)}
          col={2}
        />
      </Row>
      <Row className="mt-4">
        <div className="d-flex flex-row gap-2">
          <h5>ITEM & COA</h5>
          <div
            className="d-flex flex-column justify-content-center cursor-pointer"
            onClick={() => setOpenCoa(!openCoa)}
            aria-controls="item-coa-lists"
            aria-expanded={openCoa}
            role="button"
          >
            <FontAwesomeIcon
              icon={openCoa ? faCaretUp : faCaretDown}
              style={{ marginTop: '-.5rem', fontSize: '14px' }}
            />
          </div>
        </div>
        <Collapse in={openCoa} className="mt-3">
          <div id="item-coa-lists">
            <Table
              hover
              responsive="sm"
              className="shadow-sm mb-4 bg-white rounded"
              size="sm"
              style={{ fontSize: '12px' }}
            >
              <thead className="table-secondary">
                <tr>
                  <th className="p-3 align-middle">No</th>
                  <th className="p-3 align-middle" style={{ width: '20%' }}>
                    Item
                  </th>
                  <th className="p-3 align-middle">Status COA</th>
                  <th className="p-3 text-end align-middle">Batch</th>
                  <th className="p-3 text-end align-middle">Total Pack</th>
                  <th className="p-3 text-end align-middle">Total Qty</th>
                  <th className="p-3 text-end align-middle">COA Document</th>
                  <th className="p-3 text-end align-middle">COA MD</th>
                  <th className="p-3 text-end align-middle">COA ED</th>
                  <th className="p-3 text-end align-middle">Label MD</th>
                  <th className="p-3 text-end align-middle">Label ED</th>
                  <th className="p-3 text-center align-middle">Action</th>
                </tr>
              </thead>
              <tbody>
                {detail?.items_coa?.length > 0 &&
                  detail?.items_coa?.map((item, index) => (
                    <tr key={item?.id}>
                      <td className="p-3">{index + 1}</td>
                      <td className="p-3">{item?.item?.item_name}</td>
                      <td className="p-3">
                        <BadgeStatusCOA
                          text={
                            statusCoaMapping[item?.coa_status] || 'unchecked'
                          }
                        />
                      </td>
                      <td className="p-3">{item?.batch?.batch}</td>
                      <td className="p-3 text-end">{item?.total_pack}</td>
                      <td className="p-3 text-end">{item?.total_qty}</td>
                      <td className="p-3 text-end">
                        <span className="text-primary">
                          {item?.coa_doc?.split('/').pop()}
                        </span>
                      </td>
                      <td className="p-3 text-end">
                        {item?.coa_pd
                          ? convertDateWithoutTime(item?.coa_pd)
                          : '-'}
                      </td>
                      <td className="p-3 text-end">
                        {item?.coa_ed
                          ? convertDateWithoutTime(item?.coa_ed)
                          : '-'}
                      </td>
                      <td className="p-3 text-end">
                        {item?.label_pd
                          ? convertDateWithoutTime(item?.label_pd)
                          : '-'}
                      </td>
                      <td className="p-3 text-end">
                        {item?.label_ed
                          ? convertDateWithoutTime(item?.label_ed)
                          : '-'}
                      </td>
                      <td className="p-3">
                        <button
                          className="btn btn-secondary"
                          onClick={() => {
                            setShowModalUpdateCoa(true)
                            setDetailCoa(item)
                          }}
                        >
                          <FontAwesomeIcon icon={faPenToSquare} />
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </Collapse>
      </Row>
      <div style={{ margin: 0, padding: 0 }}>
        <div style={{ borderTop: '3px solid #F1F2F7' }} className="mt-4"></div>
      </div>
      <Row className="mt-4">
        {detail?.inbounds?.length > 0 &&
          detail?.inbounds.map((inbound) => (
            <>
              <Col xs={6} md={6} lg={6}>
                <div className="d-flex flex-row justify-content-start gap-2">
                  <FontAwesomeIcon
                    icon={faHistory}
                    style={{
                      fontSize: '16px',
                      color: '#828282',
                      marginTop: '.1rem'
                    }}
                    className="cursor-pointer"
                    role="button"
                    onClick={() => {
                      setShowModalTimeline(true)
                      setPrTimelineId(inbound?.id)
                    }}
                  />
                  <p className="text-uppercase fw-bold text-muted">
                    {inbound?.stagings
                      ?.map((staging) => staging?.name)
                      .join(', ')}
                  </p>
                </div>
              </Col>
              <Col xs={6} md={6} lg={6}>
                <div
                  className="d-flex flex-column justify-content-center"
                  style={{ marginTop: '.1rem' }}
                >
                  <div className="d-flex w-100 flex-row justify-content-end fs-6">
                    <div className="d-flex flex-row gap-2">
                      <p className="fw-bold">
                        <span>
                          {statusTextMapping[inbound?.status].toUpperCase()}
                        </span>
                      </p>
                      <div style={{ marginTop: '-.2rem' }}>
                        <img
                          src={reportIcon}
                          style={{ width: '16px', height: '16px' }}
                          alt="report icon"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={12}>
                <Table
                  hover
                  responsive="sm"
                  className="shadow-sm mb-4 bg-white rounded"
                  size="sm"
                  style={{ fontSize: '12px' }}
                >
                  <thead className="table-secondary">
                    <tr>
                      <th className="p-3 align-middle">Palet</th>
                      <th className="p-3 align-middle" style={{ width: '20%' }}>
                        Item
                      </th>
                      <th className="p-3 text-end">Batch</th>
                      <th className="p-3 text-end">Pack</th>
                      <th className="p-3 text-end">Condition</th>
                      <th className="p-3 text-end">Location</th>
                      <th className="p-3 text-end">Destination Area</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!inbound?.items?.length && (
                      <tr>
                        <td colSpan={7} className="fw-bold text-center">
                          Data empty
                        </td>
                      </tr>
                    )}
                    {inbound?.items?.length > 0 &&
                      inbound?.items?.map((item) => (
                        <tr>
                          <td
                            className="p-3"
                            style={{
                              backgroundColor: `${
                                item.from_mobile ? '#FFCCCC' : 'white'
                              }`
                            }}
                          >
                            {!item.palet_id ? '-' : `#${item.palet_id}`}
                          </td>
                          <td
                            className="p-3"
                            style={{
                              backgroundColor: `${
                                item.from_mobile ? '#FFCCCC' : 'white'
                              }`
                            }}
                          >
                            {item.item_name}
                          </td>
                          <td
                            className="p-3 text-end"
                            style={{
                              backgroundColor: `${
                                item.from_mobile ? '#FFCCCC' : 'white'
                              }`
                            }}
                          >
                            {item.batch}
                          </td>
                          <td
                            className="p-3 text-end"
                            style={{
                              backgroundColor: `${
                                item.from_mobile ? '#FFCCCC' : 'white'
                              }`
                            }}
                          >
                            {item.total_pack}
                          </td>
                          <td
                            className="p-3 text-end"
                            style={{
                              backgroundColor: `${
                                item.from_mobile ? '#FFCCCC' : 'white'
                              }`
                            }}
                          >
                            <Badge
                              bg={`${
                                item.condition === 'good' ? 'success' : 'danger'
                              }`}
                              className="text-uppercase fw-bold p-2"
                            >
                              {`${
                                item.condition === 'good' ? 'baik' : 'rusak'
                              }`}
                            </Badge>
                          </td>
                          <td
                            className="p-3 text-end"
                            style={{
                              backgroundColor: `${
                                item.from_mobile ? '#FFCCCC' : 'white'
                              }`
                            }}
                          >
                            {item.current_location}
                          </td>
                          <td
                            className="p-3 text-end"
                            style={{
                              backgroundColor: `${
                                item.from_mobile ? '#FFCCCC' : 'white'
                              }`
                            }}
                          >
                            {(!inbound?.erp_code ||
                              (inbound?.has_karantina &&
                                !inbound?.erp_code_karantina)) &&
                              inbound?.status === 'waiting_om' && (
                                <Select
                                  menuPortalTarget={document.body}
                                  isSearchable={true}
                                  isLoading={loadingArea}
                                  loadingMessage={() => 'Loading...'}
                                  options={areaData}
                                  defaultOptions
                                  placeholder="Select Assign Area"
                                  styles={customStyles}
                                  key={1}
                                  autoLoad={false}
                                  onFocus={() =>
                                    setConditionItemInbound(
                                      (prevConditionItemInbound) => ({
                                        ...prevConditionItemInbound,
                                        condition: item?.condition
                                      })
                                    )
                                  }
                                  onInputChange={(e) => {
                                    setConditionItemInbound({
                                      inputValue: e,
                                      condition: item?.condition
                                    })
                                  }}
                                  onChange={(e) =>
                                    handleSetAssignArea(
                                      inbound?.id,
                                      item?.id,
                                      e
                                    )
                                  }
                                />
                              )}
                            {(inbound?.erp_code ||
                              (inbound?.has_karantina &&
                                inbound?.erp_code_karantina)) && (
                              <p className="text-end d-flex h-100 justify-content-end">
                                {item?.area && <span>{item?.area}</span>}
                                {!item?.area && (
                                  <span
                                    className="text-danger text-uppercase"
                                    style={{
                                      fontWeight: 'bold'
                                    }}
                                  >
                                    tolak
                                  </span>
                                )}
                              </p>
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </Col>
              {inbound?.status === 'waiting_om' &&
                (!inbound?.erp_code ||
                  (inbound?.has_karantina && !inbound?.erp_code_karantina)) && (
                  <Row>
                    {inbound?.erp?.erp_status === false &&
                      inbound?.erp?.err_notes && (
                        <Col md={7}>
                          <Row>
                            <Col md={12}>
                              <p>
                                {' '}
                                {inbound?.erp?.description}, <br />
                                status:{' '}
                                <span
                                  style={{
                                    color:
                                      inbound?.erp?.erp_status === true
                                        ? 'green'
                                        : '#C12727'
                                  }}
                                  className="ms-2 fw-bold text-uppercase"
                                >
                                  {inbound?.erp?.erp_status === true
                                    ? 'Success'
                                    : 'Failed'}
                                </span>
                              </p>
                            </Col>
                            <Col md={12}>
                              <span>Failed Reason: </span>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: inbound?.erp?.err_notes
                                }}
                              ></div>
                            </Col>
                          </Row>
                        </Col>
                      )}
                    <Col
                      md={
                        inbound?.erp?.erp_status === false &&
                        inbound?.erp?.err_notes
                          ? 5
                          : 12
                      }
                      className="d-flex flex-row justify-content-end mb-3"
                    >
                      <small className="text-danger me-4 mt-1 fs-6">
                        Ensure that all destination areas match the items
                      </small>

                      <div>
                        <Button
                          className="btn btn-warning text-white"
                          onClick={() => handleSubmitAndSendErp(inbound?.id)}
                          style={{ fontSize: '11.8px' }}
                        >
                          Submit & Send to ERP
                        </Button>
                      </div>
                    </Col>
                  </Row>
                )}
            </>
          ))}
      </Row>
      <div style={{ margin: 0, padding: 0 }}>
        <div style={{ borderTop: '3px solid #F1F2F7' }} className="mt-4"></div>
      </div>
      <Row className="mt-4">
        <Col md={12}>
          <h5 className="fw-bold"> ITEM RECEIPT DETAILS</h5>
        </Col>
        <Col md={12} className="mt-2">
          <Table
            hover
            responsive="sm"
            className="shadow-sm mb-4 bg-white rounded"
            size="sm"
            style={{ fontSize: '12px' }}
          >
            <thead className="table-secondary">
              <tr>
                <th className="p-3 align-middle" style={{ width: '20%' }}>
                  Item
                </th>
                <th className="p-3 text-end">Batch</th>
                <th className="p-3 text-end">TR Pack</th>
                <th className="p-3 text-end">TR Qty</th>
                <th className="p-3 text-center">Accepted Pack</th>
                <th className="p-3 text-end">Accepted Qty</th>
                <th className="p-3 text-end">Quarantine Pack</th>
                <th className="p-3 text-end">Quarantine Qty</th>
                <th className="p-3 text-end">Rejected Qty</th>
                <th className="p-3 text-end">Rejected Pack</th>
              </tr>
            </thead>
            <tbody>
              {!detail?.receipt_detail?.length && (
                <tr>
                  <td colSpan={10} className="fw-bold text-center">
                    <p>No data</p>
                  </td>
                </tr>
              )}
              {detail?.receipt_detail?.length &&
                detail?.receipt_detail?.map((receipt_detail) => (
                  <tr>
                    <td className="p-3">{receipt_detail?.item}</td>
                    <td className="p-3">{receipt_detail?.batch}</td>
                    <td className="p-3 text-end">{receipt_detail?.acc_pack}</td>
                    <td className="p-3 text-end">{receipt_detail?.acc_qty}</td>
                    <td className="p-3 text-center">
                      {receipt_detail?.acc_pack}
                    </td>
                    <td className="p-3 text-end">{receipt_detail?.acc_qty}</td>
                    <td className="p-3 text-end">
                      {receipt_detail?.quarantine_pack}
                    </td>
                    <td className="p-3 text-end">
                      {receipt_detail?.quarantine_qty}
                    </td>
                    <td className="p-3 text-end">
                      {receipt_detail?.damaged_pack}
                    </td>
                    <td className="p-3 text-end">
                      {receipt_detail?.damaged_qty}
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col md={12}>
          <div className="w-100">
            <Card>
              <Card.Body className="ps-md-5 pe-md-5 ps-lg-5 pe-lg-5 pt-4">
                <Row>
                  <Col xs={12} md={3} lg={3}>
                    <Label
                      label="CREATED BY"
                      value={detail?.po_info?.createdby ?? '-'}
                    />
                  </Col>
                  <Col xs={12} md={3} lg={3}>
                    <Label
                      label="CREATED AT"
                      value={convertDateWithTime(detail?.po_info?.createdAt)}
                    />
                  </Col>
                  <Col xs={12} md={3} lg={3}>
                    <Label
                      label="LAST UPDATED BY"
                      value={detail?.po_info?.updatedby}
                    />
                  </Col>
                  <Col xs={12} md={3} lg={3}>
                    <Label
                      label="LAST UPDATED AT"
                      value={convertDateWithTime(detail?.po_info?.updatedAt)}
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </div>
        </Col>
      </Row>
      <ModalPrDetailTimeline
        show={showModalTimeline}
        timelineId={prTimelineId}
        onHide={() => setShowModalTimeline(false)}
      />
      <ModalUpdateCoa
        show={showModalUpdateCoa}
        onHide={() => setShowModalUpdateCoa(false)}
        detailCoa={detailCoa}
        refreshdata={() => fetchDetail()}
      />
    </Container>
  )
}

export default Detail
