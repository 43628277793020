import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Col, Container, Row, Table } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import Loader from '../../../components/atoms/Loader'
import api from '../../../config/AxiosInstance'
import { convertDateWithTime } from '../../../helpers/constants'
import '../../../styles/style.css'
import * as Common from '../../../utils/Common'
import RowEmpty from '../../atoms/RowEmpty'
import TableAction from '../../atoms/Table/TableAction'
import TablePagination from '../../atoms/Table/TablePagination'

const headerTitleStyle = {
  color: 'var(--primary-orange)',
  fontSize: '24px',
  fontWeight: '600'
}

const History = () => {
  const [meta, setMeta] = useState({
    per_page: 10,
    current_page: 1,
    total_row: 0,
    total_page: 0
  })
  const [loading, setLoading] = useState(false)
  const [outboundHistories, setOutboundHistories] = useState([])
  const { per_page, current_page } = meta

  const navigate = useNavigate()

  useEffect(() => {
    fetchOutboundHistories()
  }, [per_page, current_page])

  const fetchOutboundHistories = async () => {
    setLoading(true)
    try {
      const res = await api.get('outbound/history/list', {
        params: {
          per_page: per_page,
          page: current_page
        }
      })
      const { metadata, data } = res.data
      setOutboundHistories(data)
      setMeta(metadata)
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setLoading(false)
    }
  }

  const handleMetaChange = (e) => {
    setMeta((prevMeta) => ({
      ...prevMeta,
      per_page: parseInt(e.target.value, 10)
    }))
  }

  const handlePageChange = (data) => {
    setMeta((prevMeta) => ({
      ...prevMeta,
      current_page: parseInt(data.selected + 1)
    }))
    window.scrollTo(0, 0)
  }

  const navigateToDetail = (data) => {
    if (data.type === 'sj') {
      return `/outbound/sj/detail/${encodeURIComponent(data.erp_doc_no)}`
    }
    if (data.type === 'tr') {
      return `/outbound/transfer/detail/${data.sj_draft_id}`
    }
  }
  return (
    <Container>
      <Row>
        <Col>
          <h4 className="fw-bold" style={headerTitleStyle}>
            Outbound History
          </h4>
        </Col>
      </Row>
      <Row className="mt-2 mt-md-0 mt-lg-0">
        <Col md={12}>
          <Table
            hover
            size="sm"
            responsive="sm"
            className="shadow-sm mb-5 bg-white rounded"
            style={{ fontSize: '13px' }}
          >
            <thead className="table-secondary">
              <tr>
                <th className="p-2 align-middle ">Source Doc No.</th>
                <th className="p-2 align-middle">ERP Doc No.</th>
                <th className="p-2 align-middle text-end">Submit</th>
                <th className="p-2 align-middle text-end">Action</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan={6}>
                    <Loader />
                  </td>
                </tr>
              ) : outboundHistories.length > 0 ? (
                outboundHistories.map((data, i) => (
                  <tr key={i}>
                    <td className="p-2 align-middle">{data.source_doc_no}</td>
                    <td className="p-2 align-middle">{data.erp_doc_no}</td>
                    <td className="p-2 align-middle text-end">
                      {!data.submit ? '-' : convertDateWithTime(data.submit)}
                    </td>
                    <td className="p-2 align-middle">
                      <TableAction>
                        <button
                          className="btn btn-secondary ml-2"
                          onClick={() =>
                            navigate(navigateToDetail(data), { replace: true })
                          }
                        >
                          <FontAwesomeIcon icon={faMagnifyingGlass} />
                        </button>
                      </TableAction>
                    </td>
                  </tr>
                ))
              ) : (
                <RowEmpty colSpan={6} />
              )}
            </tbody>
            <tfoot>
              <tr>
                <td colSpan={4} className="p-3">
                  <TablePagination
                    meta={meta}
                    handlePageChange={handlePageChange}
                    handleOnchangeMeta={handleMetaChange}
                  />
                </td>
              </tr>
            </tfoot>
          </Table>
        </Col>
      </Row>
    </Container>
  )
}

export default History
