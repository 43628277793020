import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { Badge, Col, Container, Form, Row, Table } from 'react-bootstrap'
import { Loader } from 'react-bootstrap-typeahead'
import { useLocation, useNavigate } from 'react-router-dom'
import TableAction from '../../../components/atoms/Table/TableAction'
import TablePagination from '../../../components/atoms/Table/TablePagination'
import InputDatePicker from '../../../components/Input/DatePicker/InputDatePicker'
import api from '../../../config/AxiosInstance'
import { convertDateWithoutTime } from '../../../helpers/constants'
import * as Common from '../../../utils/Common'
const headerTitleStyle = {
  color: 'var(--primary-orange)',
  fontSize: '24px',
  fontWeight: '600'
}

const titleStyle = {
  fontSize: '12px',
  letterSpacing: '0.5px'
}

const btnOrange = {
  backgroundColor: 'var(--primary-orange)',
  borderColor: 'var(--primary-orange)',
  color: 'white'
}

const DeliveryHistory = () => {
  const [deliveryList, setDeliveryList] = useState([])
  const [loading, setLoading] = useState(false)
  const [plateNo, setPlateNo] = useState([])
  const [selected, setSelected] = useState([])
  const { state } = useLocation()
  const [meta, setMeta] = useState({
    per_page: 10,
    current_page: 1,
    total_row: 0,
    total_page: 0
  })
  const [filter, setFilter] = useState({
    plate_no: state ? state?.plate_no : '',
    startDate: state
      ? moment(state?.startDate).format('YYYY-MM-DD')
      : moment().format('YYYY-MM-DD'),
    endDate: state
      ? moment(state?.endDate).format('YYYY-MM-DD')
      : moment().add('14', 'days').format('YYYY-MM-DD')
  })
  const navigate = useNavigate()

  const navigateToDetail = (data) => {
    const encode = encodeURIComponent(data.outbound_id)
    navigate(`/outbound/delivery-history/detail/${encode}`, {
      state: {
        plate_no: filter.plate_no,
        startDate: filter.startDate,
        endDate: filter.endDate
      }
    })
  }

  const getList = async () => {
    try {
      setLoading(true)
      const response = await api.get(`outbound/delivery_history/list`, {
        params: {
          page: meta.current_page,
          per_page: meta.per_page,
          plate_no: filter.plate_no,
          start_delivery_date: filter.startDate,
          end_delivery_date: filter.endDate
        }
      })
      const { data } = response
      if (data.success) {
        setDeliveryList(data.data)
        setMeta(data.metadata)
      }
    } catch (error) {
      const err = error.response.data.errors.message || 'An error occurred'
      Common.showPopUpErrorMessage(err)
    } finally {
      setLoading(false)
    }
  }

  const getPlateNo = async () => {
    try {
      const response = await api.get('master/vehicles/all')
      const { data } = response
      if (data.success) {
        setPlateNo(response.data.data)
      }
    } catch (error) {
      const err = error.response.data.errors.message || 'An error occurred'
      Common.showPopUpErrorMessage(err)
    }
  }

  const handleOnCHange = (e) => {
    const { name, value } = e.target
    setFilter((prev) => ({ ...prev, [name]: value }))
  }

  const handleDisable = () => {
    if (moment(filter.startDate) > moment(filter.endDate)) {
      return true
    }
    return false
  }

  const handlePageClick = async (data) => {
    const current_page = data.selected + 1
    setMeta({
      ...meta,
      current_page: current_page
    })
    window.scrollTo(0, 0)
  }

  const handleSearch = () => {
    setMeta({ ...meta, current_page: 1 })
    setSelected((prevSelected) => !prevSelected)
  }
  function handleOnChangeMeta(e) {
    setMeta({
      ...meta,
      [e.target.name]: e.target.value
    })
  }

  useEffect(() => {
    getList()
    getPlateNo()
  }, [meta.per_page, meta.current_page, selected])

  return (
    <Container>
      <Row>
        <Col>
          <h1 style={headerTitleStyle}>Delivery History</h1>
        </Col>
      </Row>
      <Row>
        <Col md={2}>
          <Form.Label
            className="fw-bold text-secondary text-uppercase"
            style={titleStyle}
          >
            <small>plate no.</small>
          </Form.Label>
          <Form.Select
            name="plate_no"
            value={filter.plate_no}
            onChange={(e) => handleOnCHange(e)}
          >
            <option value="">All</option>
            {plateNo.map((data) => {
              return (
                <option value={data.plate_no} key={data.id}>
                  {data.plate_no}
                </option>
              )
            })}
          </Form.Select>
        </Col>
        <Col md={4} className="d-flex gap-2">
          <div className="w-50">
            <Form.Label
              className="fw-bold text-secondary text-uppercase"
              style={titleStyle}
            >
              <small>Delivery Date</small>
            </Form.Label>
            <InputDatePicker
              value={filter?.startDate}
              onChange={(date) =>
                setFilter((prevFilter) => ({
                  ...prevFilter,
                  startDate: moment(date).format('YYYY-MM-DD')
                }))
              }
            />
          </div>
          <h1 style={{ marginTop: '18px' }} className="text-secondary">
            -
          </h1>
          <div className="w-50">
            <Form.Label
              className="fw-bold text-secondary text-uppercase"
              style={(titleStyle, { marginTop: '15px' })}
            >
              <small></small>
            </Form.Label>
            <div className="d-flex flex-row">
              <InputDatePicker
                value={filter?.endDate}
                onChange={(date) =>
                  setFilter((prevFilter) => ({
                    ...prevFilter,
                    endDate: moment(date).format('YYYY-MM-DD')
                  }))
                }
              />
            </div>
          </div>
        </Col>
        <Col md={2} className="d-flex align-items-center">
          <button
            style={btnOrange}
            className={`btn mt-4 ${handleDisable() ? 'cursor-disabled' : ''}`}
            onClick={() => handleSearch()}
            disabled={handleDisable()}
          >
            Search
          </button>
        </Col>
      </Row>
      <Row className="mb-3 mt-2">
        <Col>
          <hr />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <Table
            hover
            size="m"
            responsive="sm"
            className="shadow-sm mb-5 bg-white rounded"
            style={{ fontSize: '13px' }}
          >
            <thead className="table-secondary">
              <tr>
                <th className="p-2 align-middle">Plate No.</th>
                <th className="p-2 align-middle">Vehicle Type</th>
                <th className="p-2 align-middle">Driver</th>
                <th className="p-2 align-middle">Capacity(Kg)</th>
                <th className="p-2 align-middle">
                  MAX Volume(cm<sup>3</sup>)
                </th>
                <th className="p-2 align-middle">
                  Total Volume Picked(cm<sup>3</sup>)
                </th>
                <th className="p-2 align-middle">#Outbound</th>
                <th className="p-2 align-middle">Delivery Date</th>
                <th className="p-2 align-middle">Delivery Status</th>
                <th className="p-2 align-middle">Action</th>
              </tr>
            </thead>
            <tbody>
              {loading && (
                <tr>
                  <td colSpan={10} className="p-2 text-center">
                    <Loader />
                  </td>
                </tr>
              )}
              {!loading && !deliveryList.length && (
                <tr>
                  <td
                    colSpan={10}
                    className="p-2 align-middle text-center fw-bold"
                  >
                    No Data
                  </td>
                </tr>
              )}
              {!loading &&
                deliveryList.length > 0 &&
                deliveryList.map((item) => {
                  return (
                    <tr key={item.outbound_id}>
                      <td className="p-2 align-middle">{item.plate_no}</td>
                      <td className="p-2 align-middle">{item.vehicle_type}</td>
                      <td className="p-2 align-middle">{item.driver}</td>
                      <td className="p-2 align-middle">{item.capacity}</td>
                      <td className="p-2 align-middle">{item.max_volume}</td>
                      <td className="p-2 align-middle">
                        {item.total_volume_picked}
                      </td>
                      <td className="p-2 align-middle">{item.outbound}</td>
                      <td className="p-2 align-middle">
                        {convertDateWithoutTime(item.delivery_date)}
                      </td>
                      <td className="p-2 align-middle">
                        {item.delivery_status === 'done' ? (
                          <Badge pill bg="success" className="text-uppercase">
                            {item.delivery_status}
                          </Badge>
                        ) : (
                          <Badge pill bg="primary" className="text-uppercase">
                            {item.delivery_status}
                          </Badge>
                        )}
                      </td>
                      <td className="p-2 align-middle">
                        <TableAction>
                          <button
                            type="button"
                            className="btn btn-secondary ml-2"
                            onClick={() => navigateToDetail(item)}
                          >
                            <FontAwesomeIcon icon={faMagnifyingGlass} />
                          </button>
                        </TableAction>
                      </td>
                    </tr>
                  )
                })}
            </tbody>
            <tfoot>
              <tr>
                <td className="p-3" colSpan={10}>
                  <TablePagination
                    meta={meta}
                    handleOnChangeMeta={handleOnChangeMeta}
                    handlePageChange={handlePageClick}
                  />
                </td>
              </tr>
            </tfoot>
          </Table>
        </Col>
      </Row>
    </Container>
  )
}

export default DeliveryHistory
