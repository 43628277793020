import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Container, Form, Row, Table } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import Select from 'react-select'
import Loader from '../../../components/atoms/Loader'
import RowEmpty from '../../../components/atoms/RowEmpty'
import { ReceiveBill } from '../../../components/atoms/StatusLabel'
import TableAction from '../../../components/atoms/Table/TableAction'
import TablePagination from '../../../components/atoms/Table/TablePagination'
import ModalTypePr from '../../../components/modal/pr/ModalTypePr'
import api from '../../../config/AxiosInstance'
import { PURCHASE_ORDERS } from '../../../helpers/constants'
import '../../../styles/style.css'
import * as Common from '../../../utils/Common'

const headerTitleStyle = {
  color: 'var(--primary-orange)',
  fontSize: '24px',
  fontWeight: '600'
}

const titleStyle = {
  fontSize: '12px',
  letterSpacing: '0.5px'
}

const btnOrange = {
  backgroundColor: 'var(--primary-orange)',
  borderColor: 'var(--primary-orange)',
  color: 'white'
}

const PurchaseOrder = () => {
  const [loading, setLoading] = useState(false)
  const [purchaseOrders, setPurchaseOrders] = useState([])
  const [status, setStatus] = useState('')
  const [meta, setMeta] = useState({
    per_page: 10,
    current_page: 1,
    total_row: 0,
    total_page: 0,
    applyFilter: false
  })
  const { per_page, current_page } = meta
  const [poName, setPoName] = useState(0)
  const [selectedPrinciple, setSelectedPrinciple] = useState('')
  const [principles, setPrinciples] = useState([])
  const refSrcPrinciples = useRef()
  const [selectedWarehouse, setSelectedWarehouse] = useState('')
  const [warehouses, setWarehouses] = useState([])
  const refSrcWarehouses = useRef()
  const refSrcStatus = useRef()
  const [po, setPo] = useState('')
  const [applyFilter, setApplyFilter] = useState(false)
  useEffect(() => {
    fetchPO(
      per_page,
      current_page,
      selectedPrinciple,
      selectedWarehouse,
      po,
      status
    )
  }, [per_page, current_page, applyFilter])

  useEffect(() => {
    getPrinciples()
    getWarehouses()
  }, [])

  const getPrinciples = async () => {
    try {
      const options = [{ value: '', label: 'All' }]
      const res = await api.get('/inbound/purchase-order/principles')
      const { data } = res.data
      data.map((data) =>
        options.push({ value: data.supplier, label: data.supplier })
      )
      setPrinciples(options)
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }

  const getWarehouses = async () => {
    try {
      const options = [{ value: '', label: 'All' }]
      const res = await api.get('/inbound/purchase-order/dest-warehouses')
      const { data } = res.data
      data.map((data) =>
        options.push({ value: data.set_warehouse, label: data.set_warehouse })
      )
      setWarehouses(options)
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }

  const handlePageChange = (data) => {
    setMeta((prevMeta) => ({
      ...prevMeta,
      current_page: parseInt(data.selected + 1)
    }))
    window.scrollTo(0, 0)
  }

  async function fetchPO(
    per_page,
    current_page,
    selectedPrinciple,
    selectedWarehouse,
    po,
    status
  ) {
    try {
      setLoading(true)
      const res = await api.get('inbound/purchase-order', {
        params: {
          page: current_page,
          per_page,
          principle: selectedPrinciple || '',
          dest_warehouse: selectedWarehouse || '',
          po: po || '',
          status: status || ''
        }
      })
      const { metadata, data } = res.data

      setPurchaseOrders(data)
      setMeta(metadata)
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setLoading(false)
    }
  }

  const handleOnChangeMeta = (e) => {
    setMeta((prevMeta) => ({
      ...prevMeta,
      per_page: parseInt(e.target.value, 10),
      current_page: 1
    }))
  }

  const navigate = useNavigate()
  const [modalTypePr, setModalTypePr] = useState(false)
  const [dataCreatePR, setDataCreatePR] = useState({})

  function handleCreate(code, data) {
    setModalTypePr(true)
    setPoName(code)
    setDataCreatePR(data)
  }

  const navigateToDetail = (purchaseOrder) => {
    const encodedName = encodeURIComponent(purchaseOrder.name)
    navigate(`/inbound/purchase-order/detail/${encodedName}`)
  }

  function handleReset() {
    refSrcPrinciples.current?.clearValue()
    refSrcWarehouses.current?.clearValue()
    refSrcStatus.current?.clearValue()
    setPo('')
    setStatus('')
    setMeta((prevMeta) => ({
      ...prevMeta,
      per_page: 10,
      current_page: 1
    }))
  }

  async function handleSelected() {
    try {
      const page = 1
      setMeta((prevMeta) => ({
        ...prevMeta,
        current_page: page
      }))
      setApplyFilter((prevApplyFilter) => !prevApplyFilter)
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }

  const SelectStyles = {
    control: (base) => ({
      ...base,
      height: 40,
      minHeight: 40
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: '40px',
      padding: '0 6px'
    }),
    input: (provided, state) => ({
      ...provided,
      margin: '0px'
    })
  }

  return (
    <>
      <Container>
        <Row>
          <Col>
            <h3 style={headerTitleStyle}>Purchase Order</h3>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={3}>
            <Form.Group>
              <Form.Label className="fw-bold text-secondary" style={titleStyle}>
                <small>PRINCIPLE</small>
              </Form.Label>
              <Select
                isClearable
                defaultValue={selectedPrinciple}
                onChange={(e) => {
                  if (!e) {
                    e = {
                      target: refSrcPrinciples,
                      value: ''
                    }
                  }
                  setSelectedPrinciple(e?.value)
                }}
                options={principles}
                isSearchable
                ref={refSrcPrinciples}
                styles={SelectStyles}
              />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group>
              <Form.Label className="fw-bold text-secondary" style={titleStyle}>
                <small>DESTINATION WAREHOUSE</small>
              </Form.Label>
              <Select
                isClearable
                defaultValue={selectedWarehouse}
                onChange={(e) => {
                  if (!e) {
                    e = {
                      target: refSrcWarehouses,
                      value: ''
                    }
                  }
                  setSelectedWarehouse(e?.value)
                }}
                options={warehouses}
                isSearchable
                ref={refSrcWarehouses}
                styles={SelectStyles}
              />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Label className="fw-bold text-secondary" style={titleStyle}>
              <small>#PO</small>
            </Form.Label>
            <Form.Group>
              <Form.Control
                type="text"
                value={po}
                onChange={(e) => setPo(e.target.value)}
                style={{ height: '40px' }}
                placeholder="Enter PO..."
              />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Label className="fw-bold text-secondary" style={titleStyle}>
              <small>STATUS</small>
            </Form.Label>
            <Form.Select
              aria-label="Select Warehouse"
              name="status"
              id="status"
              onChange={(e) => setStatus(e.target.value)}
              value={status}
              style={{ height: '40px' }}
            >
              <option value="">All</option>
              {PURCHASE_ORDERS.map((devType, index) => (
                <option value={devType.value} key={devType.id}>
                  {devType.label}
                </option>
              ))}
            </Form.Select>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md={6} className="mt-3"></Col>
          <Col md={6} className="text-end">
            <Button
              type="button"
              className="btn btn-secondary text-white me-2"
              onClick={() => handleReset()}
            >
              Reset Filter
            </Button>
            <Button
              style={btnOrange}
              onClick={() => {
                handleSelected()
              }}
            >
              Apply Filter
            </Button>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md={12}>
            <Table
              hover
              responsive="sm"
              className="shadow-sm mb-5 bg-white rounded"
              size="sm"
              style={{ fontSize: '12px' }}
            >
              <thead className="table-secondary">
                <tr>
                  <th className="p-3 align-middle">#PO</th>
                  <th className="p-3 text-end align-middle">Principle</th>
                  <th
                    className="p-3 text-end align-middle"
                    style={{ width: '25%' }}
                  >
                    Destination Warehouse
                  </th>
                  <th className="p-3 text-end align-middle">Status</th>
                  <th className="p-3 text-end align-middle">Action</th>
                </tr>
              </thead>
              <tbody>
                {loading && (
                  <tr>
                    <td colSpan={5}>
                      <Loader />
                    </td>
                  </tr>
                )}
                {!loading && purchaseOrders.length === 0 && (
                  <RowEmpty colSpan={5} />
                )}
                {!loading &&
                  purchaseOrders?.map((purchaseOrder, i) => {
                    return (
                      <tr key={i}>
                        <td className="p-3 align-middle">
                          {purchaseOrder.name}
                        </td>
                        <td className="p-3 text-end align-middle">
                          {purchaseOrder.supplier}
                        </td>
                        <td className="p-3 text-end align-middle">
                          {purchaseOrder.set_warehouse}
                        </td>
                        <td className="p-3 text-end align-middle">
                          <ReceiveBill text={purchaseOrder.status} />
                        </td>
                        <td className="p-3 text-end align-middle">
                          <TableAction>
                            <button
                              type="button"
                              className="btn btn-secondary ml-2"
                              onClick={() => {
                                navigateToDetail(purchaseOrder)
                              }}
                            >
                              <FontAwesomeIcon icon={faMagnifyingGlass} />
                            </button>
                            <button
                              type="button"
                              className="btn btn-primary-blue ml-2 ms-2"
                              onClick={() =>
                                handleCreate(purchaseOrder.name, purchaseOrder)
                              }
                            >
                              <small>+ PR DRAFT</small>
                            </button>
                          </TableAction>
                        </td>
                      </tr>
                    )
                  })}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={5} className="p-3">
                    <TablePagination
                      meta={meta}
                      handleOnChangeMeta={handleOnChangeMeta}
                      handlePageChange={handlePageChange}
                    />
                  </td>
                </tr>
              </tfoot>
            </Table>
          </Col>
        </Row>
      </Container>

      <ModalTypePr
        show={modalTypePr}
        onHide={() => setModalTypePr(false)}
        code={poName}
        data={dataCreatePR}
      />
    </>
  )
}

export default PurchaseOrder
