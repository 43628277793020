import { faClose, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Formik } from 'formik'
import { useRef, useState } from 'react'
import { Card, Col, Form, Modal, Row } from 'react-bootstrap'
import api from '../../../config/AxiosInstance'
import '../../../styles/style.css'
import * as Common from '../../../utils/Common'
import { VehicleAddEditSchema } from '../../../utils/validationSchema'
import CustomButton from '../../Button/CustomButton'
import CustomCloseButton from '../../Button/CustomCloseButton'
import TextField from '../../Input/TextInput'
import SelectInput from '../../Input/SelectInput'

const statuses = [
  { id: '1', name: 'Active' },
  { id: '0', name: 'Inactive' }
]

function ModalAddVehicle(props) {
  const initialValues = {
    plate_no: '',
    vehicle_type: '',
    length: 0,
    width: 0,
    height: 0,
    volume: 0,
    capacity: 0,
    status: 1,
    typeVehicle: props.activeKey === 'external' ? true : false,
    stnk_doc: ''
  }
  const fileUploadStnk = useRef(null)
  const [fileNameStnk, setFileNameStnk] = useState('')
  const handleSubmit = async (values, actions) => {
    try {
      values.type = props.activeKey
      const formData = new FormData()
      for (const key in values) {
        formData.append(key, values[key])
      }
      const response = await api.postForm('/master/vehicles/add', formData)

      if (response.data.success) {
        setFileNameStnk('')
        props.onHide()
        props.setIsAdd((add) => !add)
        Common.showPopUpSuccessMessage(response.data.message)
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }

  return (
    <Modal {...props} centered>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={VehicleAddEditSchema}
        onSubmit={(values) => {
          handleSubmit(values)
        }}
      >
        {({
          handleBlur,
          handleSubmit,
          handleChange,
          values,
          errors,
          touched,
          isValid,
          dirty,
          setFieldValue
        }) => {
          return (
            <Form className="d-grid gap-2" onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title>Add Vehicle</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col md={6}>
                    <TextField
                      label="PLATE NO."
                      name="plate_no"
                      value={values.plate_no}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                      required={true}
                    />
                  </Col>
                  <Col md={6}>
                    <TextField
                      label="VEHICLE TYPE"
                      name="vehicle_type"
                      value={values.vehicle_type}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                      required={true}
                    />
                  </Col>
                  <Col md={6}>
                    <TextField
                      label="PANJANG(CM)"
                      type="number"
                      name="length"
                      value={values.length}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                      required={true}
                    />
                  </Col>
                  <Col md={6}>
                    <TextField
                      label="LEBAR(CM)"
                      type="number"
                      name="width"
                      value={values.width}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                      required={true}
                    />
                  </Col>
                  <Col md={6}>
                    <TextField
                      label="TINGGI(CM)"
                      type="number"
                      name="height"
                      value={values.height}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                      required={true}
                    />
                  </Col>
                  <Col md={6}>
                    <TextField
                      label="CAPACITY(KG)"
                      type="number"
                      name="capacity"
                      value={values.capacity}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                      required={true}
                    />
                  </Col>
                  <Col md={12}>
                    <TextField
                      label="VOLUME(CM³)"
                      type="number"
                      name="volume"
                      value={values.volume}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                      required={true}
                    />
                  </Col>
                  {props.activeKey === 'external' && (
                    <Col md={12}>
                      <Form.Group>
                        <Form.Label
                          className="text-muted"
                          style={{ fontSize: '13px' }}
                        >
                          STNK <sup style={{ color: 'red' }}>*</sup>
                        </Form.Label>
                        {fileNameStnk === '' && (
                          <Card
                            style={{
                              width: '3rem',
                              height: '3rem',
                              cursor: 'pointer'
                            }}
                            onClick={() => fileUploadStnk.current.click()}
                          >
                            <Card.Body>
                              <div style={{ marginTop: '-.3rem' }}>
                                <FontAwesomeIcon
                                  icon={faPlus}
                                  style={{ color: '#828282' }}
                                />
                              </div>
                            </Card.Body>
                          </Card>
                        )}
                        {fileNameStnk !== '' && (
                          <Card>
                            <Card.Body>
                              <div className="d-flex h-100 align-items-center">
                                <FontAwesomeIcon
                                  icon={faClose}
                                  style={{
                                    color: '#828282',
                                    fontSize: '20px',
                                    cursor: 'pointer'
                                  }}
                                  onClick={() => {
                                    setFileNameStnk('')
                                    setFieldValue('stnk_doc', '')
                                  }}
                                />
                                <span className="ml-2">{fileNameStnk}</span>
                              </div>
                            </Card.Body>
                          </Card>
                        )}
                        <input
                          type="file"
                          name="stnk_doc"
                          accept="image/jpg,image/jpeg,image/png,.doc,.docx,.pdf"
                          onChange={(e) => {
                            setFieldValue('stnk_doc', e.target.files[0])
                            setFileNameStnk(e.target.files[0].name)
                          }}
                          onBlur={handleBlur}
                          className="d-none"
                          ref={fileUploadStnk}
                        />
                        {!!errors.stnk_doc && (
                          <p className="text-danger mt-2">{errors.stnk_doc}</p>
                        )}
                      </Form.Group>
                    </Col>
                  )}
                  <Col md={12}>
                    <SelectInput
                      label="Status"
                      name="status"
                      id="status"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                      options={statuses}
                      required={true}
                    />
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <CustomCloseButton onClick={props.onHide} />
                <CustomButton
                  onClick={handleSubmit}
                  variant={'warning'}
                  disabled={!isValid || !dirty}
                  text={'Create'}
                />
              </Modal.Footer>
            </Form>
          )
        }}
      </Formik>
    </Modal>
  )
}

export default ModalAddVehicle
