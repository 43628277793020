import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Container, Form, Row, Table } from 'react-bootstrap'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import { useNavigate } from 'react-router-dom'
import Loader from '../../../components/atoms/Loader'
import { BadgeStatusPrListInbounds } from '../../../components/atoms/StatusLabel'
import TableAction from '../../../components/atoms/Table/TableAction'
import TablePagination from '../../../components/atoms/Table/TablePagination'
import InputDatePicker from '../../../components/Input/DatePicker/InputDatePicker'
import api from '../../../config/AxiosInstance'
import {
  convertDateWithoutTime,
  DELIVERY_TYPES
} from '../../../helpers/constants'
import '../../../styles/style.css'
import * as Common from '../../../utils/Common'
const SJ = () => {
  const titleStyle = {
    fontSize: '12px',
    letterSpacing: '0.5px'
  }

  const btnOrange = {
    backgroundColor: 'var(--primary-orange)',
    borderColor: 'var(--primary-orange)',
    color: 'white'
  }
  const headerTitleStyle = {
    color: 'var(--primary-orange)',
    fontSize: '24px',
    fontWeight: '600'
  }

  const [loading, setLoading] = useState(false)
  const [sjList, setSjList] = useState([])
  const [sjDraftId, setSjDraftId] = useState('')
  const [meta, setMeta] = useState({
    per_page: 10,
    current_page: 1,
    total_row: 0,
    total_page: 0
  })
  const [inputCustomer, setInputCustomer] = useState('')
  const [inputSrcWarehouse, setInputSrcWarehouse] = useState('')
  const [inputSo, setInputSo] = useState('')
  const [deliveryType, setDeliveryType] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const { per_page, current_page } = meta
  useEffect(
    function () {
      fetchSjList(
        sjDraftId,
        inputSo,
        inputCustomer,
        inputSrcWarehouse,
        startDate,
        endDate,
        deliveryType
      )
    },
    [per_page, current_page]
  )

  const fetchSjList = async (
    sjDraftId,
    inputSo,
    inputCustomer,
    inputSrcWarehouse,
    startDate,
    endDate,
    deliveryType
  ) => {
    try {
      setLoading(true)
      const res = await api.get('/outbound/sjdraft', {
        params: {
          per_page,
          page: current_page,
          sj_draft: sjDraftId || '',
          so: inputSo || '',
          customer: inputCustomer || '',
          src: inputSrcWarehouse || '',
          start_date: startDate || '',
          end_date: endDate || '',
          delivery_type:
            deliveryType === 'self-pickup' ? 'pick up' : deliveryType
        }
      })
      const { metadata, data } = res.data
      setSjList(data)
      setMeta(metadata)
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setLoading(false)
    }
  }

  // handle page
  function handleOnChangeMeta(e) {
    const perPageValue = parseInt(e.target.value, 10)
    setMeta((prevMeta) => ({
      ...prevMeta,
      per_page: perPageValue,
      current_page: 1
    }))
  }

  const handlePageChange = (data) => {
    setMeta((prevMeta) => ({
      ...prevMeta,
      current_page: parseInt(data.selected + 1)
    }))
    window.scrollTo(0, 0)
  }

  const navigate = useNavigate()
  const navigateToDetail = (data) => {
    const encodedName = encodeURIComponent(data.sj_draft)
    navigate(`/outbound/sj/detail/${encodedName}`)
  }

  function handleReset() {
    refSrcWarehouse.current?.clear()
    refCustomer.current?.clear()
    setInputSo('')
    setSjDraftId('')
    setStartDate('')
    setEndDate('')
    setDeliveryType('')
    setMeta((meta) => ({
      ...meta,
      per_page: 10,
      current_page: 1
    }))
  }

  const filterBy = () => true
  const [isLoadingSrcWarehouse, setLoadingSrcWarehouse] = useState(false)
  const [optionsSrcWarehouse, setOptionsSrcWarehouse] = useState([])
  async function handleSearchSrcWarehouse(query) {
    setLoadingSrcWarehouse(true)
    try {
      const params = `?q=${query}`
      const res = await api.get(`erp/sales-order/all-source-wr/${params}`)
      setOptionsSrcWarehouse(res.data.data)
      setLoadingSrcWarehouse(false)
    } catch (err) {
      setLoadingSrcWarehouse(false)
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }

  const filterCustomer = () => true
  const [isLoadingCustomer, setLoadingCustomer] = useState(false)
  const [optionsCustomer, setOptionsCustomer] = useState([])
  async function handleSearchCustomer(query) {
    setLoadingCustomer(true)
    try {
      const params = `?q=${query}`
      const res = await api.get(`erp/sales-order/all-customer/${params}`)
      setOptionsCustomer(res.data.data)
      setLoadingCustomer(false)
    } catch (err) {
      setLoadingCustomer(false)
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }

  const refSrcWarehouse = useRef(AsyncTypeahead)
  const refCustomer = useRef(AsyncTypeahead)
  // handle selected
  const handleSelected = () => {
    try {
      const page = 1
      setMeta((prevMeta) => ({
        ...prevMeta,
        current_page: page
      }))
      fetchSjList(
        sjDraftId,
        inputSo,
        inputCustomer,
        inputSrcWarehouse,
        startDate,
        endDate,
        deliveryType
      )
    } catch (error) {
      const errMsg =
        error?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }

  return (
    <>
      <Container>
        <Row>
          <Col>
            <h4 className="fw-bold" style={headerTitleStyle}>
              SJ Draft List
            </h4>
          </Col>
        </Row>
        {/* FILTER */}
        <Row className="mt-3">
          <Col md={3}>
            <Form.Label className="fw-bold text-secondary" style={titleStyle}>
              <small>#SJ DRAFT</small>
            </Form.Label>
            <Form.Control
              type="text"
              name="sj_draft"
              id="sj_draft"
              placeholder="Input"
              onChange={(e) => setSjDraftId(e.target.value)}
              value={sjDraftId}
            />
          </Col>
          <Col md={3}>
            <Form.Group>
              <Form.Label className="fw-bold text-secondary" style={titleStyle}>
                <small>#SO</small>
              </Form.Label>
              <Form.Control
                type="text"
                name="so"
                id="so"
                placeholder="Input"
                onChange={(e) => setInputSo(e.target.value)}
                value={inputSo}
              />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group>
              <Form.Label className="fw-bold text-secondary" style={titleStyle}>
                <small>CUSTOMER</small>
              </Form.Label>
              <AsyncTypeahead
                id="basic-typeahead-single"
                labelKey="customer"
                isLoading={isLoadingCustomer}
                filterBy={filterCustomer}
                onSearch={handleSearchCustomer}
                onChange={(e) => {
                  setInputCustomer(e.length > 0 ? e[0].customer : '')
                }}
                options={optionsCustomer}
                placeholder="Choose a customer ..."
                ref={refCustomer}
              />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group>
              <Form.Label className="fw-bold text-secondary" style={titleStyle}>
                <small>SOURCE WAREHOUSE</small>
              </Form.Label>
              <AsyncTypeahead
                id="basic-typeahead-single"
                labelKey="warehouse"
                isLoading={isLoadingSrcWarehouse}
                filterBy={filterBy}
                onSearch={handleSearchSrcWarehouse}
                onChange={(e) => {
                  setInputSrcWarehouse(e.length > 0 ? e[0].warehouse : '')
                }}
                options={optionsSrcWarehouse}
                placeholder="Choose a source warehouse..."
                ref={refSrcWarehouse}
              />
            </Form.Group>
          </Col>
          <Col md={3} className="mt-3">
            <Form.Group>
              <Form.Label className="fw-bold text-secondary" style={titleStyle}>
                <small>DELIVERY DATE START</small>
              </Form.Label>
              <InputDatePicker
                value={startDate}
                onChange={(e) => setStartDate(moment(e).format('YYYY-MM-DD'))}
                style={{ height: '14px' }}
              />
            </Form.Group>
          </Col>
          <Col md={3} className="mt-3">
            <Form.Group controlId="sjdelivend">
              <Form.Label className="fw-bold text-secondary" style={titleStyle}>
                <small>DELIVERY DATE END</small>
              </Form.Label>
              <InputDatePicker
                value={endDate}
                onChange={(e) => setEndDate(moment(e).format('YYYY-MM-DD'))}
                style={{ height: '14px' }}
              />
            </Form.Group>
          </Col>
          <Col md={3} className="mt-3">
            <Form.Group>
              <Form.Label className="fw-bold text-secondary" style={titleStyle}>
                <small>DELIVERY TYPE</small>
              </Form.Label>
              <Form.Select
                aria-label="Select Warehouse"
                name="delivery_type"
                id="delivery_type"
                onChange={(e) => setDeliveryType(e.target.value)}
                value={deliveryType}
              >
                <option value="">All</option>
                {DELIVERY_TYPES.map((devType, index) => (
                  <option value={devType.value} key={devType.id}>
                    {devType.label}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6} className="mt-3"></Col>
          <Col md={6} className="text-end">
            <Button
              className="btn btn-secondary text-white me-2"
              onClick={() => handleReset()}
            >
              Reset Filter
            </Button>
            <Button style={btnOrange} onClick={() => handleSelected()}>
              Apply Filter
            </Button>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={12}>
            <Table
              hover
              size="sm"
              responsive="sm"
              className="shadow-sm mb-5 bg-white rounded"
              style={{ fontSize: '13px' }}
            >
              <thead className="table-secondary">
                <tr>
                  <th className="p-2 align-middle">#SJ DRAFT</th>
                  <th className="p-2 align-middle">#SO</th>
                  <th className="p-2 align-middle">Customer</th>
                  <th
                    className="p-2 align-middle text-end"
                    style={{ width: '15%' }}
                  >
                    Source Warehouse
                  </th>
                  <th className="p-2 align-middle text-end">#Outbound</th>
                  <th className="p-2 align-middle text-end">Status</th>
                  <th className="p-2 align-middle text-end">Delivery Date</th>
                  <th className="p-2 text-end" style={{ width: '10%' }}>
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {loading && (
                  <tr>
                    <td colSpan={9}>
                      <Loader />
                    </td>
                  </tr>
                )}
                {!loading && sjList.length === 0 && (
                  <tr>
                    <td colSpan={9} className="fw-bold text-center">
                      No Data
                    </td>
                  </tr>
                )}
                {!loading &&
                  sjList.length > 0 &&
                  sjList?.map((data, i) => (
                    <tr key={i}>
                      <td className="p-2 align-middle">{data.sj_draft}</td>
                      <td className="p-2 align-middle">{data.so}</td>
                      <td className="p-2 align-middle">{data.customer}</td>
                      <td className="p-2 align-middle text-end">
                        {data.source}
                      </td>
                      <td className="p-2 align-middle text-end">
                        {data.outbound}
                      </td>
                      <td className="p-2 align-middle text-end">
                        <BadgeStatusPrListInbounds text={data.status} />
                      </td>
                      <td className="p-2 align-middle text-end">
                        {convertDateWithoutTime(data.delivery_date)}
                      </td>
                      <td className="p-2 align-middle gap-2">
                        <TableAction>
                          <button
                            type="button"
                            className="btn btn-secondary ml-2"
                            onClick={() => {
                              navigateToDetail(data)
                            }}
                          >
                            <FontAwesomeIcon icon={faMagnifyingGlass} />
                          </button>
                        </TableAction>
                      </td>
                    </tr>
                  ))}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={8}>
                    <TablePagination
                      meta={meta}
                      handlePageChange={handlePageChange}
                      handleOnChangeMeta={handleOnChangeMeta}
                    />
                  </td>
                </tr>
              </tfoot>
            </Table>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default SJ
