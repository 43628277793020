import {
  faCaretDown,
  faPlus,
  faSearch
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { OverlayTrigger, Popover, Table } from 'react-bootstrap'
import { Loader } from 'react-bootstrap-typeahead'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import RowEmpty from '../../../components/atoms/RowEmpty'
import { BadgeStatusOutboundPlan } from '../../../components/atoms/StatusLabel'
import TableAction from '../../../components/atoms/Table/TableAction'
import TablePagination from '../../../components/atoms/Table/TablePagination'
import ModalAddSelectStagingArea from '../../../components/modal/Outbound/ModalAddSelectStagingArea'
import Notification from '../../../components/Notifications/Notification'
import api from '../../../config/AxiosInstance'
import { convertDateWithoutTime } from '../../../helpers/constants'
import * as Common from '../../../utils/Common'
import { firstLetterUpperCase } from '../../../utils/TextFormatter'

const headerTitleStyle = {
  color: 'var(--primary-orange)',
  fontSize: '24px',
  fontWeight: '600'
}

const btnCreateSjStyle = {
  color: 'white',
  backgroundColor: '#203568'
}

const tableSecondarySj = {
  borderColor: 'transparent'
}

const OutboundPlan = () => {
  const navigate = useNavigate()
  const { state } = useLocation()
  const [outboundPlans, setOutboundPlans] = useState([])
  const [selectedWarehouseId, setSelectedWarehouseId] = useState({
    id: 0,
    warehouse_id: 0
  })
  const [loadingData, setLoadingData] = useState(false)
  const [meta, setMeta] = useState({
    per_page: 10,
    current_page: 1,
    total_row: 100,
    total_page: 10
  })
  const { per_page, current_page } = meta
  const [modalShowSelectStagingArea, setModalShowSelectStagingArea] =
    useState(false)

  const handleOnChangeMeta = (e) => {
    setLoadingData(true)
    setMeta((prevMeta) => ({
      ...prevMeta,
      per_page: parseInt(e.target.value, 10),
      current_page: 1
    }))
    setLoadingData(false)
  }

  const handlePageChange = (data) => {
    setLoadingData(true)
    setMeta((prevMeta) => ({
      ...prevMeta,
      current_page: data.selected + 1
    }))
    window.scrollTo(0, 0)
    setLoadingData(false)
  }

  const handleShowModalStagingArea = (id, warehouse_id) => {
    setModalShowSelectStagingArea(true)
    setSelectedWarehouseId({ id, warehouse_id })
  }

  useEffect(() => {
    getOutboundPlans()
    const timeoutScroll = setTimeout(() => window.scrollTo(0, 0), 0)
    const timeoutCloseNotification = setTimeout(() => {
      navigate('', { state: null })
    }, 10000)
    // Cleanup function to clear the timeout if the component unmounts before the timeout completes
    return () => {
      clearTimeout(timeoutCloseNotification)
      clearTimeout(timeoutScroll)
    }
  }, [per_page, current_page])

  // get lists outbound plan
  const getOutboundPlans = async () => {
    try {
      setLoadingData(true)
      const res = await api.get('/outbound/outbound_plan/list/', {
        params: { per_page, page: current_page }
      })
      if (res.status === 200) {
        const { data, metadata } = res.data
        setOutboundPlans(data)
        setMeta(metadata)
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setLoadingData(false)
    }
  }

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-8 col-lg-8">
            <h4 style={headerTitleStyle}>Outbound Plan</h4>
          </div>
          {state && (
            <Notification
              type="success"
              text="Outbound created!"
              handleClose={(e) => {
                e.stopPropagation()
                navigate('', { state: null })
              }}
            />
          )}
          {!state && (
            <div className="col-12 col-md-4 col-lg-4">
              <div className="d-flex flex-row flex-wrap justify-content-start justify-content-md-end justify-content-lg-end mt-2 mt-md-1 mt-lg-1">
                <Link
                  to="/outbound/plan/create"
                  className="text-decoration-none"
                >
                  <div className="d-flex flex-row flex-wrap gap-2 align-items-center">
                    <button
                      className="btn fs-6 d-flex flex-row flex-wrap align-items-center gap-2"
                      style={btnCreateSjStyle}
                    >
                      <FontAwesomeIcon
                        icon={faPlus}
                        className="fa-flip-horizontal"
                        style={{ fontSize: '14px' }}
                      />
                      <span>
                        <small>Create Outbound</small>
                      </span>
                    </button>
                  </div>
                </Link>
              </div>
            </div>
          )}
        </div>
        <div className="row mt-4 pe-2">
          <div className="col-12">
            <Table
              hover
              responsive="sm"
              size="sm"
              className="shadow-sm mb-5 bg-white rounded"
              style={{ fontSize: '14px' }}
            >
              <thead className="table-secondary" style={tableSecondarySj}>
                <tr>
                  <th className="p-2 align-middle">#Outbound</th>
                  <th className="p-2 align-middle">Source Warehouse</th>
                  <th className="p-2 align-middle">Delivery Type</th>
                  <th className="p-2 align-middle">Driver</th>
                  <th className="p-2 align-middle">Car</th>
                  <th className="p-2 align-middle">Delivery Date</th>
                  <th className="p-2 align-middle">Staging Area</th>
                  <th className="p-2 align-middle text-end">Status</th>
                  <th className="p-2 align-middle text-end" width="8%">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {loadingData && (
                  <td colSpan={9} className="text-center">
                    {' '}
                    <Loader />
                  </td>
                )}
                {!loadingData && outboundPlans.length === 0 && (
                  <RowEmpty colSpan={9} />
                )}
                {!loadingData &&
                  outboundPlans.length > 0 &&
                  outboundPlans.map((value, index) => (
                    <RowData
                      key={index}
                      data={value}
                      showModalStagingArea={handleShowModalStagingArea}
                    />
                  ))}
              </tbody>
              <tfoot>
                <tr>
                  <td className="p-3" colSpan={9}>
                    <TablePagination
                      meta={meta}
                      handleOnchangeMeta={handleOnChangeMeta}
                      handlePageChange={handlePageChange}
                    />
                  </td>
                </tr>
              </tfoot>
            </Table>
          </div>
        </div>
      </div>
      <ModalAddSelectStagingArea
        show={modalShowSelectStagingArea}
        onHide={() => {
          setModalShowSelectStagingArea(false)
        }}
        warehouseId={selectedWarehouseId}
        refreshData={() => getOutboundPlans()}
      />
    </>
  )
}

function RowData({ data, showModalStagingArea }) {
  let urlDetail = `/outbound/plan/detail/${data.id}`
  const propsStaging = (value, showModalStagingArea) => {
    if (value.delivery_type === 'pick_up' && value.status === 'unassigned') {
      return {
        onClick: () => showModalStagingArea(data.id, data.warehouse_id),
        className: 'd-flex pe-auto',
        role: 'button'
      }
    }
    if (
      value.status === 'unassigned' &&
      value.car !== '-' &&
      value.driver !== '-'
    ) {
      return {
        onClick: () => showModalStagingArea(data.id, data.warehouse_id),
        className: 'd-flex pe-auto',
        role: 'button'
      }
    }
    return { className: 'd-flex' }
  }
  return (
    <tr key={data.id}>
      <td className="p-2 align-middle">{data.outbound}</td>
      <td className="p-2 align-middle">{data.source}</td>
      <td className="p-2 align-middle">
        {!data?.delivery_type
          ? '-'
          : firstLetterUpperCase(
              data?.delivery_type === 'pick_up'
                ? 'Pick Up'
                : data?.delivery_type
            )}
      </td>
      <td className="p-2 align-middle">{!data.driver ? '-' : data.driver}</td>
      <td className="p-2 align-middle">{!data.car ? '-' : data.car}</td>
      <td className="p-2 align-middle">
        {convertDateWithoutTime(data.delivery_date)}
      </td>
      <td className="p-2 align-middle">
        <div {...propsStaging(data, showModalStagingArea)}>
          {!data.staging_name ? (
            'Select Staging Area'
          ) : (
            <>
              <OverlayTrigger
                trigger={['hover', 'click']}
                key={'bottom'}
                placement={'bottom'}
                overlay={
                  <Popover id={`popover-positioned-bottom`}>
                    <Popover.Body>
                      <p>{data.staging_name}</p>
                    </Popover.Body>
                  </Popover>
                }
              >
                <p className="text-truncate mt-3" style={{ maxWidth: '150px' }}>
                  {data.staging_name}
                </p>
              </OverlayTrigger>
            </>
          )}
          {!data.staging_name &&
            data.driver !== '-' &&
            data.car !== '-' &&
            data.delivery_tye !== 'pick_up' && (
              <FontAwesomeIcon
                icon={faCaretDown}
                className="fa-flip-horizontal ml-2 mt-1"
              />
            )}
          {!data.staging_name && data.delivery_type === 'pick_up' && (
            <FontAwesomeIcon
              icon={faCaretDown}
              className="fa-flip-horizontal ml-2 mt-1"
            />
          )}
        </div>
      </td>
      <td className="text-end p-2 align-middle">
        {<BadgeStatusOutboundPlan text={data.status} />}
      </td>
      <td className="p-2 align-middle text-end">
        <TableAction>
          <Link to={urlDetail}>
            <button className="btn btn-secondary btn-sm">
              <FontAwesomeIcon icon={faSearch} className="fa-flip-horizontal" />
            </button>
          </Link>
        </TableAction>
      </td>
    </tr>
  )
}

export default OutboundPlan
