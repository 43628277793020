import { faPen } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Nav, Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Loader from '../../../components/atoms/Loader'
import { ActiveInActive } from '../../../components/atoms/StatusLabel'
import TableAction from '../../../components/atoms/Table/TableAction'
import TablePagination from '../../../components/atoms/Table/TablePagination'
import ModalAddVehicle from '../../../components/modal/vehicle/ModalAddVehicle'
import ModalUpdateVehicle from '../../../components/modal/vehicle/ModalUpdateVehicle'
import api from '../../../config/AxiosInstance'
import '../../../styles/style.css'
import * as Common from '../../../utils/Common'
import { formatThousandSeparator } from '../../../utils/TextFormatter'

export default function MasterVehicle() {
  const headerTitleStyle = {
    color: 'var(--primary-orange)',
    fontSize: '24px',
    fontWeight: '600'
  }
  const titleStyle = {
    fontSize: '20px'
  }

  const [showModalAdd, setShowModalAdd] = useState(false)
  const [showModalUpdate, setShowModalUpdate] = useState(false)
  const [isAdd, setIsAdd] = useState(false)
  const [isUpdate, setIsUpdate] = useState(false)
  const [vehicles, setVehicles] = useState([])
  const [activeKey, setActiveKey] = useState('internal')
  const [meta, setMeta] = useState({
    per_page: 10,
    current_page: 1,
    total_row: 0,
    total_page: 0
  })
  const [editId, setEditId] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [pageCount, setPageCount] = useState(0)
  const { per_page, current_page } = meta

  useEffect(
    function () {
      fetchVehicles(per_page, current_page)
    },
    [isAdd, isUpdate, per_page, current_page, activeKey]
  )

  function handleOnChangeMeta(e) {
    const perPageValue = parseInt(e.target.value, 10)
    setMeta((prevMeta) => ({
      ...prevMeta,
      per_page: perPageValue,
      current_page: 1
    }))
  }

  const handlePageChange = (data) => {
    const selectedPage = data.selected + 1
    setMeta((prevMeta) => ({ ...prevMeta, current_page: selectedPage }))
    window.scrollTo(0, 0)
  }

  function handleEdit(id) {
    setShowModalUpdate((show) => !show)
    setEditId(id)
  }

  async function fetchVehicles(per_page, current_page) {
    setIsLoading(true)
    try {
      const res = await api.get('master/vehicles', {
        params: { per_page, page: current_page, type: activeKey }
      })
      if (res.data.success) {
        const { metadata, data } = res.data
        setVehicles(data)
        setMeta(metadata)
        setPageCount(metadata.total_page || 0)
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'

      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setIsLoading(false)
    }
  }

  const handleSelectActive = (key) => {
    setActiveKey(key) // Update the active key when an item is selected
  }

  return (
    <div className="container">
      <h3 style={headerTitleStyle}>Vehicle</h3>
      <div className="row p-2">
        <Nav
          variant="underline"
          onSelect={handleSelectActive}
          defaultActiveKey={activeKey}
          className="ml-2"
        >
          <Nav.Item>
            <Nav.Link eventKey={'internal'} className="text-muted">
              Internal
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey={'external'} className="text-muted">
              External
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <hr />
      </div>
      <div className="row">
        <div className="col-7">
          <h4 className="text-muted mt-3" style={titleStyle}>
            Vehicle List
          </h4>
        </div>
        <div className="col-5">
          <div className="d-flex justify-content-end h-100 align-items-center mt-1">
            <button
              className="btn btn-primary-orange"
              onClick={() => setShowModalAdd(true)}
            >
              Add
            </button>
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <Table
          responsive="sm"
          hover
          className="shadow-sm mb-5 bg-white rounded"
          size="sm"
          style={{ fontSize: '14px' }}
        >
          <thead className="table-secondary">
            <tr>
              <th className="p-2 align-middle">Plate No</th>
              <th className="p-2 align-middle">Vehicle Type</th>
              <th className="p-2 align-middle">Capacity(Kg)</th>
              <th className="p-2 align-middle">Panjang</th>
              <th className="p-2 align-middle">Lebar</th>
              <th className="p-2 align-middle">Tinggi</th>
              <th className="p-2 align-middle">Volume(CM³)</th>
              {activeKey === 'external' && (
                <th className="p-2 align-middle">STNK</th>
              )}
              <th className="p-2 align-middle">Status</th>
              <th className="p-2 align-middle text-end">Action</th>
            </tr>
          </thead>
          <tbody>
            {isLoading && (
              <tr>
                <td
                  colSpan={activeKey === 'external' ? 10 : 9}
                  className="text-center"
                >
                  <Loader />
                </td>
              </tr>
            )}
            {!isLoading && !vehicles.length && (
              <tr>
                <td
                  colSpan={activeKey === 'external' ? 10 : 9}
                  className="text-center"
                >
                  No data
                </td>
              </tr>
            )}
            {!isLoading &&
              vehicles.length > 0 &&
              vehicles?.map((item, i) => (
                <TbodyRow
                  data={item}
                  key={item.id}
                  handleEdit={() => handleEdit(item.id)}
                  activeKey={activeKey}
                />
              ))}
          </tbody>
          <tfoot>
            <tr>
              <td className="p-3" colSpan={activeKey === 'external' ? 10 : 9}>
                <TablePagination
                  meta={meta}
                  handleOnChangeMeta={handleOnChangeMeta}
                  handlePageChange={handlePageChange}
                />
              </td>
            </tr>
          </tfoot>
        </Table>
      </div>
      <ModalAddVehicle
        show={showModalAdd}
        onHide={() => setShowModalAdd(false)}
        setIsAdd={setIsAdd}
        activeKey={activeKey}
      />
      <ModalUpdateVehicle
        show={showModalUpdate}
        onHide={() => {
          setShowModalUpdate(false)
        }}
        data={editId}
        setIsUpdate={setIsUpdate}
        activeKey={activeKey}
      />
    </div>
  )
}

function TbodyRow({ data, handleEdit, activeKey }) {
  return (
    <tr key={data.id}>
      <td className="p-2 align-middle">{data.plate_no}</td>
      <td className="p-2 align-middle">{data.vehicle_type}</td>
      <td className="p-2 align-middle">
        {formatThousandSeparator(data.capacity)}
      </td>
      <td className="p-2 align-middle">{data.length ?? '-'}</td>
      <td className="p-2 align-middle">{data.width ?? '-'}</td>
      <td className="p-2 align-middle">{data.height ?? '-'}</td>
      <td className="p-2 align-middle">
        {formatThousandSeparator(data.volume)}
      </td>
      {activeKey === 'external' && (
        <td className="p-2 align-middle">
          {data.stnk_doc ? (
            <Link to={data.stnk_doc} className="text-decoration-none">
              {data.stnk_doc ? data.stnk_doc.split('/').pop() : '-'}
            </Link>
          ) : (
            '-'
          )}
        </td>
      )}
      <td className="p-2 align-middle">
        <ActiveInActive text={data.status ? 'active' : 'inactive'} />
      </td>
      <td className="p-2 align-middle text-end">
        <TableAction>
          <button
            className="btn btn-secondary ml-2"
            onClick={() => handleEdit(data.id)}
          >
            <FontAwesomeIcon
              icon={faPen}
              style={{ fontSize: '15px', rotate: '90deg' }}
              className="fa-flip-horizontal"
            />
          </button>
        </TableAction>
      </td>
    </tr>
  )
}
