import { faPen } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Row, Table } from 'react-bootstrap'
import Loader from '../../../components/atoms/Loader'
import { ActiveInActive } from '../../../components/atoms/StatusLabel'
import TableAction from '../../../components/atoms/Table/TableAction'
import TablePagination from '../../../components/atoms/Table/TablePagination'
import ModalAddCategory from '../../../components/modal/category/ModalAddCategory'
import ModalUpdateCategory from '../../../components/modal/category/ModalUpdateCategory'
import api from '../../../config/AxiosInstance'
import { convertDateWithTime } from '../../../helpers/constants'
import * as Common from '../../../utils/Common'

const headerTitleStyle = {
  color: 'var(--primary-orange)',
  fontSize: '24px',
  fontWeight: '600'
}

const initialMeta = {
  per_page: 10,
  current_page: 1,
  total_row: 0,
  total_page: 0
}

const MasterCategory = () => {
  const [modalAddShow, setModalAddShow] = useState(false)
  const [modalUpdateShow, setModalUpdateShow] = useState(false)
  const [editId, setEditId] = useState(0)
  const [categories, setCategories] = useState([])
  const [meta, setMeta] = useState(initialMeta)
  const [loading, setLoading] = useState(false)

  const loadCategory = async (perPage, currentPage) => {
    setLoading(true)
    try {
      const { data } = await api.get('master/categories', {
        params: { per_page: perPage, page: currentPage }
      })
      setMeta(data.metadata)
      setCategories(data.data)
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    loadCategory(meta.per_page, meta.current_page)
  }, [meta.per_page, meta.current_page])

  const handleOnChangeMeta = (e) => {
    setMeta((prevMeta) => ({
      ...prevMeta,
      per_page: parseInt(e.target.value, 10),
      current_page: 1
    }))
  }

  const handleEdit = (id) => {
    setEditId(id)
    setModalUpdateShow(true)
  }

  const handlePageChange = (data) => {
    setMeta((prevMeta) => ({
      ...prevMeta,
      current_page: data.selected + 1
    }))
    window.scrollTo(0, 0)
  }

  return (
    <Container>
      <Row>
        <h3 style={headerTitleStyle}>Category</h3>
      </Row>
      <Row>
        <Col xs={7} md={4} lg={4} className="mt-3">
          <small className="text-muted">
            <strong>CATEGORY LIST</strong>
          </small>
        </Col>
        <Col xs={5} md={8} lg={8} className="text-end">
          <Button
            className="btn btn-warning text-white mt-1 mt-md-1 mt-lg-1"
            onClick={() => setModalAddShow(true)}
          >
            Add
          </Button>
          <ModalAddCategory
            show={modalAddShow}
            onHide={() => setModalAddShow(false)}
            refreshData={() => loadCategory(meta.per_page, meta.current_page)}
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md={12}>
          <CategoryTable
            categories={categories}
            loading={loading}
            handleEdit={handleEdit}
            meta={meta}
            handleOnChangeMeta={handleOnChangeMeta}
            handlePageChange={handlePageChange}
          />
        </Col>
      </Row>
      <ModalUpdateCategory
        show={modalUpdateShow}
        onHide={() => setModalUpdateShow(false)}
        id={editId}
        refreshData={() => loadCategory(meta.per_page, meta.current_page)}
      />
    </Container>
  )
}

const CategoryTable = ({
  categories,
  loading,
  handleEdit,
  meta,
  handleOnChangeMeta,
  handlePageChange
}) => {
  return (
    <Table
      hover
      responsive="sm"
      className="shadow-sm mb-5 bg-white rounded"
      size="sm"
      style={{ fontSize: '14px' }}
    >
      <thead className="table-secondary">
        <tr>
          <th className="p-2 align-middle">Category Name</th>
          <th className="p-2 align-middle text-end">Status</th>
          <th className="p-2 align-middle text-end">Created</th>
          <th className="p-2 align-middle text-end">Action</th>
        </tr>
      </thead>
      <tbody>
        {loading ? (
          <tr>
            <td colSpan={4}>
              <Loader />
            </td>
          </tr>
        ) : (
          categories.map((data, i) => (
            <tr key={i}>
              <td className="p-2 align-middle">{data?.name}</td>
              <td className="p-2 align-middle text-end">
                <ActiveInActive text={data.status ? 'active' : 'inactive'} />
              </td>
              <td className="p-2 align-middle text-end">
                {convertDateWithTime(data?.createdAt)}
              </td>
              <td className="p-2 align-middle">
                <TableAction>
                  <Button
                    variant="secondary"
                    onClick={() => handleEdit(data.id)}
                  >
                    <FontAwesomeIcon
                      icon={faPen}
                      style={{ fontSize: '15px' }}
                    />
                  </Button>
                </TableAction>
              </td>
            </tr>
          ))
        )}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan={4} className="p-3">
            <TablePagination
              meta={meta}
              handlePageChange={handlePageChange}
              handleOnChangeMeta={handleOnChangeMeta}
            />
          </td>
        </tr>
      </tfoot>
    </Table>
  )
}

export default MasterCategory
