import { useEffect, useState } from 'react'
import {
  Col,
  Collapse,
  Container,
  Form,
  Row,
  Spinner,
  Table
} from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import BreadCrumb from '../../components/Label/Breadcrumb/index'
import api from '../../config/AxiosInstance'
import {
  convertDateWithoutTime,
  convertDateWithTime
} from '../../helpers/constants'
import * as Common from '../../utils/Common'

const WorkOrderPlanDetail = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const [detail, setDetail] = useState([])
  const [target, setTarget] = useState([])
  const [source, setSource] = useState([])
  const [progress, setProgress] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const titleStyle = {
    fontSize: '11px',
    letterSpacing: '0.5px'
  }

  const btnOrange = {
    backgroundColor: 'var(--primary-orange)',
    borderColor: 'var(--primary-orange)',
    color: 'white'
  }

  const fetchDetailWorkOrder = async () => {
    try {
      setIsLoading(true)
      const res = await api.get(`/outbound/work-order/${id}`)
      setDetail(res.data.data)
      setTarget(res.data.data.target)
      setSource(res.data.data.source)
      setProgress(res.data.data.progress_detail)
    } catch (error) {
      const errMsg =
        error?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setIsLoading(false)
    }
  }

  const cancelWo = () => {
    try {
      Swal.fire({
        title: 'Cancel Wo?',
        text: "Continue? Action cant't be Repeated",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#C12727',
        cancelButtonColor: '',
        confirmButtonText: 'Cancel Wo',
        cancelButtonText: 'Continue Wo',
        reverseButtons: true
      }).then(async (result) => {
        if (result.isConfirmed) {
          await api.post(`/outbound/work-order/${id}/cancel`)
          fetchDetailWorkOrder()
        }
      })
    } catch (error) {
      const errMsg =
        error?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }

  const continueWo = () => {
    try {
      Swal.fire({
        title: 'Continue with current stock ?',
        text: "Source qty don't match target qty!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#F89D1D',
        cancelButtonColor: '',
        confirmButtonText: 'Continue',
        cancelButtonText: 'Cancel',
        reverseButtons: true
      }).then(async (result) => {
        if (result.isConfirmed) {
          await api.post(`/outbound/work-order/${id}/continue`)
          fetchDetailWorkOrder()
        }
      })
    } catch (error) {
      const errMsg =
        error?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }

  useEffect(() => {
    fetchDetailWorkOrder()
  }, [])

  if (isLoading) {
    return (
      <>
        <div>
          <Row>
            <Col md={12}>
              <div
                style={{ height: '100vh' }}
                className="d-flex flex-column justify-content-center"
              >
                <div className="d-flex flex-row justify-content-center">
                  <div className="d-flex flex-column gap-3">
                    <div className="d-flex flex-row justify-content-center gap-2">
                      <Spinner animation="grow" variant="warning" />
                      <Spinner animation="grow" variant="warning" />
                      <Spinner animation="grow" variant="warning" />
                      <Spinner animation="grow" variant="warning" />
                      <Spinner animation="grow" variant="warning" />
                    </div>
                    <p
                      className="fw-bold text-center"
                      style={{ fontSize: '13px' }}
                    >
                      Loading...
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </>
    )
  }

  return (
    <>
      <Container>
        <Row className="mb-3">
          <Col md={4}>
            <BreadCrumb
              label={detail.wo_code}
              link="/workorder-plan/"
              back="WORK ORDER PLAN"
              status={detail.status}
              type={'WorkOrderStatus'}
            />
          </Col>

          {detail.status === 'problem' && (
            <Col md={8} className="text-end">
              <button
                className="btn btn-danger me-3"
                onClick={() => cancelWo()}
              >
                Cancel WO
              </button>
              <button
                className="btn"
                style={btnOrange}
                onClick={() => continueWo()}
              >
                Continue with available source
              </button>
            </Col>
          )}
        </Row>
        <Row className="mb-4">
          <Col md={2}>
            <h6
              style={titleStyle}
              className="fw-bold text-secondary text-uppercase"
            >
              <small>request time</small>
            </h6>
            <h5>{convertDateWithTime(detail.request_time)}</h5>
          </Col>
          <Col md={2}>
            <h6
              style={titleStyle}
              className="fw-bold text-secondary text-uppercase"
            >
              <small>type</small>
            </h6>
            <h5>{detail.type}</h5>
          </Col>
          <Col md={2}>
            <h6
              style={titleStyle}
              className="fw-bold text-secondary text-uppercase"
            >
              <small>warehouse</small>
            </h6>
            <h5>{detail.warehouse}</h5>
          </Col>
        </Row>
        <Row>
          <Col>
            <h5 className="text-secondary fw-semibold mb-3">TARGET</h5>
            <Table
              hover
              size="m"
              responsive="sm"
              className="shadow-sm mb-5 bg-white rounded"
              style={{ fontSize: '13px' }}
            >
              <thead className="table-secondary">
                <tr>
                  <th className="p-3 align-middle">No</th>
                  <th className="p-3 align-middle">Item</th>
                  <th className="p-3 align-middle">Item Alias</th>
                  <th className="p-3 align-middle">Item Code</th>
                  <th className="p-3 align-middle">Batch</th>
                  <th className="p-3 align-middle">Expired</th>
                  <th className="p-3 align-middle">Qty@Pack</th>
                  <th className="p-3 align-middle">Total Qty</th>
                  <th className="p-3 align-middle">UOM</th>
                  <th className="p-3 align-middle">Total Pack</th>
                </tr>
              </thead>
              <tbody>
                {target.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className="p-3">{index + 1}</td>
                      <td className="p-3">{item.item_name}</td>
                      <td className="p-3">{item.item_alias}</td>
                      <td className="p-3">{item.item_code}</td>
                      <td className="p-3">{item.batch}</td>
                      <td className="p-3">
                        {convertDateWithoutTime(item.exp_date)}
                      </td>
                      <td className="p-3">{item.qty_pack}</td>
                      <td className="p-3">{item.total_qty}</td>
                      <td className="p-3">{item.uom}</td>
                      <td className="p-3">{item.total_pack}</td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col>
            <h5 className="text-secondary fw-semibold mb-3">SOURCE</h5>
            <Table
              hover
              size="m"
              responsive="sm"
              className="shadow-sm mb-5 bg-white rounded"
              style={{ fontSize: '13px' }}
            >
              <thead className="table-secondary">
                <tr>
                  <th className="p-3 align-middle">No</th>
                  <th className="p-3 align-middle">Item</th>
                  <th className="p-3 align-middle">Item Alias</th>
                  <th className="p-3 align-middle">Item Code</th>
                  <th className="p-3 align-middle">Batch</th>
                  <th className="p-3 align-middle">Expired</th>
                  <th className="p-3 align-middle">Total Qty</th>
                  <th className="p-3 align-middle">Total Pack</th>
                  <th className="p-3 align-middle">Location</th>
                </tr>
              </thead>
              <tbody>
                {source.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className="p-3">{index + 1}</td>
                      <td className="p-3">{item.item_name}</td>
                      <td className="p-3">{item.item_alias}</td>
                      <td className="p-3">{item.item_code}</td>
                      <td className="p-3">{item.batch}</td>
                      <td className="p-3">
                        {convertDateWithoutTime(item.exp_date)}
                      </td>
                      <td className="p-3">{item.total_qty}</td>
                      <td className="p-3">{item.total_pack}</td>
                      <td className="p-3">
                        <p
                          onClick={() =>
                            navigate(`/workorder-plan/location`, {
                              replace: true,
                              state: {
                                location: item.locations,
                                code: detail.wo_code,
                                id: detail.id
                              }
                            })
                          }
                          className="text-primary cursor-pointer"
                          role="button"
                        >
                          {item.location}
                        </p>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row className="mb-4">
          <hr />
        </Row>
        <Row>
          <Col>
            <h5 className="fw-semibold mb-3">PROGRESS DETAIL</h5>
          </Col>
          <Collapse in={true}>
            <ul className="list-unstyled">
              {progress?.length > 0 &&
                progress?.map((item) => (
                  <>
                    <li key={item.id}>
                      <div className="d-flex flex-row gap-3 progress-detail">
                        <div className="circle-progress-detail"></div>
                        <p>{convertDateWithoutTime(item.datetime)}</p>
                        <div
                          className="d-flex flex-column justify-content-center"
                          style={{ height: '13px' }}
                        >
                          <div className="d-flex flex-row gap-3">
                            <div className="circle-small-progress-detail mt-2"></div>
                            <span
                              style={{ fontSize: '13px' }}
                              className="fw-bold"
                            >
                              {item.description}
                            </span>
                          </div>
                        </div>
                      </div>
                      <ul
                        className="list-unstyled"
                        style={{
                          marginLeft: '2rem',
                          marginTop: '-10px',
                          marginBottom: '8px'
                        }}
                      >
                        {item?.detail?.length > 0 &&
                          item?.detail?.map((detail, index) => (
                            <span
                              style={{
                                color: detail.color
                              }}
                            >
                              {detail.description}{' '}
                              {index < item.detail.length - 1 ? ', ' : ''}
                            </span>
                          ))}
                      </ul>
                    </li>
                  </>
                ))}
            </ul>
          </Collapse>
        </Row>
        <Row className="mb-5">
          <Col md={6}>
            <Form.Label
              className="fw-semibold text-secondary text-uppercase"
              style={titleStyle}
            >
              <small>pnp</small>
            </Form.Label>
            <Form.Control
              disabled
              placeholder={detail.pnp ? detail.pnp : '-'}
            />
          </Col>
          <Col md={6}>
            <Form.Label
              className="fw-semibold text-secondary text-uppercase"
              style={titleStyle}
            >
              <small>rnr</small>
            </Form.Label>
            <Form.Control
              disabled
              placeholder={detail.rnr ? detail.rnr : '-'}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <hr />
        </Row>
        <Row>
          <Col md={3}>
            <Form.Label
              className="fw-semibold text-secondary text-uppercase"
              style={titleStyle}
            >
              <small>created by</small>
            </Form.Label>
            <Form.Control disabled placeholder={detail.created_by} />
          </Col>
          <Col md={3}>
            <Form.Label
              className="fw-semibold text-secondary text-uppercase"
              style={titleStyle}
            >
              <small>created at</small>
            </Form.Label>
            <Form.Control
              disabled
              placeholder={convertDateWithTime(detail.created_at)}
            />
          </Col>
          <Col md={3}>
            <Form.Label
              className="fw-semibold text-secondary text-uppercase"
              style={titleStyle}
            >
              <small>last update by</small>
            </Form.Label>
            <Form.Control
              disabled
              placeholder={
                detail.last_updated_by ? detail.last_updated_by : '-'
              }
            />
          </Col>
          <Col md={3}>
            <Form.Label
              className="fw-semibold text-secondary text-uppercase"
              style={titleStyle}
            >
              <small>last update at</small>
            </Form.Label>
            <Form.Control
              disabled
              placeholder={
                detail.last_updated_at
                  ? convertDateWithTime(detail.last_updated_at)
                  : '-'
              }
            />
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default WorkOrderPlanDetail
