import { Col, Row, Table, Container } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'

const SLotLocationWorkOrderPlan = () => {
  const { state } = useLocation()

  const headerTitleStyle = {
    color: 'var(--primary-orange)',
    fontSize: '24px',
    fontWeight: '600'
  }
  return (
    <>
      <Container>
        <Row>
          <h4 style={headerTitleStyle}>Slot Location</h4>
        </Row>
        <Row className="mb-3">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item sj-item">
                <Link to={'/workorder-plan/'} className="text-decoration-none">
                  <span className="text-black fw-semibold">
                    WORK ORDER PLAN
                  </span>
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link
                  to={`/workorder-plan/detail/${state.id}`}
                  className="text-decoration-none"
                >
                  <span className="text-black fw-semibold">{state.code}</span>
                </Link>
              </li>
              <li
                className="breadcrumb-item active fw-semibold"
                aria-current="page"
              >
                SLOT LOCATION
              </li>
            </ol>
          </nav>
        </Row>
        <Row className="mb-2">
          <Col className="d-flex justify-content-between">
            <h5 className="fw-semibold text-uppercase text-secondary">
              slot location
            </h5>
            <h5>
              <span className="text-success">{state.location.need_pack}</span>/
              {state.location.available_pack} PACK
            </h5>
          </Col>
        </Row>
        <Row>
          <Table
            hover
            size="sm"
            responsive="sm"
            className="shadow-sm mb-5 bg-white rounded"
            style={{ fontSize: '13px' }}
          >
            <thead className="table-secondary">
              <tr>
                <th className="p-3 align-middle">No</th>
                <th className="p-3 align-middle">Item</th>
                <th className="p-3 align-middle">Item Alias</th>
                <th className="p-3 align-middle">Item Code</th>
                <th className="p-3 align-middle">Batch</th>
                <th className="p-3 align-middle">Total Pack</th>
                <th className="p-3 align-middle">Slot</th>
              </tr>
            </thead>
            <tbody>
              {state.location.slots?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td className="p-3">{index + 1}</td>
                    <td className="p-3">{item?.item_name}</td>
                    <td className="p-3">{item?.item_alias}</td>
                    <td className="p-3">{item?.item_code}</td>
                    <td className="p-3">{item?.batch}</td>
                    <td className="p-3">
                      {item?.total_pack} {item?.uom_pack}
                    </td>
                    <td className="p-3">{item?.slot_location}</td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </Row>
      </Container>
    </>
  )
}

export default SLotLocationWorkOrderPlan
