import { useEffect, useState } from 'react'
import { Col, Row, Table } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'

function SlotLocation() {
  const { pathname } = useLocation()
  const headMenu = pathname.split('/')[1]
  const poHeadMenu = pathname.split('/')[2]
  const routeValue = `${headMenu}/${poHeadMenu}`
  const headerTitleStyle = {
    color: 'var(--primary-orange)',
    fontSize: '24px',
    fontWeight: '600'
  }
  const [item, setItem] = useState([])
  const { state } = useLocation()

  useEffect(() => {
    // get data from state
    const { slotLocations, batch } = state
    // search slot location based on batch
    const slotLocation = slotLocations.find(
      (value) => value.item_batch === batch
    )
    setItem(slotLocation)
  }, [])

  return (
    <>
      <h4 style={headerTitleStyle}>Slot Location</h4>
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item sj-item">
            <Link to={'/outbound/sj'} className="text-decoration-none">
              <span className="text-black fw-bold">
                {routeValue === 'outbound/history'
                  ? 'Outbound History'
                  : 'SJ List'}
              </span>
            </Link>
          </li>
          <li className="breadcrumb-item sj-item">
            <Link
              to={
                routeValue === 'outbound/history'
                  ? `/outbound/history/detail/${encodeURIComponent(
                      state.sjCode
                    )}`
                  : `/outbound/sj/detail/${encodeURIComponent(state.sjCode)}`
              }
              className="text-decoration-none"
            >
              <span className="text-black fw-bold">{state.sjCode}</span>
            </Link>
          </li>
          <li className="breadcrumb-item sj-item active" aria-current="page">
            SLOT LOCATION
          </li>
        </ol>
      </nav>
      {/* detail */}
      <div className="mt-5 d-flex flex-row justify-content-between">
        <h5 className="fw-bold text-uppercase" style={{ color: '#828282' }}>
          slot location
        </h5>
        <h6
          className="fw-bold text-uppercase mt-1 me-4"
          style={{ color: '#828282' }}
        >
          <span style={{ color: '#219653' }}>{item?.available_qty}</span>
          <span> / </span>
          <span className="fw-bold">{item?.need_qty} Pack</span>
        </h6>
      </div>
      {/* table detail */}
      <Row className="mt-3">
        <Col md={12}>
          <Table
            hover
            size="sm"
            responsive="sm"
            className="shadow-sm mb-5 bg-white rounded"
            style={{ fontSize: '13px' }}
          >
            <thead className="table-secondary">
              <tr>
                <th className="p-3 align-middle">No</th>
                <th className="p-3 align-middle">Item</th>
                <th className="p-3 align-middle text-center">Item Alias</th>
                <th
                  className="p-3 align-middle text-center"
                  style={{ width: '15%' }}
                >
                  Item Code
                </th>
                <th className="p-3 align-middle text-center">Batch</th>
                <th className="p-3 align-middle text-center">Total Pack</th>
                <th className="p-3 align-middle text-end">Slot</th>
              </tr>
            </thead>
            <tbody>
              {item?.items?.length === 0 && (
                <>
                  <tr>
                    <td colSpan={7} className="fw-bold text-center">
                      No Data
                    </td>
                  </tr>{' '}
                </>
              )}
              {item?.items?.length > 0 && (
                <>
                  {item?.items.map((data, i) => (
                    <tr key={i}>
                      <td className="p-3 align-middle">{i + 1}</td>
                      <td className="p-3 align-middle">{data?.item_name}</td>
                      <td className="p-3 align-middle text-center">
                        {data?.item_alias ?? '-'}
                      </td>
                      <td className="p-3 align-middle text-center">
                        {data?.item_code}
                      </td>
                      <td className="p-3 align-middle text-center">
                        {data?.batch ?? '-'}
                      </td>
                      <td className="p-3 align-middle text-center">
                        {data?.qty} {data?.uom_pack}
                      </td>
                      <td className="p-3 align-middle text-end">
                        {data?.location}
                      </td>
                    </tr>
                  ))}
                </>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  )
}

export default SlotLocation
