import {
  faCaretDown,
  faCaretUp,
  faCircleExclamation,
  faPenToSquare,
  faPlus,
  faTrash
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Formik } from 'formik'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import {
  Badge,
  Button,
  Col,
  Collapse,
  Container,
  Form,
  OverlayTrigger,
  Popover,
  Row,
  Spinner,
  Table
} from 'react-bootstrap'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import Loader from '../../../components/atoms/Loader'
import CustomButton from '../../../components/Button/CustomButton'
import DatePickerComponent from '../../../components/Input/DatePicker'
import SelectInput from '../../../components/Input/SelectInput'
import TextField from '../../../components/Input/TextInput'
import ModalAddBatch from '../../../components/modal/batch/ModalAddBatch'
import ModalAddCategoryItem from '../../../components/modal/items/ModalAddCategoryItem'
import ModalUpdateItemCoaPrDraft from '../../../components/modal/pr/ModalUpdateItemCoaPrDraft'
import ModalUpdateVehiclePrDraft from '../../../components/modal/pr/ModalUpdateVehiclePrDraft'
import api from '../../../config/AxiosInstance'
import { convertDateWithoutTime } from '../../../helpers/constants'
import * as Common from '../../../utils/Common'
import {
  PurchaseReceiptCreateImportSchema,
  PurchaseReceiptCreateLocalSchema
} from '../../../utils/validationSchema'
import styles from './pr.module.css'
const PurchaseReceiptCreate = () => {
  const location = useLocation()
  const [poData, setPoData] = useState({})
  const navigate = useNavigate()
  const [isLoadingPage, setIsLoadingPage] = useState(false)
  const [open, setOpen] = useState(true)
  const [icon, setIcon] = useState(faCaretUp)
  const [openVehicle, setOpenVehicle] = useState(true)
  const [iconVehicle, setIconVehicle] = useState(faCaretUp)
  const [openItemCoa, setOpenItemCoa] = useState(true)
  const [iconItemCoa, setIconItemCoa] = useState(faCaretUp)
  const [modalUpdateShow, setModalUpdateShow] = useState(false)
  const [modalAddBatch, setModalAddBatch] = useState(false)
  const [modalAddCategory, setModalAddCategory] = useState(false)
  const [modalUpdateItemShow, setModalUpdateItemShow] = useState(false)
  const pr_type = location.state.type
  const [vehicles, setVehicles] = useState([])
  const [selectedVehicle, setSelectedVehicle] = useState(0)
  const [itemCoas, setItemCoas] = useState([])
  const [selectedItemCoa, setSelectedItemCoa] = useState(0)
  const [items, setItems] = useState([])
  const [inputBatch, setInputBatch] = useState('')
  const [batchId, setBatchId] = useState(0)
  const [itemId, setItemId] = useState(0)
  const [options, setOptions] = useState([])
  const [newOption, setNewOption] = useState(null)
  const [selectItem, setSelectItem] = useState({})
  const [dataItemSelect, setDataItemSelect] = useState()
  const [totalPack, setTotalPack] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [series, setSeries] = useState([])
  const [listItemsId, setListItemsId] = useState({})
  const [simDocFile, setSimDocFile] = useState(false)
  const [stnkDocFile, setStnkDocFile] = useState(false)
  const [coaDocFile, setCoaDocFile] = useState(false)
  const { code } = useParams()

  useEffect(() => {
    loadData()
    loadItems()
    fetchSeries()
  }, [])

  const loadData = async () => {
    try {
      setIsLoadingPage(true)
      const res = await api.get(`inbound/purchase-order/show?po=${code}`)
      setPoData(res.data.data)
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setIsLoadingPage(false)
    }
  }

  const loadItems = async () => {
    try {
      const res = await api.get(`/inbound/pr-draft/show-items?po=${code}`)
      const { data } = res.data
      setItems(data)
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }

  const handleSubmit = (values) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'This will also create new inbound document. Proceed ? ',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#f89d1d',
      confirmButtonText: 'Yes, add it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsLoading(true)
        try {
          if (pr_type === 'import') {
            values.pib_date = moment(values.pib_date).format('YYYY-MM-DD')
          }
          const data = {
            ...values,
            vehicles: vehicles,
            item_coas: itemCoas,
            po_num: poData.po_name
          }
          const res = await api.postForm(`/inbound/pr-draft`, data)
          if (res.data.success) {
            navigate('/inbound/purchase-receipt')
            Common.showPopUpSuccessMessage(res.data.message)
            setIsLoading(false)
          }
        } catch (error) {
          const errMsg =
            error?.response?.data?.errors?.message || 'An error occurred'
          Common.showPopUpErrorMessage(errMsg)
          setIsLoading(false)
        }
      }
    })
  }

  const addVehicle = (values) => {
    const isPlateNoExists = vehicles.some(
      (vehicle) => vehicle.plate_no === values
    )

    if (isPlateNoExists) {
      Common.showPopUpErrorMessage('Plate No already in list')
      return
    }

    const newVehicle = {
      id: vehicles.length + 1,
      plate_no: values,
      sim: '',
      sim_validity: null,
      sim_notes: '',
      stnk: '',
      stnk_validity: null,
      stnk_notes: '',
      sim_doc: '',
      stnk_doc: ''
    }

    setVehicles([...vehicles, newVehicle])
  }

  useEffect(() => {
    if (vehicles.length > 0) {
      const simDocs = vehicles.map((vehicle) => vehicle.sim_doc)
      const stnkDocs = vehicles.map((vehicle) => vehicle.stnk_doc)
      const hasNullSimDoc = simDocs.some((simDoc) => simDoc === '')
      const hasNullStnkDoc = stnkDocs.some((stnkDoc) => stnkDoc === '')
      setSimDocFile(!hasNullSimDoc)
      setStnkDocFile(!hasNullStnkDoc)
    }

    if (itemCoas.length > 0) {
      const coaDocs = itemCoas.map((itemCoa) => itemCoa.coa)
      const hasNullCoaDoc = coaDocs.some((coaDoc) => coaDoc === '')
      setCoaDocFile(!hasNullCoaDoc)
    }
  }, [vehicles, itemCoas])

  const addItemCoas = (
    totalPack,
    totalQty,
    conversionUom,
    conversionQtyUom,
    conversionFactor
  ) => {
    // check batch exist or not
    const batchExist = itemCoas.some(
      (itemCoa) => itemCoa.batch.id === inputBatch.id
    )
    !batchExist
      ? setItemCoas([
          ...itemCoas,
          {
            id: itemCoas.length + 1,
            item_id: itemId,
            total_pack: parseInt(totalPack),
            total_qty: parseInt(totalQty),
            conversion_factor: conversionFactor,
            conversion_uom: conversionUom,
            conversion_qty_uom: conversionQtyUom,
            items: selectItem,
            batch: inputBatch,
            batch_id: batchId,
            coa: '',
            coa_pd: inputBatch?.newBatch ? inputBatch?.manufacturing_date : '',
            coa_ed: inputBatch?.newBatch ? inputBatch?.exp_date : '',
            label_pd: '',
            label_ed: ''
          }
        ])
      : Common.showPopUpErrorMessage('Batch already in list')
  }

  function handleUpdateVehicle(item) {
    setModalUpdateShow(true)
    setSelectedVehicle(item)
  }

  function handleUpdateItemCoa(item) {
    setModalUpdateItemShow(true)
    setSelectedItemCoa(item)
  }

  function handleAddBatch(item) {
    setModalAddBatch(true)
  }

  function handleAddCategory(item) {
    setModalAddCategory(true)
    setListItemsId(item)
  }

  const updateVehicleInList = (updatedVehicle) => {
    const updatedVehicles = vehicles.map((vehicle) =>
      vehicle.id === updatedVehicle.id ? updatedVehicle : vehicle
    )
    setVehicles(updatedVehicles)
  }

  const updateItemCoasInList = (updatedItemCoas) => {
    const updatedItemCoass = itemCoas.map((itemCoa) =>
      itemCoa.id === updatedItemCoas.id ? updatedItemCoas : itemCoa
    )
    setItemCoas(updatedItemCoass)
  }

  function handleClickItem(item) {
    setSelectItem(item)
    items.map((b, i) => {
      b.id === item.id && setDataItemSelect(b)
    })
    setItemId(item.id)
    if (item.id !== '0') {
      try {
        api.get(`inbound/pr-draft/show-batches?item_id=${item.id}`)
      } catch (err) {
        const errMsg =
          err?.response?.data?.errors?.message || 'An error occurred'
        Common.showPopUpErrorMessage(errMsg)
      }
    }
  }

  function onDeleteItemCoa(item) {
    Swal.fire({
      title: 'Are you sure delete?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      allowOutsideClick: false,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const index = itemCoas.findIndex((coa) => coa.id === item.id)
          if (index !== -1) {
            const updatedItemCoas = [...itemCoas]
            updatedItemCoas.splice(index, 1)
            setItemCoas(updatedItemCoas)
          }
        } catch (err) {
          const errMsg = err?.message || 'An error occurred'
          Common.showPopUpErrorMessage(errMsg)
        }
      }
    })
  }

  function onDeleteItemVehicle(item) {
    Swal.fire({
      title: 'Are you sure delete?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      allowOutsideClick: false,
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const index = vehicles.findIndex((vehicle) => vehicle.id === item.id)
          if (index !== -1) {
            const updatedVehicle = [...vehicles]
            updatedVehicle.splice(index, 1)
            setVehicles(updatedVehicle)
          }
        } catch (err) {
          const errMsg = err?.message || 'An error occurred'
          Common.showPopUpErrorMessage(errMsg)
        }
      }
    })
  }

  const handleSearch = async (query) => {
    const res = await api.get(`inbound/pr-draft/show-batches?item_id=${itemId}`)
    setOptions(res.data.data)
    setTimeout(() => {
      setNewOption({ batch: query })
    }, 1000)
  }

  const renderMenuItemChildren = (option) => (
    <div>
      {option.isNew ? (
        <>
          <h6 onClick={() => handleAddBatch(option)}>
            <FontAwesomeIcon icon={faPlus} className="me-1" /> NEW BATCH
          </h6>
        </>
      ) : (
        <span>{option.batch}</span>
      )}
    </div>
  )

  const allOptions = [
    ...options,
    ...(newOption ? [{ ...newOption, isNew: true }] : [])
  ]

  const refBatch = useRef(AsyncTypeahead)
  const refItems = useRef(null)

  const fetchSeries = async () => {
    try {
      const res = await api.get(`inbound/pr-draft/show-series?po=${code}`)
      const { data } = res.data
      const series = data.map((item) => ({
        ...item,
        id: item.name,
        value: item.name
      }))
      setSeries(series)
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }
  const capitalizeWords = (value) => {
    return value.toUpperCase()
  }
  const removeWhiteSpace = (value) => {
    return value.trim()
  }
  if (isLoadingPage) {
    return (
      <>
        <div>
          <Row>
            <Col md={12}>
              <div
                style={{ height: '100vh' }}
                className="d-flex flex-column justify-content-center"
              >
                <div className="d-flex flex-row justify-content-center">
                  <div className="d-flex flex-column gap-3">
                    <div className="d-flex flex-row justify-content-center gap-2">
                      <Spinner animation="grow" variant="warning" />
                      <Spinner animation="grow" variant="warning" />
                      <Spinner animation="grow" variant="warning" />
                      <Spinner animation="grow" variant="warning" />
                      <Spinner animation="grow" variant="warning" />
                    </div>
                    <p
                      className="fw-bold text-center"
                      style={{ fontSize: '13px' }}
                    >
                      Loading...
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </>
    )
  }
  return (
    <>
      <Container className="mb-5">
        <Row>
          <Col md={12} className="mt-3">
            <h4 className="text-warning">Create PR Draft</h4>
          </Col>
          <Col md={12} className="">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <small>
                    <strong
                      style={{ textDecoration: 'none', cursor: 'pointer' }}
                      onClick={() => navigate('/purchase-order')}
                    >
                      PURCHASE ORDER
                    </strong>
                  </small>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  <small>CREATE PR DRAFT</small>
                </li>
              </ol>
            </nav>
          </Col>
        </Row>
        <Row className="mt-3">
          <Formik
            initialValues={{
              do: '',
              pib_date: '',
              expedition: '',
              aju: '',
              pic_number: '',
              pr_type: pr_type ? pr_type : poData.type,
              vehicle: '',
              total_pack: '',
              series_number: ''
            }}
            enableReinitialize={true}
            validateOnChange={true}
            validationSchema={
              pr_type === 'import'
                ? PurchaseReceiptCreateImportSchema
                : PurchaseReceiptCreateLocalSchema
            }
            onSubmit={(values) => {
              handleSubmit(values)
            }}
            context={{ pr_type }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              errors,
              isValid,
              dirty,
              setFieldValue,
              setFieldTouched
            }) => {
              return (
                <Form onSubmit={handleSubmit}>
                  <Row className="mb-4">
                    <TextField
                      label="#po"
                      name="po_name"
                      value={poData.po_name}
                      touched={touched}
                      errors={errors}
                      disabled={true}
                      col={3}
                      bold
                    />
                    <TextField
                      label="po type"
                      name="po_type"
                      value={pr_type ? pr_type : poData.type}
                      touched={touched}
                      errors={errors}
                      disabled={true}
                      col={3}
                      bold
                    />
                    <TextField
                      label="branch"
                      name="po_branch"
                      value={poData.branch}
                      touched={touched}
                      errors={errors}
                      disabled={true}
                      col={3}
                      bold
                    />
                    <TextField
                      label="supplier"
                      name="po_supplier"
                      value={poData.supplier}
                      touched={touched}
                      errors={errors}
                      disabled={true}
                      col={3}
                      bold
                    />
                    <SelectInput
                      label="SERIES"
                      name="series_number"
                      id="series_number"
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                      options={series}
                      required={true}
                      col={3}
                      value={values.series_number}
                      placeholder="Select Series"
                    />
                    <TextField
                      label="pr destination"
                      name="po_pr_destination"
                      value={poData.dest_warehouse}
                      touched={touched}
                      errors={errors}
                      disabled={true}
                      col={3}
                      bold
                    />
                    <TextField
                      label="accepted warehouse"
                      name="po_acc_warehouse"
                      value={poData.acc_warehouse}
                      touched={touched}
                      errors={errors}
                      disabled={true}
                      col={3}
                      bold
                    />
                  </Row>
                  {/* ARRIVAL */}
                  <Row className="mb-3">
                    <Col md={12} className="mb-2">
                      <h5 className="text-secondary">
                        ARRIVAL INFO
                        <FontAwesomeIcon
                          icon={icon}
                          className="ms-3"
                          onClick={() => {
                            setOpen(!open)
                            open === true
                              ? setIcon(faCaretDown)
                              : setIcon(faCaretUp)
                          }}
                          aria-expanded={open}
                          aria-controls="example-collapse-text"
                          style={{ cursor: 'pointer' }}
                        />
                      </h5>
                    </Col>
                    <Collapse in={open}>
                      <div id="example-collapse-text">
                        <Row className="mb-3">
                          <TextField
                            label="#do"
                            name="do"
                            value={values.do}
                            handleChange={(e) => {
                              const capitalizedValue = capitalizeWords(
                                e.target.value
                              )
                              setFieldValue('do', capitalizedValue)
                            }}
                            handleBlur={handleBlur}
                            touched={touched}
                            errors={errors}
                            placeholder="Input do"
                            col={3}
                            bold
                            required
                          />
                          <TextField
                            label="expedition"
                            name="expedition"
                            value={values.expedition}
                            handleChange={(e) => {
                              const capitalizedValue = capitalizeWords(
                                e.target.value
                              )
                              setFieldValue('expedition', capitalizedValue)
                            }}
                            handleBlur={handleBlur}
                            touched={touched}
                            errors={errors}
                            placeholder="Input expedition"
                            col={3}
                            bold
                            required
                          />
                          {pr_type === 'import' && (
                            <>
                              <DatePickerComponent
                                label="pib date"
                                name="pib_date"
                                value={values.pib_date}
                                handleChange={setFieldValue}
                                handleBlur={() =>
                                  setFieldTouched('pib_date', true)
                                }
                                errors={errors}
                                touched={touched}
                                col={3}
                                required
                              />
                              <TextField
                                label="#aju"
                                name="aju"
                                value={values.aju}
                                handleChange={(e) => {
                                  const capitalizedValue = capitalizeWords(
                                    e.target.value
                                  )
                                  setFieldValue('aju', capitalizedValue)
                                }}
                                handleBlur={handleBlur}
                                touched={touched}
                                errors={errors}
                                col={3}
                                bold
                                required
                                placeholder="Input aju"
                              />
                            </>
                          )}
                          <TextField
                            label="pic name"
                            name="pic_name"
                            value={values.pic_name}
                            handleChange={(e) => {
                              const capitalizedValue = capitalizeWords(
                                e.target.value
                              )
                              setFieldValue('pic_name', capitalizedValue)
                            }}
                            handleBlur={handleBlur}
                            touched={touched}
                            errors={errors}
                            col={3}
                            bold
                            required
                            placeholder="Input pic name"
                          />
                          <TextField
                            label="pic number"
                            name="pic_number"
                            value={values.pic_number}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            touched={touched}
                            errors={errors}
                            type="number"
                            col={3}
                            bold
                            required
                            placeholder="Input pic number"
                          />
                        </Row>
                      </div>
                    </Collapse>
                  </Row>
                  {/* VEHICLE */}
                  <Row className="mb-2">
                    <Col md={12} className="mb-3">
                      <h5 className="text-secondary">
                        VEHICLE
                        <FontAwesomeIcon
                          icon={iconVehicle}
                          className="ms-3"
                          onClick={() => {
                            setOpenVehicle(!openVehicle)
                            openVehicle === true
                              ? setIconVehicle(faCaretDown)
                              : setIconVehicle(faCaretUp)
                          }}
                          aria-expanded={openVehicle}
                          aria-controls="example-collapse-text1"
                          style={{ cursor: 'pointer' }}
                        />
                      </h5>
                    </Col>
                    <Collapse in={openVehicle}>
                      <div id="example-collapse-text1">
                        <Row>
                          <Col md={10}>
                            <TextField
                              label="plate no."
                              name="vehicle"
                              value={values.vehicle}
                              handleChange={(e) => {
                                const capitalizedValue = capitalizeWords(
                                  e.target.value
                                )
                                const removeWhiteSpaceValue =
                                  removeWhiteSpace(capitalizedValue)
                                setFieldValue('vehicle', removeWhiteSpaceValue)
                              }}
                              handleBlur={handleBlur}
                              touched={touched}
                              errors={errors}
                              bold
                              required
                              placeholder="Input vehicle"
                            />
                          </Col>
                          <Col md={2} className="text-end ">
                            <Button
                              type="button"
                              className="btn btn-primary-blue w-100"
                              style={{ marginTop: '25px' }}
                              onClick={() => {
                                addVehicle(values.vehicle)
                                setFieldValue('vehicle', '')
                              }}
                              disabled={!values.vehicle}
                            >
                              Add Vehicle
                            </Button>
                          </Col>
                          <Col md={12} className="mt-3">
                            {vehicles.length > 0 && (
                              <Table
                                hover
                                responsive="sm"
                                className="shadow-sm mb-5 bg-white rounded"
                                size="sm"
                                style={{ fontSize: '12px' }}
                              >
                                <thead className="table-secondary">
                                  <tr>
                                    <th className="p-2 align-middle">No</th>
                                    <th
                                      className="p-2"
                                      style={{ width: '30%' }}
                                    >
                                      Plate No
                                    </th>
                                    <th className="p-2 text-end align-middle">
                                      SIM
                                    </th>
                                    <th className="p-2 text-end align-middle">
                                      Validity
                                    </th>
                                    <th className="p-2 text-end align-middle">
                                      Notes
                                    </th>
                                    <th className="p-2 text-end align-middle">
                                      STNK
                                    </th>
                                    <th className="p-2 text-end align-middle">
                                      Validity
                                    </th>
                                    <th className="p-2 text-end align-middle">
                                      Notes
                                    </th>
                                    <th className="p-2 text-end align-middle">
                                      Action
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {vehicles.map((vehicle, index) => (
                                    <tr key={index}>
                                      <td className="p-2 align-middle">
                                        {index + 1}
                                      </td>
                                      <td className="p-2 align-middle">
                                        {vehicle.plate_no
                                          ? vehicle.plate_no.toUpperCase()
                                          : '-'}
                                      </td>
                                      <td className="p-2 text-end align-middle text-primary">
                                        {vehicle.sim_doc.name || '-'}
                                      </td>
                                      <td className="p-2 text-end align-middle">
                                        {vehicle.sim_validity === null
                                          ? '-'
                                          : vehicle.sim_validity === true
                                          ? 'Valid'
                                          : 'Invalid'}
                                      </td>
                                      <td className="p-2 text-end align-middle">
                                        {vehicle.sim_notes || '-'}
                                      </td>
                                      <td className="p-2 text-end align-middle text-primary">
                                        {vehicle.stnk_doc.name || '-'}
                                      </td>
                                      <td className="p-2 text-end align-middle">
                                        {vehicle.stnk_validity === null
                                          ? '-'
                                          : vehicle.stnk_validity === true
                                          ? 'Valid'
                                          : 'Invalid'}
                                      </td>
                                      <td className="p-2 text-end align-middle">
                                        {vehicle.stnk_notes || '-'}
                                      </td>
                                      <td className="p-2 text-end align-middle">
                                        <div className="d-flex flex-row gap-2 justify-content-end">
                                          <div className="d-flex flex-column justify-content-center">
                                            <FontAwesomeIcon
                                              icon={faCircleExclamation}
                                              className="text-danger me-2"
                                              style={{ fontSize: '16px' }}
                                              hidden={
                                                vehicle.sim_doc !== '' &&
                                                vehicle.stnk_doc !== ''
                                                  ? true
                                                  : false
                                              }
                                            />
                                          </div>
                                          <button
                                            className="btn btn-secondary ml-2"
                                            type="button"
                                            onClick={() =>
                                              handleUpdateVehicle(vehicle)
                                            }
                                          >
                                            <FontAwesomeIcon
                                              icon={faPenToSquare}
                                            />
                                          </button>
                                          <button
                                            type="button"
                                            className="btn btn-secondary ml-2"
                                            onClick={() =>
                                              onDeleteItemVehicle(vehicle)
                                            }
                                          >
                                            <FontAwesomeIcon icon={faTrash} />
                                          </button>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                            )}
                          </Col>
                        </Row>
                      </div>
                    </Collapse>
                  </Row>
                  {/* ITEM & COA */}
                  <Row>
                    <Col md={12} className="mb-3">
                      <h5 className="text-secondary">
                        ITEM & COA
                        <FontAwesomeIcon
                          icon={iconItemCoa}
                          className="ms-3"
                          onClick={() => {
                            setOpenItemCoa(!openItemCoa)
                            openItemCoa === true
                              ? setIconItemCoa(faCaretDown)
                              : setIconItemCoa(faCaretUp)
                          }}
                          aria-expanded={openItemCoa}
                          aria-controls="example-collapse-text2"
                          style={{ cursor: 'pointer' }}
                        />
                      </h5>
                    </Col>
                    <Collapse in={openItemCoa}>
                      <div id="example-collapse-text2">
                        <Row className="mb-5">
                          <SelectInput
                            label="item"
                            name="item_id"
                            id="item_id"
                            handleChange={(e) => {
                              const selectedOption =
                                e.target.options[e.target.selectedIndex]
                              const selectedHasCategory = parseInt(
                                selectedOption.getAttribute('hasData')
                              )
                              return handleClickItem({
                                id: e.target.value,
                                name: e.target.options[e.target.selectedIndex]
                                  .text,
                                has_category: selectedHasCategory
                              })
                            }}
                            reff={refItems}
                            handleBlur={handleBlur}
                            touched={touched}
                            errors={errors}
                            options={items}
                            required
                            hasData
                            col={4}
                            bold
                          />
                          <Form.Group className="col-12 col-md-2 col-lg-2">
                            <Form.Label>
                              <small>
                                <strong>
                                  BATCH NUMBER
                                  <sup className="text-danger">*</sup>
                                </strong>
                              </small>
                            </Form.Label>
                            <AsyncTypeahead
                              id="async-typeahead-example"
                              labelKey="batch"
                              onSearch={handleSearch}
                              options={allOptions}
                              renderMenuItemChildren={renderMenuItemChildren}
                              onChange={(e) => {
                                setInputBatch(e.length > 0 ? e[0] : '')
                                setBatchId(e.length > 0 ? e[0].id : 0)
                              }}
                              inputProps={{
                                value: inputBatch,
                                className: styles.input
                              }}
                              placeholder="Input"
                              disabled={
                                itemId === '0' || itemId === 0 ? true : false
                              }
                              ref={refBatch}
                            />
                          </Form.Group>
                          <TextField
                            label="total pack"
                            name="total_pack"
                            value={values.total_pack}
                            handleChange={(e) => {
                              handleChange(e)
                              setTotalPack((prevData) => ({
                                ...prevData,
                                total_qty:
                                  e.target.value *
                                  dataItemSelect.uom.conversion_factor,
                                conversion_uom:
                                  dataItemSelect.uom.conversion_uom,
                                conversion_qty_uom:
                                  dataItemSelect.uom.conversion_qty_uom,
                                conversion_factor:
                                  dataItemSelect.uom.conversion_factor
                              }))
                            }}
                            handleBlur={handleBlur}
                            touched={touched}
                            errors={errors}
                            col={2}
                            bold
                            required
                            placeholder="Input"
                            disabled={
                              itemId === '0' || itemId === 0 ? true : false
                            }
                          />
                          <TextField
                            label="total qty"
                            name="total_qty"
                            value={totalPack.total_qty}
                            touched={touched}
                            errors={errors}
                            col={2}
                            bold
                            required
                            disabled
                          />
                          <Col md={2} className="text-end">
                            <Button
                              className="btn btn-primary-blue w-100"
                              style={{ marginTop: '25px' }}
                              onClick={() => {
                                addItemCoas(
                                  values.total_pack,
                                  totalPack.total_qty,
                                  totalPack.conversion_uom,
                                  totalPack.conversion_qty_uom,
                                  totalPack.conversion_factor
                                )
                                setFieldValue('total_pack', '')
                                refBatch.current?.clear()
                                refItems.current.value = ''
                                setTotalPack({
                                  total_qty: 0,
                                  conversion_uom: '',
                                  conversion_qty_uom: '',
                                  conversion_factor: ''
                                })
                              }}
                              disabled={!values.total_pack}
                            >
                              Add Item
                            </Button>
                          </Col>
                          <Col md={12} className="mt-3">
                            {itemCoas.length > 0 && (
                              <Table
                                hover
                                responsive="sm"
                                className="shadow-sm mb-5 bg-white rounded"
                                size="sm"
                                style={{ fontSize: '12px' }}
                              >
                                <thead className="table-secondary">
                                  <tr>
                                    <th className="p-2 align-middle">No</th>
                                    <th
                                      className="p-2 align-middle"
                                      style={{ width: '20%' }}
                                    >
                                      Item
                                    </th>
                                    <th className="p-2 text-end align-middle">
                                      Status COA
                                    </th>
                                    <th className="p-2 text-end align-middle">
                                      Batch
                                    </th>
                                    <th className="p-2 text-end align-middle">
                                      Total Pack
                                    </th>
                                    <th className="p-2 text-end align-middle">
                                      Total Qty
                                    </th>
                                    <th className="p-2 text-end align-middle">
                                      COA Document
                                    </th>
                                    <th className="p-2 text-end align-middle">
                                      COA MD
                                    </th>
                                    <th className="p-2 text-end align-middle">
                                      COA ED
                                    </th>
                                    <th className="p-2 text-end align-middle">
                                      Label MD
                                    </th>
                                    <th className="p-2 text-end align-middle">
                                      Label ED
                                    </th>
                                    <th className="p-2 text-end align-middle">
                                      Action
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {itemCoas.map((item, i) => {
                                    return (
                                      <>
                                        <tr key={i}>
                                          <td className="p-2 align-middle">
                                            {i + 1}
                                          </td>
                                          <OverlayTrigger
                                            trigger={['hover', 'click']}
                                            key={'bottom'}
                                            placement={'bottom'}
                                            overlay={
                                              <Popover
                                                id={`popover-positioned-bottom`}
                                              >
                                                <Popover.Body>
                                                  <p className="text-center fw-bold text-uppercase">
                                                    item name
                                                  </p>
                                                  <p className="text-center">
                                                    {item?.items?.name}
                                                  </p>
                                                  <p className="text-center fw-bold text-uppercase">
                                                    item code
                                                  </p>
                                                  <p className="text-center">
                                                    {item?.items?.id}
                                                  </p>
                                                </Popover.Body>
                                              </Popover>
                                            }
                                          >
                                            <td className="p-2 align-middle">
                                              <Row>
                                                <Col md={12}>
                                                  {item?.items?.name}
                                                </Col>
                                                {
                                                  // items[i]?.has_category ===
                                                  // 0
                                                  items.find(
                                                    (itemValue) =>
                                                      itemValue.id ===
                                                      item.items.id
                                                  )?.has_category === 0 ? (
                                                    <Col md={12}>
                                                      <small
                                                        className="text-danger fw-bold"
                                                        onClick={() =>
                                                          handleAddCategory(
                                                            item.items
                                                          )
                                                        }
                                                        style={{
                                                          cursor: 'pointer'
                                                        }}
                                                      >
                                                        ! Uncategorized Item
                                                      </small>
                                                    </Col>
                                                  ) : null
                                                }
                                              </Row>
                                            </td>
                                          </OverlayTrigger>
                                          <td className="p-2 text-end align-middle">
                                            <Badge pill bg="secondary">
                                              UNCHECKED
                                            </Badge>
                                          </td>
                                          <td className="p-2 text-end align-middle">
                                            {item?.batch?.batch}
                                          </td>
                                          <td className="p-2 text-end align-middle">
                                            {item?.total_pack}{' '}
                                            {item?.conversion_uom}
                                          </td>
                                          <td className="p-2 text-end align-middle">
                                            {item?.total_qty}{' '}
                                            {item?.conversion_qty_uom}
                                          </td>
                                          <td className="p-2 text-end align-middle">
                                            {item?.coa?.name
                                              ? item?.coa?.name
                                              : '-'}
                                          </td>
                                          <td className="p-2 text-end align-middle">
                                            {item.coa_pd
                                              ? convertDateWithoutTime(
                                                  item.coa_pd
                                                )
                                              : '-'}
                                          </td>
                                          <td className="p-2 text-end align-middle">
                                            {item.coa_ed
                                              ? convertDateWithoutTime(
                                                  item.coa_ed
                                                )
                                              : '-'}
                                          </td>
                                          <td className="p-2 text-end align-middle">
                                            -
                                          </td>
                                          <td className="p-2 text-end align-middle">
                                            -
                                          </td>
                                          <td className="p-2 text-end align-middle">
                                            <div className="d-flex flex-row gap-2 justify-content-end">
                                              <div className="d-flex flex-column justify-content-center">
                                                <FontAwesomeIcon
                                                  icon={faCircleExclamation}
                                                  className="text-danger me-2"
                                                  style={{ fontSize: '16px' }}
                                                  hidden={
                                                    item.coa !== ''
                                                      ? true
                                                      : false
                                                  }
                                                />
                                              </div>
                                              <button
                                                className="btn btn-secondary ml-2"
                                                type="button"
                                                onClick={() =>
                                                  handleUpdateItemCoa(item)
                                                }
                                              >
                                                <FontAwesomeIcon
                                                  icon={faPenToSquare}
                                                />
                                              </button>
                                              <button
                                                type="button"
                                                className="btn btn-secondary ml-2"
                                                onClick={() =>
                                                  onDeleteItemCoa(item)
                                                }
                                              >
                                                <FontAwesomeIcon
                                                  icon={faTrash}
                                                />
                                              </button>
                                            </div>
                                          </td>
                                        </tr>
                                      </>
                                    )
                                  })}
                                </tbody>
                              </Table>
                            )}
                          </Col>
                        </Row>
                      </div>
                    </Collapse>
                  </Row>
                  {/* BUTTON */}
                  {isLoading ? (
                    <Loader />
                  ) : (
                    <Row>
                      <Col xs={6} md={6} lg={6}>
                        <Button
                          className="text-danger"
                          type="button"
                          variant="link"
                          style={{ textDecoration: 'none' }}
                          onClick={() => {
                            Swal.fire({
                              title: 'Cancel creating ticket?',
                              text: 'Draft will be discarded',
                              icon: 'warning',
                              allowOutsideClick: false,
                              showCancelButton: true,
                              confirmButtonColor: '#d33',
                              cancelButtonText: 'Continue creating',
                              confirmButtonText: 'Discard'
                            }).then((result) => {
                              if (result.isConfirmed) {
                                navigate('/purchase-order')
                              }
                            })
                          }}
                        >
                          Cancel
                        </Button>
                      </Col>
                      <Col xs={6} md={6} lg={6} className="text-end">
                        <CustomButton
                          onClick={handleSubmit}
                          type="submit"
                          variant={
                            !simDocFile && !stnkDocFile
                              ? 'secondary'
                              : !coaDocFile
                              ? 'secondary'
                              : !dirty && !isValid
                              ? 'secondary'
                              : 'warning'
                          }
                          disabled={
                            !simDocFile && !stnkDocFile
                              ? true
                              : !coaDocFile
                              ? true
                              : !dirty && !isValid
                              ? true
                              : false
                          }
                          text={'Save'}
                        />
                      </Col>
                    </Row>
                  )}
                </Form>
              )
            }}
          </Formik>
        </Row>
      </Container>

      <ModalUpdateVehiclePrDraft
        show={modalUpdateShow}
        onHide={() => setModalUpdateShow(false)}
        selectedVehicle={selectedVehicle}
        updateVehicleInList={updateVehicleInList}
      />
      <ModalAddBatch
        show={modalAddBatch}
        onHide={() => setModalAddBatch(false)}
        item={selectItem}
        batch={inputBatch}
        setBatchId={setBatchId}
        setInputBatch={setInputBatch}
        refreshData={() => refBatch.current?.clear()}
      />
      <ModalUpdateItemCoaPrDraft
        show={modalUpdateItemShow}
        onHide={() => setModalUpdateItemShow(false)}
        selecteditemcoa={selectedItemCoa}
        updateitemcoasinlist={updateItemCoasInList}
      />
      <ModalAddCategoryItem
        show={modalAddCategory}
        onHide={() => setModalAddCategory(false)}
        code={listItemsId.id}
        name={listItemsId.name}
        refreshData={() => {
          loadItems()
        }}
      />
    </>
  )
}

export default PurchaseReceiptCreate
