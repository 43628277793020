import { Formik } from 'formik'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { Col, Form, Modal, Row } from 'react-bootstrap'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import { useLocation } from 'react-router-dom'
import api from '../../../config/AxiosInstance'
import '../../../styles/style.css'
import * as Common from '../../../utils/Common'
import { BatchAddEditSchema } from '../../../utils/validationSchema'
import CustomButton from '../../Button/CustomButton'
import CustomCloseButton from '../../Button/CustomCloseButton'
import DatePickerComponent from '../../Input/DatePicker/'
import TextField from '../../Input/TextInput'
function ModalAddBatch({
  show,
  onHide,
  onAdd,
  item,
  batch,
  setBatchId = '',
  setInputBatch = ''}) {
  const { pathname } = useLocation()
  const [items, setItems] = useState([])
  const refItem = useRef(AsyncTypeahead)
  const filterItem = () => true
  const [isLoadingItem, setIsLoadingItem] = useState(false)
  useEffect(function () {
    if (pathname === '/master/batch') {
      fetchItems()
    }
  }, [])

  async function fetchItems(query) {
    try {
      const res = await api.get(`master/items/all`, { params: { q: query } })
      const results = res.data
      setItems(results.data)
    } catch (error) {
      const errMsg =
        error?.response?.data?.errors?.message || 'An error occurred'

      Common.showPopUpErrorMessage(errMsg)
    }
  }

  const handleSubmit = async (values) => {
    try {
      // override batche to value uppercase
      values.batch = values.batch.toUpperCase()
      const response = await api.post('/master/batch', values)
      const { data } = response
      if (data.success) {
        onHide()
        setBatchId(data.data.id)
        setInputBatch({
          batch: data.data.batch,
          exp_date: data.data.exp_date,
          id: data.data.id,
          manufacturing_date: values.manufacturing_date,
          newBatch: true
        })
        Common.showPopUpSuccessMessage(response?.data?.message)
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }

  async function handleSearchItem(query) {
    try {
      setIsLoadingItem(true)

      if (query.length >= 3) {
        fetchItems(query)
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setIsLoadingItem(false)
    }
  }

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Formik
        initialValues={{
          item_id: item ? item.id : '',
          exp_date: '',
          batch: batch ? batch.batch : '',
          manufacturing_date: '',
          note: ''
        }}
        validateOnChange={true}
        enableReinitialize={true}
        validationSchema={BatchAddEditSchema}
        onSubmit={(values) => {
          values.manufacturing_date = moment(values.manufacturing_date).format(
            'YYYY-MM-DD'
          )
          values.exp_date = moment(values.exp_date).format('YYYY-MM-DD')
          handleSubmit(values)
        }}
      >
        {({
          handleBlur,
          handleSubmit,
          handleChange,
          setFieldValue,
          values,
          errors,
          touched,
          isValid,
          dirty,
          setFieldTouched
        }) => {
          return (
            <Form className="d-grid gap-2" onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title>Add Batch</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col md={12}>
                    <Form.Label
                      className="text-muted fw-bold text-uppercase"
                      style={{ fontSize: '13px', letterSpacing: '0.5px' }}
                    >
                      <small>ITEM</small>
                    </Form.Label>
                    {!item ? (
                      <>
                        <AsyncTypeahead
                          id="basic-typeahead-single"
                          labelKey="item_name"
                          isLoading={isLoadingItem}
                          filterBy={filterItem}
                          onSearch={handleSearchItem}
                          onChange={(e) => {
                            setFieldValue('item_id', e.length ? e[0].id : '')
                          }}
                          value={values.item_id}
                          options={items}
                          placeholder="Choose a item ..."
                          ref={refItem}
                          isInvalid={errors.item_id}
                        />
                        {errors.item_id && (
                          <Form.Control.Feedback type="invalid">
                            {errors.item_id}
                          </Form.Control.Feedback>
                        )}
                      </>
                    ) : (
                      <Form.Control
                        id="item"
                        name="item"
                        className="mb-1 mt-1"
                        type="text"
                        placeholder={item.name}
                        value={item.name}
                        onBlur={handleBlur}
                        style={{ marginTop: '-10px' }}
                        disabled={item ? true : false}
                      />
                    )}
                  </Col>
                  <Col md={6} className="mt-3">
                    <TextField
                      label="batch"
                      name="batch"
                      value={
                        batch
                          ? batch.batch.toUpperCase()
                          : values.batch.toUpperCase()
                      }
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                      disabled={batch ? true : false}
                      placeholder="Enter Batch"
                    />
                  </Col>
                  <DatePickerComponent
                    label="manufacturing date"
                    name="manufacturing_date"
                    value={values.manufacturing_date}
                    handleChange={setFieldValue}
                    handleBlur={() =>
                      setFieldTouched('manufacturing_date', true)
                    }
                    errors={errors}
                    touched={touched}
                    col={6}
                    required={false}
                  />
                  <DatePickerComponent
                    label="Exp. Date"
                    name="exp_date"
                    value={values.exp_date}
                    handleChange={setFieldValue}
                    handleBlur={() => setFieldTouched('exp_date', true)}
                    errors={errors}
                    touched={touched}
                    col={6}
                    required={false}
                  />
                  <DatePickerComponent
                    label="date patch"
                    name="date_patch"
                    value={values.date_patch}
                    handleChange={setFieldValue}
                    handleBlur={() => setFieldTouched('date_patch', true)}
                    errors={errors}
                    touched={touched}
                    col={6}
                    required={false}
                    disabled={true}
                  />
                  <Col md={12} className="mt-1">
                    <TextField
                      label="note"
                      name="note"
                      value={values.note}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      touched={touched}
                      errors={errors}
                      placeholder="Enter Note"
                      as="textarea"
                    />
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <CustomCloseButton onClick={onHide} />
                <CustomButton
                  onClick={handleSubmit}
                  variant={'warning'}
                  disabled={!isValid || !dirty}
                  text={'Add'}
                />
              </Modal.Footer>
            </Form>
          )
        }}
      </Formik>
    </Modal>
  )
}

export default ModalAddBatch
