import { faFileUpload, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import Loader from '../../../components/atoms/Loader'
import TableAction from '../../../components/atoms/Table/TableAction'
import TablePagination from '../../../components/atoms/Table/TablePagination'
import ModalUploadSupportingDocument from '../../../components/modal/InboundHistory/ModalUploadSupportingDocument'
import api from '../../../config/AxiosInstance'
import { convertDateWithTime } from '../../../helpers/constants'
import '../../../styles/style.css'
import * as Common from '../../../utils/Common'

const headerTitleStyle = {
  color: 'var(--primary-orange)',
  fontSize: '24px',
  fontWeight: '600'
}

const InboundHistory = () => {
  const [inboundHistories, setInboundHistories] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [
    showModalUploadDocumentSupporting,
    setShowModalUploadDocumentSupporting
  ] = useState(false)
  const [valueInboundId, setValueInboundId] = useState(null)
  const [pageFilter, setPageFilter] = useState(10)
  const [metaData, setMetaData] = useState({
    per_page: 10,
    current_page: 1,
    total_row: 0,
    total_page: 0
  })
  const { per_page, current_page } = metaData
  const [pageCount, setPageCount] = useState(0)
  useEffect(() => {
    fetchListInboundHistories()
  }, [per_page, current_page])

  const handleOnChangeMeta = (e) => {
    setMetaData({
      ...metaData,
      [e.target.name]: Number(e.target.value),
      current_page: 1
    })
    setPageFilter(e.target.value)
  }

  const handlePageChange = (data) => {
    const selectedPage = data.selected + 1
    setMetaData((prevMetaData) => ({
      ...prevMetaData,
      current_page: selectedPage
    }))
    window.scrollTo(0, 0)
  }

  const handleCloseModalUploadDocument = () => {
    setShowModalUploadDocumentSupporting(false)
    setValueInboundId(null)
  }

  const handleShowModalModalUploadDocument = (id) => {
    setShowModalUploadDocumentSupporting(true)
    setValueInboundId(id)
  }

  // function fetch list inbound history
  const fetchListInboundHistories = async () => {
    try {
      setIsLoading(true)
      const response = await api.get('/inbound/history', {
        params: {
          per_page,
          page: current_page
        }
      })
      const { metadata, data } = response.data
      setMetaData(metadata)
      setInboundHistories(data)
      setPageCount(metadata.total_page || 0)
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'

      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-10">
            <h4 style={headerTitleStyle}>Inbound History</h4>
          </div>
        </div>
        <div className="row mt-3">
          <TableData
            isLoading={isLoading}
            inboundHistories={inboundHistories}
            page={metaData}
            pageCount={pageCount}
            pageFilter={pageFilter}
            onHandleOnChangeMeta={handleOnChangeMeta}
            onHandlePageChange={handlePageChange}
            onHandleShowModalUploadDocument={handleShowModalModalUploadDocument}
          />
        </div>
      </div>
      <ModalUploadSupportingDocument
        show={showModalUploadDocumentSupporting}
        onHide={() => handleCloseModalUploadDocument()}
        valueInboundId={valueInboundId}
        onRefreshData={() => fetchListInboundHistories(per_page, current_page)}
      />
    </>
  )
}

function TableData({
  isLoading,
  inboundHistories,
  page,
  pageCount,
  pageFilter,
  onHandleOnChangeMeta,
  onHandlePageChange,
  onHandleShowModalUploadDocument
}) {
  const tableSecondarySj = {
    borderColor: 'transparent'
  }
  return (
    <>
      <Table
        hover
        responsive="sm"
        className="shadow-sm mb-5 bg-white rounded"
        size="sm"
        style={{ fontSize: '14px' }}
      >
        <thead className="table-secondary" style={tableSecondarySj}>
          <tr>
            <th className="p-2 align-middle">Type</th>
            <th className="p-2 align-middle">ERP Doc No.</th>
            <th className="p-2 align-middle">Inb Doc No.</th>
            <th className="p-2 align-middle">Supporting Document</th>
            <th className="p-2 align-middle">Submit</th>
            <th className="p-2 text-center align-middle" width="15%">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {isLoading && (
            <td colSpan={6} className="text-center">
              <Loader />
            </td>
          )}
          {!isLoading && inboundHistories.length === 0 && (
            <td colSpan={6} className="text-center fw-bold">
              No Data
            </td>
          )}
          {!isLoading &&
            inboundHistories.length > 0 &&
            inboundHistories.map((value) => (
              <RowData
                data={value}
                onHandleShowModalUploadDocument={
                  onHandleShowModalUploadDocument
                }
              />
            ))}
        </tbody>
        <tfoot>
          <tr>
            <td className="p-3" colSpan={6}>
              <TablePagination
                meta={page}
                handleOnChangeMeta={onHandleOnChangeMeta}
                handlePageChange={onHandlePageChange}
              />
            </td>
          </tr>
        </tfoot>
      </Table>
    </>
  )
}

function RowData({ data, onHandleShowModalUploadDocument }) {
  const navigate = useNavigate()
  const fileName = data.support_doc ? data.support_doc.split('/').pop() : '-'
  const urlDetail = (type, prId, mlId) => {
    let url = ''
    if (type === 'pr') {
      url = `/inbound/history/detail/pr/${prId}`
    }
    if (type === 'ml') {
      url = `/inbound/ml/detail/${mlId}`
    }
    return url
  }
  return (
    <tr key={data.id}>
      <td className="p-2 align-middle text-uppercase">{data.inbound_type}</td>
      <td className="p-2 align-middle text-center">{data.erp_code ?? '-'}</td>
      <td className="p-2 align-middle">{data?.inbound_code}</td>
      <td className="p-2 align-middle">
        {!data.support_doc && '-'}
        {data.support_doc && (
          <Link to={data.support_doc} className="text-decoration-none">
            {fileName}
          </Link>
        )}
      </td>
      <td className="p-2 align-middle">
        {data?.submited_at ? convertDateWithTime(data?.submited_at) : '-'}
      </td>
      <td className="text-center p-2 align-middle">
        <TableAction>
          <button
            className="btn btn-secondary btn-sm"
            onClick={() =>
              onHandleShowModalUploadDocument({
                inboundId: data.inbound_id,
                code: data.inbound_code
              })
            }
          >
            <FontAwesomeIcon
              icon={faFileUpload}
              className="fa-flip-horizontal"
            />
          </button>
          <button
            className="btn btn-secondary btn-sm ml-2"
            onClick={() =>
              navigate(urlDetail(data?.type, data?.pr_id, data?.ml_id), {
                replace: true
              })
            }
          >
            <FontAwesomeIcon icon={faSearch} className="fa-flip-horizontal" />
          </button>
        </TableAction>
      </td>
    </tr>
  )
}

export default InboundHistory
