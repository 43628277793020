import { Form } from 'react-bootstrap'
import moment from 'moment'
import './datepickercomponent.css'
import InputDatePicker from './InputDatePicker'
function index({
  label,
  name = '',
  value,
  handleChange,
  handleBlur,
  touched = false,
  errors = false,
  required = false,
  col,
  bold = 'bold',
  disabled = false
}) {
  const classValue = (name) => {
    switch (name) {
      case 'manufacturing_date':
      case 'coa_pd':
      case 'coa_ed':
        return 'mt-3'
      case 'exp_date':
      case 'date_patch':
        return 'mt-1'
      default:
        return ''
    }
  }

  return (
    <>
      <Form.Group
        className={`mb-3 ${classValue(
          name
        )} col-12 col-md-${col} col-lg-${col}`}
        controlId={name}
      >
        <Form.Label
          className={`text-muted text-uppercase fw-${bold ? 'bold' : 'normal'}`}
          style={{ fontSize: '13px', letterSpacing: '0.5px' }}
        >
          {label}
          {required === true && <sup style={{ color: 'red' }}>*</sup>}
        </Form.Label>
        <div className="w-100">
          <InputDatePicker
            value={value}
            name={name}
            onChange={(date) => {
              handleChange(name, moment(date).format('YYYY-MM-DD'))
            }}
            onBlur={handleBlur}
            disabled={disabled}
            touched={touched}
            errors={errors}
          />
        </div>
        {touched[name] && !!errors[name] && (
          <div className="mt-1" style={{ fontSize: '11px', color: '#dc3545' }}>
            {errors[name]}
          </div>
        )}
      </Form.Group>
    </>
  )
}

export default index
