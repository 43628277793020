import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View
} from '@react-pdf/renderer'
import React from 'react'
Font.register({
  family: 'Open Sans',
  fonts: [
    {
      src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf'
    },
    {
      src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf',
      fontWeight: 600
    },
    {
      src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-800.ttf',
      fontWeight: 800
    }
  ]
})
const styles = StyleSheet.create({
  table: {
    width: '100%',
    borderCollapse: 'collapse'
  },
  row: {
    flexDirection: 'row'
  },
  cell: {
    width: '50%',
    textAlign: 'center',
    verticalAlign: 'middle',
    border: '0.5px solid black',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column' // Ensure content is stacked vertically
  },
  qrImage: {
    width: 120,
    height: 120
  },
  qrTextWrapper: {
    width: '100%',
    borderTop: '0.5px solid black', // Separator within the cell
    marginTop: 10, // Adds space between QR code and separator
    paddingTop: 5 // Adds space between separator and text
  },
  qrTextWrapperBackground: {
    width: '100%',
    borderTop: '0.5px solid black', // Separator within the cell
    marginTop: 10, // Adds space between QR code and separator
    paddingTop: 5,
    backgroundColor: 'cyan'
  },
  qrText: {
    textAlign: 'center',
    fontSize: 14,
    fontFamily: 'Open Sans',
    fontWeight: 800
  }
})
const Pdf = ({ data, typeDownload = null }) => {
  const itemsPerRow = 2 // Number of items per row
  const itemsPerPage = 10 // Number of items per page (total)
  const renderPage = (rows) => (
    <Page size="A4" wrap={false} style={styles.page}>
      {rows.map((row, rowIndex) => (
        <View key={rowIndex} style={styles.row}>
          {row.map((colItem, colIndex) => (
            <View key={colIndex} style={styles.cell}>
              <View style={{ padding: 2 }}>
                <Image src={colItem.qr} style={styles.qrImage} />
              </View>
              <View
                style={
                  typeDownload === 'beam'
                    ? styles.qrTextWrapperBackground
                    : styles.qrTextWrapper
                }
              >
                <View style={{ marginBottom: 5 }}>
                  <Text style={styles.qrText}>{colItem.name}</Text>
                </View>
              </View>
            </View>
          ))}
        </View>
      ))}
    </Page>
  )

  const pages = []
  let currentPageRows = []
  let currentRow = []

  data.forEach((rowItem, rowIndex) => {
    rowItem.forEach((colItem, colIndex) => {
      currentRow.push(colItem)
      // Check if the row is full
      if (currentRow.length === itemsPerRow) {
        currentPageRows.push(currentRow)
        currentRow = [] // Reset the current row
      }

      // Check if the page is full
      if (currentPageRows.length === itemsPerPage / itemsPerRow) {
        pages.push(renderPage(currentPageRows))
        currentPageRows = [] // Reset the current page rows
      }
    })
  })

  // Add the last row if it's not empty
  if (currentRow.length > 0) {
    currentPageRows.push(currentRow)
  }

  // Add the remaining rows to the last page if there are any left
  if (currentPageRows.length > 0) {
    pages.push(renderPage(currentPageRows))
  }

  return <Document>{pages}</Document>
}

export default Pdf
