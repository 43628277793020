import {
  faBars,
  faCaretDown,
  faMagnifyingGlass
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Container, Form, Row, Table } from 'react-bootstrap'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import { useNavigate } from 'react-router-dom'
import Select from 'react-select'
import Loader from '../../../components/atoms/Loader'
import RowEmpty from '../../../components/atoms/RowEmpty'
import {
  BadgeStatusCOA,
  BadgeStatusPrList,
  BadgeStatusPrLists
} from '../../../components/atoms/StatusLabel'
import TableAction from '../../../components/atoms/Table/TableAction'
import TablePagination from '../../../components/atoms/Table/TablePagination'
import ModalAddSelectStagingArea from '../../../components/modal/Inbound/ModalAddSelectStagingArea'
import ModalTypePr from '../../../components/modal/pr/ModalTypePr'
import api from '../../../config/AxiosInstance'
import { convertDateWithTime } from '../../../helpers/constants'
import '../../../styles/style.css'
import * as Common from '../../../utils/Common'

const headerTitleStyle = {
  color: 'var(--primary-orange)',
  fontSize: '24px',
  fontWeight: '600'
}

const titleStyle = {
  fontSize: '12px',
  letterSpacing: '0.5px'
}

const btnOrange = {
  backgroundColor: 'var(--primary-orange)',
  borderColor: 'var(--primary-orange)',
  color: 'white'
}

const PurchaseReceipt = () => {
  const [loading, setLoading] = useState(false)
  const [prDraftList, setPrDraftList] = useState([])
  const [prDraftId, setPrDraftId] = useState('')
  const [expandedRows, setExpandedRows] = useState(null)
  const [idWarehouseDst, setIdWarehouseDst] = useState()
  const [meta, setMeta] = useState({
    per_page: 10,
    current_page: 1,
    total_row: 0,
    total_page: 0
  })
  const { per_page, current_page } = meta
  const [selectedPrinciple, setSelectedPrinciple] = useState('')
  const [principles, setPrinciples] = useState([])
  const refSrcPrinciples = useRef()
  const [selectedWarehouse, setSelectedWarehouse] = useState('')
  const [warehouses, setWarehouses] = useState([])
  const refSrcWarehouses = useRef()
  const refSrcPos = useRef()
  const [showModalStaging, setShowModalStaging] = useState(false)
  const [inboundData, setInboundData] = useState({})
  const [modalTypePr, setModalTypePr] = useState(false)
  const filterPo = () => true
  const [isLoadingPo, setIsLoadingPo] = useState(false)
  const [optionsPo, setOptionsPo] = useState([])
  const refPo = useRef(AsyncTypeahead)
  const [inputPo, setInputPo] = useState('')
  const [selected, setSelected] = useState(false)
  const handleExpandRow = (userId, idWarehouse) => {
    setIdWarehouseDst(idWarehouse)
    let currentExpandedRows = null
    const isRowExpanded = currentExpandedRows === userId ? userId : null
    const newExpandedRows = isRowExpanded
      ? null
      : (currentExpandedRows = userId)
    if (expandedRows !== userId) {
      setExpandedRows(newExpandedRows)
    } else {
      setExpandedRows(null)
    }
  }

  useEffect(() => {
    fetchPRLIST(
      per_page,
      current_page,
      prDraftId,
      inputPo,
      selectedPrinciple,
      selectedWarehouse
    )
  }, [per_page, current_page, selected])

  useEffect(() => {
    getPrinciples()
    getWarehouses()
  }, [])

  async function handleSearchPo(query) {
    setIsLoadingPo(true)
    try {
      const params = `?po=${query}`
      const res = await api.get(`inbound/purchase-order/all${params}`)
      setOptionsPo(res.data.data)
      setIsLoadingPo(false)
    } catch (err) {
      setIsLoadingPo(false)
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }

  const getPrinciples = async () => {
    try {
      const options = [{ value: '', label: 'All' }]
      const res = await api.get('/inbound/purchase-order/principles')
      const { data } = res.data
      data.map((data) =>
        options.push({ value: data.supplier, label: data.supplier })
      )
      setPrinciples(options)
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }

  const getWarehouses = async () => {
    try {
      const options = [{ value: '', label: 'All' }]
      const res = await api.get('/inbound/purchase-order/dest-warehouses')
      const { data } = res.data
      data.map((data) =>
        options.push({ value: data.set_warehouse, label: data.set_warehouse })
      )
      setWarehouses(options)
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }

  async function fetchPRLIST(
    per_page,
    current_page,
    prDraftId,
    inputPo,
    selectedPrinciple,
    selectedWarehouse
  ) {
    setLoading(true)
    try {
      const res = await api.get('inbound/pr-draft', {
        params: {
          per_page,
          page: current_page,
          principle: selectedPrinciple || '',
          dest_warehouse: selectedWarehouse || '',
          po: inputPo || '',
          pr_draft: prDraftId || ''
        }
      })
      const { metadata, data } = res.data
      setPrDraftList(data)
      setMeta(metadata)
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setLoading(false)
    }
  }

  const handleOnChangeMeta = (e) => {
    setMeta((prevMeta) => ({
      ...prevMeta,
      per_page: parseInt(e.target.value, 10),
      current_page: 1
    }))
  }

  const handlePageChange = (data) => {
    setMeta((prevMeta) => ({
      ...prevMeta,
      current_page: data.selected + 1
    }))
    window.scrollTo(0, 0)
  }

  const navigate = useNavigate()
  const navigateToDetail = (purchaseOrder) => {
    const encodedName = encodeURIComponent(purchaseOrder.id)
    navigate(`/inbound/purchase-receipt/${encodedName}`)
  }

  function handleReset(e) {
    refSrcPrinciples.current?.clearValue()
    refSrcWarehouses.current?.clearValue()
    refSrcPos.current?.clearValue()
    refPo.current?.clear()
    setPrDraftId('')
    setInputPo('')
    setMeta((prevMeta) => ({
      ...prevMeta,
      per_page: 10,
      current_page: 1
    }))
  }

  const handleShowModalStagingArea = (data) => {
    setShowModalStaging(true)
    setInboundData(data)
  }

  function handleCreate() {
    setModalTypePr(true)
  }
  const handleSelected = () => {
    setSelected((selected) => !selected)
    setMeta((prevMeta) => ({
      ...prevMeta,
      current_page: 1
    }))
  }

  const statusTextMapping = {
    unassigned: 'unassigned',
    waiting_rnr: 'waiting rnr',
    receiving_progress: 'receiving progress',
    rnr: 'receiving progress',
    waiting_om: 'waiting om',
    request_pnp: 'waiting om',
    waiting_pnp: 'waiting pnp',
    pnp: 'put away',
    put_away: 'put away',
    done: 'done',
    revised: 'waiting rnr'
  }

  const statusCoaMapping = {
    unchecked: 'unchecked',
    match: 'match',
    not_match: 'not match'
  }

  return (
    <>
      <Container>
        <Row>
          <Col md={6} xs={7}>
            <h3 style={headerTitleStyle}>PR Draft List</h3>
          </Col>
          <Col md={6} xs={5} className="text-end">
            <Button
              className="btn btn-primary-blue"
              onClick={() => handleCreate()}
            >
              + Create PR Draft
            </Button>
          </Col>
        </Row>
        {/* FILTER */}
        <Row className="mt-3">
          <Col md={3}>
            <Form.Label className="fw-bold text-secondary" style={titleStyle}>
              <small>#PR DRAFT</small>
            </Form.Label>
            <Form.Control
              type="text"
              name="pr_draft"
              id="pr_draft"
              placeholder="Input"
              onChange={(e) => setPrDraftId(e.target.value)}
              value={prDraftId}
              style={{ height: '40px', border: '1px solid #D2D2D2' }}
            />
          </Col>
          <Col md={3}>
            <Form.Group>
              <Form.Label className="fw-bold text-secondary" style={titleStyle}>
                <small>#PO</small>
              </Form.Label>
              <AsyncTypeahead
                id="basic-typeahead-single"
                labelKey="po"
                isLoading={isLoadingPo}
                filterBy={filterPo}
                onSearch={handleSearchPo}
                onChange={(e) => {
                  setInputPo(e.length > 0 ? e[0].po : '')
                }}
                options={optionsPo}
                placeholder="Input..."
                ref={refPo}
                style={{ height: '40px' }}
              />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group>
              <Form.Label className="fw-bold text-secondary" style={titleStyle}>
                <small>PRINCIPLE</small>
              </Form.Label>
              <Select
                isClearable
                defaultValue={selectedPrinciple}
                onChange={(e) => {
                  if (!e) {
                    e = {
                      target: refSrcPrinciples,
                      value: ''
                    }
                  }
                  setSelectedPrinciple(e?.value)
                }}
                options={principles}
                isSearchable
                ref={refSrcPrinciples}
              />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group>
              <Form.Label className="fw-bold text-secondary" style={titleStyle}>
                <small>DESTINATION WAREHOUSE</small>
              </Form.Label>
              <Select
                isClearable
                defaultValue={selectedWarehouse}
                onChange={(e) => {
                  if (!e) {
                    e = {
                      target: refSrcWarehouses,
                      value: ''
                    }
                  }
                  setSelectedWarehouse(e?.value)
                }}
                options={warehouses}
                isSearchable
                ref={refSrcWarehouses}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md={6} className="mt-3"></Col>
          <Col md={6} className="text-end">
            <Button
              className="btn btn-secondary text-white me-2"
              onClick={() => handleReset()}
            >
              Reset Filter
            </Button>
            <Button style={btnOrange} onClick={() => handleSelected()}>
              Apply Filter
            </Button>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={12}>
            <Table
              responsive="sm"
              size="sm"
              className="shadow-sm mb-5 bg-white rounded"
              style={{ fontSize: '14px' }}
              hover
            >
              <thead className="table-secondary">
                <tr>
                  <th className="p-2 align-middle">#PR DRAFT</th>
                  <th className="p-2 align-middle">#PO</th>
                  <th className="p-2 align-middle">Principle</th>
                  <th className="p-2 align-middle">Destination Warehouse</th>
                  <th className="p-2 align-middle">Created</th>
                  <th className="p-2 align-middle text-end">Status COA</th>
                  <th className="p-2 align-middle text-end">Status</th>
                  <th className="p-2 align-middle text-end">Action</th>
                </tr>
              </thead>
              <tbody>
                {loading && (
                  <tr>
                    <td colSpan={8}>
                      <Loader />
                    </td>
                  </tr>
                )}
                {!loading && prDraftList.length === 0 && (
                  <RowEmpty colSpan={8} />
                )}
                {!loading &&
                  prDraftList?.map((data, i) => {
                    return (
                      <>
                        <tr key={i}>
                          <td className="p-2 align-middle">{data.pr_draft}</td>
                          <td className="p-2 align-middle">{data.po_code}</td>
                          <td className="p-2 align-middle">{data.principle}</td>
                          <td className="p-2 align-middle">
                            {data.dest_warehouse_id}
                          </td>
                          <td className="p-2 align-middle">
                            {convertDateWithTime(data?.created_at)}
                          </td>
                          <td className="p-2 align-middle text-end">
                            <BadgeStatusCOA
                              text={
                                statusCoaMapping[data.coa_status] || 'unchecked'
                              }
                            />
                          </td>
                          <td className="p-2 align-middle text-end">
                            <BadgeStatusPrList text={data.draft_status} />
                          </td>
                          <td className="p-2 align-middle text-end">
                            <TableAction>
                              <button
                                className="btn btn-secondary"
                                onClick={() => {
                                  navigateToDetail(data)
                                }}
                              >
                                <FontAwesomeIcon icon={faMagnifyingGlass} />
                              </button>
                              <button
                                className="btn btn-secondary ml-1"
                                onClick={() =>
                                  handleExpandRow(
                                    i,
                                    data.dest_warehouse_id,
                                    data
                                  )
                                }
                              >
                                <FontAwesomeIcon icon={faBars} />
                              </button>
                            </TableAction>
                          </td>
                        </tr>
                        {expandedRows === i ? (
                          <>
                            <tr className="table-secondary collaps-viewer fw-bold">
                              <td colSpan={2} className="pt-3 ps-5 pe-5 pb-2">
                                Ticket
                              </td>
                              <td className="pt-3 ps-5 pe-5 pb-2">Car</td>
                              <td colSpan={1} className="pt-3 ps-5 pe-5 pb-2" />
                              <td
                                colSpan={3}
                                className="text-end pt-3 ps-5 pe-5 pb-2"
                              >
                                Staging Area
                              </td>
                              <td className="text-end pt-3 ps-5 pe-5 pb-2">
                                Status
                              </td>
                            </tr>
                            {data.inbounds.length === 0 && (
                              <tr>
                                <td colSpan={5}>No Data...</td>
                              </tr>
                            )}
                            {data.inbounds.map((data, i) => {
                              return (
                                <tr className="bg-secondary" key={i}>
                                  <td
                                    colSpan={2}
                                    className="pt-3 ps-5 pe-5 pb-4"
                                  >
                                    {data.ticket}
                                  </td>
                                  <td className="pt-3 ps-5 pe-5 pb-4">
                                    {data.vehicle}
                                  </td>
                                  <td
                                    colSpan={1}
                                    className="pt-3 ps-5 pe-5 pb-4"
                                  />
                                  <td
                                    colSpan={3}
                                    className="pt-3 ps-5 pe-5 pb-4"
                                  >
                                    <div
                                      className="d-flex justify-content-end"
                                      onClick={() =>
                                        data.status === 'unassigned' ||
                                        data.status === 'waiting'
                                          ? handleShowModalStagingArea(data)
                                          : ''
                                      }
                                      style={{
                                        cursor:
                                          data.status === 'unassigned' ||
                                          data.status === 'waiting'
                                            ? 'pointer'
                                            : ''
                                      }}
                                    >
                                      {data.stagings.length > 0 ? (
                                        data.stagings
                                          .map((data) => data.name)
                                          .join(', ')
                                      ) : (
                                        <>
                                          Select Staging Area
                                          <FontAwesomeIcon
                                            icon={faCaretDown}
                                            className="fa-flip-horizontal ml-2 mt-1"
                                          />
                                        </>
                                      )}
                                    </div>
                                  </td>
                                  <td className="text-end pt-3 ps-5 pe-5 pb-4">
                                    <BadgeStatusPrLists
                                      text={
                                        statusTextMapping[data.status] || 'done'
                                      }
                                    />
                                  </td>
                                </tr>
                              )
                            })}
                          </>
                        ) : null}
                      </>
                    )
                  })}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={11} className="p-3">
                    <TablePagination
                      meta={meta}
                      handlePageChange={handlePageChange}
                      handleOnchangeMeta={handleOnChangeMeta}
                    />
                  </td>
                </tr>
              </tfoot>
            </Table>
          </Col>
        </Row>
      </Container>

      <ModalAddSelectStagingArea
        show={showModalStaging}
        onHide={() => setShowModalStaging(false)}
        id={idWarehouseDst}
        data={inboundData}
        refreshData={() => fetchPRLIST()}
      />
      <ModalTypePr show={modalTypePr} onHide={() => setModalTypePr(false)} />
    </>
  )
}

export default PurchaseReceipt
