import { faQrcode } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PDFDownloadLink } from '@react-pdf/renderer'
import React, { useEffect, useState } from 'react'
import { Col, Form, Row, Table } from 'react-bootstrap'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import Loader from '../../../components/atoms/Loader'
import RowEmpty from '../../../components/atoms/RowEmpty'
import { BadgeStatusSj } from '../../../components/atoms/StatusLabel'
import TableAction from '../../../components/atoms/Table/TableAction'
import TablePagination from '../../../components/atoms/Table/TablePagination'
import Pdf from '../../../components/Download/Pdf'
import ModalAddPallete from '../../../components/modal/pallete/ModalAddPallete'
import ModalDownloadQr from '../../../components/modal/pallete/ModalDownloadQr'
import api from '../../../config/AxiosInstance'
import '../../../styles/style.css'
import * as Common from '../../../utils/Common'

const headerTitleStyle = {
  color: 'var(--primary-orange)',
  fontSize: '24px',
  fontWeight: '600'
}

const initialMeta = {
  per_page: 10,
  current_page: 1,
  total_row: 0,
  total_page: 0
}

const MasterPallete = () => {
  const [showModalAdd, setShowModalAdd] = useState(false)
  const [showDownloadQr, setShowDownloadQr] = useState(false)
  const [disabledAdd, setDisabledAdd] = useState(true)
  const [loading, setLoading] = useState(false)
  const [pallettes, setPalletes] = useState([])
  const [warehouseId, setWarehouseId] = useState('')
  const [editId, setEditId] = useState({
    src: '',
    paletName: '',
    id: ''
  })
  const [isSelected, setSelected] = useState(false)
  const [meta, setMeta] = useState(initialMeta)
  const [options, setOptions] = useState([])
  const [loadingWarehouse, setLoadingWarehouse] = useState(false)
  const [inputWarehouse, setInputWarehouse] = useState('')
  const [checkedPalletId, setCheckedPalletId] = useState([])
  const { per_page, current_page } = meta
  const [showDownloadPdf, setShowDownloadPdf] = useState(false)
  const filterBy = () => true

  async function fetchPalletes(warehouseId) {
    try {
      setLoading(true)
      const res = await api.get(`master/palets/`, {
        params: {
          per_page,
          page: current_page,
          warehouse_id: warehouseId
        }
      })
      const { metadata, data } = res.data
      setPalletes(data)
      setMeta(metadata)
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setLoading(false)
    }
  }

  const handleOnChangeMeta = (e) => {
    setMeta((prevMeta) => ({
      ...prevMeta,
      per_page: parseInt(e.target.value, 10),
      current_page: 1
    }))
  }

  const handlePageChange = (data) => {
    setMeta((prevMeta) => ({
      ...prevMeta,
      current_page: data.selected + 1
    }))
    window.scrollTo(0, 0)
  }

  function handleDownloadQr(data) {
    setShowDownloadQr(true)
    setEditId({
      ...editId,
      src: data.src,
      paletName: data.paletName,
      id: data.id
    })
  }

  useEffect(
    function () {
      if (warehouseId) {
        fetchPalletes(warehouseId)
      }
    },
    [per_page, current_page, isSelected]
  )

  const handleSearch = async (query) => {
    setLoadingWarehouse(true)
    try {
      const params = new URLSearchParams({ page: 1, q: query })
      const res = await api.get(
        `master/warehouses/all?${params.toString()}&is_parent=true&forUser=1`
      )
      setOptions(res.data.data)
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setLoadingWarehouse(false)
    }
  }

  const handleCheckInput = (e) => {
    const { value, checked } = e.target
    if (value === 'all') {
      // override to empty array first
      setCheckedPalletId([])
      if (checked) {
        // get data from all id data palletes
        // const allId = pallettes.map((pallet) => pallet.id)
        const allId = pallettes.reduce((arr, cur) => {
          arr.push({
            id: cur.id,
            qr: cur.qr,
            palet_id: cur.palet_id
          })
          return arr
        }, [])
        setCheckedPalletId(allId)
      }
    } else {
      if (checked) {
        setCheckedPalletId([...checkedPalletId, JSON.parse(value)])
      } else {
        setCheckedPalletId(
          checkedPalletId.filter(
            (pallet) => pallet.id !== JSON.parse(value)?.id
          )
        )
      }
    }
  }

  const shardingItems = (propsDataValue) => {
    const data = JSON.parse(propsDataValue).reduce((acc, cur) => {
      acc.push({ id: cur.id, qr: cur.qr, name: cur.palet_id })
      return acc
    }, [])
    const shardedData = []

    for (let i = 0; i < data.length; i += 2) {
      shardedData.push(data.slice(i, i + 2))
    }
    return shardedData
  }

  const handleSelect = () => {
    setMeta((prevMeta) => ({
      ...prevMeta,
      current_page: 1
    }))
    setSelected((prevSelected) => !prevSelected)
  }

  return (
    <div className="container">
      <h3 style={headerTitleStyle}>Palet</h3>
      <Row>
        <Col xs={9} md={11} lg={11}>
          <Form.Group>
            <Form.Label>Warehouse</Form.Label>
            <AsyncTypeahead
              id="basic-typeahead-single"
              labelKey="warehouse_name"
              loading={loadingWarehouse}
              filterBy={filterBy}
              onSearch={handleSearch}
              onChange={(selected) => {
                const selectedWarehouse =
                  selected.length > 0 ? selected[0] : null
                setInputWarehouse(
                  selectedWarehouse ? selectedWarehouse.warehouse_name : ''
                )
                setWarehouseId(selectedWarehouse ? selectedWarehouse.id : '')
                setDisabledAdd(!selectedWarehouse)
              }}
              options={options}
              placeholder="Choose a warehouse..."
            />
          </Form.Group>
        </Col>
        <Col xs={3} md={1} lg={1}>
          <div
            className="d-flex h-100 align-items-center"
            style={{ marginTop: '.9rem' }}
          >
            <button
              className="btn btn-primary-orange"
              onClick={() => handleSelect()}
              disabled={!inputWarehouse}
            >
              Select
            </button>
          </div>
        </Col>
      </Row>
      <div
        style={{
          borderBottom: '3px solid #F1F2F7',
          marginTop: '2.5rem'
        }}
      ></div>
      <div className="d-flex justify-content-between align-items-center mt-3">
        <h4 className="text-muted text-uppercase" style={{ fontSize: '14px' }}>
          Palet List
        </h4>
        <div className="d-flex flex-row gap-2">
          <PDFDownloadLink
            document={
              <Pdf
                data={shardingItems(JSON.stringify(checkedPalletId))}
                typeDownload={null}
              />
            }
            fileName="print_qr_pallete.pdf"
            onClick={() => {
              setShowDownloadPdf(true)
              setTimeout(() => {
                setShowDownloadPdf(false)
              }, 1000)
            }}
          >
            {({ loading }) => (
              <button
                className="btn btn-secondary"
                disabled={
                  checkedPalletId.length === 0 || showDownloadPdf ? true : false
                }
              >
                {showDownloadPdf ? 'Download' : 'Print QR'}
              </button>
            )}
          </PDFDownloadLink>
          <button
            className="btn btn-primary-orange"
            disabled={disabledAdd}
            onClick={() => setShowModalAdd(true)}
          >
            Add
          </button>
        </div>
      </div>
      <div className="row mt-4 mt-md-2 mt-lg-2">
        <Table
          responsive="sm"
          hover
          className="shadow-sm mb-5 bg-white rounded"
          size="sm"
          style={{ fontSize: '14px' }}
        >
          <thead className="table-secondary">
            <tr>
              <th className="p-2 align-middle" style={{ width: '5%' }}>
                <input
                  className="staging-area form-check-input"
                  type="checkbox"
                  name="parent_palette"
                  value={'all'}
                  disabled={pallettes.length === 0 ? true : false}
                  checked={
                    checkedPalletId.length === pallettes.length &&
                    pallettes.length !== 0
                      ? true
                      : false
                  }
                  onChange={(e) => handleCheckInput(e)}
                />
              </th>
              <th className="p-2 align-middle">Palet ID</th>
              <th className="p-2 align-middle">Status</th>
              <th className="p-2 align-middle">Location</th>
              <th className="p-2 align-middle text-end">Action</th>
            </tr>
          </thead>
          <tbody>
            {loading && (
              <tr>
                <td colSpan={5}>
                  <Loader />
                </td>
              </tr>
            )}
            {!loading && !pallettes.length && <RowEmpty colSpan={5} />}
            {!loading &&
              pallettes.length > 0 &&
              pallettes?.map((pallete) => (
                <TbodyRow
                  data={pallete}
                  key={pallete.id}
                  checkedPalletId={checkedPalletId}
                  handleCheckInput={handleCheckInput}
                  onHandleDownloadQr={handleDownloadQr}
                />
              ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={5} className="p-3">
                <TablePagination
                  meta={meta}
                  handleOnChangeMeta={handleOnChangeMeta}
                  handlePageChange={handlePageChange}
                />
              </td>
            </tr>
          </tfoot>
        </Table>
      </div>
      <ModalAddPallete
        show={showModalAdd}
        onHide={() => setShowModalAdd(false)}
        data={warehouseId}
        refreshData={() => fetchPalletes(warehouseId)}
      />
      <ModalDownloadQr
        show={showDownloadQr}
        onHide={() => {
          setShowDownloadQr(false)
          setEditId({ ...editId, src: '', paletName: '', id: '' })
        }}
        paletData={editId}
      />
    </div>
  )
}

function TbodyRow({
  data,
  onHandleDownloadQr,
  checkedPalletId,
  handleCheckInput
}) {
  const dataValue = {
    src: data.qr,
    paletName: data.palet_id,
    id: data.id
  }
  return (
    <tr key={data.id}>
      <td className="p-2 align-middle">
        <input
          className="staging-area form-check-input"
          type="checkbox"
          name="palette"
          value={JSON.stringify({
            id: data.id,
            palet_id: data.palet_id,
            qr: data.qr
          })}
          checked={
            checkedPalletId.find((pallet) => pallet.id === data.id)
              ? true
              : false
          }
          onChange={(e) => handleCheckInput(e)}
        />
      </td>
      <td className="p-2 align-middle">{data.palet_id}</td>
      <td className="p-2 align-middle">
        <BadgeStatusSj
          text={data.is_available ? 'available' : 'used'}
          bgColor={data.is_available ? 'success' : 'danger'}
        />
      </td>
      <td className="p-2 align-middle">{data.location}</td>
      <td className="p-2 align-middle">
        <TableAction>
          <button
            className="btn btn-secondary ml-2"
            onClick={() => onHandleDownloadQr(dataValue)}
          >
            <FontAwesomeIcon
              icon={faQrcode}
              style={{ fontSize: '15px', rotate: '90deg' }}
              className="fa-flip-horizontal"
            />
          </button>
        </TableAction>
      </td>
    </tr>
  )
}

export default MasterPallete
