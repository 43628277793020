import {
  faArrowsRotate,
  faMagnifyingGlass
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useCallback, useEffect, useState } from 'react'
import {
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  Table
} from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import Loader from '../../../components/atoms/Loader'
import TableAction from '../../../components/atoms/Table/TableAction'
import TablePagination from '../../../components/atoms/Table/TablePagination'
import api from '../../../config/AxiosInstance'
import * as Common from '../../../utils/Common'
import { convertDateWithTime } from '../../../helpers/constants'

const headerTitleStyle = {
  color: 'var(--primary-orange)',
  fontSize: '24px',
  fontWeight: '600'
}

const MasterItems = () => {
  const [state, setState] = useState({
    items: [],
    meta: {
      per_page: 10,
      current_page: 1,
      total_row: 0,
      total_page: 0
    },
    filter: '',
    pageCount: 0,
    isLoading: true
  })

  const navigate = useNavigate()
  const { items, meta, filter, isLoading } = state
  const { per_page, current_page } = meta

  const loadData = useCallback(
    async (perPage, currentPage, filterQuery = '') => {
      setState((prevState) => ({ ...prevState, isLoading: true }))
      try {
        const res = await api.get('master/items', {
          params: { per_page: perPage, page: currentPage, q: filterQuery }
        })
        setState((prevState) => ({
          ...prevState,
          items: res.data.data,
          meta: res.data.metadata,
          isLoading: false
        }))
      } catch (err) {
        const errMsg =
          err?.response?.data?.errors?.message || 'An error occurred'
        Common.showPopUpErrorMessage(errMsg)
        setState((prevState) => ({ ...prevState, isLoading: false }))
      }
    },
    []
  )

  useEffect(() => {
    loadData(per_page, current_page, filter)
  }, [per_page, current_page, loadData])

  const handleSearch = (e) => {
    if (e.key === 'Enter') {
      setState((prevState) => ({
        ...prevState,
        meta: { ...prevState.meta, current_page: 1 }
      }))
      loadData(per_page, 1, filter)
    }
  }

  const handleClickSearch = () => {
    setState((prevState) => ({
      ...prevState,
      meta: { ...prevState.meta, current_page: 1 }
    }))
    loadData(per_page, 1, filter)
  }

  const handleOnChangeMeta = (e) => {
    const perPageValue = parseInt(e.target.value, 10)
    setState((prevState) => ({
      ...prevState,
      meta: { ...prevState.meta, per_page: perPageValue, current_page: 1 }
    }))
  }

  const handlePageChange = (data) => {
    const selectedPage = data.selected + 1
    setState((prevState) => ({
      ...prevState,
      meta: { ...prevState.meta, current_page: selectedPage }
    }))
    window.scrollTo(0, 0)
  }

  const handleDetail = (code) => {
    const encodedName = encodeURIComponent(code)
    navigate(`/master/items/${encodedName}`)
  }

  const handleOnChangeFilter = (e) => {
    setState((prevState) => ({ ...prevState, filter: e.target.value }))
  }

  return (
    <Container>
      <Row>
        <Col xs={6} md={2}>
          <h3 style={headerTitleStyle}>Master Item</h3>
        </Col>
        <Col xs={6} md={10} className="text-end">
          <Button
            className="btn btn-warning btn-sm"
            onClick={() => loadData(per_page, current_page)}
          >
            <FontAwesomeIcon icon={faArrowsRotate} />
          </Button>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs={12} md={8} className="mt-3 mt-md-2 mt-lg-2">
          <small className="text-secondary">
            <strong>ITEM LIST</strong>
          </small>
        </Col>
        <Col xs={12} md={4} className="text-end mt-3 mt-md-0 mt-lg-0">
          <InputGroup className="mb-3">
            <Form.Control
              placeholder="Search Item Name"
              aria-label="search"
              aria-describedby="basic-addon1"
              type="text"
              onChange={handleOnChangeFilter}
              onKeyDown={handleSearch}
            />
            <InputGroup.Text
              id="basic-addon1"
              style={{ cursor: 'pointer' }}
              onClick={handleClickSearch}
            >
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </InputGroup.Text>
          </InputGroup>
        </Col>
      </Row>
      <Row className="mt-1">
        <Col md={12}>
          <Table
            responsive="sm"
            hover
            className="shadow-sm mb-5 bg-white rounded"
            size="sm"
            style={{ fontSize: '14px' }}
          >
            <thead className="table-secondary">
              <tr>
                <th className="p-2 align-middle">Action</th>
                <th className="p-2 align-middle">Item Name</th>
                <th className="p-2 align-middle">Item Code</th>
                <th className="p-2 align-middle">Item Group</th>
                <th className="p-2 align-middle">Description</th>
                <th className="p-2 align-middle">Qty/Unit</th>
                <th className="p-2 align-middle">UOM</th>
                <th className="p-2 align-middle">Primary Item Group</th>
                <th className="p-2 align-middle">P x L x T / cm</th>
                <th className="p-2 align-middle">Volume / cm³</th>
                <th className="p-2 align-middle">Last Update</th>
              </tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td colSpan={11}>
                    <Loader />
                  </td>
                </tr>
              ) : (
                items.map((data, i) => (
                  <tr key={i}>
                    <td className="p-2 align-middle">
                      <TableAction direction="start">
                        <button
                          className="btn btn-secondary btn-sm"
                          onClick={() => handleDetail(data.item_code)}
                        >
                          <FontAwesomeIcon
                            icon={faMagnifyingGlass}
                            style={{ fontSize: '14px' }}
                          />
                        </button>
                      </TableAction>
                    </td>
                    <td className="p-2 align-middle">{data?.item_name}</td>
                    <td className="p-2 align-middle">{data?.item_code}</td>
                    <td className="p-2 align-middle">{data?.item_group}</td>
                    <td className="p-2 align-middle">{data?.description}</td>
                    <td className="p-2 align-middle">{data?.qty_unit}</td>
                    <td className="p-2 align-middle">{data?.uom}</td>
                    <td className="p-2 align-middle">
                      {data?.primary_item_group || '-'}
                    </td>
                    <td className="p-2 align-middle">{data?.p_l_t}</td>
                    <td className="p-2 align-middle">{data?.volume_item}</td>
                    <td className="p-2 align-middle">
                      {convertDateWithTime(data?.updatedAt)}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
            <tfoot>
              <tr>
                <td className="p-3" colSpan={11}>
                  <TablePagination
                    meta={meta}
                    handlePageChange={handlePageChange}
                    handleOnChangeMeta={handleOnChangeMeta}
                  />
                </td>
              </tr>
            </tfoot>
          </Table>
        </Col>
      </Row>
    </Container>
  )
}

export default MasterItems
