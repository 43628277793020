import {
  faCircleXmark,
  faFileArrowDown
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Button, Col, Modal, Row } from 'react-bootstrap'
import api from '../../../config/AxiosInstance'
import { convertDateWithTime } from '../../../helpers/constants'
import * as Common from '../../../utils/Common'
import { firstLetterUpperCase } from '../../../utils/TextFormatter'
import './style.css'
const veticalLine = {
  borderLeft: '3px #E0E0E0 solid',
  height: '50px',
  width: '0px',
  marginLeft: '6px'
}

const circle = {
  width: '15px',
  height: '15px',
  background: '#E0E0E0',
  borderRadius: '50%',
  marginTop: '5px'
}

const titleHistory = {
  color: '#828282'
}

const PrDetailTimeline = (props) => {
  const { timelineId } = props
  const [detail, setDetail] = useState({})
  const [loadingFile, setLoadingFile] = useState({
    armada: false,
    material: false,
    goodReceipt: false
  })
  const fetchTimeline = async () => {
    try {
      const res = await api.get(`inbound/pr-draft/history/${timelineId}`)
      const { data } = res
      if (data.success) {
        setDetail(data.data)
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }

  useEffect(() => {
    if (timelineId) {
      fetchTimeline()
    }
  }, [timelineId])
  const handleRemove = async () => {
    try {
      const data = {
        action: detail?.inbound?.status
      }
      const res = await api.post(
        `inbound/pr-draft/unassign/${detail?.inbound.id}`,
        data
      )

      if (res.data.success) {
        props.refreshData()
        Common.showPopUpSuccessMessage(res.data.message)
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }

  const handleDownloadArmada = async () => {
    try {
      setLoadingFile((prev) => ({ ...prev, armada: true }))
      const res = await api.get(
        `/inbound/pr-draft/${detail?.inbound?.id}/download-armada`,
        {
          responseType: 'arraybuffer'
        }
      )
      const { status, data } = res
      if (status === 200) {
        const blob = new Blob([data], { type: 'application/pdf' })
        const url = window.URL.createObjectURL(blob)
        // Create a link element, set the href to the URL, and trigger a download
        const link = document.createElement('a')
        link.href = url
        link.setAttribute(
          'download',
          `download-armada-${detail?.inbound?.id}.pdf`
        ) // Set the file name
        document.body.appendChild(link)
        link.click() // Trigger the download
        // Clean up
        document.body.removeChild(link)
        window.URL.revokeObjectURL(url)
      }
    } catch (error) {
      const err = error?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(err)
    } finally {
      setLoadingFile((prev) => ({ ...prev, armada: false }))
    }
  }

  const handleDownloadMaterial = async () => {
    try {
      setLoadingFile((prev) => ({ ...prev, material: true }))
      const res = await api.get(
        `/inbound/pr-draft/${detail?.inbound?.id}/download-material`,
        {
          responseType: 'arraybuffer'
        }
      )
      const { status, data } = res
      if (status === 200) {
        const blob = new Blob([data], { type: 'application/pdf' })
        const url = window.URL.createObjectURL(blob)
        // Create a link element, set the href to the URL, and trigger a download
        const link = document.createElement('a')
        link.href = url
        link.setAttribute(
          'download',
          `download-material-${detail?.inbound?.id}.pdf`
        ) // Set the file name
        document.body.appendChild(link)
        link.click() // Trigger the download
        // Clean up
        document.body.removeChild(link)
        window.URL.revokeObjectURL(url)
      }
    } catch (error) {
      const err = error?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(err)
    } finally {
      setLoadingFile((prev) => ({ ...prev, material: false }))
    }
  }
  const handleDownloadGoodReceipt = async () => {
    try {
      setLoadingFile((prev) => ({ ...prev, goodReceipt: true }))
      const res = await api.get(
        `/inbound/pr-draft/${detail?.inbound?.id}/download-good-recipt`,
        {
          responseType: 'arraybuffer'
        }
      )
      const { status, data } = res
      if (status === 200) {
        const blob = new Blob([data], { type: 'application/pdf' })
        const url = window.URL.createObjectURL(blob)
        // Create a link element, set the href to the URL, and trigger a download
        const link = document.createElement('a')
        link.href = url
        link.setAttribute(
          'download',
          `download-goodsreceipt-${detail?.inbound?.id}.pdf`
        ) // Set the file name
        document.body.appendChild(link)
        link.click() // Trigger the download
        // Clean up
        document.body.removeChild(link)
        window.URL.revokeObjectURL(url)
      }
    } catch (error) {
      const err = error?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(err)
    } finally {
      setLoadingFile((prev) => ({ ...prev, goodReceipt: false }))
    }
  }

  return (
    <Modal {...props} centered size="lg">
      <Modal.Header closeButton>
        <Modal.Title>
          <small>{detail?.inbound?.ticket}</small>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="mt-3">
          <Col md={4}>
            <h4 className="fw-bold">{detail?.inbound?.vehicle}</h4>
          </Col>
          {detail?.inbound?.status === 'put_away' ? (
            <>
              <Col md={3} style={{ fontSize: '14px' }}>
                <li>
                  Put Away by{' '}
                  {detail?.inbound?.action?.pnp?.name === null ? (
                    <text style={{ color: '#F89D1D' }}>---</text>
                  ) : (
                    <div class="dropdown">
                      <span>
                        <text style={{ color: '#F89D1D' }}>
                          {detail?.inbound?.action?.pnp?.name}
                        </text>{' '}
                      </span>
                      <div class="dropdown-content">
                        <small
                          style={{ cursor: 'pointer' }}
                          className="text-danger fw-bold"
                          onClick={() => handleRemove()}
                        >
                          <FontAwesomeIcon
                            icon={faCircleXmark}
                            className="text-danger me-2"
                          />
                          REMOVE
                        </small>
                      </div>
                    </div>
                  )}
                </li>
              </Col>
              <Col md={3} style={{ fontSize: '14px' }}>
                <li>
                  Mover by{' '}
                  {detail?.inbound?.action?.mover?.name === null ? (
                    <text style={{ color: '#F89D1D' }}>---</text>
                  ) : (
                    <div class="dropdown">
                      <span>
                        <text style={{ color: '#F89D1D' }}>
                          {detail?.inbound?.action?.mover?.name === null
                            ? '-'
                            : detail?.inbound?.action?.mover?.name}
                        </text>{' '}
                      </span>
                      <div class="dropdown-content">
                        <small
                          className="text-danger fw-bold"
                          onClick={() => handleRemove()}
                          style={{ cursor: 'pointer' }}
                        >
                          <FontAwesomeIcon
                            icon={faCircleXmark}
                            className="text-danger me-2"
                          />
                          REMOVE
                        </small>
                      </div>
                    </div>
                  )}
                </li>
              </Col>
            </>
          ) : detail?.inbound?.status === 'waiting_om' ||
            detail?.inbound?.status === 'waiting_pnp' ? (
            <>
              <Col md={3} style={{ fontSize: '14px' }}>
                <li>
                  {detail?.inbound?.status === 'waiting_om'
                    ? 'Waiting OM'
                    : 'Waiting PNP'}
                </li>
              </Col>
            </>
          ) : detail?.inbound?.status === 'receiving_progress' ? (
            <>
              <Col md={3} style={{ fontSize: '14px' }}>
                <li>
                  Receiving By{' '}
                  {detail?.inbound?.action?.rnr?.name === null ? (
                    <text style={{ color: '#F89D1D' }}>---</text>
                  ) : (
                    <div class="dropdown">
                      <span>
                        <text style={{ color: '#F89D1D' }}>
                          {detail?.inbound?.action?.rnr?.name}
                        </text>{' '}
                      </span>
                      <div class="dropdown-content">
                        <small
                          style={{ cursor: 'pointer' }}
                          className="text-danger fw-bold"
                          onClick={() => handleRemove()}
                        >
                          <FontAwesomeIcon
                            icon={faCircleXmark}
                            className="text-danger me-2"
                          />
                          REMOVE
                        </small>
                      </div>
                    </div>
                  )}
                </li>
              </Col>
            </>
          ) : (
            <>
              <Col md={3} style={{ fontSize: '14px' }}>
                <li>{firstLetterUpperCase(detail?.inbound?.status)}</li>
              </Col>
            </>
          )}
        </Row>
        <Row className="mt-4">
          {['receiving_progress', 'waiting_om', 'put_away', 'done'].includes(
            detail?.inbound?.status
          ) && (
            <Col xs={4} md={4} lg={4}>
              <Button
                variant="transparent"
                onClick={() => handleDownloadArmada()}
                style={{ cursor: 'pointer' }}
              >
                <div className="d-flex flex-column flex-md-row flex-lg-row gap-2 gap-md-3 gap-lg-3">
                  <FontAwesomeIcon
                    icon={faFileArrowDown}
                    className="text-warning"
                  />
                  <small
                    style={{
                      fontSize: '14px',
                      cursor: 'pointer'
                    }}
                    className="text-center text-md-start text-lg-start"
                  >
                    {!loadingFile.armada ? 'Checklist Armada' : 'Download...'}
                  </small>
                </div>
              </Button>
            </Col>
          )}
          {['waiting_om', 'put_away', 'done'].includes(
            detail?.inbound?.status
          ) && (
            <Col xs={4} md={4} lg={4}>
              <Button
                variant="transparent"
                style={{ cursor: 'pointer' }}
                onClick={() => handleDownloadMaterial()}
              >
                <div className="d-flex flex-column flex-md-row flex-lg-row gap-2 gap-md-3 gap-lg-3">
                  <FontAwesomeIcon
                    icon={faFileArrowDown}
                    className="text-warning"
                  />
                  <small
                    style={{
                      fontSize: '14px'
                    }}
                    className="text-center text-md-start text-lg-start"
                  >
                    {!loadingFile.material
                      ? 'Checklist Materials'
                      : 'Download...'}
                  </small>
                </div>
              </Button>
            </Col>
          )}
          {detail?.inbound?.status === 'done' && (
            <Col xs={4} md={4} lg={4}>
              <Button
                variant="transparent"
                style={{ cursor: 'pointer' }}
                onClick={() => handleDownloadGoodReceipt()}
              >
                <div className="d-flex flex-column flex-md-row flex-lg-row gap-2 gap-md-3 gap-lg-3">
                  <FontAwesomeIcon
                    icon={faFileArrowDown}
                    className="text-warning"
                  />
                  <small
                    style={{
                      fontSize: '14px'
                    }}
                  >
                    {!loadingFile.goodReceipt ? 'Goods Receipt' : 'Download...'}
                  </small>
                </div>
              </Button>
            </Col>
          )}
        </Row>
        <Row className="mt-5">
          <Col xs={1} md={1} lg={1} className="text-center">
            {detail?.histories?.map((history, index) => (
              <div key={history.id} style={{ marginTop: '-4px' }}>
                <div style={circle} />
                <div style={veticalLine} />
              </div>
            ))}
          </Col>

          <Col>
            {detail?.histories?.map((history, index) => (
              <div key={history.id} className="mb-4">
                <h5 style={titleHistory}>
                  {convertDateWithTime(history.createdAt)}
                </h5>
                <h5 style={titleHistory}>
                  <small style={{ fontSize: '14px', marginRight: '5px' }}>
                    {history.notes}
                  </small>
                </h5>
              </div>
            ))}
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  )
}

export default PrDetailTimeline
