import { Col, Row } from 'react-bootstrap'
import Pagination from '../Pagination/Index'

function TablePagination(props) {
  const { meta, handleOnChangeMeta, handlePageChange } = props
  return (
    <div className="mt-3">
      <Row className="gap-2 gap-md-0 gap-lg-0">
        <Col md={4} xs={12}>
          <div className="d-flex flex-column justify-content-center h-100">
            <div className="d-flex flex-row justify-content-center justify-content-md-start gap-2 fs-6">
              <span className="mr-2">Show</span>
              <select
                name="per_page"
                className="form-select"
                aria-label="show-page"
                style={{ width: 'auto', marginTop: '-.5rem' }}
                value={meta.per_page}
                onChange={(e) => handleOnChangeMeta(e)}
              >
                {[10, 25, 50, 100].map((value) => (
                  <option key={value} value={value}>
                    {value}
                  </option>
                ))}
              </select>
              <span className="ml-2">
                Entries | {meta.current_page} to {meta.per_page} of{' '}
                {meta.total_row} entries
              </span>
            </div>
          </div>
        </Col>
        <Col md={8} xs={12}>
          <div className="d-flex justify-content-center justify-content-md-end justify-content-lg-end mt-2">
            <Pagination
              pageCount={meta.total_page}
              onPageChange={handlePageChange}
              page={meta.current_page}
            />
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default TablePagination
