import { faPencil, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import { useEffect, useState } from 'react'
import {
  Col,
  Container,
  Form,
  OverlayTrigger,
  Popover,
  Row,
  Spinner,
  Table
} from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import BreadCrumb from '../../components/Label/Breadcrumb/index'
import ModalAddMaterialRequest from '../../components/modal/MaterialRequest/ModalAddMaterialRequest'
import ModalUpdateMaterialRequest from '../../components/modal/MaterialRequest/ModalUpdateMaterialRequest'
import api from '../../config/AxiosInstance'
import * as Common from '../../utils/Common'
const CreateMaterialRequest = () => {
  const titleStyle = {
    fontSize: '12px',
    letterSpacing: '0.5px'
  }

  const btnOrange = {
    backgroundColor: 'var(--primary-orange)',
    borderColor: 'var(--primary-orange)',
    color: 'white'
  }

  const { id } = useParams()
  const navigate = useNavigate()
  const [detailMaterialRequest, setDetailMaterialRequest] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [loadingSubmit, setLoadingSubmit] = useState(false)
  const [pickupCommands, setPickupCommands] = useState({
    mr_code: id,
    series_number: null,
    items: []
  })
  const [seriesNumber, setSeriesNumber] = useState([])
  const [pickupCommandId, setPickupCommandId] = useState(null)
  const [showModalAdd, setShowModalAdd] = useState(false)
  const [showModalUpdate, setShowModalUpdate] = useState(false)

  useEffect(() => {
    fetchDetailMaterialRequest(id)
    fetchShowSeries()
  }, [id])
  const fetchDetailMaterialRequest = async (id) => {
    try {
      setIsLoading(true)
      const response = await api.post(`/material-request/detail`, {
        mr_code: id
      })
      const { data } = response
      if (data.success) {
        setDetailMaterialRequest(data.data)
        setPickupCommands((prevPickupCommand) => ({
          ...prevPickupCommand,
          items: data.data.pickup_commands
        }))
      }
    } catch (error) {
      const errMsg =
        error?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setIsLoading(false)
    }
  }
  const fetchShowSeries = async () => {
    try {
      const res = await api.post(`material-request/show-series`, {
        mr_code: id
      })
      const { data } = res
      if (data.success) {
        setSeriesNumber(data.data)
      }
    } catch (error) {
      const errMsg =
        error?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }
  const handlePickupCommands = (value, valueInput) => {
    if (valueInput.isEdit) {
      setPickupCommands((prevPickupCommand) => {
        return {
          ...prevPickupCommand,
          items: prevPickupCommand.items.map((item) => {
            if (
              item.item_id === valueInput.oldItemId &&
              item.item_batch_id === valueInput.oldItemBatchId
            ) {
              return value
            }
            return item
          })
        }
      })
    } else {
      setPickupCommands((prevPickupCommand) => {
        const itemExists = prevPickupCommand.items.some(
          (item) =>
            item.item_id === value.item_id &&
            item.item_batch_id === value.item_batch_id
        )

        if (itemExists) {
          // Update the existing item
          return {
            ...prevPickupCommand,
            items: prevPickupCommand.items.map((item) => {
              if (
                item.item_id === value.item_id &&
                item.item_batch_id === value.item_batch_id
              ) {
                return {
                  ...item,
                  slots: item.slots.map((slot) => ({
                    ...slot,
                    total_pack:
                      parseInt(slot.total_pack) + parseInt(valueInput.pack),
                    total_qty:
                      parseInt(slot.total_qty) + parseInt(valueInput.qty)
                  }))
                }
              }
              return item
            })
          }
        } else {
          // Add a new item
          return {
            ...prevPickupCommand,
            items: [...prevPickupCommand.items, value]
          }
        }
      })
    }
  }
  const handleRemovePickupCommands = (itemBatchId) => {
    setPickupCommands((prevPickupCommand) => {
      return {
        ...prevPickupCommand,
        items: prevPickupCommand.items.filter(
          (item) => item.item_batch_id !== itemBatchId
        )
      }
    })
  }
  const handleSubmit = () => {
    if (!pickupCommands.series_number) {
      Swal.fire({
        icon: 'error',
        text: 'Please select series number first',
        confirmButtonColor: '#f89d1d'
      })
      return
    }
    if (!pickupCommands.items.length) {
      Swal.fire({
        icon: 'error',
        text: 'Please add pickup command first',
        confirmButtonColor: '#f89d1d'
      })

      return
    }
    // compare length value item request with length value item pickup command
    const distinctItems = pickupCommands?.items?.reduce((acc, current) => {
      const x = acc.find((item) => item.item_id === current.item_id)
      if (!x) {
        return acc.concat([current])
      } else {
        return acc
      }
    }, [])

    const totalQtyRequest = detailMaterialRequest?.items?.reduce(
      (acc, cur) => (acc += parseInt(cur.total_qty)),
      0
    )
    const totalQtyPickupCommand = pickupCommands?.items
      ?.flatMap((item) => item.slots)
      .reduce((total, slot) => total + parseInt(slot.total_qty, 10), 0)

    if (distinctItems.length !== detailMaterialRequest.items.length) {
      Swal.fire({
        icon: 'error',
        text: 'Cant submit cause item pickup command not match item request',
        confirmButtonColor: '#f89d1d'
      })
      return
    }

    if (totalQtyPickupCommand > totalQtyRequest) {
      Swal.fire({
        icon: 'error',
        text: 'Quantity exceeds request!',
        confirmButtonColor: '#f89d1d'
      })
      return
    }

    if (totalQtyPickupCommand < totalQtyRequest) {
      Swal.fire({
        title: 'Pick Up less than Request!',
        text: `Continue making TR?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#f89d1d',
        confirmButtonText: 'Create',
        cancelButtonColor: 'transparent',

        iconColor: '#828282',
        customClass: {
          title: 'swal-title-outbound',
          popup: 'swal-popup-outbound',
          actions: 'swal-remove-outbound'
        }
      }).then(async (result) => {
        if (result.isConfirmed) {
          handleCreate()
        } else {
          return false
        }
      })
      return
    }
    handleCreate()
  }
  const handleCreate = async () => {
    try {
      setLoadingSubmit(true)
      const res = await api.post('/material-request/create_tr', pickupCommands)
      const { data } = res
      if (data.success) {
        navigate('/material-request', {
          state: { sendNotificationSuccess: true },
          replace: true
        })
      }
    } catch (error) {
      const errMsg =
        error?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setLoadingSubmit(false)
    }
  }
  if (isLoading) {
    return (
      <div>
        <Row>
          <Col md={12}>
            <div
              style={{ height: '100vh' }}
              className="d-flex flex-column justify-content-center"
            >
              <div className="d-flex flex-row justify-content-center">
                <div className="d-flex flex-column gap-3">
                  <div className="d-flex flex-row justify-content-center gap-2">
                    <Spinner animation="grow" variant="warning" />
                    <Spinner animation="grow" variant="warning" />
                    <Spinner animation="grow" variant="warning" />
                    <Spinner animation="grow" variant="warning" />
                    <Spinner animation="grow" variant="warning" />
                  </div>
                  <p
                    className="fw-bold text-center"
                    style={{ fontSize: '13px' }}
                  >
                    Loading...
                  </p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
  return (
    <>
      <Container>
        <Row className="mb-2">
          <Col>
            <BreadCrumb
              label={'Create TR'}
              link="/material-request/"
              back="Material Request"
            />
          </Col>
        </Row>
        <Row className="mb-4 d-flex flex-row gap-3 gap-md-0 gap-lg-0">
          <Col md={3}>
            <Form.Label
              style={titleStyle}
              className="fw-bold text-secondary text-uppercase"
              htmlFor={'series_number'}
            >
              <small>
                series <sup style={{ color: 'red' }}>*</sup>
              </small>
            </Form.Label>
            <Form.Select
              aria-label="Default select example"
              name="series_number"
              id="series_number"
              onChange={(e) => {
                setPickupCommands((prevPickupCommand) => ({
                  ...prevPickupCommand,
                  series_number: e.target.value
                }))
              }}
            >
              <option value="">Please select series</option>
              {seriesNumber?.map((series, index) => (
                <option key={series.name} value={series.name}>
                  {series.name}
                </option>
              ))}
            </Form.Select>
          </Col>
          <Col md={3}>
            <Form.Label
              style={titleStyle}
              className="fw-bold text-secondary text-uppercase"
            >
              <small>source warehouse</small>
            </Form.Label>
            <Form.Control
              disabled
              placeholder={detailMaterialRequest?.src_name ?? '-'}
            />
          </Col>
          <Col md={3}>
            <Form.Label
              style={titleStyle}
              className="fw-bold text-secondary text-uppercase"
            >
              <small>target warehouse</small>
            </Form.Label>
            <Form.Control
              disabled
              placeholder={detailMaterialRequest?.target_name ?? '-'}
            />
          </Col>
          <Col md={3}>
            <Form.Label
              style={titleStyle}
              className="fw-bold text-secondary text-uppercase"
            >
              <small>#ERP doc no.</small>
            </Form.Label>
            <Form.Control disabled placeholder={''} />
          </Col>
          <Col md={2} className="mt-2">
            <Form.Label
              style={titleStyle}
              className="fw-bold text-secondary text-uppercase"
            >
              <small>delivery date</small>
            </Form.Label>
            <Form.Control
              disabled
              placeholder={
                detailMaterialRequest?.delivery_date
                  ? moment(detailMaterialRequest?.delivery_date).format(
                      'DD-MM-YYYY'
                    )
                  : '-'
              }
            />
          </Col>
          <Col md={2} className="mt-2">
            <Form.Label
              style={titleStyle}
              className="fw-bold text-secondary text-uppercase"
            >
              <small>#MR</small>
            </Form.Label>
            <Form.Control disabled placeholder={detailMaterialRequest?.code} />
          </Col>
        </Row>
        <Row>
          <Col>
            <h5 className="text-secondary fw-semibold mb-3">REQUEST</h5>
            <Table
              hover
              size="m"
              responsive="sm"
              className="shadow-sm mb-5 bg-white rounded"
              style={{ fontSize: '13px' }}
            >
              <thead className="table-secondary">
                <tr>
                  <th className="p-3 align-middle">#</th>
                  <th className="p-3 align-middle">Item</th>
                  <th className="p-3 align-middle">Item Code</th>
                  <th className="p-3 align-middle text-end">Qty</th>
                </tr>
              </thead>
              <tbody>
                {!detailMaterialRequest?.items?.length && (
                  <tr key={1}>
                    <td colSpan={4} className="text-center fw-bold">
                      No Data
                    </td>
                  </tr>
                )}
                {detailMaterialRequest?.items?.length > 0 &&
                  detailMaterialRequest?.items.map((item, index) => (
                    <tr key={item.id}>
                      <td className="p-3">{index + 1}</td>
                      <td className="p-3">{item.item_name}</td>
                      <td className="p-3">{item.item_code}</td>
                      <td className="p-3 text-end">
                        {item.total_qty} {item.uom_qty}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col className="d-flex justify-content-between">
            <h5 className="text-secondary fw-semibold mb-3">PICK UP COMMAND</h5>
            <button
              style={btnOrange}
              className="btn"
              onClick={() => setShowModalAdd(true)}
            >
              Add
            </button>
            <ModalAddMaterialRequest
              show={showModalAdd}
              onHide={() => setShowModalAdd(false)}
              items={detailMaterialRequest?.items}
              warehouseId={detailMaterialRequest?.warehouse_id}
              pickupCommands={pickupCommands}
              handlePickupCommands={handlePickupCommands}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Table
              hover
              size="m"
              responsive="sm"
              className="shadow-sm mb-5 bg-white rounded"
              style={{ fontSize: '13px' }}
            >
              <thead className="table-secondary">
                <tr>
                  <th className="p-3 align-middle">#</th>
                  <th className="p-3 align-middle">Item</th>
                  <th className="p-3 align-middle">Item Code</th>
                  <th className="p-3 align-middle text-end">Batch</th>
                  <th className="p-3 align-middle text-end">Expired</th>
                  <th className="p-3 align-middle text-end">Total Qty</th>
                  <th className="p-3 align-middle text-end">Total Pack</th>
                  <th className="p-3 align-middle">Location</th>
                  <th className="p-3 align-middle">Action</th>
                </tr>
              </thead>
              <tbody>
                {pickupCommands?.items.length === 0 && (
                  <tr key={1}>
                    <td colSpan={9} className="text-center fw-bold">
                      No Data
                    </td>
                  </tr>
                )}
                {pickupCommands?.items?.length > 0 &&
                  pickupCommands?.items?.map((item, index) => (
                    <tr key={item.id}>
                      <td className="p-3">{index + 1}</td>
                      <td className="p-3">{item.item_name}</td>
                      <td className="p-3">{item.item_code}</td>
                      <td className="p-3 text-end">{item.batch}</td>
                      <td className="p-3 text-end">
                        {moment(item.exp_date).format('DD-MM-YYYY')}
                      </td>
                      <td className="p-3 text-end">
                        {item.slots.reduce(
                          (acc, cur) => acc + parseInt(cur.total_qty),
                          0
                        )}{' '}
                        {item.slots[0].uom_qty}
                      </td>
                      <td className="p-3 text-end">
                        {item.slots.reduce(
                          (acc, cur) => acc + parseInt(cur.total_pack),
                          0
                        )}
                      </td>
                      <td className="p-3">
                        <OverlayTrigger
                          trigger={['hover', 'click']}
                          key={'bottom'}
                          placement={'bottom'}
                          overlay={
                            <Popover id={`popover-positioned-bottom`}>
                              <Popover.Body>
                                {item.slots
                                  .reduce((acc, cur) => {
                                    acc.push(cur.current_location)
                                    return acc
                                  }, [])
                                  .join(', ')}
                              </Popover.Body>
                            </Popover>
                          }
                        >
                          <p
                            className="text-truncate text-primary"
                            style={{ maxWidth: '80%' }}
                          >
                            {item.slots
                              .reduce((acc, cur) => {
                                acc.push(cur.current_location)
                                return acc
                              }, [])
                              .join(', ')}
                          </p>
                        </OverlayTrigger>
                      </td>
                      <td className="p-3">
                        <div className="d-flex flex-row gap-2 justify-content-end">
                          <button
                            className="btn btn-danger"
                            onClick={() =>
                              handleRemovePickupCommands(item.item_batch_id)
                            }
                            style={{ cursor: 'pointer' }}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </button>
                          <button
                            className="btn btn-secondary"
                            onClick={() => {
                              setShowModalUpdate(true)
                              setPickupCommandId(item.item_batch_id)
                            }}
                          >
                            <FontAwesomeIcon icon={faPencil} />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className="d-flex flex-row justify-content-end">
              <button
                style={btnOrange}
                className="btn"
                onClick={() => handleSubmit()}
                disabled={loadingSubmit}
              >
                {loadingSubmit ? 'Loading...' : 'Submit'}
              </button>
            </div>
          </Col>
        </Row>
      </Container>
      <ModalUpdateMaterialRequest
        show={showModalUpdate}
        onHide={() => setShowModalUpdate(false)}
        items={detailMaterialRequest?.items}
        warehouseId={detailMaterialRequest?.warehouse_id}
        pickupCommands={pickupCommands}
        handlePickupCommands={handlePickupCommands}
        findPickupCommand={pickupCommands?.items?.find((pickupCommand) => {
          return pickupCommand.item_batch_id === pickupCommandId
        })}
      />
    </>
  )
}

export default CreateMaterialRequest
