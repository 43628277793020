import { CloseButton, Modal } from 'react-bootstrap'
import api from '../../../config/AxiosInstance'
import * as Common from '../../../utils/Common'
import { firstLetterUpperCase } from '../../../utils/TextFormatter'
import FormGround from './forms/FormGround'
import FormRack from './forms/FormRack'
import FormStagingArea from './forms/FormStagingArea'
function ModalMapEditor(props) {
  const handleCloseButton = () => {
    props.onHide()
    document.querySelector(".leaflet-interactive[stroke='#3388ff']")?.remove()
  }
  const handleSubmit = async (data) => {
    try {
      let res = ''
      // override value form submit
      if (data.staging_id) {
        const values = {
          staging_id: data.staging_id.value,
          blok_id: data.blok_id.value,
          coordinates: props.rectangleBoundValue
        }
        res = await api.post(`settings/map/editor/staging/add`, values)
      } else {
        const values = {
          rack_id: data.rack.value,
          beam_id: data.beam.value,
          slot_id: data.slot.value ?? '',
          type: props.selectLevel === 1 ? data.type : '',
          type_id:
            props.selectLevel === 1
              ? data.type_id.reduce((acc, cur) => {
                  acc.push(cur.value)
                  return acc
                }, [])
              : [],
          area_id: data.area.value ?? '',
          active: data.activeToggle,
          coordinates: props.rectangleBoundValue
        }

        res = await api.post(`/settings/map/editor/slot/add`, values)
      }

      if (res?.data) {
        props.onHandleListMapEditor()
        props.onHide()
        document
          .querySelector(".leaflet-interactive[stroke='#3388ff']")
          ?.remove()
        Common.showPopUpSuccessMessage(res?.data?.message)
      }
    } catch (error) {
      const errMsg =
        error?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }
  return (
    <>
      <Modal {...props} backdrop={'static'} size="lg" centered>
        <Modal.Header>
          <Modal.Title>
            {props.selectLevel === 1 && 'Define Area'}
            {props.selectLevel === 'ground' && 'Define Ground Area'}
            {props.selectLevel === 'staging-area' && 'Define Staging Area'}
            {[
              'vas',
              'vas_pharma',
              'transit_vas',
              'transit_vas_pharma'
            ].includes(props.selectLevel) &&
              `Define ${firstLetterUpperCase(
                props.selectLevel.replaceAll('-', ' ')
              )}`}
          </Modal.Title>
          <CloseButton onClick={() => handleCloseButton()} />
        </Modal.Header>
        <Modal.Body>
          {props.selectLevel === 'staging-area' && (
            <FormStagingArea
              handleCloseButton={handleCloseButton}
              onHandleSubmit={handleSubmit}
              {...props}
            />
          )}
          {props.selectLevel === 1 && (
            <FormRack
              handleCloseButton={handleCloseButton}
              onHandleSubmit={handleSubmit}
              {...props}
            />
          )}
          {props.selectLevel === 'ground' && (
            <FormGround
              handleCloseButton={handleCloseButton}
              onHandleSubmit={handleSubmit}
              {...props}
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ModalMapEditor
