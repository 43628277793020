import { faPlusSquare } from '@fortawesome/free-regular-svg-icons'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Formik } from 'formik'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { Col, Form, Modal, Row } from 'react-bootstrap'
import api from '../../../config/AxiosInstance'
import * as Common from '../../../utils/Common'
import { updateCOA } from '../../../utils/validationSchema'
import CustomButton from '../../Button/CustomButton'
import DatePickerComponent from '../../Input/DatePicker'
function ModalUpdateItemCoaPrDraftDetail(props) {
  const { selecteditemcoa } = props
  const title = {
    fontSize: '9px',
    lineHeight: '12px',
    color: '#828282'
  }
  const [file, setFile] = useState(false)
  const fileInputSim = useRef(null)
  const [fileDocCoa, setFileDocCoa] = useState({})

  useEffect(() => {
    selecteditemcoa?.coa !== ''
      ? setFile(selecteditemcoa?.coa_doc?.split('/').pop()) &&
        setFileDocCoa(selecteditemcoa?.coa_doc?.split('/').pop())
      : setFile(false)
  }, [props.show, selecteditemcoa])

  const filehandleFileCoa = (e) => {
    const size = e.target.files[0].size / (1024 * 1024)
    const typeFile = e.target.files[0].type

    if (size > 10) {
      Common.showPopUpErrorMessage('Cant upload file more than 10 MB')
    } else if (typeFile !== 'application/pdf') {
      Common.showPopUpErrorMessage('File upload PDF')
    } else {
      setFile(e.target.files[0].name)
      setFileDocCoa(e.target.files[0])
    }
  }

  const handleSubmit = async (values) => {
    try {
      const formData = new FormData()
      // override data file upload to key docs
      formData.append('coa_file', fileDocCoa)
      formData.append('coa_pd', values.coa_pd)
      formData.append('coa_ed', values.coa_ed)
      formData.append('label_ed', values.label_ed)
      formData.append('label_pd', values.label_pd)
      const res = await api.postForm(
        `/inbound/pr-draft/coa/${selecteditemcoa.id}`,
        formData
      )

      if (res.data.success) {
        Common.showPopUpSuccessMessage(res.data.message)
        props.onHide()
        props.refreshdata()
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }

  return (
    <Modal {...props} centered>
      <Modal.Header closeButton>
        <Modal.Title>Update COA</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={{
            id: selecteditemcoa.id,
            coa_pd: selecteditemcoa.coa_pd,
            coa_ed: selecteditemcoa.coa_ed,
            label_ed: selecteditemcoa.label_ed,
            label_pd: selecteditemcoa.label_pd
          }}
          enableReinitialize={true}
          validateOnChange={true}
          validateOnBlur={true}
          validationSchema={updateCOA}
          onSubmit={(values, { resetForm }) => {
            values.coa_pd = moment(values.coa_pd).format('YYYY-MM-DD')
            values.coa_ed = moment(values.coa_ed).format('YYYY-MM-DD')
            values.label_pd = moment(values.label_pd).format('YYYY-MM-DD')
            values.label_ed = moment(values.label_ed).format('YYYY-MM-DD')
            handleSubmit(values)
            resetForm()
          }}
        >
          {({
            handleBlur,
            handleSubmit,
            handleChange,
            values,
            errors,
            touched,
            isValid,
            setFieldValue,
            setFieldTouched
          }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col md={12}>
                    <Form.Label className="fw-bold">
                      <h4>{selecteditemcoa?.item}</h4>
                      <small className="text-muted">
                        {selecteditemcoa?.batch}
                      </small>
                    </Form.Label>
                  </Col>
                  <Col md={12} className="mt-3">
                    <Row>
                      <Col md={12}>
                        <Form.Label className="fw-bold">
                          <small style={title}>
                            COA<sup style={{ color: 'red' }}>*</sup>
                          </small>
                        </Form.Label>
                      </Col>
                      <Col>
                        <Row>
                          {!file && (
                            <div style={{ marginLeft: '.2rem' }}>
                              <FontAwesomeIcon
                                icon={faPlusSquare}
                                style={{ fontSize: '22px' }}
                                onClick={() => fileInputSim.current.click()}
                                className="cursor-pointer"
                                role="button"
                              />
                              <input
                                type="file"
                                onChange={filehandleFileCoa}
                                ref={fileInputSim}
                                hidden
                                name="fileName"
                                accept="application/pdf"
                              />
                            </div>
                          )}
                          {file && (
                            <div
                              style={{
                                padding: '.7rem',
                                borderRadius: '3px',
                                border: '1px solid #F1F2F7',
                                width: '95%',
                                marginLeft: '.8rem'
                              }}
                            >
                              <div className="d-flex flex-row gap-2">
                                <div className="d-flex flex-column justify-content-center">
                                  <FontAwesomeIcon
                                    icon={faXmark}
                                    style={{
                                      fontSize: '16px'
                                    }}
                                    className="text-muted cursor-pointer"
                                    role="button"
                                    onClick={() => {
                                      setFile(false)
                                      setFileDocCoa(false)
                                    }}
                                  />
                                </div>
                                <span>{file}</span>
                              </div>
                            </div>
                          )}
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={12} className="mt-3">
                    <Row>
                      <DatePickerComponent
                        label="md"
                        name="coa_pd"
                        value={values.coa_pd}
                        handleChange={setFieldValue}
                        handleBlur={() => setFieldTouched('coa_pd', true)}
                        errors={errors}
                        touched={touched}
                        col={6}
                        required
                        disabled={
                          selecteditemcoa.coa_status === 'match' ||
                          selecteditemcoa.coa_status === 'unchecked'
                            ? true
                            : false
                        }
                      />
                      <DatePickerComponent
                        label="ed"
                        name="coa_ed"
                        value={values.coa_ed}
                        handleChange={setFieldValue}
                        handleBlur={() => setFieldTouched('coa_ed', true)}
                        errors={errors}
                        touched={touched}
                        col={6}
                        required
                        disabled={
                          selecteditemcoa.coa_status === 'match' ||
                          selecteditemcoa.coa_status === 'unchecked'
                            ? true
                            : false
                        }
                      />
                      {['match', 'not_match'].includes(
                        selecteditemcoa?.coa_status
                      ) && (
                        <>
                          <DatePickerComponent
                            label="md"
                            name="label_pd"
                            value={values.label_pd}
                            handleChange={setFieldValue}
                            handleBlur={() => setFieldTouched('label_pd', true)}
                            errors={errors}
                            touched={touched}
                            col={6}
                            required
                            disabled={
                              selecteditemcoa.coa_status === 'match' ||
                              selecteditemcoa.coa_status === 'uncheck'
                                ? true
                                : false
                            }
                          />
                          <DatePickerComponent
                            label="ed"
                            name="label_ed"
                            value={values.label_ed}
                            handleChange={setFieldValue}
                            handleBlur={() => setFieldTouched('label_ed', true)}
                            errors={errors}
                            touched={touched}
                            col={6}
                            required
                            disabled={
                              selecteditemcoa.coa_status === 'match' ||
                              selecteditemcoa.coa_status === 'uncheck'
                                ? true
                                : false
                            }
                          />
                        </>
                      )}
                    </Row>
                  </Col>
                </Row>
                <Modal.Footer className="mt-3">
                  <CustomButton
                    onClick={handleSubmit}
                    variant={
                      !isValid ||
                      !file ||
                      selecteditemcoa?.coa_status === 'match'
                        ? 'secondary'
                        : 'warning'
                    }
                    disabled={
                      !isValid ||
                      !file ||
                      selecteditemcoa?.coa_status === 'match'
                    }
                    text={'Update'}
                  />
                </Modal.Footer>
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  )
}

export default ModalUpdateItemCoaPrDraftDetail
