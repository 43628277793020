import { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
import Select from 'react-select'
import api from '../../../config/AxiosInstance'
import * as Common from '../../../utils/Common'
function ModalSelectFormat(props) {
  const {
    handleClose,
    setSelectFormatDownload,
    selectFormatDownload,
    handleDownloadPrint,
    loadingFormat,
    show
  } = props
  const [optionFormats, setOptionFormats] = useState([])
  useEffect(() => {
    if (selectFormatDownload?.id) {
      fetchFormats()
    }
  }, [selectFormatDownload?.id, show])
  const fetchFormats = async () => {
    try {
      let url = ''
      if (selectFormatDownload?.type === 'sj') {
        url = await api.get(`/outbound/sjdraft/print-format`)
      }
      if (selectFormatDownload?.type === 'tr') {
        url = await api.get(`/outbound/transfer/print-format`)
      }
      const res = url
      const { data } = res
      if (data.success) {
        const options = data.data.reduce((acc, data) => {
          let obj = {
            value: data.name,
            label: data.name
          }
          acc.push(obj)
          return acc
        }, [])
        setOptionFormats(options)
      }
    } catch (error) {
      const err = error?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(err)
    }
  }

  return (
    <>
      <Modal {...props} centered onHide={() => handleClose()}>
        <Modal.Header closeButton>
          <Modal.Title>Select Format</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label className="text-muted mb-2 text-uppercase">Format</label>
          <Select
            onChange={(e) => {
              setSelectFormatDownload((prevSelectFormatDownload) => ({
                ...prevSelectFormatDownload,
                format: e.value
              }))
            }}
            options={optionFormats}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="transparent" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="warning"
            className="text-white"
            onClick={() =>
              handleDownloadPrint(
                selectFormatDownload.type,
                selectFormatDownload.action,
                selectFormatDownload.id
              )
            }
            disabled={!selectFormatDownload.format || loadingFormat}
          >
            {loadingFormat ? 'Loading...' : 'Select'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default ModalSelectFormat
