import Pusher from 'pusher-js'
import React, { useContext, useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Outlet } from 'react-router-dom'
import Swal from 'sweetalert2'
import ModalChangePassword from '../../components/modal/changepassword/ModalChangePassword'
import Notification from '../../components/Notifications/Notification'
import { UserContext } from '../../context/userContext'
import Drawer from './Drawer'
import DrawerChangeWarehouse from './DrawerChangeWarehouse'
import NavbarPage from './Navbar'
import Sidebar from './Sidebar'
const Dashboard = () => {
  const [collapsed, setCollapsed] = useState(true)
  const [state, dispatch] = useContext(UserContext)
  const [note, setNote] = useState(false)
  const [indicator, setIndicator] = useState(false)
  const [width, setWidth] = useState(window.innerWidth)
  const [showCanvas, setShowCanvas] = useState(false)
  const [showCanvasWarehouse, setShowCanvasWarehouse] = useState(false)
  const [showModalChangePassword, setShowModalChangePassword] = useState(false)
  const pusher = new Pusher('b4fd69a93b89d910f024', {
    cluster: 'ap1'
  })
  var channelNoticationIndicator = pusher.subscribe(
    `notif-om-${state.username?.roles[0].warehouse_id}-indicator`
  )
  var channelNewNotification = pusher.subscribe(
    `notif-om-${state.username?.roles[0].warehouse_id}`
  )
  channelNewNotification.bind(`new-notification`, function (data) {
    setNote(data)
  })
  channelNoticationIndicator.bind(`notification-indicator`, function (data) {
    setIndicator(Boolean(data))
  })

  useEffect(() => {
    if (note) {
      const timeout = setTimeout(() => {
        setNote(false)
      }, 3000)
      return () => clearTimeout(timeout)
    }
  }, [note])
  useEffect(() => {
    setWidth(window.innerWidth)
  }, [width])
  const handleCloseCanvas = () => {
    setShowCanvas(false)
  }
  const handleLogOut = () => {
    Swal.fire({
      title: 'Are you sure to log out?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Log out',
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch({ type: 'logout' })
      }
    })
  }
  return (
    <>
      <Container
        fluid
        className="p-0"
        style={{ overflowX: 'hidden', height: '100vh' }}
      >
        <Row style={{ height: '100vh' }}>
          <Col
            xs={collapsed ? 3 : 3}
            md={collapsed ? 2 : 1}
            className={`shadow-sm bg-white rounded ${
              width < 768 ? 'd-none' : ''
            }`}
            fixed="true"
          >
            <Sidebar collapsed={collapsed} style={{ overflowX: 'hidden' }} />
          </Col>
          <Col md={collapsed ? 10 : 11} xs={12}>
            <Row>
              <NavbarPage
                onClick={() => setCollapsed(!collapsed)}
                test={collapsed}
                indicator={indicator}
                width={width}
                setShowCanvas={setShowCanvas}
                setShowCanvasWarehouse={setShowCanvasWarehouse}
                usernameProp={state}
              />
            </Row>
            <Row className="mt-3 p-3">
              {note && (
                <Notification
                  text={note?.message}
                  handleClose={() => setNote(null)}
                  link={note?.link}
                />
              )}
              <Outlet />
            </Row>
            <Row className="p-3">
              <Col md={6} xs={6} className="text-start">
                <small>@2024 Bahtera Adi Jaya</small>
              </Col>
              <Col md={6} xs={6} className="text-end">
                <small>
                  Powered by <strong className="text-danger">RAPIER</strong>
                </small>
              </Col>
            </Row>
            <Drawer
              showCanvas={showCanvas}
              handleCloseCanvas={handleCloseCanvas}
            />
            <DrawerChangeWarehouse
              showCanvasWarehouse={showCanvasWarehouse}
              handleCloseCanvasWarehouse={() => setShowCanvasWarehouse(false)}
              setShowModalChangePassword={setShowModalChangePassword}
              handleLogOut={handleLogOut}
            />
          </Col>
        </Row>
      </Container>
      <ModalChangePassword
        show={showModalChangePassword}
        onHide={() => setShowModalChangePassword(false)}
      />
    </>
  )
}

export default Dashboard
