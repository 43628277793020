import { faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Table } from 'react-bootstrap'
import { ActiveInActive } from '../atoms/StatusLabel'
import TableAction from '../atoms/Table/TableAction'
import TablePagination from '../atoms/Table/TablePagination'

const RentTable = ({
  dataRent,
  handleEdit,
  meta,
  handleOnChangeMeta,
  pageCount,
  handlePageChange
}) => {
  return (
    <Table
      responsive="sm"
      hover
      className="shadow-sm mb-5 bg-white rounded"
      size="sm"
      style={{ fontSize: '14px' }}
    >
      <thead className="table-secondary">
        <tr>
          <th className="p-2 align-middle">Name</th>
          <th className="p-2 align-middle">Address</th>
          <th className="p-2 align-middle">Phone Number</th>
          <th className="p-2 align-middle text-end">Status</th>
          <th className="p-2 align-middle text-end">Action</th>
        </tr>
      </thead>
      <tbody>
        {dataRent.map((data, i) => {
          return (
            <tr key={i}>
              <td className="p-2 align-middle">{data?.name}</td>
              <td className="p-2 align-middle">{data?.address}</td>
              <td className="p-2 align-middle">{data?.phone}</td>
              <td className="p-2 align-middle text-end">
                <ActiveInActive text={data.status ? 'active' : 'inactive'} />
              </td>
              <td className="p-2 align-middle text-end">
                <TableAction>
                  <button
                    className="btn btn-secondary ml-2"
                    onClick={() => handleEdit(data)}
                  >
                    <FontAwesomeIcon
                      icon={faPenToSquare}
                      style={{ fontSize: '15px', rotate: '90deg' }}
                      className="fa-flip-horizontal"
                    />
                  </button>
                </TableAction>
              </td>
            </tr>
          )
        })}
      </tbody>
      <tfoot>
        <tr>
          <td className="p-3" colSpan={5}>
            <TablePagination
              meta={meta}
              handleOnChangeMeta={handleOnChangeMeta}
              handlePageChange={handlePageChange}
            />
          </td>
        </tr>
      </tfoot>
    </Table>
  )
}

export default RentTable
