import React, { useContext } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Outlet, useLocation } from 'react-router-dom'
import { UserContext } from '../../context/userContext'
import '../../styles/style.css'
import NavbarMover from './NavbarMover'

const DashboardMover = () => {
  const [state] = useContext(UserContext)
  const { pathname } = useLocation()
  const containerClass =
    pathname === '/mover/detail/'
      ? 'p-0 min-vh-100 half-background-detail-mover'
      : 'p-0 min-vh-100'

  return (
    <Container fluid className={containerClass}>
      <div style={{ overflowX: 'hidden' }}>
        <Row>
          <Col>
            <Row>
              <NavbarMover user={state.username} />
            </Row>
            <Row className="mt-4 ms-2 me-2">
              <Outlet />
            </Row>
          </Col>
        </Row>
      </div>
    </Container>
  )
}

export default DashboardMover
