import { faPencil, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import {
  Card,
  Col,
  Container,
  Form,
  Row,
  Spinner,
  Table
} from 'react-bootstrap'
import { Link, useParams, useSearchParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import { BadgeStatusPrListInbounds } from '../../../components/atoms/StatusLabel'
import CustomButton from '../../../components/Button/CustomButton'
import Breadcrumb from '../../../components/Label/Breadcrumb'
import Label from '../../../components/Label/Input'
import ModalAddUpdateItem from '../../../components/modal/Sj/ModalAddUpdateItem'
import ModalSelectFormat from '../../../components/modal/Sj/ModalSelectFormat'
import ModalSolveContinueTicket from '../../../components/modal/Sj/ModalSolveContinueTicket'
import api from '../../../config/AxiosInstance'
import {
  convertDateWithoutTime,
  convertDateWithTime
} from '../../../helpers/constants'
import * as Common from '../../../utils/Common'
import { firstLetterUpperCase } from '../../../utils/TextFormatter'
import ActionButton from '../Plan/ActionButton'
import styles from '../Plan/outbound_plan.module.css'
const headerTitleStyle = {
  color: 'var(--primary-orange)',
  fontSize: '24px',
  fontWeight: '600'
}
const DetailTransfer = () => {
  const { id } = useParams()
  const [detail, setDetail] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const [showModalAddUpdateItem, setShowModalAddUpdateItem] = useState(false)
  const [showModalSolveContinueTicket, setShowModalSolveContinueTicket] =
    useState(false)
  const [submitSolveContinueTicket, setSubmitSolveContinueTicket] =
    useState(false)
  const [requestCommand, setRequestCommand] = useState([])
  const [pickupCommand, setPickupCommand] = useState([])
  const [loadingDownloadPrint, setDownloadPrint] = useState({
    coaDownload: false,
    coaPrint: false,
    trDownload: false,
    trPrint: false
  })
  const [showModalSelectFormat, setShowModalSelectFormat] = useState(false)
  const [selectFormatDownload, setSelectFormatDownload] = useState({
    type: 'tr',
    format: null,
    action: null,
    id: null
  })
  const [loadingFormat, setLoadingFormat] = useState(false)
  const [editData, setEditData] = useState({
    type: 'create',
    command_id: null,
    item_code: null,
    item_alias: null,
    item_batch_id: null,
    total_pack: null,
    exp_date: null,
    qty: 0
  })
  const titleStyle = {
    fontSize: '12px',
    letterSpacing: '0.5px'
  }

  useEffect(() => {
    fetchDetailTransfer()
  }, [id])
  const fetchDetailTransfer = async () => {
    try {
      setIsLoading(true)
      const res = await api.get(`/outbound/transfer/detail/${id}`)
      const { data } = res
      if (data.success) {
        setDetail(data.data)
        setRequestCommand(data.data.requests)
        setPickupCommand(data.data.pickup_command)
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setIsLoading(false)
    }
  }
  const handleSubmitSolveContinueTicket = async (type = 'direct') => {
    try {
      setSubmitSolveContinueTicket(true)
      const res = await api.post(`outbound/sjdraft/${detail?.id}/solve-problem`)
      if (res.data.success) {
        // call to load data again
        fetchDetailTransfer()
      }
    } catch (error) {
      const err = error?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(err)
    } finally {
      setSubmitSolveContinueTicket(false)
      if (type === 'modal') {
        setShowModalSolveContinueTicket(false)
      }
    }
  }
  const handleDeleteAddItemPickupCommand = async (commandId) => {
    try {
      const res = await api.delete(`outbound/sjdraft/${id}/delete/${commandId}`)
      if (res.data.success) {
        fetchDetailTransfer()
      }
    } catch (error) {
      const err = error?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(err)
    }
  }
  const handleSendToErp = () => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-primary-orange',
        cancelButton: 'btn btn-transparent',
        actions:
          'd-flex w-100 justify-content-end pt-4 ps-4 pe-4 separator-border-action-sweetalert'
      },
      buttonsStyling: false
    })
    swalWithBootstrapButtons
      .fire({
        title: 'Send to ERP?',
        text: 'This action can’t be repeated!',
        icon: 'warning',
        iconColor: '#828282',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Send!',
        cancelButtonText: 'Cancel',
        allowOutsideClick: false,
        reverseButtons: true,
        showCloseButton: true
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          // call function send to erp
          sendToErp()
        } else {
          return false
        }
      })
  }
  const sendToErp = async () => {
    try {
      const res = await api.post(`outbound/transfer/${detail?.id}/send-erp`)
      const { data } = res
      if (data.success) {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Send to erp success'
        })
      }
    } catch (error) {
      const err = error?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(err)
    } finally {
      fetchDetailTransfer()
    }
  }
  const handleDownloadPrint = async (type, typeAction, id) => {
    try {
      if (type !== 'tr') {
        setDownloadPrint((downloadPrint) => {
          return {
            ...downloadPrint,
            [type + firstLetterUpperCase(typeAction)]: true
          }
        })
      } else {
        setLoadingFormat(true)
      }
      if (type === 'tr') {
        const res = await api.get(
          `/outbound/transfer/file-tr/${id}?print=${encodeURIComponent(
            selectFormatDownload?.format
          )}`,
          {
            responseType: 'arraybuffer'
          }
        )
        const { status, data } = res
        if (status === 200) {
          const blob = new Blob([data], { type: 'application/pdf' })
          const url = window.URL.createObjectURL(blob)
          if (typeAction === 'download') {
            // Create a link element, set the href to the URL, and trigger a download
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `file-tr-${id}.pdf`) // Set the file name
            document.body.appendChild(link)
            link.click() // Trigger the download
            // Clean up
            document.body.removeChild(link)
            window.URL.revokeObjectURL(url)
          }
          if (typeAction === 'print') {
            window.open(url)
          }
        }
      }
      if (type === 'coa') {
        const res = await api.get(`/outbound/sjdraft/file-coa/${id}`, {
          responseType: 'arraybuffer'
        })
        const { status, data } = res
        if (status === 200) {
          const blob = new Blob([data], { type: 'application/pdf' })
          const url = window.URL.createObjectURL(blob)
          if (typeAction === 'download') {
            // Create a link element, set the href to the URL, and trigger a download
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `file-coa-${id}.pdf`) // Set the file name
            document.body.appendChild(link)
            link.click() // Trigger the download
            // Clean up
            document.body.removeChild(link)
            window.URL.revokeObjectURL(url)
          }
          if (typeAction === 'print') {
            window.open(url)
          }
        }
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    } finally {
      if (type === 'tr') {
        setLoadingFormat(false)
        setShowModalSelectFormat(false)
        setSelectFormatDownload((prevSelectFormatDownload) => ({
          ...prevSelectFormatDownload,
          id: null,
          format: null
        }))
      } else {
        setDownloadPrint((downloadPrint) => {
          return {
            ...downloadPrint,
            [type + firstLetterUpperCase(typeAction)]: false
          }
        })
      }
    }
  }
  if (isLoading) {
    return (
      <>
        <div>
          <Row>
            <Col md={12}>
              <div
                style={{ height: '100vh' }}
                className="d-flex flex-column justify-content-center"
              >
                <div className="d-flex flex-row justify-content-center">
                  <div className="d-flex flex-column gap-3">
                    <div className="d-flex flex-row justify-content-center gap-2">
                      <Spinner animation="grow" variant="warning" />
                      <Spinner animation="grow" variant="warning" />
                      <Spinner animation="grow" variant="warning" />
                      <Spinner animation="grow" variant="warning" />
                      <Spinner animation="grow" variant="warning" />
                    </div>
                    <p
                      className="fw-bold text-center"
                      style={{ fontSize: '13px' }}
                    >
                      Loading...
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </>
    )
  }
  return (
    <>
      <Container>
        <Row className="mb-2">
          <Col md={4} className="mt-3">
            {!searchParams.get('outboundId') && (
              <Breadcrumb
                label={detail?.draft_code}
                link="/outbound/transfer"
                back="TR"
                type="BadgeStatusPrListInbounds"
                status={detail?.status}
              />
            )}
            {searchParams.get('outboundId') &&
              searchParams.get('outboundCode') && (
                <>
                  <h4 style={headerTitleStyle}>
                    {detail?.draft_code}{' '}
                    <sup style={{ fontSize: '12px', marginLeft: '5px' }}>
                      {<BadgeStatusPrListInbounds text={detail?.status} />}
                    </sup>
                  </h4>
                  <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item sj-item">
                        <Link
                          to={'/outbound/transfer'}
                          className="text-decoration-none"
                        >
                          <span className="text-black fw-bold text-uppercase">
                            Outbound
                          </span>
                        </Link>
                      </li>
                      <li className="breadcrumb-item sj-item">
                        <Link
                          to={`/outbound/plan/detail/${searchParams.get(
                            'outboundId'
                          )}`}
                          className="text-decoration-none"
                        >
                          <span className="text-black fw-bold text-uppercase">
                            {searchParams.get('outboundCode')}
                          </span>
                        </Link>
                      </li>
                      <li
                        className="breadcrumb-item sj-item active"
                        aria-current="page"
                      >
                        {detail?.draft_code}
                      </li>
                    </ol>
                  </nav>
                </>
              )}
          </Col>
          {/* {(detail?.status === 'submit' || detail?.status === 'problem') && ( */}
          {detail?.status !== 'unassigned' && (
            <ActionButton>
              <div
                style={{ marginTop: '2.3rem' }}
                className="d-flex flex-row gap-2"
              >
                {detail?.status === 'problem' && (
                  <CustomButton
                    variant={'transparent'}
                    style={{ backgroundColor: '#F89D1D' }}
                    text={'Solve problem and continue ticket'}
                    onClick={() => setShowModalSolveContinueTicket(true)}
                  />
                )}
                {['done', 'ongoing'].includes(detail?.status) && (
                  <>
                    <CustomButton
                      variant={'transparent'}
                      style={{
                        backgroundColor: '#F89D1D',
                        display: `${
                          detail?.status === 'ongoing' ? 'none' : 'block'
                        }`
                      }}
                      text={
                        loadingDownloadPrint.trDownload
                          ? 'Downloading...'
                          : 'Download TR'
                      }
                      onClick={() => {
                        // handleDownloadPrint('tr', 'download', detail?.id)
                        setShowModalSelectFormat(true)
                        setSelectFormatDownload((prevSelectFormatDownload) => ({
                          ...prevSelectFormatDownload,
                          id: detail?.id,
                          action: 'download'
                        }))
                      }}
                    />
                    <CustomButton
                      variant={'transparent'}
                      style={{
                        backgroundColor: '#203568',
                        display: `${
                          detail?.status === 'ongoing' ? 'none' : 'block'
                        }`
                      }}
                      text={
                        loadingDownloadPrint.trPrint
                          ? 'Downloading...'
                          : 'Print TR'
                      }
                      onClick={() => {
                        // handleDownloadPrint('tr', 'print', detail?.id)
                        setShowModalSelectFormat(true)
                        setSelectFormatDownload((prevSelectFormatDownload) => ({
                          ...prevSelectFormatDownload,
                          id: detail?.id,
                          action: 'print'
                        }))
                      }}
                    />
                    <CustomButton
                      variant={'transparent'}
                      style={{ backgroundColor: '#F89D1D' }}
                      text={
                        loadingDownloadPrint.coaDownload
                          ? 'Downloading...'
                          : 'Download C.O.A'
                      }
                      onClick={() =>
                        handleDownloadPrint('coa', 'download', detail?.id)
                      }
                    />
                    <CustomButton
                      variant={'transparent'}
                      style={{ backgroundColor: '#203568' }}
                      text={
                        loadingDownloadPrint.coaPrint
                          ? 'Downloading...'
                          : 'Print C.O.A'
                      }
                      onClick={() =>
                        handleDownloadPrint('coa', 'print', detail?.id)
                      }
                    />
                  </>
                )}
              </div>
            </ActionButton>
          )}
        </Row>
        <Row className="mb-4 mt-5 mt-md-0 mt-lg-0 d-flex flex-row gap-3 gap-md-0 gap-lg-0">
          <Col md={3}>
            <Form.Label
              style={titleStyle}
              className="fw-bold text-secondary text-uppercase"
            >
              <small>series</small>
            </Form.Label>
            <Form.Control disabled placeholder={detail?.series_number} />
          </Col>
          <Col md={3}>
            <Form.Label
              style={titleStyle}
              className="fw-bold text-secondary text-uppercase"
            >
              <small>source warehouse</small>
            </Form.Label>
            <Form.Control disabled placeholder={detail?.src_warehouse} />
          </Col>
          <Col md={3}>
            <Form.Label
              style={titleStyle}
              className="fw-bold text-secondary text-uppercase"
            >
              <small>target warehouse</small>
            </Form.Label>
            <Form.Control disabled placeholder={detail?.target_warehouse} />
          </Col>
          <Col md={3}>
            <Form.Label
              style={titleStyle}
              className="fw-bold text-secondary text-uppercase"
            >
              <small>#erp doc no</small>
            </Form.Label>
            <Form.Control
              disabled
              placeholder={detail?.erp?.erp_status ?? '-'}
            />
          </Col>
          <Col md={2}>
            <Form.Label
              style={titleStyle}
              className="fw-bold text-secondary text-uppercase mt-2"
            >
              <small>delivery date</small>
            </Form.Label>
            <Form.Control
              disabled
              placeholder={convertDateWithoutTime(detail?.delivery_date)}
            />
          </Col>
          <Col md={2}>
            <Form.Label
              style={titleStyle}
              className="fw-bold text-secondary text-uppercase mt-2"
            >
              <small>#MR</small>
            </Form.Label>
            <Form.Control disabled placeholder={detail?.mr} />
          </Col>
        </Row>
        <Row>
          <Col>
            <h5 className="text-secondary fw-semibold mb-3">REQUEST</h5>
            <Table
              hover
              size="m"
              responsive="sm"
              className="shadow-sm mb-5 bg-white rounded"
              style={{ fontSize: '13px' }}
            >
              <thead className="table-secondary">
                <tr>
                  <th className="p-3 align-middle">#</th>
                  <th className="p-3 align-middle">Item</th>
                  <th className="p-3 align-middle">Item Code</th>
                  <th className="p-3 text-end">Qty</th>
                </tr>
              </thead>
              <tbody>
                {!detail?.requests.length && (
                  <tr>
                    <td className="p-3" colSpan={4}>
                      No Data
                    </td>
                  </tr>
                )}
                {detail?.requests.length &&
                  detail?.requests.map((request, index) => (
                    <tr key={request.item_code}>
                      <td className="p-3">{index + 1}</td>
                      <td className="p-3">{request.item_name}</td>
                      <td className="p-3">{request.item_code}</td>
                      <td className="p-3 text-end">{request.qty}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col md={6}>
            <h5 className="text-secondary fw-semibold mb-3">PICK UP COMMAND</h5>
          </Col>
          <Col md={6} hidden={detail?.status !== 'problem'}>
            <div
              className="d-flex flex-row justify-content-end"
              style={{ marginTop: '-0.5rem' }}
            >
              <CustomButton
                variant={'transparent'}
                style={{ backgroundColor: '#F89D1D' }}
                text={'Add'}
                onClick={() => {
                  setEditData({
                    type: 'create',
                    command_id: null,
                    item_code: null,
                    item_alias: null,
                    item_batch_id: null,
                    total_pack: null,
                    exp_date: null,
                    qty: 0
                  })
                  setShowModalAddUpdateItem(true)
                }}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Table
              hover
              size="m"
              responsive="sm"
              className="shadow-sm mb-5 bg-white rounded"
              style={{ fontSize: '13px' }}
            >
              <thead className="table-secondary">
                <tr>
                  <th className="p-3 align-middle">#</th>
                  <th className="p-3 align-middle">Item</th>
                  <th className="p-3 align-middle">Item Code</th>
                  <th className="p-3 align-middle text-end">Batch</th>
                  <th className="p-3 align-middle text-end">Expired</th>
                  <th className="p-3 align-middle text-end">Total Qty</th>
                  <th className="p-3 align-middle text-end">Total Pack</th>
                  <th className="p-3 align-middle text-center">Location</th>
                  {!detail?.erp?.erp_status && (
                    <th className="p-3 align-middle">Action</th>
                  )}
                </tr>
              </thead>
              <tbody>
                {!detail?.pickup_command.length && (
                  <tr>
                    <td className="p-3" colSpan={9}>
                      No Data
                    </td>
                  </tr>
                )}
                {detail?.pickup_command.length &&
                  detail?.pickup_command.map((pickup_command, index) => (
                    <tr key={pickup_command.item_code}>
                      <td className="p-3">{index + 1}</td>
                      <td className="p-3">{pickup_command.item_name}</td>
                      <td className="p-3">{pickup_command.item_code}</td>
                      <td className="p-3 text-end">{pickup_command.batch}</td>
                      <td className="p-3 text-end">
                        {convertDateWithoutTime(pickup_command.exp_date)}
                      </td>
                      <td className="p-3 text-end">
                        {pickup_command.qty} {pickup_command.uom}
                      </td>
                      <td className="p-3 text-end">
                        {/* {pickup_command.total_pack} */}
                        {!pickup_command?.total_pack
                          ? '-'
                          : parseInt(pickup_command?.total_pack)}
                        {pickup_command.report_pack > 0 && (
                          <span className="text-danger ms-2">
                            ({`-${pickup_command?.report_pack}`})
                          </span>
                        )}
                      </td>
                      <td
                        className="p-3 text-primary cursor-pointer text-truncate"
                        role="button"
                        title={pickup_command?.location}
                        style={{
                          maxWidth: '20px'
                        }}
                      >
                        <Link
                          to={'/outbound/transfer/location'}
                          state={{
                            slotLocations: detail?.slot_locations,
                            batch: pickup_command?.batch,
                            trCode: detail?.draft_code,
                            trId: detail?.id
                          }}
                          className="text-decoration-none"
                        >
                          {!pickup_command.location
                            ? '-'
                            : pickup_command.location}
                        </Link>
                      </td>
                      {!detail?.erp?.erp_status && (
                        <td className="p-3">
                          <div className="d-flex flex-row gap-2 justify-content-end">
                            <button
                              className={`btn ${
                                !pickup_command?.is_new_command
                                  ? 'btn-secondary'
                                  : 'btn-danger'
                              }`}
                              onClick={() =>
                                handleDeleteAddItemPickupCommand(
                                  pickup_command?.command_id
                                )
                              }
                              disabled={!pickup_command?.is_new_command}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </button>
                            <button
                              className="btn btn-secondary"
                              disabled={!pickup_command?.is_new_command}
                              onClick={() => {
                                setShowModalAddUpdateItem(true)
                                setEditData({
                                  type: 'update',
                                  command_id: pickup_command?.command_id,
                                  item_code: pickup_command?.item_code,
                                  item_alias: pickup_command?.item_alias ?? '-',
                                  item_batch_id: pickup_command?.item_batch_id,
                                  total_pack: pickup_command?.total_pack,
                                  exp_date: convertDateWithoutTime(
                                    pickup_command?.expired
                                  ),
                                  qty: pickup_command?.qty
                                })
                              }}
                            >
                              <FontAwesomeIcon icon={faPencil} />
                            </button>
                          </div>
                        </td>
                      )}
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
        {detail?.is_finish_loading && !detail?.erp?.erp_status && (
          <>
            <Row className="mb-3">
              <Col md={6} hidden={!detail?.erp?.err_notes ? true : false}>
                <p>
                  {detail?.erp?.description}, status:
                  <span
                    style={{
                      color:
                        detail?.erp?.erp_status === true ? 'green' : '#C12727'
                    }}
                    className="ms-2 fw-bold text-uppercase"
                  >
                    {detail?.erp?.erp_status === true ? 'Success' : 'Failed'}
                  </span>
                </p>
                <div className="d-flex flex-row gap-2">
                  <span>Failed Reason: </span>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: detail?.erp?.err_notes
                    }}
                  ></div>
                </div>
              </Col>
              <Col
                md={!detail?.erp?.err_notes ? 12 : 6}
                className="d-flex flex-row justify-content-end"
              >
                <div className="d-flex flex-column justify-content-center">
                  <CustomButton
                    variant={'transparent'}
                    style={{ backgroundColor: '#F89D1D' }}
                    text={'Send to ERP'}
                    onClick={() => handleSendToErp()}
                  />
                </div>
              </Col>
            </Row>
            <div
              style={{ height: '1rem', borderTop: '2px solid #F1F2F7' }}
            ></div>
          </>
        )}
        {detail?.progress_detail.length > 0 && (
          <>
            <Row className="bg-white rounded">
              <Col md={12} className="mb-3">
                <h5 className="fw-bold text-uppercase">Progress Detail</h5>
              </Col>
              <ul className="list-unstyled">
                {detail?.progress_detail?.length > 0 &&
                  detail?.progress_detail?.map((progress) => (
                    <li>
                      <div className="d-flex flex-column flex-md-row flex-lg-row gap-3 progress-detail">
                        <div className="d-flex flex-row gap-2 gap-md-3 gap-lg-3">
                          <div className="circle-progress-detail"></div>
                          <p className="fs-6">
                            {convertDateWithTime(progress?.datetime)}
                          </p>
                        </div>
                        <div
                          className={`${styles.subProgressDetail} d-flex flex-column justify-content-center`}
                          style={{ height: '13px' }}
                        >
                          <div className="d-flex flex-row gap-3">
                            <div className="circle-small-progress-detail mt-2 ms-1"></div>
                            <span style={{ fontSize: '13px' }}>
                              {progress?.description}
                            </span>
                          </div>
                        </div>
                      </div>
                      <ul
                        className="list-unstyled"
                        style={{ marginLeft: '2rem' }}
                      >
                        {progress?.detail?.length > 0 &&
                          progress?.detail?.map((detail) => (
                            <li
                              style={{
                                color: `${
                                  detail.color ? detail.color : 'black'
                                } `
                              }}
                              className="mb-3"
                            >
                              {detail?.description}
                            </li>
                          ))}
                      </ul>
                    </li>
                  ))}
              </ul>
            </Row>
          </>
        )}
        {detail?.status !== 'unassigned' && (
          <Row>
            <Label label="PNP" value={detail?.checker?.pnp ?? '-'} col={4} />
            <Label label="RNR" value={detail?.checker?.rnr ?? '-'} col={4} />
          </Row>
        )}
        <Row className="p-2">
          <Card className="p-3">
            <Card.Body className="gap-3 p-0">
              <Row>
                <Col xs={12} md={3} lg={3}>
                  <Form.Label
                    style={titleStyle}
                    className="fw-bold text-secondary text-uppercase"
                  >
                    <small>created by</small>
                  </Form.Label>
                  <Form.Control
                    disabled
                    placeholder={detail?.created_by ?? '-'}
                  />
                </Col>
                <Col xs={12} md={3} lg={3}>
                  <Form.Label
                    style={titleStyle}
                    className="fw-bold text-secondary text-uppercase"
                  >
                    <small>created at</small>
                  </Form.Label>
                  <Form.Control
                    disabled
                    placeholder={convertDateWithTime(detail?.createdAt)}
                  />
                </Col>
                <Col xs={12} md={3} lg={3}>
                  <Form.Label
                    style={titleStyle}
                    className="fw-bold text-secondary text-uppercase"
                  >
                    <small>last update by</small>
                  </Form.Label>
                  <Form.Control
                    disabled
                    placeholder={detail?.updated_by ?? '-'}
                  />
                </Col>
                <Col xs={12} md={3} lg={3}>
                  <Form.Label
                    style={titleStyle}
                    className="fw-bold text-secondary text-uppercase"
                  >
                    <small>last update at</small>
                  </Form.Label>
                  <Form.Control
                    disabled
                    placeholder={convertDateWithTime(detail?.updatedAt)}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Row>
        <ModalSolveContinueTicket
          show={showModalSolveContinueTicket}
          onHide={() => setShowModalSolveContinueTicket(false)}
          handleSubmitSolveContinueTicket={() =>
            handleSubmitSolveContinueTicket('modal')
          }
          submitSolveContinueTicket={submitSolveContinueTicket}
        />
        <ModalSelectFormat
          show={showModalSelectFormat}
          handleClose={() => {
            setShowModalSelectFormat(false)
            setSelectFormatDownload((prevSelectFormatDownload) => ({
              ...prevSelectFormatDownload,
              id: null,
              format: null
            }))
          }}
          setSelectFormatDownload={setSelectFormatDownload}
          selectFormatDownload={selectFormatDownload}
          handleDownloadPrint={handleDownloadPrint}
          loadingFormat={loadingFormat}
        />
        <ModalAddUpdateItem
          show={showModalAddUpdateItem}
          onHide={() => setShowModalAddUpdateItem(false)}
          data={editData}
          reportPickupCommand={pickupCommand?.filter(
            (item) => item.report_pack > 0
          )}
          pickupCommand={pickupCommand}
          command={detail?.requests}
          sjDraftId={detail?.id}
          options={pickupCommand
            ?.filter((item) => item.report_pack > 0)
            .reduce((acc, cur) => {
              acc.push({
                id: cur?.item_code,
                label: cur?.item_name
              })
              return acc
            }, [])}
          refreshData={() => fetchDetailTransfer()}
        />
      </Container>
    </>
  )
}

export default DetailTransfer
