import { faPen, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Container, Form, Row, Table } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import Select from 'react-select'
import Loader from '../../../components/atoms/Loader'
import RowEmpty from '../../../components/atoms/RowEmpty'
import TableAction from '../../../components/atoms/Table/TableAction'
import TablePagination from '../../../components/atoms/Table/TablePagination'
import InputDatePicker from '../../../components/Input/DatePicker/InputDatePicker'
import api from '../../../config/AxiosInstance'
import {
  convertDateWithoutTime,
  DELIVERY_TYPES
} from '../../../helpers/constants'
import '../../../styles/style.css'
import * as Common from '../../../utils/Common'
import { firstLetterUpperCase } from '../../../utils/TextFormatter'
const headerTitleStyle = {
  color: 'var(--primary-orange)',
  fontSize: '24px',
  fontWeight: '600'
}

const titleStyle = {
  fontSize: '12px',
  letterSpacing: '0.5px'
}

const btnOrange = {
  backgroundColor: 'var(--primary-orange)',
  borderColor: 'var(--primary-orange)',
  color: 'white'
}

function Index() {
  const [isLoading, setIsLoading] = useState(false)
  const [prDraftList, setPrDraftList] = useState([])
  const [driver, setDriver] = useState('')
  const [pageCount, setPageCount] = useState(0)
  const [meta, setMeta] = useState({
    per_page: 10,
    current_page: 1,
    total_row: 0,
    total_page: 0
  })
  const [poName, setPoName] = useState(0)
  const [selectedWarehouse, setSelectedWarehouse] = useState('')
  const [warehouses, setWarehouses] = useState([])
  const refSrcWarehouses = useRef()
  const [selectedCustomers, setSelectedCustomers] = useState('')
  const [customers, setCustomers] = useState([])
  const refSrcCustomers = useRef()
  const [selectedSjDraft, setSelectedSjDraft] = useState('')
  const [sjDraft, setSjDraft] = useState([])
  const refSrcSjDraft = useRef()
  const [selectedSo, setSelectedSo] = useState('')
  const [loadingSo, setLoadingSo] = useState(false)
  const [loadingSj, setLoadingSj] = useState(false)
  const [loadingWarehouse, setLoadingWarehouse] = useState(false)
  const [loadingCustomer, setLoadingCustomer] = useState(false)
  const [so, setSo] = useState([])
  const refSrcSo = useRef()
  const [deliveryType, setDeliveryType] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [applyFilter, setApplyFilter] = useState(false)
  const { per_page, current_page } = meta

  useEffect(() => {
    fetchDcList(
      selectedWarehouse,
      selectedCustomers,
      selectedSjDraft,
      selectedSo,
      startDate,
      endDate,
      deliveryType,
      driver
    )
  }, [per_page, current_page, applyFilter])

  const loadWarehouseOptions = async (inputValue) => {
    try {
      setLoadingWarehouse(true)
      const res = await api.get('/erp/sales-order/all-source-wr', {
        params: {
          q: inputValue
        }
      })

      if (res.data.success) {
        const options = res.data.data.reduce((acc, data) => {
          acc.unshift({
            value: data.warehouse,
            label: data.warehouse
          })
          return acc
        }, [])
        setWarehouses(options)
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setLoadingWarehouse(false)
    }
  }
  const loadWarehouseFocusOptions = (inputValue) => {
    loadWarehouseOptions(inputValue)
  }

  const loadCustomerOptions = async (inputValue) => {
    try {
      setLoadingCustomer(true)
      const res = await api.get('/erp/sales-order/all-customer', {
        params: {
          q: inputValue
        }
      })

      if (res.data.success) {
        const options = res.data.data.reduce((acc, data) => {
          acc.unshift({
            value: data.customer,
            label: data.customer
          })
          return acc
        }, [])
        setCustomers(options)
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setLoadingCustomer(false)
    }
  }
  const loadCustomerFocusOptions = (inputValue) => {
    loadCustomerOptions(inputValue)
  }

  async function fetchDcList(
    selectedWarehouse,
    selectedCustomers,
    selectedSjDraft,
    selectedSo,
    startDate,
    endDate,
    deliveryType,
    driver
  ) {
    try {
      setIsLoading(true)
      const res = await api.get(
        `outbound/dcdrafts/list?per_page=${meta.per_page}&page=${meta.current_page}&src=${selectedWarehouse}&customer=${selectedCustomers}&erp_sj_no=${selectedSjDraft}&so=${selectedSo}&start_date=${startDate}&end_date=${endDate}&delivery_type=${deliveryType}&driver=${driver}`
      )
      const { metadata, data } = res.data
      setPrDraftList(data)
      setMeta(metadata)
      setPageCount(metadata.total_page || 0)
    } catch (error) {
      const errMsg =
        error?.response?.data?.errors?.message || 'An error occurred'

      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setIsLoading(false)
    }
  }

  // handle page
  function handleOnChangeMeta(e) {
    const perPageValue = parseInt(e.target.value, 10)
    setMeta((prevMeta) => ({ ...prevMeta, per_page: perPageValue }))
  }

  const handlePageChange = (data) => {
    setMeta((prevMeta) => ({
      ...prevMeta,
      current_page: parseInt(data.selected + 1)
    }))
    window.scrollTo(0, 0)
  }

  const navigate = useNavigate()
  const navigateToDetail = (dc) => {
    const encodedName = encodeURIComponent(dc.dc_id)
    navigate(`/outbound/dc/${encodedName}`)
  }

  // handle selected
  const handleSelected = () => {
    try {
      const page = 1
      setApplyFilter((prevApplyFilter) => !prevApplyFilter)
      setMeta((prevMeta) => ({
        ...prevMeta,
        current_page: page
      }))
    } catch (error) {
      const errMsg =
        error?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }

  function handleReset() {
    setMeta((prevMeta) => ({
      ...prevMeta,
      per_page: 10,
      current_page: 1
    }))
    refSrcWarehouses.current?.clearValue()
    refSrcCustomers.current?.clearValue()
    refSrcSo.current?.clearValue()
    refSrcSjDraft.current?.clearValue()
    setStartDate('')
    setEndDate('')
    setDeliveryType('')
    setDriver('')
  }
  const loadSoOptions = async (inputValue) => {
    try {
      setLoadingSo(true)
      const res = await api.get('/erp/sales-order/all', {
        params: {
          q: inputValue
        }
      })

      if (res.data.success) {
        const options = res.data.data.reduce((acc, data) => {
          acc.unshift({
            value: data.so,
            label: data.so
          })
          return acc
        }, [])
        setSo(options)
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setLoadingSo(false)
    }
  }
  const loadSoFocusOptions = (inputValue) => {
    loadSoOptions(inputValue)
  }
  const loadSjOptions = async (inputValue) => {
    try {
      setLoadingSj(true)
      const res = await api.get('/outbound/sjdraft/all-draft', {
        params: {
          q: inputValue
        }
      })

      if (res.data.success) {
        const options = res.data.data.reduce((acc, data) => {
          acc.unshift({
            value: data.draft_code,
            label: data.draft_code
          })
          return acc
        }, [])
        setSjDraft(options)
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setLoadingSj(false)
    }
  }
  const loadSjFocusOptions = (inputValue) => {
    loadSjOptions(inputValue)
  }

  return (
    <>
      <Container>
        <Row>
          <Col md={6}>
            <h3 style={headerTitleStyle}>Delivery Confirmation</h3>
          </Col>
        </Row>
        {/* FILTER */}
        <Row className="mt-3">
          <Col md={3}>
            <Form.Group>
              <Form.Label className="fw-bold text-secondary" style={titleStyle}>
                <small>SOURCE</small>
              </Form.Label>
              <Select
                defaultValue={selectedWarehouse}
                isSearchable={true}
                isClearable={true}
                isLoading={loadingWarehouse}
                loadingMessage={() => 'Loading...'}
                options={warehouses}
                defaultOptions
                placeholder="Select Warehouse"
                key={1}
                autoLoad={false}
                onFocus={() => loadWarehouseFocusOptions('')}
                onInputChange={(e) => {
                  loadWarehouseFocusOptions(e)
                }}
                ref={refSrcWarehouses}
                onChange={(e) => {
                  if (!e) {
                    e = {
                      target: refSrcWarehouses,
                      value: ''
                    }
                  }
                  setSelectedWarehouse(e?.value)
                }}
              />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group>
              <Form.Label className="fw-bold text-secondary" style={titleStyle}>
                <small>CUSTOMER</small>
              </Form.Label>
              <Select
                defaultValue={selectedCustomers}
                isSearchable={true}
                isClearable={true}
                isLoading={loadingCustomer}
                loadingMessage={() => 'Loading...'}
                options={customers}
                defaultOptions
                placeholder="Select Customers"
                key={1}
                autoLoad={false}
                onFocus={() => loadCustomerFocusOptions('')}
                onInputChange={(e) => {
                  loadCustomerFocusOptions(e)
                }}
                ref={refSrcCustomers}
                onChange={(e) => {
                  if (!e) {
                    e = {
                      target: refSrcCustomers,
                      value: ''
                    }
                  }
                  setSelectedCustomers(e?.value)
                }}
              />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group>
              <Form.Label className="fw-bold text-secondary" style={titleStyle}>
                <small>#ERP SJ NO.</small>
              </Form.Label>
              <Select
                defaultValue={selectedSjDraft}
                isSearchable={true}
                isClearable={true}
                isLoading={loadingSj}
                loadingMessage={() => 'Loading...'}
                options={sjDraft}
                defaultOptions
                placeholder="Select SJ Draft"
                key={1}
                autoLoad={false}
                onFocus={() => loadSjFocusOptions('')}
                onInputChange={(e) => {
                  loadSjFocusOptions(e)
                }}
                ref={refSrcSjDraft}
                onChange={(e) => {
                  if (!e) {
                    e = {
                      target: refSrcSjDraft,
                      value: ''
                    }
                  }
                  setSelectedSjDraft(e?.value)
                }}
              />
            </Form.Group>
          </Col>
          <Col md={3} className="mb-4">
            <Form.Group>
              <Form.Label className="fw-bold text-secondary" style={titleStyle}>
                <small>#SO</small>
              </Form.Label>
              <Select
                defaultValue={selectedSo}
                isClearable={true}
                isSearchable={true}
                isLoading={loadingSo}
                loadingMessage={() => 'Loading...'}
                options={so}
                defaultOptions
                placeholder="Select So"
                key={1}
                autoLoad={false}
                onFocus={() => loadSoFocusOptions('')}
                onInputChange={(e) => {
                  loadSoFocusOptions(e)
                }}
                ref={refSrcSo}
                onChange={(e) => {
                  if (!e) {
                    e = {
                      target: refSrcSo,
                      value: ''
                    }
                  }
                  setSelectedSo(e?.value)
                }}
              />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Label className="fw-bold text-secondary" style={titleStyle}>
              <small>START DATE</small>
            </Form.Label>
            <InputDatePicker
              value={startDate}
              onChange={(date) => {
                setStartDate(moment(date).format('YYYY-MM-DD'))
              }}
            />
          </Col>
          <Col md={3}>
            <Form.Group>
              <Form.Label className="fw-bold text-secondary" style={titleStyle}>
                <small>END DATE</small>
              </Form.Label>
              <InputDatePicker
                value={endDate}
                onChange={(date) => {
                  setEndDate(moment(date).format('YYYY-MM-DD'))
                }}
              />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group>
              <Form.Label className="fw-bold text-secondary" style={titleStyle}>
                <small>DELIVERY TYPE</small>
              </Form.Label>
              <Form.Select
                aria-label="Select Warehouse"
                name="delivery_type"
                id="delivery_type"
                onChange={(e) => setDeliveryType(e.target.value)}
                value={deliveryType}
              >
                <option value="">All</option>
                {DELIVERY_TYPES.map((devType, index) => (
                  <option value={devType.value} key={devType.id}>
                    {devType.label}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Label className="fw-bold text-secondary" style={titleStyle}>
              <small>DRIVER</small>
            </Form.Label>
            <Form.Control
              type="text"
              name="driver"
              id="driver"
              placeholder="Input"
              onChange={(e) => setDriver(e.target.value)}
              value={driver}
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md={12} className="text-end">
            <Button
              type="button"
              className="btn btn-secondary text-white me-2"
              onClick={() => handleReset()}
            >
              Reset Filter
            </Button>
            <Button onClick={() => handleSelected()} style={btnOrange}>
              Apply Filter
            </Button>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={12}>
            <Table
              responsive="sm"
              className="shadow-sm mb-5"
              style={{ fontSize: '13px' }}
              hover
            >
              <thead className="table-secondary">
                <tr>
                  <th className="p-2 align-middle">Act</th>
                  <th className="p-2 align-middle">WMS DC No.</th>
                  <th className="p-2 align-middle">ERP SJ No.</th>
                  <th className="p-2 align-middle">ERP DC No.</th>
                  <th className="p-2 align-middle">#SO</th>
                  <th className="p-2 align-middle">Driver</th>
                  <th className="p-2 align-middle">Customer</th>
                  <th className="p-2 align-middle">Source</th>
                  <th className="p-2 align-middle">Delivery Type</th>
                  <th className="p-2 align-middle">Delivery Date</th>
                </tr>
              </thead>
              <tbody>
                {isLoading && (
                  <tr>
                    <td colSpan={10}>
                      <Loader />
                    </td>
                  </tr>
                )}
                {!isLoading && prDraftList.length === 0 && (
                  <RowEmpty colSpan={10} />
                )}
                {!isLoading &&
                  prDraftList?.map((data, i) => {
                    return (
                      <>
                        <tr key={i}>
                          <td>
                            <TableAction direction="start">
                              <button
                                type="button"
                                className="btn btn-secondary ml-2"
                                onClick={() => {
                                  navigateToDetail(data)
                                }}
                                code={poName}
                              >
                                <FontAwesomeIcon
                                  icon={data?.erp_dc_no ? faSearch : faPen}
                                />
                              </button>
                            </TableAction>
                          </td>
                          <td>{data?.wms_dc_no || '-'}</td>
                          <td>{data?.erp_sj_no || '-'}</td>
                          <td>{data?.erp_dc_no || '-'}</td>
                          <td>{data.so}</td>
                          <td>{data.driver}</td>
                          <td>{data.customer}</td>
                          <td>{data.source}</td>
                          <td>{firstLetterUpperCase(data.delivery_type)}</td>
                          <td>{convertDateWithoutTime(data.delivery_date)}</td>
                        </tr>
                      </>
                    )
                  })}
              </tbody>
              <tfoot>
                <tr>
                  <td className="p-3" colSpan={10}>
                    <TablePagination
                      meta={meta}
                      handlePageChange={handlePageChange}
                      handleOnChangeMeta={handleOnChangeMeta}
                    />
                  </td>
                </tr>
              </tfoot>
            </Table>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Index
