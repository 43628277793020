import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { Button, Col, Container, Form, Row, Table } from 'react-bootstrap'
import { Loader } from 'react-bootstrap-typeahead'
import { useLocation, useNavigate } from 'react-router-dom'
import TableAction from '../../components/atoms/Table/TableAction'
import TablePagination from '../../components/atoms/Table/TablePagination'
import Notification from '../../components/Notifications/Notification'
import api from '../../config/AxiosInstance'
import { convertDateWithoutTime } from '../../helpers/constants'
import * as Common from '../../utils/Common'
const MaterialRequest = () => {
  const initialMeta = {
    per_page: 10,
    current_page: 1,
    total_row: 0,
    total_page: 0
  }
  const headerTitleStyle = {
    color: 'var(--primary-orange)',
    fontSize: '24px',
    fontWeight: '600'
  }
  const titleStyle = {
    fontSize: '12px',
    letterSpacing: '0.5px'
  }
  const btnOrange = {
    backgroundColor: 'var(--primary-orange)',
    borderColor: 'var(--primary-orange)',
    color: 'white'
  }
  const navigate = useNavigate()
  const { state } = useLocation()

  const [warehouses, setWarehouses] = useState([])
  const [targetWarehouses, setTargetWarehouses] = useState([])
  const [successNotification, setSuccessNotification] = useState(false)
  const [filter, setFilter] = useState({
    target_warehouse: null,
    src_warehouse: null
  })
  const [meta, setMeta] = useState(initialMeta)
  const [materialRequests, setMaterialRequests] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    fetchWarehouses()
    fetchTargetWarehouses()
    if (state?.sendNotificationSuccess) {
      setSuccessNotification(true)
      const time = setTimeout(() => {
        setSuccessNotification(false)
      }, 5000)
      return () => clearTimeout(time)
    }
  }, [state?.sendNotificationSuccess])
  useEffect(() => {
    fetchMaterialRequest()
  }, [meta.per_page, meta.current_page])
  const fetchWarehouses = async () => {
    try {
      const response = await api.get(
        '/master/warehouses/all?is_parent=true&forUser=1'
      )
      const { data } = response
      if (data.success) {
        setWarehouses(data.data)
      }
    } catch (error) {
      const errMsg =
        error?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }
  const fetchTargetWarehouses = async () => {
    try {
      const response = await api.get('/master/warehouses/all?is_parent=true')
      const { data } = response
      if (data.success) {
        setTargetWarehouses(data.data)
      }
    } catch (error) {
      const errMsg =
        error?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }

  const fetchMaterialRequest = async () => {
    try {
      setIsLoading(true)
      const response = await api.get('/material-request/list', {
        params: {
          target_warehouse: filter.target_warehouse,
          src_warehouse: filter.src_warehouse,
          per_page: meta.per_page,
          page: meta.current_page
        }
      })
      const { data } = response

      if (data.success) {
        setMeta(data.metadata)
        setMaterialRequests(data.data)
      }
    } catch (error) {
      const errMsg =
        error?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setIsLoading(false)
    }
  }
  const handleOnChangeMeta = (e) => {
    setMeta((prevMeta) => ({
      ...prevMeta,
      per_page: parseInt(e.target.value, 10),
      current_page: 1
    }))
  }

  const handlePageChange = (data) => {
    setMeta((prevMeta) => ({
      ...prevMeta,
      current_page: data.selected + 1
    }))
    window.scrollTo(0, 0)
  }

  return (
    <>
      <Container>
        <Row>
          <Col md={8}>
            <h1 style={headerTitleStyle}>Material Request</h1>
          </Col>
          {successNotification && (
            <Notification
              type="success"
              text="Success!"
              handleClose={() => setSuccessNotification(false)}
            />
          )}
        </Row>
        <Row className="mt-4">
          <Col md={4}>
            <Form.Label
              className="fw-semibold text-secondary text-uppercase"
              style={titleStyle}
            >
              <small>source warehouse</small>
            </Form.Label>
            <Form.Select
              name="source warehouse"
              value={filter.src_warehouse}
              onChange={(e) =>
                setFilter((prevFilter) => ({
                  ...prevFilter,
                  src_warehouse: e.target.value
                }))
              }
            >
              <option value="">All</option>
              {warehouses.map((warehouse) => (
                <option
                  value={warehouse.warehouse_id}
                  key={warehouse.warehouse_id}
                >
                  {warehouse.warehouse_name}
                </option>
              ))}
            </Form.Select>
          </Col>
          <Col md={4}>
            <Form.Label
              className="fw-semibold text-secondary text-uppercase"
              style={titleStyle}
            >
              <small>target warehouse</small>
            </Form.Label>
            <Form.Select
              name="target warehouse"
              value={filter.target_warehouse}
              onChange={(e) =>
                setFilter((prevFilter) => ({
                  ...prevFilter,
                  target_warehouse: e.target.value
                }))
              }
            >
              <option value="">All</option>
              {targetWarehouses.map((targetWarehouse) => (
                <option
                  value={targetWarehouse.warehouse_id}
                  key={targetWarehouse.warehouse_id}
                >
                  {targetWarehouse.warehouse_name}
                </option>
              ))}
            </Form.Select>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={6}></Col>
          <Col md={6} className="text-end">
            <Button
              variant="transparent"
              className="btn text-secondary me-2"
              onClick={() => {
                setFilter((prevFilter) => ({
                  ...prevFilter,
                  src_warehouse: '',
                  target_warehouse: ''
                }))
              }}
            >
              Reset Filter
            </Button>
            <Button style={btnOrange} onClick={() => fetchMaterialRequest()}>
              Apply Filter
            </Button>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={12}>
            <Table
              hover
              size="m"
              responsive="sm"
              className="shadow-sm mb-5 bg-white rounded"
              style={{ fontSize: '13px' }}
            >
              <thead className="table-secondary">
                <tr>
                  <th className="p-3 align-middle">#MR</th>
                  <th className="p-3 align-middle">Source Warehouse</th>
                  <th className="p-3 align-middle">Target Warehouse</th>
                  <th className="p-3 align-middle">Delivery Date</th>
                  <th className="p-3 align-middle">Action</th>
                </tr>
              </thead>
              <tbody>
                {isLoading && (
                  <td colSpan={5} className="text-center">
                    <div className="d-flex flex-row justify-content-center p-2">
                      <Loader />
                    </div>
                  </td>
                )}
                {!isLoading && materialRequests.length === 0 && (
                  <td colSpan={5} className="text-center fw-bold">
                    No data
                  </td>
                )}
                {!isLoading &&
                  materialRequests.length > 0 &&
                  materialRequests.map((item) => (
                    <>
                      <tr key={item.id}>
                        <td className="p-3 align-middle">{item.code ?? '-'}</td>
                        <td className="p-3 align-middle">
                          {item.src_name ?? '-'}
                        </td>
                        <td className="p-3 align-middle">
                          {item.target_name ?? '-'}
                        </td>
                        <td className="p-3 align-middle">
                          {item.delivery_date
                            ? convertDateWithoutTime(item?.delivery_date)
                            : '-'}
                        </td>
                        <td className="p-3 align-middle">
                          <TableAction>
                            <button
                              className="btn btn-secondary"
                              onClick={() =>
                                navigate(
                                  `/material-request/detail/${encodeURIComponent(
                                    item.code
                                  )}`
                                )
                              }
                            >
                              <FontAwesomeIcon icon={faMagnifyingGlass} />
                            </button>
                            <button
                              className="btn btn-primary-blue"
                              onClick={() =>
                                navigate(
                                  `/material-request/create/${encodeURIComponent(
                                    item.code
                                  )}`
                                )
                              }
                            >
                              +TR
                            </button>
                          </TableAction>
                        </td>
                      </tr>
                    </>
                  ))}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={5}>
                    <TablePagination
                      meta={meta}
                      handleOnChangeMeta={handleOnChangeMeta}
                      handlePageChange={handlePageChange}
                    />
                  </td>
                </tr>
              </tfoot>
            </Table>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default MaterialRequest
