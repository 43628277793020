import {
  faCaretDown,
  faCaretUp,
  faCartShopping,
  faCogs,
  faDatabase,
  faFileArchive,
  faGear,
  faReceipt,
  faTruckFast
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext, useEffect, useState } from 'react'
import { Col, Collapse, Nav, Offcanvas, Row } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import baj_logo from '../../assets/baj_logo.png'
import { UserContext } from '../../context/userContext'
function Drawer(props) {
  const headMenuStyle = {
    backgroundColor: 'var(--primary-orange)',
    color: 'white'
  }
  const headMenuStyleHover = {
    color: 'var(--primary-grey)'
  }
  const { showCanvas, handleCloseCanvas } = props
  const navigate = useNavigate()
  const [state, dispatch] = useContext(UserContext)
  const subMenusData = state.username?.access.reduce((acc, cur) => {
    acc.push({ ...cur, collapse: false })
    return acc
  }, [])
  const [sidebars, setSidebars] = useState(subMenusData)
  const { pathname } = useLocation()
  const headMenu = pathname.split('/')[1]
  const poHeadMenu = pathname.split('/')[2]
  const routeValue = `${headMenu}/${poHeadMenu}`

  // read icon menu
  const icons = (value) => {
    let icon = null
    switch (value) {
      case 'Purchase Order':
        icon = faCartShopping
        break
      case 'Arrival Plan':
      case 'Material Request':
        icon = faReceipt
        break
      case 'Inbound':
      case 'Outbound Plan':
      case 'Outbound':
        icon = faTruckFast
        break
      case 'Master':
        icon = faDatabase
        break
      case 'Setting':
        icon = faGear
        break
      case 'CMS':
        icon = faCogs
        break
      case 'Work Order Plan':
        icon = faFileArchive
        break
      default:
        icon = faCaretUp
    }
    return icon
  }
  const collapseAction = (action) => {
    setSidebars(
      sidebars.map((sidebar) =>
        sidebar.menu === action
          ? { ...sidebar, collapse: !sidebar.collapse }
          : sidebar
      )
    )
  }
  const regexSidebarActive = (menu, pathname) => {
    const pattern = menu.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
    const patternRegex = new RegExp(`^${pattern}(\\/.*)?$`)
    return patternRegex.test(pathname)
  }
  useEffect(() => {
    setSidebars((prevSidebar) =>
      prevSidebar.map((value) =>
        value.menu?.split(' ')[0].toLowerCase() ===
          (headMenu === 'manage' ? 'cms' : headMenu) &&
        /^\/outbound\/plan(\/.*)?$/.test(pathname) === false
          ? { ...value, collapse: true }
          : value
      )
    )
  }, [pathname])
  return (
    <>
      <Offcanvas
        show={showCanvas}
        onHide={handleCloseCanvas}
        style={{ width: '80vw' }}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <img src={baj_logo} alt="baj" style={{ width: '50%' }} />
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {sidebars?.map((sidebar, index) =>
            !sidebar.submenu.length ? (
              <Col md={12} className="mb-3" key={sidebar.sequence}>
                <Row
                  style={
                    regexSidebarActive(`/${sidebar.slug}`, pathname)
                      ? headMenuStyle
                      : headMenuStyleHover
                  }
                  className="pt-2 pb-2 rounded-3"
                  key={sidebar.sequence}
                >
                  <div
                    className="d-flex flex-row gap-3 cursor-pointer"
                    role="button"
                    onClick={() => {
                      navigate(`/${sidebar.slug}`, { replace: true })
                      handleCloseCanvas()
                    }}
                  >
                    <FontAwesomeIcon
                      icon={icons(sidebar.menu)}
                      style={{ fontSize: '13px' }}
                      className="mt-1"
                    />
                    <small
                      className={`fw-bold cursor-pointer`}
                      style={{
                        fontSize: '14px'
                      }}
                    >
                      {sidebar.menu}
                    </small>
                  </div>
                </Row>
              </Col>
            ) : (
              <>
                <Col
                  md={12}
                  onClick={() => {
                    collapseAction(sidebar?.menu)
                  }}
                  role="button"
                  className="pointer-auto mb-3"
                  key={sidebar.sequence}
                >
                  <Row
                    style={
                      headMenu === sidebar?.submenu[0].slug?.split('/')[0] &&
                      /^\/outbound\/plan(\/.*)?$/.test(pathname) === false
                        ? headMenuStyle
                        : headMenuStyleHover
                    }
                    className="pt-2 pb-2 rounded-3"
                  >
                    <>
                      <div className="d-flex flex-row gap-3">
                        <FontAwesomeIcon
                          icon={icons(sidebar.menu)}
                          style={{ fontSize: '13px' }}
                          className="mt-1"
                        />
                        <small className="fw-bold fs-6">{sidebar.menu}</small>
                        <FontAwesomeIcon
                          icon={sidebar.collapse ? faCaretUp : faCaretDown}
                          aria-expanded={sidebar.collapse ? true : false}
                          aria-controls={sidebar.menu}
                          className="mt-1"
                        />
                      </div>
                    </>
                  </Row>
                  <Row>
                    <Col>
                      <Collapse
                        in={sidebar.collapse ? true : false}
                        key={sidebar.sequence}
                      >
                        <div id={sidebar.menu} key={sidebar.sequence}>
                          <Row className="ps-3 pe-3 pt-3">
                            {sidebar.submenu
                              .filter(
                                (valueSubmenu) =>
                                  valueSubmenu.menu !== 'Customer Delivery Plan'
                              )
                              .sort((a, b) => a.sequence - b.sequence)
                              .map((submenu, index) => (
                                <Nav.Link
                                  className={`${
                                    index === sidebar.submenu.length - 1 ||
                                    submenu.slug === 'outbound/history'
                                      ? 'mb-0'
                                      : 'mb-3'
                                  }`}
                                  onClick={() => {
                                    navigate(
                                      `${
                                        submenu.slug ? `/${submenu.slug}` : ''
                                      }`
                                    )
                                    handleCloseCanvas()
                                  }}
                                  disabled={!submenu.slug ? true : false}
                                  key={submenu.sequence}
                                >
                                  <small
                                    className={
                                      submenu.slug &&
                                      routeValue ===
                                        `${submenu.slug?.split('/')[0]}/${
                                          submenu.slug?.split('/')[1]
                                        }`
                                        ? `text-orange fw-bold`
                                        : 'text-secondary'
                                    }
                                    style={{
                                      fontSize: '14px'
                                    }}
                                  >
                                    {submenu.menu}
                                  </small>
                                </Nav.Link>
                              ))}
                          </Row>
                        </div>
                      </Collapse>
                    </Col>
                  </Row>
                </Col>
              </>
            )
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
}

export default Drawer
